
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const REPLENISH_LINE_BASE = "/replenishlines";
const buildUrl = (endpoint) => REPLENISH_LINE_BASE + endpoint;
const ApiName = "ReplenishLines";

const replenishLineApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReplenishLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}`}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getReplenishLines', `${ApiName}`)
        }),
    })
});

export const {
    useGetReplenishLinesQuery
  } = replenishLineApiSlice;