// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../../../../theme';
import { useSelector } from 'react-redux';
import { showToastMessage } from '../../../../../utils/toasts';
import { DropdownBase } from '../../../../theme/dropdowns/Dropdown';
import { useGetProductsSelectQuery } from '../../../../../newapi/warehouse/productSlice';
/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    
    const [newChildProducts, setNewChildProducts] = useState([{
        productId : 0,
        quantity: 1,
        index: 1
    }]);

    const handleChange = (index, key, value) => {
        if(key === "quantity" && value <= 0){
            showToastMessage("error", t("quantity_must_be_greater_than_0"));
            setNewChildProducts(currentProducts => {
                return currentProducts.map(product => {
                    if (product.index === index) {
                        return {
                            ...product,
                            [key]: 1    
                        };
                    }
                    return product;
                });
            });
            return;

        }
        setNewChildProducts(currentProducts => {
            return currentProducts.map(product => {
                if (product.index === index) {
                    return {
                        ...product,
                        [key]: value
                    };
                }
                return product;
            });
        });

        if (key === "productId") {
            setNewChildProducts(currentProducts => {
                if (value) {
                    if (index === currentProducts.length) {
                        return [...currentProducts, {
                            productId: 0,
                            quantity: 1,
                            index: currentProducts[currentProducts.length - 1].index + 1
                        }];
                    }
                } else {
                    showToastMessage("error", t("invalid_sku"));
                }
                return currentProducts;
            });
        }
    };

    const { data: products } = useGetProductsSelectQuery({relationId: data.relationId},
        {skip: !data.relationId}
    );
    
    useEffect(() => {
        const mappedChildProducts = newChildProducts
        .filter(x => x.productId > 0 && x.quantity > 0)
        .map(x => ({
            productId: x.productId,
            quantity: x.quantity
        }));
        props.handleDataUpdate({ "childProducts": mappedChildProducts });
    },[newChildProducts]);
      
    const handleDeleleteChildRow = (index) => {
        const updatedChildProducts = newChildProducts
            .filter(product => product.index !== index)
            .map((product, idx) => ({
                ...product,
                index: idx + 1
            }));
        setNewChildProducts(updatedChildProducts);
    }

    return (
        <Box
            sx={{
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                borderTopLeftRadius:16,
                borderTopRightRadius:16,
                display:"flex",
                flexDirection:"column",
                gap:2
            }}
        >
            {newChildProducts.map((childProduct, index) => (
            <Box key={"new-virtual-child-product-"+index} flex={1}
                sx={{
                    display:"flex",
                    gap:4,
                    justifyContent:"flex-start"
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:4
                    }}
                >
                    <Box
                        sx={{
                            width:40,
                        }}
                    >
                        <Text light>{t("product")}</Text>
                    </Box>
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            paddingLeft: 1,
                            paddingRight: 1,
                            height:32,
                            width:500
                        }}
                    >
                        <DropdownBase
                                            key={childProduct.product?.id}
                                            displayName={"combined"} 
                                            changeField={"id"}
                                            valueKey={"id"}
                                            onChange={(selected) => handleChange(childProduct.index, 'productId', selected)}
                                            noSelection={t("search_product_placeholder")} //Custom text when nothing is selected
                                            options={products} // array
                                            selected={products?.find(product => product.id === childProduct.productId)?.id ?? -1}
                                            closeOnSelection
                                            searchOptions={{
                                                enabled: true,
                                                placeholder: t("search_product_placeholder")
                                            }}
                                            styles={{
                                                minWidth:300
                                            }}
                                            disallowDeselection
                                        >
                                            <Box
                                                sx={{
                                                    flex:1,
                                                    // flexGrow:0,
                                                    display:"flex",
                                                    alignItems:"center",
                                                    justifyContent:"center",
                                                    //background:colorSettings.streamrulevalue,
                                                    height:32,
                                                    borderRadius:4,
                                                    "&:hover" : {
                                                        //background:colorSettings.streamrulevaluehover,
                                                        cursor:"pointer",
                                                    },
                                                    "@media screen and (max-width: 768px)" : {
                                                        // ml:1,
                                                    }
                                                }}
                                            >
                                                <Text noBreak>{products?.find(product => product.id === childProduct.productId)?.combined}</Text>
                                            </Box>
                                        </DropdownBase>
                        {/* <InputBase 
                            sx={{
                                px:2,
                                flex:1, 
                                color:colors.txt["primary"],
                                lineHeight:"32px",
                            }}
                            value={childProduct.sku}
                            onChange={(e) => handleChange(childProduct.index, 'sku', e.target.value)}
                            /> */}
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:1
                    }}
                >
                    <Box
                        sx={{
                            width:64,
                        }}
                    >
                        <Text light>{t("quantity")}</Text>
                    </Box>
                    <Box
                        sx={{
                            flex:1,
                            width:120,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <NumberInput btnSize={"24"}
                            onlyPositive={true}
                            value={childProduct.quantity}
                        change={(e) => handleChange(childProduct.index, 'quantity', e)}/>
                    </Box>

                    { newChildProducts.length > 1 ?
                    <>
                        <IconButton type="button" onClick={() => handleDeleleteChildRow(childProduct.index)} sx={{p:1}}>
                            <DeleteIcon/>
                        </IconButton>
                    </> 
                    : null
                    }
                </Box>
            </Box>
            

            ))}
        </Box>
    );
};

const ChildProducts = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
        tabId="tabChildProducts" 
        subtitle={t("products")} 
        step={2}
        maxSteps={2}
    >
        <Content />
    </ProgressionTab>
    )
}

export default ChildProducts;

