// CustomLoading.js
import React from 'react';
import { Box } from '@mui/material'; // or any other library/component you prefer

const SPLoader = () => {
  return (
    <Box 
      sx={{ 
        position: "fixed", 
        zIndex: 128002, 
        top: 0, 
        left: 0, 
        width: "100%", 
        height: "100%", 
        background: "green", 
        display: "flex", 
        justifyContent: "center", 
        alignItems: "center",
        display:"none"
      }}
    >
      <span>Loading...</span>
    </Box>
  );
};

export default SPLoader;