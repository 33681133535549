import AddIcon from '@mui/icons-material/Add';
import MessageIcon from '@mui/icons-material/MessageOutlined';
import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../theme";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";
import Shbutton from "../../../../theme/buttons/Shbutton";

import ShLabel from "../../../../theme/label/ShLabel";
import Text from "../../../../theme/text/Text";

import { useTranslation } from "react-i18next";
import { useGetInboundLinesQuery } from '../../../../../newapi/inbound/inboundsSlice';
import { WarehouseInboundLineProductCard } from '../../../../global/cards/inbounds/InboundLineProductCard';
import ShDataGrid2 from '../../../../theme/datagrid2/ShDataGrid2';
import ShLabelSkeleton from '../../../../theme/skeleton/ShLabelSkeleton';
import ShTextSkeleton from '../../../../theme/skeleton/ShTextSkeleton';
import SidepanelLoader from '../../../../theme/sidepanel2/SidepanelLoader';
import { general_states } from '../../../../../utils/staticEnums';
import { useGetBolreplenishmentLinesQuery } from '../../../../../newapi/webshop/bolreplenishmentSlice';

const SpStockDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("save")}</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    
    filter : {
        name: "productsFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const Products = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [spStockLoading, setSpStockLoading] = useState();

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.product?.id);
        if(newProductId === null || isNaN(newProductId)) {
            return;
        }
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            if(!spStockLoading){
                setSpStockLoading(true);
            }
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }

            setIsExtended(true);
    };
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const columns = [
        {field: "ean", headerName: t("ean"), flex: 1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
            }
            const sku = params.row.isDescriptionRow ? "" : params.row.product.sku;
            return (
                <Box >
                    <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{sku}</Text>
                </Box>
            );
        }},
        {field: "amount", headerName: t("amount"), flex: 1, 
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {field: "product.description", headerName: t("description"), flex:4, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            const description = params.row.isDescriptionRow ? params.row.description : params.row.product.description;
            return (
                <Box>
                    <Text semibold>{description}</Text>
                </Box>
            )
        }},
        {
            field: "phoneView", 
            headerName: "phoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseInboundLineProductCard skeleton={params.row.skeleton ?? false} {...params.row} onClick={() => getProductDetails(params)}/>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            {props.data && props.data.id && 
                <ShDataGrid2 key={theme.palette.mode}
                    itemId={props.data?.id}
                    content={useGetBolreplenishmentLinesQuery}
                    // gridActions={<GridActions></GridActions>}
                    title={t("products")}
                    gridOptions={gridOptions}
                    isSidepanelGrid
                    columns={columns}
                    onRowClick={getProductDetails}
                    sortModel={{field: columns[0].field,type:"DESC"}}
                />
            }
            {spStockLoading && 
                <SpStockDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    productId={productId}
                />
            }
        </Box>

      );
      
}

export default Products;