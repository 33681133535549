
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import InputIcon from '@mui/icons-material/Input';

export const settingsWarehousePickingResourcedConfig = {
    pickupcarts:  {
        title: 'pickupcarts',
        subTitle:"pickingcarts_description",
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
    pickupcrates:  {
        title: 'pickupcrates',
        subtitle: 'pickingcrates_description',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
    containersizes:  {
        title: 'containersizes',
        subtitle: 'containersizes_description',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
    
};
