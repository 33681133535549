import { Box, useTheme, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
// import { tokens } from "../../../../theme";
import Text from "../../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ButtonGroup from "../../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../../components/theme/buttons/Shbutton";

import { GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import MpAddStream from "../../../../../../../components/global/ModalsProgression/MpAddStream/MpAddStream";
import ShDataGrid2 from "../../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetStreamsQuery, useUpdateIndexMutation } from "../../../../../../../newapi/global/stream/streamSlice";

import { QuestionMarkOutlined } from "@mui/icons-material";
import WarehousePicker from '../../../../../../../components/global/WarehousePicker';
import { WarehouseStreamCard } from "../../../../../../../components/global/cards/settings/StreamCard";
import SidepanelLoader from "../../../../../../../components/theme/sidepanel2/SidepanelLoader";
import { ReactComponent as BolLogo } from '../../../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../../../../../styles/svg/postnl.svg';
import { ReactComponent as CirroLogo } from '../../../../../../../styles/svg/cirro.svg';
import { ReactComponent as SendcloudLogo } from '../../../../../../../styles/svg/sendcloud.svg';
import { selectUserPermission } from "../../../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../../../utils/permissions';
import { useSelector } from "react-redux";
import SHQuickAction  from '../../../../../../../components/theme/Modal/SHQuickAction';
import { getDisplayValue, getMobileCondition } from "../../../../../../../components/Streams/Conditions/ConditionSettings";

import cx from 'classnames';
import { countriesFromLocale } from "../../../../../../../utils/countries";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../../../utils/toasts";

const SpStreamDetails = SidepanelLoader(() => import("../../../../../../../components/global/Sidepanels/SpStreamDetails/SpStreamDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("add_stream")}</Shbutton>
            )}
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};


const QuickAction = (props) => {
    
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            sx={{
                px:1.5,
            }}
        >
            {props.conditions.map((ruleline,index) => (
                <Box key={index+"pcs"}
                    sx={{
                        position:'relative',
                        height:40,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                    }}
                >
                     <Box
                        className={cx('',{
                            'error': props.errorMap && props.errorMap[index] && props.errorMap[index].key,
                        })}
                        sx={{
                            width:120,
                            display:"flex",
                            alignItems:"center",
                            borderRadius:4,
                            "&:hover" : {
                                background:colors.grey[200],
                                cursor:"pointer",
                            },
                            "@media screen and (max-width: 768px)" : {
                                // px:0.5,
                            },
                            "&.error" : {
                                background:colors.red[100]
                            }
                        }}
                    >
                        {/* {ru} */}
                        <Text noBreak>{t(`condition_${ruleline.key}`)}</Text>
                    </Box>
                    <Box
                        sx={{
                            width:155,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            height:32,
                            borderRadius:4,
                            
                        }}
                    >
                        <Text
                            light
                            sx={{
                                display:'none',
                                "@media screen and (min-width: 768px)" : {
                                    display:"inline-block",
                                }
                            }}
                        >
                            {t(ruleline.nominator)}
                        </Text>
                        <Text
                            light
                            sx={{
                                display:'none',
                                "@media screen and (max-width: 768px)" : {
                                    display:"inline-block",
                                }
                            }}
                        >
                            {getMobileCondition(ruleline.nominator)}
                        </Text>
                    </Box>
                        <Box
                            sx={{
                                display:"flex",
                                height:32,
                                borderRadius:4,
                                width:120,
                                alignItems:"center",
                                justifyContent:"center",
                            }}
                        >
                            <Text bold>{getDisplayValue(ruleline, props.countries)}</Text>
                        </Box>
                </Box>
            ))}

        </Box>        
    );
}

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language);

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.STREAM_MANAGE, UserPrivileges.SETTINGS_ALL])
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

      const [UpdateIndex,{syncEditIsLoading}] = useUpdateIndexMutation();
      const handleUpdateIndex = async (id, mutation) => {
        const updateObject = {
            id: id,
            mutation: mutation
        }

          try {
              const response = await UpdateIndex(updateObject).unwrap();
              showToastMessageUpdateRequest(t, response);
          } catch (err) {
              showToastMessageRequestError(t('update_failed'), t, err);
          }
      };

    const [streamId, setStreamId] = useState();
    const streamIdRef = useRef(streamId);
    
    const getStreamDetails = (params) => {
        if (!params) return;
    
        const newstreamId = parseInt(params.row.id);
        // Only update state if the streamId has actually changed
        if (streamIdRef.current !== newstreamId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setStreamId(newstreamId);
            streamIdRef.current = newstreamId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WarehousePicker desktop={false}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props.warehouseModal]);
    
    const columns = [
        {
            field: "actions", 
            headerName: "",
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return (
                <Box>
                    {hasCreatePermission && (
                    <>
                    { params.row.index > 1 ? 
                    <IconButton type="button" onClick={(event) => {
                        event.stopPropagation();
                        handleUpdateIndex(params.row.id, -1);
                    }}
                    sx={{transform: 'rotate(270deg)'}}>
                        <ArrowRightAltIcon/>
                    </IconButton> 
                    : 
                    <></>
                    }
                    { !params.row.isMax ? 
                    <IconButton type="button" onClick={(event) => {
                        event.stopPropagation();
                        handleUpdateIndex(params.row.id, 1);
                    }}
                    sx={{transform: 'rotate(90deg)'}}>
                        <ArrowRightAltIcon/>
                    </IconButton> 
                    : 
                    <></>
                    }
                    </>
                    )}
                </Box>
                )
            }
        },
        {field: "name", headerName: t("stream") , flex:1, cellClassName: "stream", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
    
            return (<Text>{cellValues.row.name}</Text>);
        }},
        {field: "state", headerName: t("state"), flex:1, renderCell: (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShLabelSkeleton/>
            }
            return (<Text>
                {cellValues.value == 'active' ? (
                     <ShLabel palette="normal"  size={32} fitted variant={"green"}>
                     <Text variant="green">{t("active")}</Text>
                 </ShLabel>
                ) : (
                    <ShLabel  size={32} fitted variant={"grey"}>
                    <Text variant="grey">{t("inactive")}</Text>
                </ShLabel>
                    
                )}
            </Text>)
        }},
        {field: "warehouse", headerName: t("warehouse"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                    <Text >{cellValues.row.warehouse?.name}</Text>
            )
        }},
        {field: "deliverytimePriority", headerName: t("deliverytimepriority"), flex:1, renderCell: (cellValues) => {
            return (<Text sx={{color:cellValues.value ? "green" : "red"}}>
                {cellValues.value ? (
                    <GridCheckIcon />
                ) : (
                    <ClearIcon/>
                )}
            </Text>)
        }},
        {field: "pickingprocesstype", headerName: t("pickingprocesstype"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (<Text>{t(cellValues.value)}</Text>)
        }},
        {field: "shipper", headerName: t("shipper"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box sx={{display:"flex", flexDirection:"row", alignItems:'center', gap:1}}>
                    <Box sx={{ paddingRight: '10px' }}>
                        {cellValues.value?.type === "postnl" ? <PostNLLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "dhl" ? <DhlLogo  width={30} height={30}/> : null}
                        {cellValues.value?.type === "dpd" ? <DpdLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "bol" ? <BolLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "cirro" ? <CirroLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "sendcloud" ? <SendcloudLogo width={30} height={30}/> : null}
                        {cellValues.value?.type === "other" ? <QuestionMarkOutlined width={30} height={30}/> : null}
                    </Box>
                    <Box sx={{display:"flex", flexDirection:"column", gap:1}}>
                        <Text>{ cellValues.value?.name}</Text>
                        {cellValues.row.streamrules?.length > 0 && (
                            <Text light>+ { cellValues.row.streamrules.length} {t("rules")}</Text>
                        )}
                    </Box>    
                </Box>            
            )
        }},
        {field: "reachType", headerName: t("reachtype"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (<Text>{ cellValues.value?.description}</Text>)
        }},
        {field: "conditions", headerName: t("conditions"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <SHQuickAction
                    position={'right'} //triangle position
                    component={
                        <QuickAction
                            countries={countries}
                            conditions={cellValues.row.conditions ?? []}
                        />
                    }
                >
                    {({anchorEl,onMouseEnter,onMouseLeave,onTouchStart}) => (
                        <Box
                            aria-owns={anchorEl ? 'simple-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={onMouseLeave}
                            onTouchStart={onTouchStart} 
                            sx={{
                                display:"flex",
                                height:32,
                                width:1,
                                gap:0.5,
                                alignItems:'center',
                                justifyContent:'center',
                                position:'relative',
                                borderRadius:4,
                                background:colors.grey[50],
                                border: `1px solid ${colors.grey[400]}`
                                
                            }}
                        >
                            <Text bold>{cellValues.row.conditions?.length ?? 0}</Text> <Text>{t('conditions')}</Text>
                        </Box> 

                    )}
                </SHQuickAction>
            )
        }},

        // {field: "fitsPost", headerName: "Fits in dutch postorder", flex:1, renderCell : (cellValues) => {
        //     return (
        //         cellValues.value === "true" ?
        //             <Text variant="green"><CheckOutlinedIcon fontSize="small"/></Text>:
        //         cellValues.value === "false" ?
        //             <Text variant="red"><CloseOutlinedIcon fontSize="Small" /></Text> 
        //         :
        //             <Text variant="orange"><HorizontalRuleOutlinedIcon fontSize="Small"/></Text>
        //     )
        // }},

        // {field: "channels", headerName: "Channels", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "weight", headerName: "Weight including label", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "length", headerName: t("dimensions_lwh"), flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{`${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`}</Text>
        //     )
        // }},
        // {field: "barcodes", headerName: "Nr. of barcodes", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
               return (
                    <WarehouseStreamCard
                        skeleton={cellValues.skeleton ?? false}
                        {...cellValues.row}
                        onClick={() => getStreamDetails(cellValues)}
                    />
                    // <Box>
                    //     <Text>{cellValues.row.name}</Text>
                    // </Box>
               )
            }
        }
    ];
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetStreamsQuery}
                onRowClick={getStreamDetails}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                columns={columns}
                sortModel={{field: columns[0].field,type:"ASC"}}>
            </ShDataGrid2>

            <MpAddStream 
                onOpen={() => addModal.setOpen(true)} 
                onClose={() => addModal.setOpen(false)} 
                open={addModal.open} 
                handleClose={handleClose}
            />

            {spLoading && 
            <SpStreamDetails
                isExtended={isExtended}
                setIsExtended={setIsExtended}
                streamId={streamId}
                // data={streamData} 
                /> 
            }
        </Box>
    )
};

export default All;