import { Box, useTheme } from "@mui/material";


import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {NOTIFICATION_COMPONENTS} from "./NotificationMap";
import SidepanelLoader from "../../theme/sidepanel2/SidepanelLoader";
import { tokens } from "../../../theme";
import { ENV_TYPES } from "../Sidebar";
import { useNavigate } from "react-router-dom";

const SpInvoiceDetails = SidepanelLoader(() => import("../Sidepanels/SpInvoiceDetails/SpInvoiceDetails"));
const SpOrderDetails = SidepanelLoader(() => import("../Sidepanels/SpOrderDetails/SpOrderDetails"));
const SPStockDetails = SidepanelLoader(() => import("../Sidepanels/SpStockDetails/SPStockDetails"));
const SpLocationDetails = SidepanelLoader(() => import("../Sidepanels/SpLocationDetails/SpLocationDetails"));
const SpInboundDetails = SidepanelLoader(() => import("../Sidepanels/SpInboundDetails/SpInboundDetails"));
const SpReturnDetails = SidepanelLoader(() => import("../Sidepanels/SpReturnDetails/SpReturnDetails"));
const SpInvoiceProductDetails = SidepanelLoader(() => import("../Sidepanels/SpInvoiceProductDetails/SpInvoiceProductDetails"));



const NotificationsContent = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const envType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const navigate = useNavigate();

    const [orderId,setOrderId] = useState();
    const [invoiceId,setInvoiceId] = useState();
    const [invoiceProductId,setInvoiceProductId] = useState();
    const [productId,setProductId] = useState();
    const [locationId,setLocationId] = useState();
    const [inboundId,setInboundId] = useState();
    const [returnId,setReturnId] = useState();

    const [ordersSpIsExtended, setOrdersSpIsExtended] = useState(false);
    const [startLoadingOrderDetails, setStartLoadingOrderDetails] = useState();

    const [invoicesSpIsExtended, setInvoicesSpIsExtended] = useState(false);
    const [spInvoiceLoading, setSpInvoiceLoading] = useState();

    const [invoiceProductsSpIsExtended, setInvoiceProductsSpIsExtended] = useState(false);
    const [spInvoiceProductLoading, setSpInvoiceProductLoading] = useState();

    const [productsSpIsExtended, setProductsSpIsExtended] = useState(false);
    const [spProductLoading, setSpProductLoading] = useState();

    const [locationsSpIsExtended, setLocationsSpIsExtended] = useState(false);
    const [spLocationLoading, setSpLocationLoading] = useState();

    const [inboundsSpIsExtended, setInboundsSpIsExtended] = useState(false);
    const [spInboundLoading, setSpInboundLoading] = useState();

    const [returnsSpIsExtended, setReturnsSpIsExtended] = useState(false);
    const [spReturnLoading, setSpReturnLoading] = useState();

    const handleResultClick = (key,item) => {
        setOrderId(null);
        setInvoiceId(null);
        setProductId(null);
        setLocationId(null);
        setInboundId(null);
        setReturnId(null);

        switch(key) {
            case "orders" :
                setOrderId(item.id); 
                if(!startLoadingOrderDetails){
                    setStartLoadingOrderDetails(true);
                } 
                setOrdersSpIsExtended(true);
                break;
            case "invoices" : 
                setInvoiceId(item.id); 
                if(!spInvoiceLoading){
                    setSpInvoiceLoading(true);
                }
                setInvoicesSpIsExtended(true);
                break;
            case "stock_difference" : 
                setProductId(item.body.externalId); 
                if(!spProductLoading) {
                    setSpProductLoading(true);
                } 
                setProductsSpIsExtended(true);
                break;
            case "locations" :
                setLocationId(item.id); 
                if(!spLocationLoading) {
                    setSpLocationLoading(true);
                } 
                setLocationsSpIsExtended(true);
                break;
            case "inbounds" : 
                setInboundId(item.id); 
                if (!spInboundLoading) {
                    setSpInboundLoading(true);
                }
                    setInboundsSpIsExtended(true);
                break;
            case "returns" : 
                setReturnId(item.id); 
                if(!spReturnLoading){
                    setSpReturnLoading(true);
                } 
                setReturnsSpIsExtended(true);
                break;
            case "invoice_product_zero_price" :
                setInvoiceProductId(item.body.externalId); 
                if(!spReturnLoading){
                    setSpInvoiceProductLoading(true);
                } 
                setInvoiceProductsSpIsExtended(true);
                break;
            case "orders_in_maintenance": 
                if(envType === ENV_TYPES[200]) {
                    window.location.href = "/relation/outbound?state=MAINTENANCE";
                }
                if(envType === ENV_TYPES[100]) {
                    window.location.href = "/warehouse/outbound/orders?state=MAINTENANCE";
                }
                if(props.onClose) {
                    props.onClose();
                }
                break;
            case "orders_in_backorder": 
                if(envType === ENV_TYPES[200]) {
                    window.location.href = "/relation/outbound?state=BACKORDER";
                    // navigate("relation/outbound?state=BACKORDER")
                }
                if(envType === ENV_TYPES[100]) {
                    window.location.href = "/warehouse/outbound/orders?state=BACKORDER";
                    // navigate("warehouse/outbound/orders?state=BACKORDER")
                }
                if(props.onClose) {
                    props.onClose();
                }
                break;
            default :
                    // What drugs are u on mate?
                return;
        }
    }

    return (
        <>
        
            {props.data?.map((item, index) => {
                const { notifications_comp: Notification } = NOTIFICATION_COMPONENTS[item.type];

                return  (
                    <Box
                        sx={{
                            px:1,
                            py:1,
                            borderBottom: index !== props.data.length - 1 ? `1px solid ${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100]}`: null,
                        }}
                    >
                        <Notification
                            key={item.type+index}
                            onClick={() => handleResultClick(item.type,item)}
                            {...item}
                        />
                    </Box>
                );
            })}

            {startLoadingOrderDetails && 
                <SpOrderDetails
                    isExtended={ordersSpIsExtended}
                    setIsExtended={setOrdersSpIsExtended}
                    orderId={orderId}
                    relation={envType === ENV_TYPES[200]}
                    canReserveOrderLines={envType === ENV_TYPES[200]}
                />
            }

            {spInvoiceLoading && 
                <SpInvoiceDetails
                    isExtended={invoicesSpIsExtended}
                    setIsExtended={setInvoicesSpIsExtended}
                    invoiceId={invoiceId}
                />
            }

            {spInvoiceProductLoading && 
                <SpInvoiceProductDetails
                    isExtended={invoiceProductsSpIsExtended}
                    setIsExtended={setInvoiceProductsSpIsExtended}
                    invoiceId={invoiceProductId}
                />
            }
            
            
            {spProductLoading && 
                <SPStockDetails
                    isExtended={productsSpIsExtended}
                    setIsExtended={setProductsSpIsExtended}
                    productId={productId}
                />
            }

            {/* spLocationLoading */}

            {spLocationLoading && 
                <SpLocationDetails
                    isExtended={locationsSpIsExtended}
                    setIsExtended={setLocationsSpIsExtended}
                    locationId={locationId}
                />
            }

            {spInboundLoading && 
                <SpInboundDetails
                    isExtended={inboundsSpIsExtended}
                    setIsExtended={setInboundsSpIsExtended}
                    inboundId={inboundId}
                />
            }

            {spReturnLoading && 
                <SpReturnDetails
                    isExtended={returnsSpIsExtended}
                    setIsExtended={setReturnsSpIsExtended}
                    returnId={returnId}
                />
            }

        </>
    )

}

export default NotificationsContent;