export const getCookie = (key) => {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    // console.log(b ? b.pop() : "");
    return b ? b.pop() : "";
}

export const getTranslatedDateAndTime = (prop, i18n) => {
    if (!prop || typeof prop === "undefined") {
        return { date: "-", time: "", combined: "-"};
    }
    const dChanged = new Date(prop);
    return { date: dChanged.toLocaleDateString(i18n.language), time: dChanged.toLocaleTimeString(i18n.language), combined: dChanged.toLocaleString(i18n.language) };
}

export const checkAllPropertiesTruthy = (obj) => {
    if (!obj) return false;
    return Object.values(obj).every(value => {
        if (typeof value === 'string') {
            return Boolean(value.trim());
        }
        return Boolean(value);
    });
}


export const getOrderStatusProgress = (order, t) => {
    let color = "";
    let percentage = 0;

    if (order) {
        if(order?.pickingJob){

        } else {
            color = "grey";
            percentage = 0;
            
        }
    }

    return { color, percentage };
    
}