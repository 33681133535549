import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import Details from "./views/Details";
import Shipments from "./views/Shipments";
import ReturnLines from "./views/ReturnLines";
import { useCreateReturnMutation } from "../../../../newapi/inbound/returnsSlice";

const MpAddReturn = (props) => {
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useCreateReturnMutation} isModal title={t("create_return")}>
                <Details data={props.data}/>
                <ReturnLines/>
                <Shipments/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddReturn;