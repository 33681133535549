import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const BOLREPLENISHMENT_BASE = "/bolreplenishments";
const buildUrl = (endpoint) => BOLREPLENISHMENT_BASE + endpoint;

const bolreplenishmentSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        findAllBolreplenishments: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Bolreplenishments' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'findAllBolreplenishments', 'Bolreplenishments')
        }),
        findBolreplenishmentById: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: 'Bolreplenishments', id }],
        }),
        getBolreplenishmentLines: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/lines?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (id) => [{ type: 'BolreplenishmentLines', id }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getBolreplenishmentLines', 'BolreplenishmentLines')
        }),
        createBolreplenishment: builder.mutation({
            query: info => ({
                url: buildUrl(`/create`),
                method: 'POST',
                body: info,
            }),
            invalidatesTags: [{ type: 'Bolreplenishments' }],
        }),
        updateBolreplenishment: builder.mutation({
            query: ({ id, ...info }) => ({
                url: buildUrl(`/${id}`),
                method: 'PUT',
                body: info,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'Bolreplenishments', id }],
        }),
        deleteBolreplenishment: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Bolreplenishments', id }],
        }),
        approveBolreplenishment: builder.mutation({
            query: data => ({
                url: buildUrl(`/${data.bolReplenishmentId}/approve`),
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Bolreplenishments', id }],
        }),
        rejectBolreplenishment: builder.mutation({
            query: data => ({
                url: buildUrl(`/${data.bolReplenishmentId}/reject`),
                method: 'POST',
                body: data,
            }),
            invalidatesTags: (result, error, id) => [{ type: 'Bolreplenishments', id }],
        }),
        getAvailableWarehouses: builder.mutation({
            query: data => ({
                url: buildUrl(`/warehouses`),
                method: 'POST',
                body: data,
            }),
        }),
        getAvailableTimeslots: builder.query({
            query: data => ({
                url: buildUrl(`/timeslots`),
                method: 'POST',
                body: data,
            }),
        }),
        getPicklist: builder.query({
            query: id => ({
                url: buildUrl(`/${id}/picklist`),
                method: 'GET',
            }),
        }),
        getCarrierLabel: builder.query({
            query: id => ({
                url: buildUrl(`/${id}/carrierlabel`),
                method: 'GET',
            }),
        }),
    })
});

export const { 
    useFindAllBolreplenishmentsQuery,
    useFindBolreplenishmentByIdQuery,
    useGetBolreplenishmentLinesQuery,
    useCreateBolreplenishmentMutation,
    useUpdateBolreplenishmentMutation,
    useDeleteBolreplenishmentMutation,
    useApproveBolreplenishmentMutation,
    useRejectBolreplenishmentMutation,
    useGetAvailableWarehousesMutation,
    useGetAvailableTimeslotsQuery,
    useGetPicklistQuery,
    useGetCarrierLabelQuery,
} = bolreplenishmentSlice;
