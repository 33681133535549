import React, { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import ShSwitch from "../../theme/inputs/ShSwitch";
import Text from "../../theme/text/Text";
import { useTranslation } from "react-i18next";
import { useUpdateSettingMutation, useUpdateSettingForRelationMutation } from "../../../newapi/global/generalApiSlice";
import ShThreeSwitch from "../../theme/inputs/ShThreeSwitch";
import EMGeneralExplainerBox from "../ModalsPopover/explainers/EMGeneralExplainerBox";

// variants
//    1: default has both a title and subtitle
//    2: has no subtitle ans text is light
const BoolSetting = (props) => {
  const { title = true, subtitle = true, variant = 1, disabled = false } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [updateSetting] = useUpdateSettingMutation();
  const [updateSettingForRelation] = useUpdateSettingForRelationMutation();

  const [setting, setSetting] = useState();
  const [val, setVal] = useState();

  const handleBoxClick = () => {
    if (disabled) return;
    setVal(!val);
    handleChange(!val);
  };

  const handleChange = async (value) => {
    if (props.onChange) {
      props.onChange(value);
    }
    if(props.relationId){
      await updateSettingForRelation({
        key: setting.key,
        value: value,
        relationId: props.relationId
      });
    }else{
      await updateSetting({
        key: setting.key,
        value: value,
        warehouseId: setting.warehouseId,
      });
    }
  };

  const handleChangeUnset = async (value) => {
    console.log("value",value);
    const newValue = (value == 2) ? "true" : (value === 0) ? "false" : "unset";
    if (props.onChange) {
      props.onChange(newValue);
    }

    setVal(newValue);
    if(props.relationId){
      await updateSettingForRelation({
        key: setting.key,
        value: newValue,
        relationId: props.relationId
      });
    }else{
      await updateSetting({
        key: setting.key,
        value: newValue,
        warehouseId: setting.warehouseId,
      });
    }
  };

  useEffect(() => {
    if(props.skey && typeof props.settings !== "undefined"){
        let xsetting = props.settings.find((setting) => setting.key === props.skey);
        if (!xsetting) {
          if(props.relationId){
            xsetting = { key: props.skey, value: "unset", relationId: props.relationId };
          }else{
            xsetting = { key: props.skey, value: false, warehouseId: -1 };
          }
        }
        setSetting(xsetting);
        let xval = disabled ? false : xsetting.value;
        setVal(xval);
    }
  }, [props.settings, props.skey, props.relationId, disabled]);

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 4,
        height:40,
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      onClick={handleBoxClick}
    >
      <Box
        sx={{
          display:"flex",
          flexDirection:"column",
          overflow:"hidden",
          cursor:"pointer"
        }}
      >
        <Box sx={props.explainer ? {display: "flex", alignItems: "center"} : {}}>
        {title && <Text noBreak light={variant === 2} size={variant === 2 ?? "fs13"} semibold={variant === 1} >{t(`${setting?.key}_title`)}</Text>}
        {props.explainer && <EMGeneralExplainerBox title={props.explainerTitle} text={props.explainerText}/>}
        </Box>
        {subtitle && variant !== 2 && <Text light>{t(`${setting?.key}_subtitle`)}</Text>}
      </Box>
      <ShSwitch
        checked={val === "true"}
        disabled={disabled}
        onChange={(e) => {
          console.log("change called");
          e.stopPropagation();
          if (!disabled) handleChange(e.target.checked);
        }}
        color={theme.palette.mode === "dark" ? "blue" : "primary"}
      />
    </Box>
  );
};

export default BoolSetting;