import { createContext, useState, useMemo, useContext } from "react";
import { createTheme } from "@mui/material/styles";
import { colors } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useDispatch } from "react-redux";
// import { setThemeMode } from './newapi/global/settingsSlice'; // Import the action


export const withOpacity = (color, opacity = 1) => {
    return `rgba(${color}, ${opacity})`;
}

export const chartTokens = {
    text: {
        primary: withOpacity('49,65,95'),
        secondary: withOpacity('49,65,95','.55'),
    },
    default : {
        st: {
            100:withOpacity('49,65,95','.15'),
            300:withOpacity('49,65,95','.35'),
            500:withOpacity('49,65,95','.55'),
            700:withOpacity('49,65,95','.75'),
            1000:withOpacity('49,65,95'),
        },
        light: {
            100: '#404054',
            300: '#4B4B62',
            500: '#565671',
            700: '#61617F',
            1000: "#88869D",
        },
        bg:{
            default: "#fff",
            alt: "#1F1F28",
        },
        shadow: withOpacity('49,65,95','.25'),
    },
    primary : {
        st: {
            100:withOpacity('13,97,255','.15'),
            300:withOpacity('13,97,255','.35'),
            500:withOpacity('13,97,255','.55'),
            700:withOpacity('13,97,255','.75'),
            1000:withOpacity('13,97,255'),
        },
        light: {
            100: '#D1DFFC',
            300: '#AFC9FB',
            500: '#8DB2F9',
            700: '#6B9CF8',
            1000: withOpacity('13,97,255'),
        },
        bg:{
            default: withOpacity('217,227,251','.55'),
            alt: withOpacity('234,240,253'),
        },
        shadow: withOpacity('13,97,255','.25'),
    },
    yellow : {
        st: {
            100:withOpacity('246,171,65','.15'),
            300:withOpacity('246,171,65','.35'),
            500:withOpacity('246,171,65','.55'),
            700:withOpacity('246,171,65','.75'),
            1000:withOpacity('246,171,65'),
        },
        light: {
            100: '#FEE9CC',
            300: '#FCDAAB',
            500: '#FACC8A',
            700: '#F8BD6A',
            1000: withOpacity('246,171,65'),
        },
        bg:{
            default: withOpacity('255,240,219','.75'),
            alt: withOpacity('255,244,228'),
        },
        shadow: withOpacity('246,171,65','.25'),
    },
    red : {
        st: {
            100:withOpacity('248,107,105','.15'),
            300:withOpacity('248,107,105','.35'),
            500:withOpacity('248,107,105','.55'),
            700:withOpacity('248,107,105','.75'),
            1000:withOpacity('248,107,105'),
        },
        light: {
            100: '#FED8DB',
            300: '#FDBEC0',
            500: '#FBA5A5',
            700: '#FA8B8A',
            1000: withOpacity('248,107,105'),
        },
        bg:{
            default: withOpacity('255,219,228','.55'),
            alt: withOpacity('255,235,239'),
        },
        shadow: withOpacity('248,107,105','.25'),
    },
    pink : {
        st: {
            100:withOpacity('191,51,194','.15'),
            300:withOpacity('191,51,194','.35'),
            500:withOpacity('191,51,194','.55'),
            700:withOpacity('191,51,194','.75'),
            1000:withOpacity('191,51,194'),
        },
        light: {
            100: '#F5D4F6',
            300: '#E9AEEA',
            500: '#DC88DE',
            700: '#CF62D1',
            1000: withOpacity('191,51,194'),
        },
        bg:{
            default: withOpacity('254,228,255','.55'),
            alt: withOpacity('255,240,255'),
        },
        shadow: withOpacity('191,51,194','.25'),
    },
    purple : {
        st: {
            100:withOpacity('120,76,170','.15'),
            300:withOpacity('120,76,170','.35'),
            500:withOpacity('120,76,170','.55'),
            700:withOpacity('120,76,170','.75'),
            1000:withOpacity('120,76,170'),
        },
        light: {
            100: '#E0D3F0',
            300: '#C7B3DF',
            500: '#AF94CF',
            700: '#9774BF',
            1000: withOpacity('120,76,170'),
        },
        bg:{
            default: withOpacity('254,228,255','.55'),
            alt: withOpacity('242,235,252'),
        },
        shadow: withOpacity('120,76,170','.25'),
    },
    cyan : {
        st: {
            100:withOpacity('42,169,190','.15'),
            300:withOpacity('42,169,190','.35'),
            500:withOpacity('42,169,190','.55'),
            700:withOpacity('42,169,190','.75'),
            1000:withOpacity('42,169,190'),
        },
        light: {
            100: '#CEEEF4',
            300: '#A7DEE7',
            500: '#81CDDA',
            700: '#5ABDCE',
            1000: withOpacity('42,169,190'),
        },
        bg:{
            default: withOpacity('221,246,250','.55'),
            alt: withOpacity('235,250,253'),
        },
        shadow: withOpacity('42,169,190','.25'),
    },
    green : {
        st: {
            100:withOpacity('10,189,167','.15'),
            300:withOpacity('10,189,167','.35'),
            500:withOpacity('10,189,167','.55'),
            700:withOpacity('10,189,167','.75'),
            1000:withOpacity('10,189,167'),
        },
        light: {
            100: '#CEEEF4',
            300: '#A7DEE7',
            500: '#81CDDA',
            700: '#5ABDCE',
            1000: withOpacity('10,189,167'),
        },
        bg:{
            default: withOpacity('218,245,242','.55'),
            alt: withOpacity('235,250,248'),
        },
        shadow: withOpacity('10,189,167','.25'),
    },
    brown : {
        st: {
            100:withOpacity('180,97,62','.15'),
            300:withOpacity('180,97,62','.35'),
            500:withOpacity('180,97,62','.55'),
            700:withOpacity('180,97,62','.75'),
            1000:withOpacity('180,97,62'),
        },
        light: {
            100: '#F4D7CB',
            300: '#E5BBAA',
            500: '#D6A089',
            700: '#C78467',
            1000: withOpacity('180,97,62'),
        },
        bg:{
            default: withOpacity('255,245,240','.55'),
            alt: withOpacity('255,236,228'),
        },
        shadow: withOpacity('180,97,62','.25'),
    },
};

const pastel = {
    light: {
        grey: {
            100 : "#F5F7FA",
            200 : "#D8DCE3",
            300 : "#B0B7C4",
            400 : "#8993A5",
            500 : "#626F86",
            600 : "#31415f",
        },
        purple: {
            100 : "#F2EBFC",
            200 : "#D3C9E0",
            300 : "#A99CBB",
            400 : "#7F6F96",
            500 : "#554271",
            600 : "#210A43"
        },
        pink : {
            100 : "#FFF0FF",
            200 : "#E4CCE4",
            300 : "#C09CC1",
            400 : "#9D6C9D",
            500 : "#793C7A",
            600 : "#4C004D"
        },
        red : {
            100 : "#FFEBEF",
            200 : "#E4C8CD",
            300 : "#C099A1",
            400 : "#9D6A74",
            500 : "#793B47",
            600 : "#4D000F"
        },
        orange : {
            100 : "#FFECE4",
            200 : "#E4CCC2",
            300 : "#C1A294",
            400 : "#9D7767",
            500 : "#7A4C39",
            600 : "#4D1700"
        },
        yellow : {
            100 : "#FFF4E4",
            200 : "#E4D6C2",
            300 : "#C1AE94",
            400 : "#9D8767",
            500 : "#7A5F39",
            600 : "#4D2D00"
        },
        blue: {
            100 : "#EAF0FD",
            200 : "#C8D0E2",
            300 : "#9BA5BD",
            400 : "#6D7B98",
            500 : "#405074",
            600 : "#071B46"
        },
        lightblue: {
            100 : "#E4F1FF",
            200 : "#C2D2E4",
            300 : "#94AAC0",
            400 : "#67819D",
            500 : "#395879",
            600 : "#00254D"
        },
        green : {
            100 : "#EBF9FA",
            200 : "#CADCDE",
            300 : "#9EB6B8",
            400 : "#729092",
            500 : "#466A6C",
            600 : "#0F3A3D"
        },
        brown: {
            100: '#FFF5F0',
            200: '#F4D7CB',
            300: '#E5BBAA',
            400: '#D6A089',
            500: '#C78467',
            600: withOpacity('180,97,62'),
        }
    },
    st : {
        grey: {
            100 : "rgba(236,240,245,.55)",
            200:  "rgba(49,65,95,.15)",
            300:  "rgba(49,65,95,.35)",
            400:  "rgba(49,65,95,.55)",
            500:  "rgba(49,65,95,.75)",
            600:  "rgba(49,65,95)",
        },
        purple : {
            100 : "rgba(232,218,249,.55)",
            200 : "rgba(33,10,67,.15)",
            300 : "rgba(33,10,67,.35)",
            400 : "rgba(33,10,67,.55)",
            500 : "rgba(33,10,67,.75)",
            600 : "rgb(33,10,67)",
        },
        pink : {
            100 : "rgba(254,228,255,.55)",
            200 : "rgba(76,0,77,.15)",
            300 : "rgba(76,0,77,.35)",
            400 : "rgba(76,0,77,.55)",
            500 : "rgba(76,0,77,.75)",
            600 : "rgb(76,0,77)",
        },
        red : {
            100 : "rgba(255,219,226,.55)",
            200 : "rgba(77,0,15,.15)",
            300 : "rgba(77,0,15,.35)",
            400 : "rgba(77,0,15,.55)",
            500 : "rgba(77,0,15,.75)",
            600 : "rgb(77,0,15)",
        },
        orange : {
            100 : "rgba(255,230,219,.55)",
            200 : "rgba(77,23,0,.15)",
            300 : "rgba(77,23,0,.35)",
            400 : "rgba(77,23,0,.55)",
            500 : "rgba(77,23,0,.75)",
            600 : "rgb(77,23,0)",
        },
        yellow : {
            100 : "rgba(255,241,219,.55)",
            200 : "rgba(77,45,0,.15)",
            300 : "rgba(77,45,0,.35)",
            400 : "rgba(77,45,0,.55)",
            500 : "rgba(77,45,0,.75)",
            600 : "rgb(77,45,0)",
        },
        blue : {
            100 : "rgba(219,230,255,.55)",
            200 : "rgba(7,27,70,.15)",
            300 : "rgba(7,27,70,.35)",
            400 : "rgba(7,27,70,.55)",
            500 : "rgba(7,27,70,.75)",
            600 : "rgb(7,27,70)",
        },
        lightblue : {
            100 : "rgba(219,238,255,.55)",
            200 : "rgba(0,37,77,.15)",
            300 : "rgba(0,37,77,.35)",
            400 : "rgba(0,37,77,.55)",
            500 : "rgba(0,37,77,.75)",
            600 : "rgb(0,37,77)",
        },
        green : {
            100 : "rgba(218,244,245,.55)",
            200 : "rgba(15,58,61,.15)",
            300 : "rgba(15,58,61,.35)",
            400 : "rgba(15,58,61,.55)",
            500 : "rgba(15,58,61,.75)",
            600 : "rgb(15,58,61)",
        },
        brown: {
            100: withOpacity('244,215,203','.55'),
            200: withOpacity('180,97,62','.15'),
            300: withOpacity('180,97,62','.35'),
            400: withOpacity('180,97,62','.55'),
            500: withOpacity('180,97,62','.75'),
            600: withOpacity('180,97,62'),
        }
    }
}

// color design tokens
export const tokens = (mode) => ({
...(mode === 'dark'
    ? 
    {
        ...{pastel},
        txt: {
            primary: "#fff",
            secondary: "#88869D",
            softened: "rgba(255,255,255,.75)",
            softenedMore: "rgba(255,255,255,.55)",
            darkened: "rgba(0,0,0,.75)",
            primaryDark: "#31415f",
            secondaryDark: "rgba(46,65,95,.55)",
        },
        bg: {
            "primary": "#0E0E11",
            "secondary": "#14141A",
            "menu": "20,20,26",
            "tertiary": "#181820",
        },
        nav: {
            "color": "rgba(0,0,0,.75)",
            "linkBgFull": "#1F1F28",
            "linkBg": "rgba(255,255,255,.15)",
            "linkBgColor": "#FFF",
            "pill": "#fff"
        },
        grey: {
            100: "#14141A",
            200: "#1F1F28",
            300: "#2A2A37",
            400: "#353545",
            500: "#404054",
            600: "#4B4B62",
            700: "#565671",
            800: "#61617F"
        },
        greyLight: {
            100: "#404054",
            400:"rgba(255,255,255,.75)",
            600:"rgba(255,255,255,.75)"
        },
        white: {
            100: "rgba(255,255,255,.15)",
            200: "rgba(255,255,255,.35)",
            300: "rgba(255,255,255,.55)",
            400: "#fff",
        },
        cyan: {
            100:withOpacity('42,169,190','.15'),
            200:withOpacity('42,169,190','.35'),
            300:withOpacity('42,169,190','.55'),
            400:withOpacity('42,169,190'),
        },

        urbangreen: {
            100:withOpacity('210,247,150','.10'),
            200:withOpacity('210,247,150','.35'),
            300:withOpacity('210,247,150','.55'),
            400:withOpacity('210,247,150'),
        },

        primary: {
            100: "rgba(13,97,255,.15)",
            200: "rgba(13,97,255,.35)",
            300: "rgba(13,97,255,.55)",
            400: "#0D61FF",
        },
        blue: {
            100: "rgba(141,181,255,.15)",
            200: "rgba(141,181,255,.35)",
            300: "rgba(141,181,255,.55)",
            400: "#8DB5FF",
        },
        purple: {
            100: "rgba(164,167,232,.15)",
            200: "rgba(164,167,232,.35)",
            300: "rgba(164,167,232,.55)",
            400: "#C2C4EF",
        },
        green: {
            100: "rgba(147,251,245,.15)",
            200: "rgba(147,251,245,.35)",
            300: "rgba(147,251,245,.55)",
            400: "#93FBF5",
        },
        orange: {
            100: "rgba(251,202,147,.15)",
            200: "rgba(251,202,147,.35)",
            300: "rgba(251,202,147,.55)",
            400: "#FBCA93",
        },
        red: {
            100: "rgba(251,149,147,.15)",
            200: "rgba(251,149,147,.35)",
            300: "rgba(251,149,147,.55)",
            400: "#FB9593",
        },

        yellow: {
            100: "rgba(253,245,211,.15)",
            200: "rgba(253,245,211,.35)",
            300: "rgba(253,245,211,.55)",
            400: "#FDF5D3",
        },
        pink: {
            100: "rgba(239,194,239,.15)",
            200: "rgba(239,194,239,.35)",
            300: "rgba(239,194,239,.55)",
            400: "#EFC2EF",
        },
        
       
    } : {
        ...{pastel},
        // Light mode
        txt: {
            primary: "#31415f",
            secondary: "rgba(46,65,95,.55)",
            softened: "rgba(255,255,255,.55)",
            light: "rgb(255,255,255)",
            white:"#fff"
        },
        bg: {
            "primary": "#030225",
            "secondary": "#181F3D",
            "menu": "24,31,61",
            "tertiary": "#ecf0f5",
        },
        nav: {
            "color": "rgba(0,0,0,.75)",
            "linkBgFull": "#2F3A4E",
            "linkBg": "rgba(255,255,255,.15)",
            "linkBgColor": "#FFF",
            "pill": "#fff"
        },
        grey: {
            0: "#fff",
            50 : "#F8FAFC",
            100: "#ecf0f5",
            200: "#ced7e1",
            300: "#8E97A7",
            400: "#31415F",
        },
        white: {
            100: "rgba(255,255,255,.10)",
            200: "rgba(255,255,255,.35)",
            300: "rgba(255,255,255,.55)",
            400: "#fff",
        },
        cyan: {
            100:withOpacity('42,169,190','.10'),
            200:withOpacity('42,169,190','.35'),
            300:withOpacity('42,169,190','.55'),
            400:withOpacity('42,169,190'),
        },
        urbangreen: {
            100:withOpacity('120,186,13','.10'),
            200:withOpacity('120,186,13','.35'),
            300:withOpacity('120,186,13','.55'),
            400:withOpacity('120,186,13'),
        },
        primary: {
            100: "rgba(13,97,255,.10)",
            200: "rgba(13,97,255,.35)",
            300: "rgba(13,97,255,.55)",
            400: "#0D61FF",
        },
        blue: {
            100: "rgba(141,181,255,.10)",
            200: "rgba(141,181,255,.35)",
            300: "rgba(141,181,255,.55)",
            400: "#8DB5FF",
        },
        purple: {
            100: "rgba(86,93,255,.10)",
            200: "rgba(86,93,255,.35)",
            300: "rgba(86,93,255,.55)",
            400: "#565DFF",
        },
        green: {
            100: "rgba(10,178,189,.10)",
            200: "rgba(10,178,189,.35)",
            300: "rgba(10,178,189,.55)",
            400: "#0AB2BD",
        },
        orange: {
            100: "rgba(231,149,73,.10)",
            200: "rgba(231,149,73,.35)",
            300: "rgba(231,149,73,.55)",
            400: "#E79549",
        },
        red: {
            100: "rgba(220,0,50,.10)",
            200: "rgba(220,0,50,.35)",
            300: "rgba(220,0,50,.55)",
            400: "#DC0032",
        },
        pink: {
            100: "rgba(255,101,166,.10)",
            200: "rgba(255,101,166,.35)",
            300: "rgba(255,101,166,.55)",
            400: "#FF65A6",
        },
        yellow: {
            100: "rgba(253,245,211.10)",
            200: "rgba(253,245,211.35)",
            300: "rgba(253,245,211.55)",
            400: "#FDF5D3",
        },

    })
})

// mui theme settings
export const themeSettings = (mode) => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...(mode === 'dark'
            ? {
                primary: {
                    main: colors.primary[400],
                    contrastText: colors.txt["primary"]
                },
                secondaryGrey: {
                    main: colors.txt["secondary"],
                    contrastText: colors.grey[400],
                },
                primaryGrey: {
                    main: colors.grey[500],
                    contrastText: colors.txt["primary"],
                },
                grey: {
                    main: colors.txt["secondary"],
                    contrastText: colors.grey[400],
                },
                primaryGreyOpposite: {
                    main: colors.txt["primary"],
                    contrastText: colors.grey[500]
                },
                blue: {
                    main: colors.blue[400],
                    contrastText: colors.txt["darkened"],
                },
                red: {
                    main: colors.red[400],
                    contrastText: colors.txt["darkened"]
                },
                orange: {
                    main: colors.orange[400],
                    contrastText: colors.txt["darkened"]
                },
                green: {
                    main: colors.green[400],
                    contrastText: colors.txt["darkened"]
                },
                yellow: {
                    main: colors.yellow[400],
                    contrastText: colors.txt["darkened"]
                },
                pink: {
                    main: colors.pink[400],
                    contrastText: colors.txt["darkened"]
                },
                purple: {
                    main: colors.purple[400],
                    contrastText: colors.txt["darkened"]
                },
                secondary: {
                    main: colors.yellow[400]
                },
                neutral: {
                    dark: colors.grey[400],
                    main: colors.grey[300],
                    light: colors.grey[100],
                },
                background: {
                    default: colors.bg["tertiary"]
                }
            } : {

                primary: {
                    main: colors.primary[400],
                    contrastText: colors.grey[0]
                },
                primaryGrey: {
                    main: colors.grey[400],
                    contrastText: colors.grey[0]
                },
                secondaryGrey: {
                    main: colors.grey[100],
                    contrastText: colors.grey[400],
                },
                grey: {
                    main: colors.grey[400],
                    contrastText: colors.grey[0]
                },
                blue: {
                    main: colors.blue[400],
                    contrastText: colors.txt["darkened"]
                },
                red: {
                    main: colors.red[400],
                    contrastText: colors.txt["white"]
                },
                orange: {
                    main: colors.orange[400],
                    contrastText: colors.txt["darkened"]
                },
                green: {
                    main: colors.green[400],
                    contrastText: colors.txt["light"]
                },
                yellow: {
                    main: colors.yellow[400],
                    contrastText: colors.txt["darkened"]
                },
                pink: {
                    main: colors.pink[400],
                    contrastText: colors.txt["darkened"]
                },
                purple: {
                    main: colors.purple[400],
                    contrastText: colors.txt["darkened"]
                },
                secondary: {
                    main: colors.orange[400]
                },
                neutral: {
                    dark: colors.grey[400],
                    main: colors.grey[300],
                    light: colors.grey[100]
                },
                background: {
                    default: colors.bg["tertiary"]
                }
            }
            )
        },
        typography: {
            fontFamily: ["Roboto", "sans-serif"].join(","),
            fontSize: 14,
            h1: {
                fontFamily: ["Roboto", "sans-serif"].join(","),
            fontSize: 40,
            },
            h2: {
                fontFamily: ["Roboto", "sans-serif"].join(","),
            fontSize: 32,
            },
            h3: {
                fontFamily: ["Roboto", "sans-serif"].join(","),
            fontSize: 24,
            },
            h4: {
                fontFamily: ["Roboto", "sans-serif"].join(","),
            fontSize: 20,
            },
            h5: {
                fontFamily: ["Roboto", "sans-serif"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Roboto", "sans-serif"].join(","),
                fontSize: 14,
            }

        },
    }
}

// context for color mode
export const ColorModeContext = createContext( {
    toggleColorMode: () => {},
    setColorMode: () => {}
});

export const useMode = () => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    // const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
    const [mode, setMode] = useState('light');

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () =>  setMode((prev) => (prev === "light" ? "dark" : "light")),
        }),
        []
    );

    const setColorMode = useMemo(
        () => ({
            setColorMode: (mode) => setMode(mode),
        }),
        []
    )

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme, colorMode, setColorMode];
}

// export const useMode = () => {
//     // setThemeM
//     const dispatch = useDispatch(); // Use useDispatch hook to dispatch actions
//     const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
//     // const [mode, setMode] = useState(prefersDarkMode ? 'dark' : 'light');
//     const [mode, setMode] = useState('light');

//     const colorMode = useMemo(
//         () => ({
//             toggleColorMode: () => {
//                 const newMode = mode === "light" ? "dark" : "light";
//                 setMode(newMode);
//                 dispatch(setThemeMode(newMode)); // Dispatch action to save the mode
//             },
//         }),
//         [mode, dispatch] // Add dispatch to the dependency array
//     );

//     const setColorMode = useMemo(
//         () => ({
//             setColorMode: (newMode) => {
//                 setMode(newMode);
//                 dispatch(setThemeMode(newMode)); // Dispatch action to save the mode
//             },
//         }),
//         [dispatch] // Add dispatch to the dependency array
//     )

//     const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

//     return [theme, colorMode, setColorMode];
// }