import { Box, InputBase, useTheme,IconButton } from "@mui/material"
import { useEffect, useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { useAddChildProductsMutation } from "../../../newapi/warehouse/productSlice";
import { showToastMessagePostRequest, showToastMessage, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../utils/toasts";
import DeleteIcon from '@mui/icons-material/Delete';
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../utils/validation";
import { useUploadLogoMutation } from "../../../newapi/global/uploadApiSlice";
import UploadLogoProcessor from "../../theme/upload/logo/UploadLogoProcessor";
import { Image } from "@mui/icons-material";
import { SHPBBackgroundColor } from "../../portalbuilder/Edittors/SHPBBackgroundColor";
import { useEditRelationMutation, useGetRelationForMenuQuery } from "../../../newapi/global/relation/relationSlice";
import { selectEnvironmentid } from "../../../newapi/global/settingsSlice";
import { useSelector } from "react-redux";
import { ENV_TYPES } from "../Sidebar";

const MAddRelationLogo = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [uploadLogo,{isLoading}] = useUploadLogoMutation();
    const [color,setColor] = useState();
    const selectedEnvironmentId = useSelector(selectEnvironmentid);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);

    const {data:relationMenuData,isFetching:isFetching2,isLoading:isLoading2} = useGetRelationForMenuQuery(selectedEnvironmentId, {
        skip: selectedEnvironmentType !== ENV_TYPES[200]
    });


    const handleClose = () => {
        props.handleClose();
    }
      
    
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    } 

    const [relationInfo, setRelationInfo] = useState({
        id: selectedEnvironmentId,
        color:JSON.stringify({}),
    });

    const [EditRelation,{isRelationLoading}] = useEditRelationMutation();
    const handleSaveClick = async () => {
        try {
            if(isLoading) return;
            const response = await EditRelation(relationInfo).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            if (err.name === 'ValidationError') {;
            } else {
                showToastMessageRequestError(t("update_failed"), t, err);
            }
        }
    };

    const editColor = (value) => {
        setRelationInfo(relationinfo => ({
            ...relationinfo,
            color: JSON.stringify(value),
        }));
    }

    useEffect(() => {
        setRelationInfo(relationinfo => ({
            ...relationinfo,
            id: props.relationId,
        }));
    }, [props.relationId])

    useEffect(() => {
        console.log(relationMenuData);
        if(relationMenuData && relationMenuData.color !== null){
            setRelationInfo(relationinfo => ({
                ...relationinfo,
                color: relationMenuData.color,
            }));
        }
    }, [relationMenuData])

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("upload_logo")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderRadius:4,
                    // borderTopLeftRadius:16,
                    // borderTopRightRadius:16,
                    display:"flex",
                    // flexDirection:"column",
                    gap:2,
                    "@media screen and (max-width: 47.9375em)" : {
                        flexDirection:'column'
                    }
                }}
            >
                <UploadLogoProcessor
                    close={handleClose}
                    relationId={props.relationId}
                    icon={<Image/>}
                    title={t("logo")}
                    type="product"
                    breakpoint="smallpc"
                    color={theme.palette.mode === "dark" ? "blue" : "primary"}
                />
                <Box
                    sx={{
                        border:`1px solid ${colors.grey[100]}`,
                        boxShadow:`0px 2px 4px ${colors.grey[200]}`,
                        borderRadius:4,
                        padding:2,
                        display:"flex",
                        flexDirection:"column",
                        gap:2,
                    }}
                >
                    <SHPBBackgroundColor
                        value={JSON.parse(relationInfo.color)} 
                        setValue={editColor}
                    />
                    <Shbutton
                        onClick={handleSaveClick}
                        block
                        variant={"contained"}
                        sx={{
                            mt:"auto",
                        }}
                    >
                        {t("save")}
                    </Shbutton>
                </Box>
            </Box>
            {/* <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => saveChildProducts(props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box> */}
        </SHModal>
    )
}

export default MAddRelationLogo;
