import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
// import { tokens } from "../../../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Text from "../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../theme";

import { QuestionMarkOutlined } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import MpAddShipper from "../../../../../../components/global/ModalsProgression/MpAddShipper/MpAddShipper";
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetShippersQuery } from "../../../../../../newapi/shipper/shipperSlice";
import { ReactComponent as BolLogo } from '../../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../../styles/svg/dpd.svg';
import { ReactComponent as ParcelLogo } from '../../../../../../styles/svg/parcel.svg';
import { ReactComponent as PostNLLogo } from '../../../../../../styles/svg/postnl.svg';
import { ReactComponent as CirroLogo } from '../../../../../../styles/svg/cirro.svg';
import { ReactComponent as SendcloudLogo } from '../../../../../../styles/svg/sendcloud.svg';
import SidepanelLoader from "../../../../../../components/theme/sidepanel2/SidepanelLoader";
import { selectUserPermission } from "../../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../../utils/permissions';
import { useSelector } from "react-redux";

const SpShipperDetails = SidepanelLoader(() => import("../../../../../../components/global/Sidepanels/SpShipperDetails/SpShipperDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>
                    {t("new_shipper")}
            </Shbutton>
            )}
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    

};


const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.SHIPPER_MANAGE])
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [shipperId, setShipperId] = useState();
    const shipperIdRef = useRef(shipperId);
    
    const getShipperDetails = (params) => {
        if (!params) return;
    
        const newshipperId = parseInt(params.row.id);
        // Only update state if the shipperId has actually changed
        if (shipperIdRef.current !== newshipperId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setShipperId(newshipperId);
            shipperIdRef.current = newshipperId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
    const columns = [
        // {field: "type", headerName: t("type"), flex:1, renderCell : (cellValues) => {
        //     return (
                
        //     )
        // }},
        {field: "name", headerName: t("name") , flex:1, cellClassName: "name", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <>
                <Box sx={{ paddingRight: '10px' }}>
                    {cellValues.row.type === "postnl" ? <PostNLLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "dhl" ? <DhlLogo  width={30} height={30}/> : null}
                    {cellValues.row.type === "dpd" ? <DpdLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "bol" ? <BolLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "other" ? <QuestionMarkOutlined width={30} height={30}/> : null}
                    {cellValues.row.type === "parcel" ? <ParcelLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "cirro" ? <CirroLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "sendcloud" ? <SendcloudLogo width={30} height={30}/> : null}
                </Box>
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
                </>
            )
        }},
        {field: "reference", headerName: t("reference"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return cellValues.row.reference
        }},
        {field: "warehouses", headerName: t("warehouses"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
            <Box>
                <Text>
                    {cellValues.row.warehouses?.length == 0 ? t("no_warehouses") : cellValues.row.warehouses?.length > 1 ? `${cellValues.row.warehouses?.length} ${t("warehouses")}` : `1 ${t("warehouse")}`}
                </Text>
            </Box>
            )
        }},
        {field: "maxLength", headerName: t("max_size"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
            <Box>
                <Text>
                    {`${cellValues.row.maxWidth}mm x ${cellValues.row.maxLength}mm x ${cellValues.row.maxHeight}mm (${cellValues.row.maxVolume} mm3)`}
                </Text>
            </Box>
            )
        }},
        {field: "maxWeight", headerName: t("max_weight"), flex: 1, renderCell: (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Box>
                    <Text>
                        {cellValues.value ? `${cellValues.value} ${t("gram")}` : "-"}
                    </Text>
                </Box>
            )
        }},
        {field: "standard_time", headerName: t("pickup_times"), flex: 1, renderCell: (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Box>
                    <Text>
                        {`${cellValues.row.standard_time ? new Date(new Date(cellValues.row.standard_time).getTime() - (60 * 60 * 1000)).toLocaleString('en-US', {hour: '2-digit', minute:'2-digit', hour12: false}) : '-'} | ${cellValues.row.standard_time_saturday ? new Date(new Date(cellValues.row.standard_time_saturday).getTime() - (60 * 60 * 1000)).toLocaleString('en-US', {hour: '2-digit', minute:'2-digit', hour12: false}) : '-'} | ${cellValues.row.standard_time_sunday ? new Date(new Date(cellValues.row.standard_time_sunday).getTime() - (60 * 60 * 1000)).toLocaleString('en-US', {hour: '2-digit', minute:'2-digit', hour12: false}) : '-'}`}
                    </Text>
                </Box>
            )
        }},
         {field: "alternativeAddress", headerName: t("alternativeAddress"), flex:1, renderCell: (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Box>
                    <Text>
                        {cellValues.value ? (
                            <GridCheckIcon sx={{ color: 'green' }} />
                        ) : (
                            <ClearIcon sx={{ color: 'rgba(128, 128, 128, 0.5)' }} />
                        )}
                    </Text>
                </Box>
            )
        }},
        
        // {field: "weight", headerName: "Weight including label", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "length", headerName: t("dimensions_lwh"), flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{`${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`}</Text>
        //     )
        // }},
        // {field: "barcodes", headerName: "Nr. of barcodes", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }
    
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                sortModel={{field: columns[0].field,type:"ASC"}}
                content={useGetShippersQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                
                columns={columns} 
                onRowClick={getShipperDetails}>
            </ShDataGrid2>

            <MpAddShipper onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpShipperDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    shipperId={shipperId}
                    // data={shipperData}                
                /> 
            }
        </Box>
    )
};


export default All;