
import WarehouseIcon from '@mui/icons-material/Warehouse';


export const relationOrdersConfig = {
    orders:  {
        title: 'all_orders',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    ordersActionRequired: {
        title: 'action_required',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    ordersShipped: {
        title: 'shipped',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
};
