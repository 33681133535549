import { createSlice } from '@reduxjs/toolkit';
// import { generalApiSlice } from './generalApiSlice';
import { PURGE, REHYDRATE } from 'redux-persist';
import { apiSlice } from '../createApiSlice';
import { generalApiSlice } from './generalApiSlice';
import { setupListeners } from '@reduxjs/toolkit/query';
import { ENV_TYPES } from '../../components/global/Sidebar';
import { cacheKeys } from '../cacheKeys';
import { themeSettings } from '../../theme';
import { createTheme } from '@mui/material';

const initialSidebarState = { 
  selectedMenuItem: "Dashboard",
  selectedEnvironmentType: 'COMPANY',
  selectedEnvironmentActive: 'core',
  selectedEnvironmentIndex: 1,
  selectedId: 0,
  isFulfilment: false,
  relationCustomerId: 0,
  relationRootId: 0,
  collapsed: window.innerWidth <= 1369,
};

const initialState = {
  environment: initialSidebarState,
  language: 'nl',
  workSpace: {
    id: -1,
    name: "workspace_not_set"
  },
  themeMode: 'light',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialState,
  
  reducers: {
    toggleMenu: (state) => {
      state.environment.collapsed = !state.environment.collapsed; // Toggle the menu state
    },
    updateMenuState: (state, action) => {
      state.environment.collapsed = action.payload; // Set the menu state explicitly
    },
    //Sidebar stuff
    resetSideBar: (state) => {
      state.environment = initialSidebarState;
    },
    setSelectedMenuItem: (state, action) => {
      state.environment.selectedMenuItem = action.payload;
    },
    setWorkSpace: (state, action) => {
      state.workSpace = action.payload;
    },
    setSelectedEnvironment: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        if (state.environment.hasOwnProperty(key)) {
          state.environment[key] = action.payload[key];
        }
      });
    },
    setSelectedInnerEnvironment: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        if (state.environment.hasOwnProperty(key)) {
          state.environment[key] = action.payload[key];
        }
      });
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    //Language settings
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    // Additional reducers for other settings can be added here
  },
  extraReducers: (builder) => {
    builder
      // ... your other matchers
      .addCase(REHYDRATE, (state, action) => {
        // Update this to handle the nested structure
        if (action.payload && action.payload.settings) {
          return { ...state, ...action.payload.settings };
        }
        return state;
      })
      .addCase(PURGE, (state) => {
        // Reset only the environment part of the state
        return {
          ...state,
          workSpace: state.workSpace,
          themeMode: state.themeMode,
        };
      })
  },
});

export const { 
  setSelectedMenuItem, 
  setSelectedEnvironment, 
  setSelectedInnerEnvironment, 
  resetSideBar,
  setLanguage,
  setWorkSpace, 
  setThemeMode,
  toggleMenu,
  updateMenuState,
} = settingsSlice.actions;


// Selector to be used in components
export const selectThemeMode = (state) => state.settings.themeMode;

// Function to create a theme based on the current mode
export const getTheme = (mode) => createTheme(themeSettings(mode));

export const selectEnvironmentid = (state) => state.settings.environment.selectedId;


export default settingsSlice;

