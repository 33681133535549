
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const SHIPPERS_BASE = "/shipperprofiles";
const buildUrl = (endpoint) => SHIPPERS_BASE + endpoint;
const ApiName = "ShipperProfiles";


const shippersApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getShipperProfiles: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}` , identifier: 'datatable'}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getShipperProfiles', `${ApiName}`)
        }),
        getShipperProfilesSelect: builder.query({
            query: (args) => ({
                url: buildUrl(`/select`),
                method: 'POST',
                body: args
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}`, identifier: `select-${arg?.warehouseId ?? 0}` }],
        }),
        getShipperProfile: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        addNewShipperProfile: builder.mutation({
            query: shipperInfo => {
                
                return {
                    url: buildUrl(`/new`),
                    method: 'POST',
                    body: shipperInfo,
                }
            },
            invalidatesTags: [`${ApiName}`],
        }),
        editShipperProfile: builder.mutation({
            query: shipperInfo => ({
                url: buildUrl(`/${shipperInfo.id}`),
                method: 'PUT',
                body: shipperInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
        deleteShipperProfile: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
    })
});

export const {
    useGetShipperProfilesQuery,
    useGetShipperProfilesSelectQuery,
    useGetShipperProfileQuery,
    useAddNewShipperProfileMutation,
    useEditShipperProfileMutation,
    useDeleteShipperProfileMutation
  } = shippersApiSlice;