// Define a configuration object for different shipper types
export const shipperConfigurations = {
    postnl: {
        fields: [
            { name: 'apiKey', type: 'text', label: 'api_key' },
            { name: 'customerNumber', type: 'text', label: 'customer_number' },
            { name: 'customerCode', type: 'text', label: 'customer_code' },
            { name: 'mode', type: 'dropdown', label: 'mode', options: [
                { label: 'test', value: 'test' },
                { label: 'production', value: 'production' }
            ] }
        ],
        saveObject: {
            apiKey: "",
            customerNumber: "",
            customerCode: "",
            mode: ""
        }
    },
    dhl: {
        fields: [
            { name: 'key', type: 'text', label: 'key' },
            { name: 'userId', type: 'text', label: 'user_id' },
        ],
        saveObject: {
            key: "",
            userId: ""
        }
    },
    parcel: {
        fields: [
            { name: 'username', type: 'text', label: 'username' },
            { name: 'password', type: 'text', label: 'password' },
            { name: 'mode', type: 'dropdown', label: 'mode', options: [
                { label: 'test', value: 'test' },
                { label: 'production', value: 'production' }
            ] }
        ],
        saveObject: {
            username: "",
            password: "",
            mode: ""
        }
    },
    cirro: {
        fields: [
            { name: 'clientId', type: 'text', label: 'client_id' },
            { name: 'secret', type: 'text', label: 'secret' },
            { name: 'mode', type: 'dropdown', label: 'mode', options: [
                { label: 'test', value: 'test' },
                { label: 'production', value: 'production' }
            ] }
        ],
        saveObject: {
            clientId: "",
            secret: "",
            mode: ""
        }
    },
    parcel2send: {
        fields: [
            { name: 'shipmentMethod', type: 'dropdown', label: 'shipment_method', options: [
                { label: 'Standaard', value: 'default' },
                { label: 'DPD Home', value: 'dpd_home' },
                { label: 'DPD Home Saturday delivery', value: 'dpd_home_saturday' }
            ] },
            { name: 'insuredAmount', type: 'text', label: 'insured_amount' },
            { name: 'length', type: 'text', label: 'length' },
            { name: 'width', type: 'text', label: 'width' },
            { name: 'height', type: 'text', label: 'height' }
        ],
        saveObject: {
            shipmentMethod: "",
            insuredAmount: "",
            length: "",
            width: "",
            height: ""
        }
    },
    sendcloud: {
        fields: [
            { name: 'publicKey', type: 'text', label: 'public_key' },
            { name: 'privateKey', type: 'text', label: 'private_key' }
        ],
        saveObject: {
            publicKey: "",
            privateKey: "",
        }
    },
    dpd: {
        fields: [
            { name: 'privateKey', type: 'text', label: 'private_key' },
            { name: 'publicKey', type: 'text', label: 'public_key' },
        ],
        saveObject: {
            privateKey: "",
            publicKey: ""
        }
    }
};