import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import MpAddInbound from "../../../../../components/global/ModalsProgression/MpAddInbound/MpAddInbound";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import InboundsCard from "../../../../../components/global/cards/inbounds/InboundsCard";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetInboundsQuery } from "../../../../../newapi/inbound/inboundsSlice";
import { selectUserPermission } from "../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../utils/permissions';

import { useSelector } from "react-redux";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import { getInboundStateData } from '../../../../../utils/labelColorText';
import ShCopyButton from '../../../../../components/theme/ShCopy';
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
const SpInboundDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpInboundDetails/SpInboundDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_inbound')}
            </Shbutton>
            )}
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const InboundInbounds = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.INBOUND_MANAGE])
    );

    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const handleOpen = () => {
        setAddModalOpen(true);
    }
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>,
        });

        return () => {
            
        }
    },[]);
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };


    const [inboundIsExtended, setInboundIsExtended] = useState(false);
    const [inboundId, setInboundId] = useState();
    const inboundIdRef = useRef(inboundId);
    
    const getInboundDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (inboundIdRef.current !== newProductId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setInboundId(newProductId);
            inboundIdRef.current = newProductId; // Update the ref to the new value
        }
        setInboundIsExtended(true);
    };


    const columns = [
        ...isFulfilment ? [{field: "relation", headerName: t("relation"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                <Text >
                    {params.row.relation?.name}
                </Text>)
            }
        }] : [],
        {
            field: "shipmentDate", 
            headerName: t("shipment_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.shipmentDate);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {field: "supplier", headerName: t("supplier"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text>
                        {params.row.supplier?.name}
                    </Text>
                )
            }
        },
        {field: "reference", headerName: t("reference"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box sx={{
                        minWidth:"100%",
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        whiteSpace:"nowrap",
                        wordBreak:"keep-all",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text noBreak>{params.row.reference}</Text>
                        <ShCopyButton 
                            styles={{button:{
                                background:theme.palette.mode === "dark" ? colors.grey[600] :  colors.grey[200],
                                color: colors.txt["primary"],
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[500] :colors.grey[300],

                                }
                            }}} 
                            sx={{position:"absolute",right:0,zIndex:3000}} 
                            className={"copyel"} 
                            closeAfter={1500} 
                            value={params.row.reference}
                        ></ShCopyButton>
                    </Box>
                )
            }
        },
        {field: "warehouse", headerName: t("warehouse"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text >
                        {params.row.warehouse?.name}
                    </Text>
                )
            }
        },
        {field: "skuCount", headerName: t("sku_count"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.skuCount;
            }
        },        
        // {field: "trackandtrace", headerName: t("track_and_trace"),flex:1},
        {field: "pallets", headerName: t("pallets"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.pallets;
            }
        },
        {field: "itemCount", headerName: t("items"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.itemCount;
            }
        },
        {field: "colli", headerName: t("colli"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.colli;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:2,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                const { labelcolor, labeltext } = getInboundStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} fitted palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {
            field: "receivedFromDate", 
            headerName: t("received_date"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                if (!params.row.receivedFromDate || params.row.receivedFromDate === null) {
                    return <Text>-</Text>
                }

                let date = new Date(params.row.receivedFromDate);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <InboundsCard skeleton={params.row.skeleton ?? false} {...params.row} onClick={() => getInboundDetails(params)} />
                // <Box padding={3} sx={{background:"orange"}}></Box>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >
                    //     {cellValues.row.id + " " + cellValues.row.name}
                    //     <br/>
                    //     {cellValues.row.age}
                    //     <br/>
                    //     {cellValues.row.phone}
                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInboundsQuery}
                config={props.filter}
                gridActions={<GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t('all_inbounds') }
                gridOptions={gridOptions} 
                sortModel={{field: columns[1].field,type:"DESC"}}
                columns={columns}
                onRowClick={getInboundDetails}>
            </ShDataGrid2>
            {spLoading && 
                <SpInboundDetails
                    isExtended={inboundIsExtended}
                    setIsExtended={setInboundIsExtended}
                    inboundId={inboundId}
                // data={inboundData}
                /> 
            }
            
            <MpAddInbound onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />

        </Box>
    );
}

export default InboundInbounds;