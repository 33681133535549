// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const [accountingSoftwareCredentials, setAccountingSoftwareCredentials] = useState(data.credentials);

    const handleDetailsChange = (key, val) => {
        setAccountingSoftwareCredentials({
            ...accountingSoftwareCredentials,
            [key]: val
        });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    }

    useEffect(() => {
        props.handleDataUpdate({ credentials: accountingSoftwareCredentials });
    }, [accountingSoftwareCredentials]);

    return (
        <>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("description")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex"
                    }}
                >
                    <ShValidatedInput
                        name="description"
                        value={data.description ?? ""}
                        onChange={handleInput}
                        error={props.errors.description}
                    />
                    
                </Box>
            </Box>
            {Object.keys(accountingSoftwareCredentials)
            .map((key) => (
                <Box 
                    key={key}
                    sx={{
                        width: 1,
                        paddingTop: 1.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 7
                    }}
                >
                    <Box
                        sx={{
                            width: 120,
                        }}
                    >
                        <Text>{t(key)}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex: 1,
                            //backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            borderRadius: 4,
                            display: "flex"
                        }}
                    >
                        <ShValidatedInput
                            name={key}
                            value={accountingSoftwareCredentials[key] ?? ""}
                            onChange={handleDetailsChange}
                            error={props.errors[`credentials.${key}`]}
                        />
                    </Box>
                </Box>
            ))}
    </>
    );
};

const Details = (props) => {
    const { t } = useTranslation();

    // Create a mapping of fields to their webshop types
    const FIELD_TO_ACCOUNTINGSOFTWARE = {
        'administration_id': ['moneybird'],
        'access_token': ['moneybird'],
    };

    // Create conditional schema
    const conditionalSchema = [
        {
            key: "description",
            errorField: t('description'),
            alwaysRequired: true,
            type: "text",
        },
        ...Object.entries(FIELD_TO_ACCOUNTINGSOFTWARE).map(([field, accountingSoftwareTypes]) => ({
            key: `credentials.${field}`,  // Add credentials prefix
            errorField: t(field),
            alwaysRequired: false,
            condition: (data) => accountingSoftwareTypes.includes(data.type),
            type: "text"
        }))
    ];

    return (
        <ProgressionTab 
            tabId="tabDetails" 
            subtitle={t("details")} 
            step={2}
            maxSteps={3}
            nextTab={"tabSync"}
            conditionalSchema={conditionalSchema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default Details;