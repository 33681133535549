// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import NumberInput from '../../../../theme/inputs/NumberInput';
import ShDateRangePicker from "../../../../theme/ShDateRangePicker";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { useDispatch } from 'react-redux';
import * as yup from "yup";
import { yupRequired, yupNumberRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useGetSuppliersSelectQuery } from '../../../../../newapi/global/supplier/supplierSlice';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import { general_states } from '../../../../../utils/staticEnums';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const { data: suppliers, isLoading } = useGetSuppliersSelectQuery();

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    const select = (date) => {
        const formattedDate = date[0].startDate.toISOString().split('T')[0];
        props.handleDataUpdate({ shipmentDate: formattedDate });
    }

    // Set virtual product is true for POST request
    useEffect(() => {
        if(loaded) {
            props.handleDataUpdate({ isVirtual: true });
        }
        setLoaded(true);
    }, [loaded]);

    useEffect(() => {
        console.log(props.orderData);
        props.handleDataUpdate({ 
            state: general_states.ACTIVE,
            reference: "R"+props.orderData.barcode,
            name: props.orderData.deliveryName,
            email: props.orderData.deliveryEmail,
            relationId: props.orderData.relationId,
            orderId: props.orderData.id,
         });
    },[])

    return (
        <>
            <Box 
                sx={{
                    display:'flex',
                    flexDirection:"column",
                }}
            >
                 <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("relation")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            disabled={true}
                            name="relation"
                            value={props.orderData.relation?.name}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:140,
                        }}
                    >
                        <Text>{t("reference")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            disabled={true}
                            name="reference"
                            value={data.reference}
                            onChange={handleInput}
                            error={props.errors.reference}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 1.5,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    
                    <Box
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("name")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            name="name"
                            value={data.name}
                            onChange={handleInput}
                            error={props.errors.name}
                        />
                    </Box>
                    
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        paddingTop: 1.5,
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                    }}
                >
                    
                    <Box
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("trackandtrace")}</Text>
                    </Box>
                   
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            name="trackandtrace"
                            value={data.trackandtrace}
                            onChange={handleInput}
                            error={props.errors.trackandtrace}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        paddingTop: 1.5,
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                    }}
                >
                    <Box
                        sx={{
                            width:140,
                            display:"flex",
                            alignItems:"center",
                        }}
                    >
                        <Text>{t("email")}</Text>
                    </Box>
                   
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            name="email"
                            value={data.email}
                            onChange={handleInput}
                            error={props.errors.email}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

const Details = (props) => {
    const {t} = useTranslation();
    const schema = yup.object({
        reference: yupRequired('reference',t),
        name: yupRequired('name',t),
        email: yupRequired('email',t),
    });

    return (
        <ProgressionTab 
            tabId="tabDetails" 
            subtitle={t("Details")} 
            step={1}
            nextTab={"tabReturnLines"}
            maxSteps={2}
            schema={schema}
        >
            <Content orderData={props.data}/>
        </ProgressionTab>
    )
}

export default Details;

