import { Box, InputBase, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import Text from "../../theme/text/Text";
import { useEffect, useState } from "react";
import { hexToRgb, isValidHex } from "../../../utils/colorConverters";
import { ShColorPicker } from "../../ShColorPicker";
import { useTranslation } from "react-i18next";

export const SHPBBackgroundColor = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {t} = useTranslation();

    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
    };

    const [localValue, setLocalValue] = useState(props.value?.hex);

    const changeOpacity = (e) => {
        const value = e.target.value;
        // Allow only digits, and ensure that the value stays between 0 and 100
        if (/^\d*$/.test(value)) {
            let numericValue = Number(value);
        
            // Ensure the value is between 0 and 100
            if (numericValue < 0) {
                numericValue = 0;
            } else if (numericValue > 100) {
                numericValue = 100;
            }
        
            if(props.setValue){
                props.setValue({hex: props.value.hex,opacity: numericValue/100,puck:props.value.puck});
            }
        }
    };

    const changeHex = (e) => {
        const value = e.target.value;
        if(isValidHex(value)) {
            if(props.setValue){
                props.setValue({hex: value,opacity: props.value.opacity,puck:props.value.puck});
            }
        }
    }

    useEffect(() => {
        if(props.value  && props.value.hex){
            setLocalValue(props.value.hex);
        }
    },[props.value])

    return (
        <Box
            sx={{
            }}
        >
            <Text light sx={{pb:1,}}>
                {t("background_color")}
            </Text>
            <Box
                sx={{
                    display:"flex",
                    height:40,
                    background: colors.bg['tertiary'],
                    alignItems:"center",
                    pl:1,
                    pr:1,
                    borderRadius:5,
                    color: colors.txt["primary"]
                }}
            >
                <Box
                    onClick={handleFilterPopoverClick}
                    sx={{
                        borderRadius:3,
                        height:24,
                        width:24,
                        flexShrink:0,
                        background:localValue ? `rgba(${hexToRgb(localValue)},${props.value.opacity})` : "#fff",
                    }}
                >

                </Box>

                <InputBase
                    value={localValue}
                    onInput={changeHex}
                    sx={{
                        ml:1.5,
                        flex:1,
                        color: colors.txt["primary"],
                        fontFamily:"Roboto, sans-serif",
                    }}
                />
                <Box
                    sx={{
                        height:24,
                        background:colors.grey[200],
                        borderRadius:4,
                        display:"flex",
                        alignItems:"center",
                        pl:2,
                        pr:1.5,
                    }}
                >
                    <InputBase
                        onInput={changeOpacity}
                        value={props.value ?   parseInt(props.value.opacity * 100) : 100}
                        sx={{
                            width:32,
                            color: colors.txt["primary"],
                            fontFamily:"Roboto, sans-serif",
                        }}
                    />
                    <Text bold>%</Text>
                </Box>
            </Box>
            <ShColorPicker
                value={props.value}
                setValue={props.setValue}
                id={filterPopoverId}
                open={filterPopoverOpen}
                anchorEl={filterAnchorEl}
                onClose={handleFilterPopoverClose}
            
            />
        </Box>
    )
}