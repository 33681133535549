import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import { tokens } from "../../../../../theme";
// import { getAllStock } from "../../../../api/warehouse/stock";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { WarehouseStockCard } from "../../../../../components/global/cards/products/StockCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShCopyButton from "../../../../../components/theme/ShCopy";
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../../components/theme/text/Text";
import { useGetStockQuery } from "../../../../../newapi/warehouse/stockSlice";

const SpStockDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails"));
const SPLocationDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpLocationDetails/SpLocationDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                // onClick={confirm} 
                endIcon={<AddIcon/>}
                variant="contained"
            >
                New
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const CurrentStock = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isExtended, setIsExtended] = useState(false);
    const [spStockLoading, setSpStockLoading] = useState();

    const [locationIsExtended, setLocationIsExtended] = useState(false);
    const [spLocationLoading, setSpLocationLoading] = useState();

    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);
    

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.product.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            if(!spStockLoading){
                setSpStockLoading(true);
            }
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
            setIsExtended(true);
    };
    


    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            if(!spLocationLoading){
                setSpLocationLoading(true);
            }
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
            setLocationIsExtended(true);
    };
    

    

    const columns = useMemo(() =>[
        {
            field: "sku",
            dataField: 'product.sku',
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
                }
                // const handleClick = () => getProductDetails(params.row.product);
                return (
                    <Box sx={{
                        width:1,
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
                        <ShCopyButton className={"copyel"} closeAfter={1500} value={params.row.product.sku}></ShCopyButton>
                    </Box>
                );
            }
        },
        {
            field: "barcode",
            headerName: t("barcode"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Box sx={{
                        width:1,
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text clickable >{params.row.product.barcode}</Text>
                        <ShCopyButton color={colors.txt["primary"]} className={"copyel"} closeAfter={1500} value={params.row.product.barcode}></ShCopyButton>
                    </Box>
                );
                // return params.row.product.barcode
            },
        },
        {
            field: "description",
            headerName: t("description"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.product.description
            },
        },
        {
            field: "location",
            headerName: t("location"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton variant={"yellow"}/>
                }
                const handleClick = (event) => {
                    event.stopPropagation(); // Prevent click from propagating to the row
                    getLocationDetails(params.row.warehouseLocation);
                };
                return (
                    <Box onClick={handleClick}>
                        <ShLabel clickable size={32} fitted variant={"yellow"}>
                            {params.row.warehouseLocation.location}
                        </ShLabel>
                    </Box>
                )
            }
        },
        // {
        //     field: "handmatig",
        //     headerName: t("manual"),
        //     flex:1,
        //     renderCell : (params) => {
        //         if(params.row.skeleton) {
        //             return <ShTextSkeleton/>
        //         }
        //         return params.row.warehouseLocation.reachTypeId
        //     },
        // },
        ...isFulfilment ? [{
            field: "relation",
            headerName: t("relation"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.product?.relation?.name ?? t("unknown")
            },
        }] : [],
        {
            field: "available",
            headerName: t("stock"),
            flex:1,
            shFilter: {
                type: "number"
            },
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.available
            },
        },
        {
            field: "batch",
            headerName: t("batch"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return ""
            },
        },
        {
            field: "thtdatum",
            headerName: t("bbdate"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return ""
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseStockCard
                    isFulfilment={isFulfilment}
                        skeleton={params.row.skeleton ?? false} 
                        {...params.row} 
                        onClick={() => getProductDetails(params)} 
                    />
                )
            }
        }
    ],[theme.palette.mode,t,isFulfilment,spLocationLoading]);

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2
                key={theme.palette.mode}
                content={useGetStockQuery}
                // gridActions={<GridActions></GridActions>} 
                title={t('overview')}
                gridOptions={gridOptions}
                onRowClick={getProductDetails}
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns}
            />

            {spStockLoading && 
                <SpStockDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    productId={productId}
                />
            }
            {spLocationLoading && 
                <SPLocationDetails
                    isExtended={locationIsExtended}
                    setIsExtended={setLocationIsExtended}
                    locationId={locationId}
                />
            }
            
        </Box>
    );
};
export default CurrentStock;






























// component : (itemsLoaded) => {
//     return <Custom dropdown item/>
// }