import Loadable from 'react-loadable';
import SPLoader from './SPLoader';

// Utility to create Loadable components
const SidepanelLoader = (importPath) => {
  return Loadable({
    loader: importPath,
    loading: SPLoader,
    delay: 200, // Optional delay for fallback UI
    // timeout: 10000, // Timeout for how long to wait before failing the load
  });
};

export default SidepanelLoader;