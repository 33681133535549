// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { yupEmailRequired, yupRequired } from '../../../../../utils/validation';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { useGetRelationsSelectQuery } from '../../../../../newapi/global/relation/relationSlice';
import { InputDropdown } from '../../../../theme/dropdowns/InputDropdown';
import { useGetWebshopCredentialsSelectQuery } from '../../../../../newapi/webshop/webshopSlice';
import { ENV_TYPES } from '../../../Sidebar';
import { useGetShippersSelectQuery } from '../../../../../newapi/shipper/shipperSlice';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
    const [shipper, setShipper] = useState(props.shipper);
    const { data: shippers } = useGetShippersSelectQuery();

    const handleChange = (key, val) => {
        if(key === "shipperId"){
            const selectedShipper = shippers.find(shipper => shipper.id === val);
            const shipperType = selectedShipper ? selectedShipper.type : null;
            if(shipperType){
                props.handleDataUpdate({ shipperType: shipperType });
            }
        }
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };
    
    return (
        <>
            <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("name")}</Text>
                </Box>
                
                <ShValidatedInput
                    name="name"
                    label={t("name")}
                    value={data?.name}
                    onChange={handleChange}
                    error={props.errors.name}
                />
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                        justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("shipper")}</Text>
                </Box>
                
                
                <InputDropdown
                        key={data?.shipperId}
                        name="shipper"
                        displayName="name"
                        changeField="id"
                        valueKey="id"
                        selected={data?.shipperId ?? -1}
                        options={shippers}
                        noSelection={t("choose_shipper")} 
                        onChange={(val) => handleChange("shipperId",val)}
                    />
            </Box>
        </>
    );
};

const BasicDetails = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        name: yupRequired('name',t),
        shipperId: yupRequired('shipper',t),
    });

    return (
        <ProgressionTab 
        tabId="BasicDetails" 
        subtitle={t('basic_details')} 
        step={1}
        nextTab={"tabConfiguration"}
        maxSteps={2}
        schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default BasicDetails;