import { Box, List, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import Subheading from "../../text/Subheading";
import Text from "../../text/Text";


import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PinAlt } from '../../../../styles/svg/pin.svg';
import { useGridFilter } from "../../../../contexts/GridFilterContext";
import { updateShGridColumn } from "../../../../newapi/global/filter/gridFilterSlice";
import ShSwitch from "../../inputs/ShSwitch";

const GridcolumnSettingsView = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const {index,id} = useGridFilter();
    const gridFilter = useSelector((state) => state.gridFilter);
    const filterObject = gridFilter.viewInstances[index][id];

    const isPinned = (column) => {
        return column.pinned;
    }
    const changePin = (column) => {
        dispatch(updateShGridColumn({
            index,
            id,
            column: column.column,
            pinned: !column.pinned,
        }));
    }
    
    const changeVisibility = (column) => {
        dispatch(updateShGridColumn({
            index,
            id,
            column: column.column,
            visible: !column.visible,
        }));
    }

    return (
        <Box
            sx={{
                // padding:1.5,
                width: "520px",
                maxHeight:"400px",
                display:"flex",
                flexDirection:"column",
                overflow:"auto",
                "@media screen and (max-width: 47.9375em)" : {
                    width:1,
                }
            }}
        >
            <Box 
            sx={{
                display:"flex",
                justifyContent:"space-between",
                alignItems:"center",
                position:"relative",
                textAlign:"center",
                paddingTop:3,
                paddingLeft:3,
                paddingRight:1.75
            }}>

                <Subheading>Kolommen</Subheading>
                <Box
                    sx={{
                        display:"inline-flex",
                        alignItems:"center",
                        gap:3,
                        "& .pin-icon path" : {
                            fill: colors.txt["primary"]
                        }
                    }}
                >
                    <Box
                        sx={{
                            width:58,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                justifyContent:"flex-end",
                                pr:1,
                            }
                        }}
                    >
                        <RemoveRedEyeOutlinedIcon/>
                    </Box>
                    <Box
                        sx={{
                            width:58,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"center",
                            "@media screen and (max-width: 47.9375em)" : {
                                justifyContent:"flex-end",
                                pr:2,
                            }
                        }}
                    >
                        <PinAlt className="pin-icon"/>
                    </Box>
                </Box>
            </Box>
            <Box
                >
                    <List
                        sx={{
                            padding:0,
                            paddingBottom:2.5,
                        }}
                    >
                    {filterObject.columns.map((column, index) => (
                            <Box 
                            key={column.column}
                            sx={{
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center",
                                position:"relative",
                                textAlign:"center",
                                paddingTop:0.5,
                                paddingLeft:3,
                                paddingRight:1.25,
                            }}>
                                <Text light>{column.name}</Text>
                                <Box
                                    sx={{
                                        display:"inline-flex",
                                        alignItems:"center",
                                        gap:3,
                                        "& .pin-icon path" : {
                                            fill: colors.txt["primary"]
                                        }
                                    }}
                                >
                                    {/* Visibility switch */}
                                    <Box
                                        sx={{
                                            width:58,
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center"
                                        }}
                                    >
                                        <ShSwitch color={theme.palette.mode === "dark" ? "blue": "primary"} onChange={() => changeVisibility(column)} checked={column.visible}/>
                                    </Box>

                                    {/* Pin switch */}
                                    <Box
                                        sx={{
                                            width:58,
                                            display:"flex",
                                            alignItems:"center",
                                            justifyContent:"center"
                                        }}
                                    >
                                        <ShSwitch color={theme.palette.mode === "dark" ? "blue": "primary"} onChange={() => changePin(column)} checked={column.pinned}/>
                                    </Box>
                                </Box>
                            </Box>

                        // <ListItem key={column.field + "index"} disablePadding onClick={() => props.sort(column)}>
                        //     <ListItemButton 
                        //         sx={{
                        //             color:`${props.sortedBy !== null && props.sortedBy.field === column.field ? theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400] : colors.txt["secondary"]}`,
                        //             fontWeight:`${props.sortedBy !== null && props.sortedBy.field === column.field ? 500 : 400}`,
                        //             display:"flex",
                        //             justifyContent:"space-between",
                        //             fontSize:"14px",
                        //             paddingX:1.5,
                        //             height:40,
                        //             borderRadius:5,
                        //         }}
                        //     >
                        //         {column.headerName}

                        //         {props.sortedBy !== null && props.sortedBy.field === column.field 
                        //             ?
                        //             <CheckIcon fontSize="small"/>
                        //             :
                        //             null
                        //         }
                        //     </ListItemButton>
                        // </ListItem>
                        // <Box>
                        //     {column.headerName}
                        // </Box>
                    ))}
                    </List>
                </Box>
                
        </Box>
    )
}
export default GridcolumnSettingsView;