import { useTheme } from "@emotion/react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, ButtonBase, InputBase, Slide } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import MConfirmationWithValue from "../../../../components/global/Modals/MConfirmationWithValue";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import { DropdownBase } from "../../../../components/theme/dropdowns/Dropdown";
import ShSwitch from "../../../../components/theme/inputs/ShSwitch";
import ShLabel from "../../../../components/theme/label/ShLabel";
import Subheading from "../../../../components/theme/text/Subheading";
import Heading from "../../../../components/theme/text/Heading";
import Text from "../../../../components/theme/text/Text";
import { useGetCarrierBarcodeMutationMutation, useGetCarriersSelectQuery } from "../../../../newapi/carrier/carrierSlice";
import { useFinalizeOrderMutation, useFindPickingJobMutation, useFindProductInPickingJobMutation, useGetDeliveryOptionsQuery, useSetJokerMutation } from "../../../../newapi/global/echeckSlice";
import { useCancelShipmentMutation, useGetShipmentLabelMutationMutation, useGetShipmentLabelQuery, useReplaceShipmentMutation } from "../../../../newapi/order/ordersSlice";
import { useGetShippersSelectWithProfilesQuery } from "../../../../newapi/shipper/shipperSlice";
import { tokens } from "../../../../theme";
import { showToastMessage } from "../../../../utils/toasts";
import ContainerView from "./ContainerView";
import Truck from "./truck/Truck";
import { format } from "date-fns";
import cx from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { general_states } from "../../../../utils/staticEnums";

const ECheckView = (props) => {
    const { t, ready,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 

    const [showOptions, setShowOptions] = useState(false);
    const [dataHasBeenSet, setDataHasBeenSet] = useState(false);
    const inputRef = useRef(null);
    const secondaryInputRef=useRef(null);

    // const [firstScannedBarcode,set]
    // console.log(props.selectedWorkSpace);
    // const [currentOrderId, setCurrentOrderId] = useState(0);
    const [currentOrder, setCurrentOrder] = useState(null);
    const [currentOrderWarehouseId, setCurrentOrderWarehouseId] = useState(null);
    const [toScanRows, setToScanRows] = useState(null);

    const [toScanProducts, setToScanProducts] = useState(null);

    // getpicking
    const [findProductInPickingJob, {data: orderInJobData, isLoading: orderInJobLoading, isFetching: orderInJobFetching}] = useFindProductInPickingJobMutation();

    useEffect(() => {
        if(props.incData){
            setToScanProducts(props.incData.products);
        }
    }, [props.incData])

    const [suggestedCarrier, setSuggestedCarrier] = useState(null);

    const [selectedShipper, setSelectedShipper] = useState('');
    const [selectedShipperProfile, setSelectedShipperProfile] = useState('');

    const [totalWeight, setTotalWeight] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);

    const [matchedProduct, setMatchedProduct] = useState(null);
    const [incCarrier, setIncCarrier] = useState("dummy");

    const [finalizeResponse, {isLoading: finalizeLoading,isFetching:finalizeFetching}] = useFinalizeOrderMutation();

    const [replaceLabelResponse, {isLoading: replaceLoading,isFetching:replaceFetching}] = useReplaceShipmentMutation();

    const [setJokerResponse, {isLoading: setJokerLoading}] = useSetJokerMutation();
    const [deliveryOptions, setDeliveryOptions] = useState({});
    const [carrierForDeliveryToFetch, setCarrierForDeliveryToFetch] = useState(null);
    const [deliveryOptionsFetchData, setDeliveryOptionsFetchData] = useState(null);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedOrderSelectShipper, setSelectedOrderSelectShipper] = useState(null);
    const [selectedOrderSelectShipperProfile, setSelectedOrderSelectShipperProfile] = useState(null);


    const { data: deliveryOptionsData } = useGetDeliveryOptionsQuery(deliveryOptionsFetchData, {
        skip: !deliveryOptionsFetchData
    });

    // const [carrierRows, setCarrierRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [paModalOpen, paModalSetOpen] = useState(false);
    const [modalData, setModalData] = useState(0);
    const [paModalData, setPaModalData] = useState(0);

    const [ fetchCarrierBarcode, {data: fetchedIncCarrier,isError, isLoading: isLoadingC, refetch: refetchCarrier}] = useGetCarrierBarcodeMutationMutation();

    const [carriers, setCarriers] = useState([{
        "carrierId" : null,
        "carrierName" : "",
        "totalProducts" : 0,
        "totalWeight" : 0,
        "deliveryOption": null,
        "rows": [],
    }]);

    const confirmModalOrder = (id) => {
        // setCurrentOrderId(id);
    }

    const confirmModal = {
        open: open,
        setOpen: setOpen,
        data:modalData,
        confirm: confirmModalOrder
    }

    const confirmProductAmount = (amount) => {
        if (amount === 0) {
            return;
        }
        
        updateCarrierAndScanRows(parseInt(amount));
    }

    const productAmountModal = {
        open: paModalOpen,
        setOpen: paModalSetOpen,
        data:paModalData,
        confirm: confirmProductAmount
    }

    const [getMyJob, {data: individualJob, isLoading: invididualJobLoading}] = useFindPickingJobMutation();

    const { data: shippers } = useGetShippersSelectWithProfilesQuery({warehouseId: currentOrderWarehouseId},
        {
            skip: !currentOrderWarehouseId
        }
    );

    const { data: shippersSelected } = useGetShippersSelectWithProfilesQuery({warehouseId: selectedOrder?.warehouseId},
        {
            skip: !selectedOrder
        }
    );

    const [goFinalize, setGoFinalize] = useState(false);
    const [timer, setTimer] = useState(null);
    const [finalizeReady, setFinalizeReady] = useState(false);
    const [showSlide, setShowSlide] = useState(false); // Controls slide visibility

    // useEffect(() => {
    //     if(currentOrder !== null)
    //     {
    //         // console.log("uwu",currentOrder.orderlines);
    //         // setCurrentOrder(incData?.orders[currentOrderId]);
    //         setSuggestedCarrier(currentOrder?.suggestedCarrier);
    //         setToScanRows(currentOrder.orderlines);
    //         setCurrentOrderWarehouseId(currentOrder.warehouseId);
    //         findLocalProduct(currentOrder.ord)
            
    //         //reset carriers
    //         setCarriers([{
    //             "carrierId" : null,
    //             "carrierName" : "",
    //             "totalProducts" : 0,
    //             "totalWeight" : 0,
    //             "deliveryOption": null,
    //             "rows": [],
    //         }]);
    //     }
    // }, [currentOrder]);

    useEffect(() => {
        if(!shippers || !currentOrder) return;
        setSelectedShipper(shippers.filter(shipper => shipper.id === currentOrder?.shipper?.id)[0])
        const shipperProfileId = currentOrder?.shipperProfile?.id;
        const selectedShipperProfile = shippers.flatMap(shipper => shipper.shipperProfiles).find(profile => profile.id === shipperProfileId);
        if(selectedShipperProfile){
            setSelectedShipperProfile(selectedShipperProfile);
        } 
        // else {
            // set selected shipper profile to first profile of selected shipper
        //     setSelectedShipperProfile(shippers.filter(shipper => shipper.id === incData?.orders[currentOrderId]?.shipper?.id)[0].shipperProfiles[0]);
        // }
    }, [shippers,currentOrder])
    //     if(currentOrder !== null)
    //     {
    //         // console.log("uwu",currentOrder.orderlines);
    //         // setCurrentOrder(incData?.orders[currentOrderId]);
    //         setSuggestedCarrier(currentOrder?.suggestedCarrier);
    //         setToScanRows(currentOrder.orderlines);
    //         setCurrentOrderWarehouseId(currentOrder.warehouseId);
    //         findLocalProduct(currentOrder.ord)
            
    //         //reset carriers
    //         setCarriers([{
    //             "carrierId" : null,
    //             "carrierName" : "",
    //             "totalProducts" : 0,
    //             "totalWeight" : 0,
    //             "deliveryOption": null,
    //             "rows": [],
    //         }]);
    //     }
    // }, [currentOrder]);

    // const [selectedOrderSelectShipper, setSelectedOrderSelectShipper] = useState(null);
    // const [selectedOrderSelectShipperProfile, setSelectedOrderSelectShipperProfile] = useState(null);
    useEffect(() => {
        if(!shippersSelected || !selectedOrder) return;
        setSelectedOrderSelectShipper(shippersSelected.filter(shipper => shipper.id === selectedOrder?.shipperId)[0])
        const shipperProfileId = selectedOrder?.shipperProfileId;

        const selectedShipperProfile = shippersSelected.flatMap(shipper => shipper.shipperProfiles).find(profile => profile.id === shipperProfileId);
        if(selectedShipperProfile){
            setSelectedOrderSelectShipperProfile(selectedShipperProfile);
        } 
        // else {
            // set selected shipper profile to first profile of selected shipper
        //     setSelectedShipperProfile(shippers.filter(shipper => shipper.id === incData?.orders[currentOrderId]?.shipper?.id)[0].shipperProfiles[0]);
        // }
    }, [shippersSelected,selectedOrder])

    useEffect(() => {
        if(inputRef.current){
            inputRef.current.focus();
        }
    },[selectedShipperProfile,inputRef])

    // Handle mouse movement to cancel the timer
    useEffect(() => {
        const handleMouseMove = () => {
            if (timer) {
                clearTimeout(timer); // Cancel the timer if the mouse moves
                setShowSlide(false); // Hide the slide
                setFinalizeReady(false); // Reset finalize state
            }
        };
        window.addEventListener('mousemove', handleMouseMove);

        // Cleanup the event listener and timer on component unmount or effect cleanup
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [timer]);  // Re-run this effect if `timer` changes

    // Timer logic
    useEffect(() => {
        if (goFinalize) {
            //Temp removal of 5sec rule
            setFinalizeReady(true);
            return;

            // Start the timer for 5 seconds (or any duration you need)
            const newTimer = setTimeout(() => {
                setFinalizeReady(true); // Timer completed, ready to finalize
                setShowSlide(false); // Hide the slide once timer completes
            }, 5000); // 5000ms = 5 seconds

            setTimer(newTimer); // Store the timer ID so we can clear it later
            setShowSlide(true); // Show the slide while the timer is running

            // Cleanup the timer if finalized or component unmounts
            return () => {
                clearTimeout(newTimer);
            };
        }
    }, [goFinalize]);  // This effect runs when `goFinalize` state changes

    // Finalize logic (will only run if the timer completes and mouse doesn't move)
    useEffect(() => {
        if (finalizeReady) {
            if (selectedShipper.type === "bol") {
                if (carriers.every(carrier => carrier.deliveryOption !== null)) {
                    finalize();
                }
            } else {
                finalize();
            }

            setGoFinalize(false); 
        }
    }, [finalizeReady]);  // Runs when `finalizeReady` changes

    useEffect(() => {
        // console.log("delivery options data", deliveryOptionsData);
        if (deliveryOptionsData) {
            setDeliveryOptions(prev => ({
                ...prev,
                [carrierForDeliveryToFetch]: deliveryOptionsData.deliveryOptions
            }));
            setCarriers(prev => {
                const updatedCarriers = [...prev];
                updatedCarriers[carrierForDeliveryToFetch].deliveryOption = deliveryOptionsData.deliveryOptions?.find(option => option.recommended) || deliveryOptionsData.deliveryOptions[0];
                return updatedCarriers;
            });
            setCarrierForDeliveryToFetch(null); // Reset carrierToFetch after fetching
        }
    }, [deliveryOptionsData]);


    const selectShipper = (selected) => {
        var shipper = shippers?.filter(shipper => shipper.type !== 'bol' || currentOrder?.webshopcredentials?.webshop_type === 'bol')[selected];
        setSelectedShipper(shipper);
        setSelectedShipperProfile(-1);
    }

    const selectShipperProfile = (selected) => {
        var shipperProfile = selectedShipper?.shipperProfiles[selected];
        setSelectedShipperProfile(shipperProfile);
    }

    const selectedOrderSelectNewShipper = (selected) => {
        var shipper = shippersSelected?.filter(shipper => shipper.type !== 'bol' || selectedOrder.webshopcredentials?.webshop_type === 'bol')[selected];
        setSelectedOrderSelectShipper(shipper);
        setSelectedOrderSelectShipperProfile(-1);
    }
    const selectedOrderSelectNewShipperProfile = (selected) => {
        var shipperProfile = selectedOrderSelectShipper?.shipperProfiles[selected];
        setSelectedOrderSelectShipperProfile(shipperProfile);
    }


    const handleKeyDown = async (e) => {
        if (e.key === 'Enter') {
            if(currentOrder === null) {
                const foundProduct = props.incData.products.find(product => product.product.barcode === e.target.value);
                if(foundProduct){
                    if(foundProduct.amountEchecked === foundProduct.amount){
                    // if(currentOrder.amountEchecked === currentOrder.amount) {
                        props.setHighlightColor("orange");
                        e.target.value = "";
                        return;
                    }

                    await findProductInPickingJob({
                        pickingJobId:props.incData.pickingJobId,
                        barcode:e.target.value,
                    })
                    .unwrap()
                    .then(res => {
                        if(res.order){
                            setOrderToScanData(res,e.target.value);
                        } else {
                            props.setHighlightColor("red");
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        props.setHighlightColor("red")
                    })
                } else {
                    props.setHighlightColor("red")
                }
            } else  {
                findLocalProduct(e.target.value);
            }
            e.target.value = "";
        }
    }

    const setOrderToScanData = (res, value) => {
        setCurrentOrder(res.order);
        setSuggestedCarrier(res.order?.suggestedCarrier);
        setToScanRows(res.order.orderlines);
        setCurrentOrderWarehouseId(res.order.warehouseId);

        const foundProduct = res.order.orderlines.find(orderline => orderline.product.barcode === value);
        if (foundProduct) {
            setMatchedProduct(foundProduct);
        } 
        
    
        //reset carriers
        setCarriers([{
            "carrierId" : null,
            "carrierName" : "",
            "totalProducts" : 0,
            "totalWeight" : 0,
            "deliveryOption": null,
            "rows": [],
        }]);
    }

    const findLocalProduct = (value,shouldTryToFindCarrier = true) => {
        const foundProduct = toScanRows.find(orderline => orderline.product.barcode === value);
        if (foundProduct) {
            setMatchedProduct(foundProduct);
        } else {
            if(shouldTryToFindCarrier){
                fetchCarrierBarcode(value).unwrap()
                .then((result) => {
                    setIncCarrier(result);
                })
                .catch(err => {
                    props.setHighlightColor("red");
                })
            }
        }
    }

    useEffect(() => {
        if (!matchedProduct) return;
    
        if (matchedProduct.amount > 16) {
            setPaModalData({
                title: "Hoeveel van dit product wil je scannen?",
                content: `${matchedProduct.amount} x ${matchedProduct.product.description}`,
                value: "",
                hasInput: true,
                inputLabel: "amount",
                inputType: "number",
                confirm: confirmProductAmount
            });
            productAmountModal.setOpen(true);
        } else {
            updateCarrierAndScanRows(1);
        }
    }, [matchedProduct]);

    // const JokerClick = () => {
    //     if(carriers[carriers.length - 1].carrierId !== null && toScanRows.length === 0){
    //         setModalData({
    //             title: "Joker een voltooide order",
    //             content: `Deze order is volledig doorlopen voor de eCheck. Weet je zeker dat je deze alsnog wilt jokeren?`,
    //             value: 0,
    //             confirm: (e) => {}
    //         });
    //         confirmModal.setOpen(true);
    //     } else {
    //         setJoker();
    //     }
    // }

    // const setJoker = async () => {
    //     const response = await setJokerResponse(currentOrder.id).unwrap();
    //     NextOrder(true);
    // }

    const updateCarrierAndScanRows = (mutation) => {
        setCarriers(prev => {
            let updatedCarriers = [...prev];
            
            if (updatedCarriers.length > 0) {
                let lastCarrier = { ...updatedCarriers[updatedCarriers.length - 1] };
                const productIndexInCarrier = lastCarrier.rows.findIndex(row => row.product.barcode === matchedProduct.product.barcode);
                
                if (productIndexInCarrier !== -1) {
                    const updatedProduct = { ...lastCarrier.rows[productIndexInCarrier], amount: lastCarrier.rows[productIndexInCarrier].amount + mutation };
                    lastCarrier.rows[productIndexInCarrier] = updatedProduct;
                } else {
                    let productToAdd = { ...matchedProduct, amount: mutation };
                    lastCarrier.rows.push(productToAdd);
                }
                
                fetchDeliveryOptions(lastCarrier);
            
                updatedCarriers[updatedCarriers.length - 1] = lastCarrier;
            }
            
            return updatedCarriers;
        });

        //Removes the scan rows from the left side
        setToScanRows(prevToScanRows => 
            prevToScanRows.map(orderline => 
                orderline.product.barcode === matchedProduct.product.barcode 
                ? { ...orderline, amount: orderline.amount - mutation } 
                : orderline
            ).filter(orderline => orderline.amount > 0)
        );
        props.setHighlightColor('green');
    }


    const fetchDeliveryOptions = async (carrier) => {
        if(carriers?.length === 0) return;
        if (selectedShipper?.type === "bol" && carrier.rows.length > 0) {
            const requestBody = {
                "shipperId": selectedShipper.id,
                "items": {
                    "orderItems": carrier.rows.map(row => ({
                        "orderItemId": row.product.externalId,
                        "quantity": row.amount
                    }))
                }
            };

            
            //get index of carrier in carriers
            const carrierIndex = carriers.length === 1 ? 0 : carriers.findIndex(c => c.carrierId === carrier.carrierId);

            setCarrierForDeliveryToFetch(carrierIndex);
            setDeliveryOptionsFetchData(requestBody);
        }
    };

     useEffect(() => {
        if(incCarrier === "dummy") return;
        if (incCarrier) {
            setCarriers(prevCarriers => {
                // Copy the previous carriers array
                const updatedCarriers = [...prevCarriers];
                
                // If there are no previous carriers, just return the initial list
                if (updatedCarriers.length === 0) return updatedCarriers;
    
                // Update the last carrier
                const lastCarrier = updatedCarriers[updatedCarriers.length - 1];
                if(lastCarrier.carrierId === null){
                    lastCarrier.carrierId = incCarrier.id; 
                    lastCarrier.carrierName = incCarrier.name;
                }
                
                fetchDeliveryOptions(lastCarrier); // Fetch delivery options if shipper type is "bol" and there are products
    
                // If the last carrier has rows, add a new carrier
                if (lastCarrier.rows && lastCarrier.rows.length > 0 && toScanRows.length !== 0) {
                    // Add a new empty carrier
                    updatedCarriers.push({
                        carrierId: null,
                        carrierName: "",
                        totalProducts: 0,
                        totalWeight: 0,
                        deliveryOption: null,
                        rows: [],
                    });
                }

                // If there is a suggested carrier, check if the carrier is the same as the suggested carrier
                const color = suggestedCarrier && Object.keys(suggestedCarrier).length > 0 ? (suggestedCarrier.barcode === incCarrier.barcode ? "green" : "red") : "green";
        
                // Return the updated carriers array
                props.setHighlightColor(color);

                
                return updatedCarriers;
            });
        } else {
            props.setHighlightColor('red');
            toast.error(`Onjuiste scan`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
        setIncCarrier("dummy");
    }, [incCarrier]);

    useEffect(() => {
        if(toScanRows?.length === 0 && selectedShipper && selectedShipperProfile && props.autoFinalize){
            setGoFinalize(true);
        }
    }, [carriers,toScanRows, selectedShipper, selectedShipperProfile,props.autoFinalize]);
    
    // A utility function to compute updated carriers with totalWeight and totalProducts
    const computeUpdatedCarriers = (prevCarriers) => {
        return prevCarriers.map(carrier => {
            let newTotalWeight = 0;
            let newTotalAmount = 0;

            carrier.rows.forEach(productRow => {
                newTotalWeight += (productRow.amount * productRow.product.weight);
                newTotalAmount += productRow.amount;
            });

            return {
                ...carrier,
                totalWeight: newTotalWeight,
                totalProducts: newTotalAmount
            };
        });
    };

    const finalize = async () => {
        if(props.carrierMandatory){
            if(carriers[carriers?.length - 1]?.carrierId === null){
                showToastMessage("warning", "Er is geen drager gekozen. Selecteer of scan een drager om af te ronden.");
                return;
            }
        }
        try {
            const response = await finalizeResponse({
                "order": currentOrder,
                "carriers": carriers,
                "shipper": selectedShipper.id,
                "shipperProfile": selectedShipperProfile.id,
                "workspace": props.selectedWorkSpace.id
            }).unwrap();

            if(response.success){
                if(response.openFiles){
                    for (let i = 0; i < response.labelContent.length; i++) {
                        const content = response.labelContent[i];
                        let url = "";
                        if(response.labelType === 'pdf'){
                            const decodedData = atob(content);
                            const arrayBuffer = new ArrayBuffer(decodedData.length);
                            const uint8Array = new Uint8Array(arrayBuffer);
                            for (let i = 0; i < decodedData.length; i++) {
                                uint8Array[i] = decodedData.charCodeAt(i);
                            }
                            const blob = new Blob([uint8Array], { type: 'application/pdf' });
                            url = URL.createObjectURL(blob);
                        } else if(response.labelType === 'link'){
                            url = content;
                        }
                        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                        if (newWindow) {
                            setTimeout(() => {
                                window.URL.revokeObjectURL(url);
                            }, 500);
                        } else {
                            window.URL.revokeObjectURL(url);
                        }
                    };
                }
                
                await resetScreen();

            } else {
            
                showToastMessage("error", response.message);
            }
        } catch (error) {
            showToastMessage("error", error.data?.error || error.message);
        }
        
    }

    const resetScreen = async () => {
        setTimer(null);
        setFinalizeReady(false);
        setShowSlide(false);
        setGoFinalize(false); 
        setCurrentOrder(null);
        setSuggestedCarrier(null);
        setToScanRows(null);
        setCurrentOrderWarehouseId(null);
        setMatchedProduct(null);
        setSelectedOrder(null);
        setSelectedOrderSelectShipper(null);
        setSelectedOrderSelectShipperProfile(null);
        await getMyJob(props.incData.pickingJobId).unwrap()
        .then(result => {
            const allEcheckedEqualAmount = result.products.every(product => product.amountEchecked === product.amount);
            if (allEcheckedEqualAmount) {
                // If true, hide Echeck
                props.setShowEcheck(false);
            } else {
                props.setHighlightColor("green");
                // If false, set the incData to the result
                props.setIncData(result);
            }
        })
        .catch(error => {
            props.setHighlightColor('red');
            toast.error(error.data?.message ??  `Scan een geldige krat of pickbon`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }
    

    const selectLabel = (carrierIndex, index) => {
        setCarriers(prev => {
            const updatedCarriers = prev.map((carrier, idx) => 
                idx === carrierIndex ? { ...carrier, deliveryOption: deliveryOptions[carrierIndex][index] } : carrier
            );
            return updatedCarriers;
        });
    };


    const FinalizeClick = () => {
        if(!selectedShipper || !selectedShipperProfile) return;
        if(toScanRows.length === 0){
            if(selectedShipper.type === "bol"){
                if(carriers.every(carrier => carrier.deliveryOption !== null)){

                    finalize();
                }
            } else {

                finalize();
            }
        } else {
            setModalData({
                title: "Afronden niet mogelijk",
                content: `Je kunt deze order nog niet afronden omdat nog niet alle producten zijn gescant`,
                value: 0,
                confirm: (e) => {}
            });
            confirmModal.setOpen(true);
        }
    }

    useEffect(() => {;
        const updatedCarriers = computeUpdatedCarriers(carriers);
        
        // Check if the new calculated values differ from the current carriers
        if (JSON.stringify(updatedCarriers) !== JSON.stringify(carriers)) {
            setCarriers(updatedCarriers);
        }

        const overallTotalWeight = updatedCarriers.reduce((acc, curr) => acc + curr.totalWeight, 0);
        const overallTotalAmount = updatedCarriers.reduce((acc, curr) => acc + curr.totalProducts, 0);

        setTotalWeight(overallTotalWeight);
        setTotalAmount(overallTotalAmount);

    }, [carriers]);

    // const orderClick = (index) => {
    //     if((carriers[0]?.rows?.length ?? 0) > 0){
    //         setModalData({
    //             title: 'confirm_switch_order_title',
    //             content: t("confirm_switch_order_question"),
    //             value: index,
    //             confirm: confirmModalOrder
    //         });
    //         setOpen(true);
    //     } else {
    //         setCurrentOrderId(index);
    //     }
    // }

    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
        // setExportAnchorEl(null);
    };

    const { data: carriersSelect } = useGetCarriersSelectQuery();
    const selectManualCarrier = (selected) => {
        var carrier = carriersSelect[selected];
        setIncCarrier(carrier);
    }
    const OwnCarrierClick = () => {
        var carrier = {id: -1,
        name: t("own_carrier")};
        setIncCarrier(carrier);
    }

    const [selectedShipment, setSelectedShipment] = useState();

    const [getShipmentLabel, { data: shipmentLabelData, isLoading: shipmentLabelIsLoading, isError: shipmentLabelIsError }] = useGetShipmentLabelMutationMutation();
    const [cancelShipment, { data: cancelShipmentData, isLoading: cancelShipmentLoading, isError: cancelShipmentError }] = useCancelShipmentMutation();

    useEffect(() => {
        if(selectedOrder) {
            const unsyncedShipments = selectedOrder.shipments.filter(shipment => !shipment.synced && shipment.state !== general_states.CANCELLED);
            if(unsyncedShipments.length === 0) {
                props.setHighlightColor("red");
            } else {
                setSelectedShipment(unsyncedShipments.sort((a, b) => new Date(b.shipmentDate) - new Date(a.shipmentDate))[0]);
            }
        }
    },[selectedOrder])


    const replaceLabel = async () => {
        try {
            const response = await replaceLabelResponse({
                "id": selectedShipment.id,
                "shipperId": selectedOrderSelectShipper.id,
                "shipperProfileId": selectedOrderSelectShipperProfile.id,
                "workspaceId": props.selectedWorkSpace.id,
            }).unwrap();

            if(response.success){
                if(response.openFiles){
                    for (let i = 0; i < response.labelContent.length; i++) {
                        const content = response.labelContent[i];
                        let url = "";
                        if(response.labelType === 'pdf'){
                            const decodedData = atob(content);
                            const arrayBuffer = new ArrayBuffer(decodedData.length);
                            const uint8Array = new Uint8Array(arrayBuffer);
                            for (let i = 0; i < decodedData.length; i++) {
                                uint8Array[i] = decodedData.charCodeAt(i);
                            }
                            const blob = new Blob([uint8Array], { type: 'application/pdf' });
                            url = URL.createObjectURL(blob);
                        } else if(response.labelType === 'link'){
                            url = content;
                        }
                        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
                        if (newWindow) {
                            setTimeout(() => {
                                window.URL.revokeObjectURL(url);
                            }, 500);
                        } else {
                            window.URL.revokeObjectURL(url);
                        }
                    };
                }
                
                await resetScreen();

            } else {
            
                showToastMessage("error", response.message);
            }
        } catch (error) {
            showToastMessage("error", error.data?.error || error.message);
        }
            
    }

    const [shipmentHighlightColor, setShipmentHighlightColor] = useState('none');
    const [highlightColor, setHighlightColor] = useState('none');
    const [labelScale, setLabelScale] = useState(1);

    useEffect(() => {
        if (highlightColor !== 'none') {
            setTimeout(() => setHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [highlightColor]);

    useEffect(() => {
        if (shipmentHighlightColor !== 'none') {
            setTimeout(() => setShipmentHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [shipmentHighlightColor]);

    useEffect(() => {
        if (labelScale !== 1) {
            setTimeout(() => setLabelScale(1), 500); // Duration to stay colored
        }
    }, [labelScale]);

    const getBackgroundColor = () => {
        switch (highlightColor) {
            case "blue": 
                return theme.palette.mode === "dark" ? colors.blue[300] : colors.blue[400];
            case 'orange':
                return colors.orange[400];
            case 'green':
                return colors.green[400];
            case 'red':
                return colors.red[400];
            default:
                return theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0];
        }
    };

    const getShipmentBackgroundColor = () => {
        switch (shipmentHighlightColor) {
            case "blue": 
                return theme.palette.mode === "dark" ? colors.blue[300] : colors.blue[200];
            case 'orange':
                return colors.orange[400];
            case 'green':
                return colors.green[400];
            case 'red':
                return colors.red[400];
            default:
                return theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0];
        }

    }
    return(
        <Box
            sx={{
                flex:1,
                marginBottom:3,
                position:"relative",
                display:"flex",
                overflow:"hidden",
                "@media screen and (max-width: 1080px)" : {
                    flexDirection:'column'
                }
            }}
        >
            <Box 
                sx={{
                    "@media screen and (max-width: 1080px)" : {
                        display:'flex'
                    }
                }}
            >
                <ButtonBase
                    onClick={() => {
                        if(selectedOrder !== null) {
                            setSelectedOrder(null);
                            setSelectedOrderSelectShipper(null);
                            setSelectedOrderSelectShipperProfile(null);
                        }else {
                        props.setShowEcheck(false);
                        }
                    }}
                    sx={{
                        ml:"-16px",
                        fontWeight:"bold",
                        p:2,
                        color:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]
                    }}
                >
                    {selectedOrder !== null ? t("go_back") : t("close")}
                </ButtonBase>
                {/* <Box 
                    sx={{
                        mb:3,
                        "@media screen and (max-width: 1080px)" : {
                            mb:0,
                            mr:6,
                        }
                    }}
                >
                    <Box
                        sx={{
                            display:"flex",
                            alignItems:'center',
                            gap:2,
                        }}
                    >
                        <ShLabel onClick={handleFilterPopoverClick} size={32} borderless palette="normal" fitted>{t("view_all")}</ShLabel>

                        <OrdersView
                            incData={incData} 
                            currentOrderId={currentOrderId}
                            orderClick={orderClick}

                            id={filterPopoverId}
                            open={filterPopoverOpen}
                            anchorEl={filterAnchorEl}
                            onClose={handleFilterPopoverClose}
                        />
                    </Box>
                </Box> */}
                {/* <Subheading variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{t("order")} {currentOrder?.barcode}</Subheading> */}
                    {/* <Text>{currentOrder?.relation.name}</Text><br/> */}
                    {/* <Text light>{currentOrder?.webshopcredentials?.description}</Text> */}

                {currentOrder !== null &&
                    <Box
                        sx={{
                            // display:"flex",
                            display:"flex",
                            "@media screen and (max-width: 1080px)" : {
                                flexDirection:'column',
                                justifyContent:"center",
                            }
                        }}
                    >
                        <Box
                            sx={{
                                width:380,
                                background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                                padding:2,
                                borderRadius:4,
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    // gap:1.5,
                                    flexDirection:'column',
                                    mb:3,
                                }}
                            >


                                {toScanRows?.map((row, index) => {
                                    return (
                                        <Box key={index}>
                                            <Box
                                                sx={{
                                                    flex:1,
                                                    display:"flex",
                                                    background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                                                    border: theme.palette.mode === "light" ? `1px solid ${colors.grey[100]}` :null,
                                                    boxShadow: theme.palette.mode === "light" ? "0 2px 4px rgba(49,65,95,.15)" : null,
                                                    borderRadius:3,
                                                    padding:1,
                                                    alignItems:"center",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                    <Box
                                                        sx={{
                                                            display:"flex",

                                                        }}
                                                    >
                                                        <ShLabel size={32} fitted palette="normal" variant={"orange"}>
                                                            {row.amount}
                                                        </ShLabel>
                                                        <Box
                                                            sx={{
                                                                ml:2,
                                                                display:"flex",
                                                                flexDirection:'column',
                                                            }}
                                                        >
                                                            <Text>
                                                                {row.product.barcode}
                                                            </Text>
                                                            <Text semibold>
                                                                {row.product.description}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                        {/* <Text light>
                                                            {sku}
                                                        </Text> */}
                                                {/* {scanlabel} */}
                                            </Box>
                                        </Box>
                                    )
                                })}

                                {toScanRows?.length === 0 &&
                                    <Box
                                        sx={{
                                            flex:1,
                                            display:"flex",
                                            background:theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[0],
                                            border: theme.palette.mode === "light" ? `1px solid ${colors.grey[100]}` :null,
                                            boxShadow: theme.palette.mode === "light" ? "0 2px 4px rgba(49,65,95,.15)" : null,
                                            borderRadius:3,
                                            padding:1,
                                            alignItems:"center",
                                            justifyContent: "center",
                                            opacity:0.7,
                                        }}
                                    >
                                        <Text semibold sx={{marginLeft: 2,
                                            opacity:0.7,}}>
                                            {t("everything_scanned")}
                                        </Text>
                                    </Box>
                                }
                            </Box>

                            <Box
                                sx={{
                                    flex: 1,
                                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
                                    borderRadius: 4,
                                    // marginTop: 3,
                                    paddingTop:4,
                                    overflow: "hidden",
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingBottom: 3,
                                    justifyContent: 'center',  // To center contents vertically
                                    alignItems: 'center',      // To center contents horizontally
                                }}
                            >
                                <img 
                                    src="/img/barcode.svg" 
                                    alt="Barcode" 
                                    style={{
                                        width: '30%', 
                                        height: '30%', 
                                        objectFit: 'contain',
                                        animation: 'pulsate 1.5s infinite'
                                    }} 
                                />

                                <Box display="flex"
                                    height="32px"
                                    // maxWidth="400px"
                                    borderRadius="20px"
                                    backgroundColor={colors.grey[100]}
                                    sx={{
                                        marginTop:0.5,
                                        marginBottom:0.5,
                                        justifyContent:"space-between",
                                        "@media screen and (max-width: 47.9375em)" : {
                                            flexDirection:"column"
                                        }
                                    }}
                                >
                                    <InputBase 
                                        inputRef={inputRef}
                                        sx={{
                                            ml: 2, 
                                            flex:1, 
                                            width: 248, 
                                            textAlign: 'center', 
                                            justifyContent: 'center'
                                        }} 
                                        placeholder={t("scan_type_barcode")}
                                        onKeyDown={handleKeyDown}
                                        autoFocus={true}
                                    />
                                </Box>
                            </Box>

                        </Box>

                        <Box
                            width={120}
                            sx={{
                                display: "flex",
                                // flexDirection: "column",
                                paddingBottom: 3,
                                justifyContent: 'center',  // To center contents vertically
                                alignItems: 'center',
                                color: colors.grey[300],
                                "&.supra-arrow" : {

                                    width: '40%', 
                                    height: '40%', 
                                    objectFit: 'contain',
                                    "@media screen and (max-width: 1080px)" : {
                                        width:120,
                                    }
                                },
                                "@media screen and (max-width: 1080px)" : {
                                    transform:"rotate(90deg)",
                                    width:380,
                                }
                                
                            }}
                        >
                            {/* <DoubleArrowTwoToneIcon sx={{fontSize:'72px', opacity:'30%'}}/> */}
                            <img
                                className={"supra-arrow"} 
                                src="/img/arrow.svg" 
                                alt="Barcode" 
                                style={{
                                }} 
                            />
                        </Box>
                    </Box>
                }

                <Box
                    sx={{
                        display:"flex",
                        "@media screen and (max-width: 1080px)" : {
                            flexDirection:'column',
                            justifyContent:"center",
                        }
                    }}
                >
                    <Box
                        sx={{
                            width:380,
                            mt:currentOrder !== null ? 4 : 0,
                            borderRadius:4,
                        }}
                    >
                        {toScanProducts?.length > 0 &&
                            <Box
                                sx={currentOrder !== null ? {
                                    userSelect:"none",
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    position:'relative',
                                } : {
                                    userSelect:"unset",
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    position:'relative',
                                }}
                            >
                                <Subheading
                                    sx={{
                                        opacity: currentOrder !== null || selectedOrder !== null ? 0.35 : 1,
                                    }}
                                >
                                    {t("to_process")}
                                </Subheading>
                                {toScanProducts?.map((row, index) => {
                                    if(row.amountEchecked >= row.amount) return null;
                                    return (
                                        <Box
                                            key={"scan-prod"+index}
                                            sx={{
                                                opacity: currentOrder !== null || selectedOrder !== null ? 0.35 : 1,
                                                width:1,
                                                display:'flex',
                                                boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                                border:`1px solid ${colors.grey[100]}`,
                                                p:2,
                                                borderRadius:4,
                                                justifyContent:"space-between",
                                                alignItems:"center",
                                                pointerEvents: row.amountEchecked === row.amount ? "none" : null,
                                                userSelect: row.amountEchecked === row.amount ? "none" : null,
                                                background: theme.palette.mode === 'dark' ? row.amountEchecked === row.amount ? colors.green[100] : colors.grey[300] :  row.amountEchecked === row.amount ? colors.green[100] : colors.grey[0]
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    opacity:row.amountEchecked === row.amount ? 0.5 : 1,
                                                }}
                                            >
                                                <Text bold>{row.product.description}</Text><br/>
                                                <Text>
                                                    {row.product?.barcode}
                                                </Text>
                                            </Box>

                                            <Box
                                                sx={{
                                                    flexShrink:0,
                                                    height:32,
                                                    px:2,
                                                    display:"flex",
                                                    justifyContent:'center',
                                                    alignItems:'center',
                                                    background: row.amountEchecked === row.amount ? colors.green[100] : theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100],
                                                    fontFamily:"Roboto, sans-serif",
                                                    color: row.amountEchecked === row.amount ? colors.green[400] : colors.txt["primary"],
                                                    borderRadius:4,
                                                    border: `1px solid ${row.amountEchecked === row.amount ? colors.green[400] : "transparent"}`
                                                }}
                                            >
                                                {row.amountEchecked} / <b>{row.amount}</b>


                                            </Box>

                                        </Box>
                                    );
                                })}

                                {selectedOrder !== null &&
                                
                                <Box
                                    sx={{
                                        position:'absolute',
                                        left:0,
                                        top:32,
                                        bottom:0,
                                        width:1,
                                        backdropFilter:"blur(1px)",
                                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50],
                                        border:`1px solid ${colors.grey[200]}`,
                                        borderRadius:4,
                                        display:'flex',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        textAlign:'center',
                                        px:3,
                                        // background:"orange"
                                    }}
                                >
                                    <Text align="center">
                                        Om verder te gaan met verwerken druk dan hierboven op <b>"Ga terug"</b>.
                                    </Text>
                                </Box>
                                }
                                
                            </Box>
                        }
                        {props.incData.shippedOrders.length > 0 &&
                            <Box
                                sx={currentOrder !== null ? {
                                    opacity: currentOrder !== null || selectedOrder !== null ? 0.35 : 1,
                                    mt:5,
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    userSelect:"none",
                                } : {
                                    opacity:  1,
                                    mt:5,
                                    display:"flex",
                                    gap:1.5,
                                    flexDirection:'column',
                                    userSelect:"unset",

                                }}
                            >
                                <Subheading>
                                    {t("processed")}
                                </Subheading>
                                {[...props.incData.shippedOrders] // Create a shallow copy of the array
                                    .reverse() // Reverse the copy
                                    .map((order, index) => {
                                    // if(row.amountEchecked >= row.amount) return null;
                                    return (
                                        <Box
                                            key={"order-list"+index}
                                            sx={{
                                                pb:1.5,
                                                ml:3.5,
                                                position:'relative',
                                                "&:before" : {
                                                    zIndex:2,
                                                    content:'""',
                                                    display:'block',
                                                    width:6,
                                                    height:6,
                                                    background:colors.grey[400],
                                                    position:'absolute',
                                                    top:10,
                                                    left:-24,
                                                    borderRadius:1,
                                                },
                                                "&:after" : {
                                                    zIndex:1,
                                                    content:'""',
                                                    display:'block',
                                                    width:2,
                                                    height:1,
                                                    background:colors.grey[100],
                                                    position:'absolute',
                                                    top:10,
                                                    left:-22,
                                                    borderRadius:1,
                                                }
                                            }}
                                        >
                                            <Text>{format(new Date(order.date_sent),'HH:mm:ss')}</Text>
                                            {currentOrder ===null ?
                                            <ButtonBase
                                                onClick={() => {
                                                    setShipmentHighlightColor("blue");
                                                    setSelectedOrder(order)
                                                }}
                                                key={index}
                                                sx={{
                                                    display:"flex",
                                                    flexDirection:"column",
                                                    alignItems:"flex-start",
                                                    mt:0.5,
                                                    width:1,
                                                    boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                                    border:`1px solid ${colors.grey[100]}`,
                                                    p:2,
                                                    borderRadius:4,
                                                    justifyContent:"space-between",
                                                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width:1,
                                                        display:"flex",
                                                        justifyContent:"space-between"
                                                    }}
                                                >
                                                    <Text bold>{order.deliveryName}</Text>
                                                    <ShLabel
                                                        clickable
                                                        // palette={order.shipments.length > 0 ? "pastel" : "pastel"}
                                                        variant={order.shipments.length > 0 ? "lightblue" : order.shipments[0].synced ? "purple" : "orange"}
                                                        fitted
                                                        borderless
                                                        size={32}
                                                    >
                                                        <b>{order.shipments.length}</b> {t("shipments")}
                                                    </ShLabel>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        mt:1,
                                                        display:"flex",
                                                        flexDirection:"column"
                                                    }}
                                                >
                                                    <Text>{order.shippingAddress.addressLine1}</Text>
                                                    <Text>{order.shippingAddress.postalCode}, {order.shippingAddress.city}</Text>
                                                    <Text>{order.shippingAddress.state}, {order.shippingAddress.country}</Text>
                                                </Box>

                                            </ButtonBase>
                                            : 
                                            <Box
                                                sx={{
                                                    display:"flex",
                                                    flexDirection:"column",
                                                    alignItems:"flex-start",
                                                    mt:0.5,
                                                    width:1,
                                                    boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                                    border:`1px solid ${colors.grey[100]}`,
                                                    p:2,
                                                    borderRadius:4,
                                                    justifyContent:"space-between",
                                                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width:1,
                                                        display:"flex",
                                                        justifyContent:"space-between"
                                                    }}
                                                >
                                                    <Text bold>{order.deliveryName}</Text>
                                                    <ShLabel
                                                        clickable
                                                        // palette={order.shipments.length > 0 ? "pastel" : "pastel"}
                                                        variant={order.shipments.length > 0 ? "lightblue" : order.shipments[0].synced ? "purple" : "orange"}
                                                        fitted
                                                        borderless
                                                        size={32}
                                                    >
                                                        <b>{order.shipments.length}</b> {t("shipments")}
                                                    </ShLabel>
                                                </Box>
                                                
                                                <Box
                                                    sx={{
                                                        mt:1,
                                                        display:"flex",
                                                        flexDirection:"column"
                                                    }}
                                                >
                                                    <Text>{order.deliveryStreet} {order.deliveryHousenumber}</Text>
                                                    <Text>{order.deliveryPostalCode}, {order.deliveryCity}</Text>
                                                </Box>

                                            </Box>
                                            }
                                        </Box>
                                    );
                                })}
                                
                            </Box>
                        }

                    </Box>

                    <Box
                        width={120}
                        sx={{
                            opacity:0,
                            visibility:"hidden",
                            display: "flex",
                            // flexDirection: "column",
                            paddingBottom: 3,
                            justifyContent: 'center',  // To center contents vertically
                            alignItems: 'center',
                            color: colors.grey[300],
                            "&.supra-arrow" : {

                                width: '40%', 
                                height: '40%', 
                                objectFit: 'contain',
                                "@media screen and (max-width: 1080px)" : {
                                    width:120,
                                }
                            },
                            "@media screen and (max-width: 1080px)" : {
                                transform:"rotate(90deg)",
                                width:380,
                            }
                            
                        }}
                    >
                        {/* <DoubleArrowTwoToneIcon sx={{fontSize:'72px', opacity:'30%'}}/> */}
                        <img
                            className={"supra-arrow"} 
                            src="/img/arrow.svg" 
                            alt="Barcode" 
                            style={{
                            }} 
                        />
                    </Box>
                </Box>
                     
                
            </Box>
            

            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],

                    flex:1,
                    p:2,
                    borderRadius:6,
                    // borderTopLeftRadius:24,
                    // borderTopRightRadius:24,
                    flexDirection: "column",
                    display:"flex",
                }}
            >
                {currentOrder !== null ?
                    <ActualEchecking
                        totalAmount={totalAmount}
                        carriers={carriers}
                        deliveryOptions={deliveryOptions}
                        selectLabel={selectLabel}
                        selectManualCarrier={selectManualCarrier}
                        OwnCarrierClick={OwnCarrierClick}
                        carriersSelect={carriersSelect}
                        toScanRows={toScanRows}
                        showOptions={showOptions}
                        selectedShipper={selectedShipper}
                        selectedShipperProfile={selectedShipperProfile}
                        currentOrder={currentOrder}
                        shippers={shippers}
                        selectShipper={selectShipper}
                        selectShipperProfile={selectShipperProfile}
                        showSlide={showSlide}
                        finalizeLoading={finalizeLoading}
                        finalizeFetching={finalizeFetching}
                    />
                :
                selectedOrder !== null ?
                <Box
                    sx={{
                        flex:1,
                        flexDirection: "column",
                        display:"flex",
                        position:'relative',
                        overflow:"hidden",
                        // background: getBackgroundColor(),
                        // transition: 'background-color 0.5s'
                    }}
                >
                    <Box
                        sx={{
                            flex:1,
                            flexDirection: "column",
                            display:"flex",
                            p:3,
                            borderRadius:4,
                            position:'relative',
                            // borderTopLeftRadius:16,
                            // borderTopRightRadius:16,
                            // background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                            overflow:"hidden",
                            background: getShipmentBackgroundColor(),
                            transition: 'background-color 0.5s',
                        }}
                    >
                        <Box
                            sx={{
                                display:'flex',
                                gap:1,
                                mb:3,
                            }}
                        >
                            <Heading>{t("order")}</Heading>
                            <Heading color={theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}>{selectedOrder.deliveryName}</Heading>
                        </Box>
                        <Box
                            sx={{
                                display:"flex",
                                overflow:'scroll',
                                alignItems:"flex-start",
                                position:'relative',
                                gap:3,
                            }}
                        >
                            <Box
                                sx={{
                                    flex:1,
                                }}
                            >
                                {[...selectedOrder.shipments] // Create a shallow copy of the array
                                    .sort((a, b) => new Date(b.shipmentDate) - new Date(a.shipmentDate)) // Sort by shipmentDate, descending
                                    .map((shipment, index) => {
                                    // if(row.amountEchecked >= row.amount) return null;
                                    return (
                                        <Box
                                            key={"shipment-list"+index}
                                            sx={{
                                                pb:3,
                                                ml:3.5,
                                                position:'relative',
                                                "&:before" : {
                                                    zIndex:2,
                                                    content:'""',
                                                    display:'block',
                                                    width:6,
                                                    height:6,
                                                    background: theme.palette.mode === "dark" ? colors.txt["softenedMore"] : colors.grey[400],
                                                    position:'absolute',
                                                    top:10,
                                                    left:-24,
                                                    borderRadius:1,
                                                },
                                                "&:after" : {
                                                    zIndex:1,
                                                    content:'""',
                                                    display:'block',
                                                    width:2,
                                                    height:1,
                                                    background: theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100],
                                                    position:'absolute',
                                                    top:10,
                                                    left:-22,
                                                    borderRadius:1,
                                                }
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display:"flex",
                                                    // justifyContent:'space-between',
                                                }}
                                            >
                                                <Text bold>{format(new Date(shipment.shipmentDate),'HH:mm:ss')}</Text>&nbsp;
                                                <Text italic>
                                                    {/* {new Date(props.date).toLocaleDateString(i18n.language)} */}
                                                    ({format(new Date(shipment.shipmentDate),'dd MMMM')})
                                                </Text>
                                            </Box>
                                            <Box
                                                className={cx('',{
                                                    'active' : selectedShipment?.id === shipment.id,
                                                    'cancelled': shipment.state === general_states.CANCELLED,
                                                })}
                                                // onClick={() => {
                                                //     setSelectedShipment(shipment)
                                                // }}
                                                key={index}
                                                sx={{
                                                    display:"flex",
                                                    flexDirection:"column",
                                                    alignItems:"flex-start",
                                                    mt:0.5,
                                                    width:1,
                                                    boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                                    border:`1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100]}`,
                                                    p:2,
                                                    borderRadius:4,
                                                    justifyContent:"space-between",
                                                    background:  theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100],
                                                    "&.active" : {
                                                        background:  theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                                                        border:`1px solid ${theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100]}`,
                                                    },
                                                    "&.cancelled" : {
                                                        background: colors.red[100]
                                                    }
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width:1,
                                                        display:'flex',
                                                        justifyContent:'space-between',
                                                    }}
                                                >
                                                    <Text bold variant={shipment.state === general_states.CANCELLED ? "red" : null}>
                                                        {shipment.trackingNumber}
                                                    </Text>
                                                        <Box
                                                            sx={{
                                                                display:'flex',
                                                                gap:1,
                                                            }}
                                                        >
                                                            <ShLabel
                                                                clickable
                                                                palette={shipment.state === general_states.CANCELLED ? "normal" : "pastel"}
                                                                // palette={order.shipments.length > 0 ? "pastel" : "pastel"}
                                                                variant={shipment.state === general_states.CANCELLED ? "red" : shipment.synced ? "purple" : "orange"}
                                                                fitted
                                                                size={32}
                                                            >
                                                                {shipment.state === general_states.CANCELLED ? t("cancelled") : shipment.synced ? t("synced") : t("booked")}
                                                            </ShLabel>
                                                            {!shipment.synced && shipment.state !== general_states.CANCELLED &&
                                                                <ButtonBase
                                                                    onClick={async () => {
                                                                        await cancelShipment(shipment.id).unwrap().then(res => {
                                                                            setSelectedOrder((prevState) => {
                                                                                // Create a shallow copy of the shipments array
                                                                                const updatedShipments = [...prevState.shipments];
                                                                          
                                                                                const shipmentIndex = updatedShipments.findIndex(locShipment => locShipment.id === shipment.id);
                                                                          
                                                                                if (shipmentIndex !== -1) {
                                                                                  // Update the state of the found shipment to 'cancelled'
                                                                                  updatedShipments[shipmentIndex] = {
                                                                                    ...updatedShipments[shipmentIndex], // Keep other properties the same
                                                                                    state: general_states.CANCELLED // Update the status to cancelled
                                                                                  };
                                                                                }
                                                                          
                                                                                // Return the updated state with the modified shipments array
                                                                                return { ...prevState, shipments: updatedShipments };
                                                                              });
                                                                            // console.log(res);
                                                                        }).catch(err => {
                                                                            console.error(err);
                                                                        })
                                                                    }}
                                                                    key={index}
                                                                    sx={{
                                                                        display:"flex",
                                                                        alignItems:"center",
                                                                        justifyContent:"center",
                                                                        width:32,
                                                                        height:32,
                                                                        borderRadius:4,
                                                                        background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[400],
                                                                        color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"],
                                                                        fontWeight:"bold"
                                                                    }}
                                                                >
                                                                    <CloseIcon fontSize="small"/>
                                                                </ButtonBase>
                                                            }
                                                        </Box>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width:1,
                                                        display:'flex',
                                                        mt:2.5,
                                                        gap:2,
                                                    }}
                                                >
                                                    <ButtonBase
                                                        onClick={async () => {
                                                            await getShipmentLabel(shipment.id).unwrap().then(res => {
                                                                res.labels?.forEach(label => {
                                                                    const decodedData = atob(label);
                                                                    const arrayBuffer = new ArrayBuffer(decodedData?.length);
                                                                    const uint8Array = new Uint8Array(arrayBuffer);
                                                                    for (let i = 0; i < decodedData.length; i++) {
                                                                        uint8Array[i] = decodedData.charCodeAt(i);
                                                                    }
                                                                    const blob = new Blob([uint8Array], { type: 'application/pdf' });
                                                                    const pdfUrl = URL.createObjectURL(blob);
                                                                    window.open(pdfUrl, '_blank');
                                                                });
                                                            }).catch(err => {
                                                                // console.error(err);
                                                            })
                                                        }}      
                                                        className={cx('',{
                                                            'active' : selectedShipment?.id === shipment.id,
                                                            'cancelled': shipment.state === general_states.CANCELLED,
                                                        })}
                                                        sx={{
                                                            borderRadius:5,
                                                            boxShadow:'none',
                                                            height:40,
                                                            flex:1,
                                                            border:`2px solid ${ theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400]}`,
                                                            background:theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200],
                                                            fontSize:14,
                                                            fontWeight:"bold",
                                                            fontFamily:"Roboto,sans-serif",
                                                            color: colors.txt["primary"],
                                                            "&.active" : {
                                                                border:`2px solid ${theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400]}`,
                                                                background:theme.palette.mode === "dark" ? colors.blue[100]: colors.primary[100],
                                                                color:theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400],
                                                            },
                                                            "&.cancelled" : {
                                                                background:colors.red[400],
                                                                border:`2px solid ${colors.red[400]}`,
                                                                color:theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["white"],
                                                            }
                                                        }}
                                                    >
                                                        {t("download_label")}
                                                        
                                                    </ButtonBase>
                                                    {!shipment.synced && shipment.state !== general_states.CANCELLED ?
                                                        <ButtonBase
                                                            onClick={() => {
                                                                setLabelScale(1.35)
                                                                setHighlightColor("blue");
                                                                setSelectedShipment(shipment);
                                                            }}
                                                            className={cx('',{
                                                                'active' : selectedShipment?.id === shipment.id,
                                                            })}
                                                            sx={{
                                                                borderRadius:5,
                                                                boxShadow:'none',
                                                                height:40,
                                                                flex:1,
                                                                border:`2px solid ${theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400]}`,
                                                                background:theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400],
                                                                fontSize:14,
                                                                fontWeight:"bold",
                                                                fontFamily:"Roboto,sans-serif",
                                                                color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"],
                                                                "&.active" : {
                                                                    border:`2px solid ${theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400]}`,
                                                                    background:theme.palette.mode === "dark" ? colors.blue[400]: colors.primary[400],
                                                                    color:theme.palette.mode === "dark" ? colors.txt["primaryDark"]: colors.txt["white"],

                                                                }
                                                            }}
                                                        >
                                                            {t("replace_label")}
                                                            
                                                        </ButtonBase>
                                                    :null}
                                                    

                                                </Box>
                                                {/* <Box
                                                    sx={{
                                                        width:1,
                                                        display:"flex",
                                                        justifyContent:"space-between"
                                                    }}
                                                >
                                                    <Text bold>{order.deliveryName}</Text>
                                                    <ShLabel
                                                        clickable
                                                        // palette={order.shipments.length > 0 ? "pastel" : "pastel"}
                                                        variant={order.shipments.length > 0 ? "lightblue" : order.shipments[0].synced ? "purple" : "orange"}
                                                        fitted
                                                        borderless
                                                        size={32}
                                                    >
                                                        <b>{order.shipments.length}</b> {t("shipments")}
                                                    </ShLabel>
                                                </Box> */}
                                                
                                                {/* <Box
                                                    sx={{
                                                        mt:1,
                                                        display:"flex",
                                                        flexDirection:"column"
                                                    }}
                                                >
                                                    <Text>{order.deliveryStreet} {order.deliveryHousenumber}</Text>
                                                    <Text>{order.deliveryPostalCode}, {order.deliveryCity}</Text>
                                                </Box> */}

                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                            <Box
                                sx={{
                                    minHeight:120,
                                    // height:50,
                                    position:'sticky',
                                    top:0,
                                    borderRadius:4,
                                    p:1.5,
                                    flex:1,
                                    boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                    border:`1px solid ${theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[100]}`,
                                    // background: theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[0],
                                    background: getBackgroundColor(),
                                    transition: 'background-color 0.5s',
                                }}
                            >
                                <Box
                                    sx={{
                                        pl:1.5,
                                        pt:1.5,
                                    }}
                                >
                                    <Subheading>{t("replace_label")}</Subheading>
                                    <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}
                                        sx={{
                                            transform:`scale(${labelScale})`,
                                            transformOrigin:"left top",
                                            transition:"transform 0.5s"
                                        }}
                                    >
                                        {selectedShipment?.trackingNumber}
                                    </Text>
                                </Box>

                                <Box
                                    sx={{
                                        p:1.5,
                                        mb:1,
                                    }}
                                >
                                    <Text>{selectedOrder?.shippingAddress?.addressLine1}</Text><br/>
                                    <Text>{selectedOrder?.shippingAddress?.postalCode}, {selectedOrder.shippingAddress?.city}</Text><br/>
                                    <Text>{selectedOrder?.shippingAddress?.state}, {selectedOrder.shippingAddress?.country}</Text><br/>
                                </Box>

                                <Box
                                    sx={{
                                        background: theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100],
                                        borderRadius:3,
                                        p:1.5,
                                    }}
                                >
                                    <Box>
                                        <Text light>{t("carrier_advice")}:</Text><br/>
                                        <Text bold>
                                            {selectedOrder.suggestedCarrier && selectedOrder.suggestedCarrier.id > 0 ? 
                                                selectedOrder.suggestedCarrier.name : 
                                                "Geen drageradvies"
                                            }
                                        </Text>
                                    </Box>
                                    <Box
                                        sx={{
                                            mt:2,
                                        }}
                                    >
                                        
                                        <Box
                                            sx={{
                                            }}
                                        >
                                            <Text light>{t("shipper")}:</Text>
                                            <Box 
                                                sx={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    justifyContent:'space-between',
                                                }}
                                            >
                                                <Text bold>{selectedOrderSelectShipper?.name}</Text>
                                                {selectedOrder?.deliveryMethod !== "vvb" &&
                                                <ButtonBase
                                                    sx={{
                                                        ml:2,
                                                        width:32,
                                                        height:32,
                                                        background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                                                        color:"#fff",
                                                        borderRadius:5,
                                                    }}
                                                >
                                                    <DropdownBase
                                                        transformOrigin={{
                                                            vertical: "bottom"
                                                        }}
                                                        anchorOrigin={{
                                                            vertical:"top"
                                                        }}
                                                        displayName={"name"} 
                                                        onChange={(selected) => 
                                                            {
                                                                selectedOrderSelectNewShipper(selected);
                                                            }
                                                        }
                                                        noSelection={""} //Custom text when nothing is selected
                                                        options={shippersSelected?.filter(shipper => shipper.type !== 'bol' || selectedOrder?.webshopcredentials?.webshop_type === 'bol')}
                                                        selected={-1}
                                                        closeOnSelection
                                                        resetOnSelection
                                                    >
                                                        <EditOutlinedIcon/>
                                                    </DropdownBase>
                                                </ButtonBase>
                                                }
                                            </Box>
                                        </Box>
                                        
                                    </Box>
                                    {selectedOrderSelectShipper?.type !== "bol" && (
                                        <Box
                                            sx={{
                                                mt:2,
                                            }}
                                        >
                                            <Box >
                                                <Text light>{t("shipperprofile")}:</Text><br/>
                                                <Box
                                                    sx={{
                                                        display:"flex",
                                                        justifyContent:'space-between',
                                                        alignItems:'center',
                                                    }}
                                                >
                                                    <Text bold>{selectedOrderSelectShipperProfile?.name}</Text>
                                                    <ButtonBase
                                                        sx={{
                                                            width:32,
                                                            height:32,
                                                            background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                                                            color:"#fff",
                                                            borderRadius:5,
                                                        }}
                                                    >
                                                        <DropdownBase
                                                            transformOrigin={{
                                                                vertical: "bottom"
                                                            }}
                                                            anchorOrigin={{
                                                                vertical:"top"
                                                            }}
                                                            displayName={"name"} 
                                                            // onChange={(selected) => props.selectShipperProfile(selected)}
                                                            onChange={(selected) => 
                                                                {
                                                                    selectedOrderSelectNewShipperProfile(selected)
                                                                }
                                                            }
                                                            noSelection={""} //Custom text when nothing is selected
                                                            options={selectedOrderSelectShipper?.shipperProfiles}
                                                            selected={-1}
                                                            closeOnSelection
                                                            resetOnSelection
                                                        >
                                                            <EditOutlinedIcon/>
                                                        </DropdownBase>
                                                    </ButtonBase>
                                                </Box>
                                            </Box>
                                        
                                        </Box>
                                    )}
                                </Box>
                                <Box
                                    sx={{
                                        mt:3,
                                        display:"flex",
                                        justifyContent:"space-between",
                                        alignItems:"center",
                                        width:1,
                                        mb:1.5,
                                    }}
                                >
                                    

                                        {/* <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained" onClick={() => JokerClick()}>Joker</Shbutton> */}
                                        <Shbutton block className={"group-btn"} isLoading={replaceLoading || replaceFetching} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" onClick={() => replaceLabel()}>{t("print_new_label")}</Shbutton>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                    {/* <Box
                        sx={{
                            mt:3,
                            display:"flex",
                            justifyContent:"space-between",
                            alignItems:"center",
                        }}
                    >
                        <ButtonGroup version={2}>
                            <Shbutton 
                                onClick={() => {setSelectedOrder(null)}}
                                className={"group-btn"} 
                                color={"red"} 
                                variant="contained"
                            >
                                {t('cancel')}
                            </Shbutton>
                            
                        </ButtonGroup>
                    </Box> */}

                    <Box
                        sx={{
                            bottom:32,
                            position:'absolute',
                            width:"60%",
                            left:"20%"
                        }}
                    >
                        <Slide 
                            direction="up" 
                            in={replaceLoading || replaceFetching}
                        >
                            <Box
                                sx={{ 
                                    borderRadius:8,
                                    background:"#fff",
                                    width:1,
                                    height:65,
                                    display:"flex",
                                    justifyContent:"space-between",
                                    alignItems:'center',
                                    px:3,
                                    position:'relative',
                                    zIndex:1,
                                    // left:"50%",
                                    // transform:"translateX(-50%)",
                                    overflow:'visible',
                                    "&:before" : {
                                        position:'absolute',
                                        content:"''",
                                        left:6,
                                        right:6,
                                        top:6,
                                        bottom:6,
                                        zIndex:-1,
                                        borderRadius:12,
                                        filter:"blur(12px)",
                                        background: 'linear-gradient(var(--gradient-angle), #31415f, #0D61FF, #C2D7FF)',
                                        animation: 'rotation 5s linear infinite'
                                    },
                                    "&:after" : {
                                        position:'absolute',
                                        content:"''",
                                        left:0,
                                        right:0,
                                        top:0,
                                        bottom:0,
                                        zIndex:-1,
                                        borderRadius:12,
                                        background:'#fff'
                                    }
                                }}
                            >
                                <Box width={60} />
                                <Subheading>
                                    {t('echeck_processing_order')}
                                </Subheading>
                                <Truck/>
                            </Box>
                        </Slide>
                    </Box>

                </Box>
                :
                    <Box
                        sx={{
                            flex:1,
                            flexDirection: "column",
                            display:"flex",
                            p:2,
                            borderRadius:4,
                            position:'relative',
                            // borderTopLeftRadius:16,
                            // borderTopRightRadius:16,
                            background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],

                        }}
                    >

                        <Box
                            sx={{
                                flex: 1,
                                borderRadius: 4,
                                // marginTop: 3,
                                paddingTop:4,
                                overflow: "hidden",
                                display: "flex",
                                flexDirection: "column",
                                paddingBottom: 3,
                                justifyContent: 'center',  // To center contents vertically
                                alignItems: 'center',      // To center contents horizontally
                            }}
                        >
                            <img 
                                src="/img/barcode.svg" 
                                alt="Barcode" 
                                style={{
                                    width: '30%', 
                                    height: '30%', 
                                    objectFit: 'contain',
                                    animation: 'pulsate 1.5s infinite'
                                }} 
                            />

                            <Box display="flex"
                                height="32px"
                                // maxWidth="400px"
                                borderRadius="20px"
                                backgroundColor={colors.grey[100]}
                                sx={{
                                    marginTop:0.5,
                                    marginBottom:0.5,
                                    justifyContent:"space-between",
                                    "@media screen and (max-width: 47.9375em)" : {
                                        flexDirection:"column"
                                    }
                                }}
                            >
                                <InputBase 
                                    sx={{
                                        ml: 2, 
                                        flex:1, 
                                        width: 248, 
                                        textAlign: 'center', 
                                        justifyContent: 'center'
                                    }} 
                                    placeholder={t("scan_type_barcode")}
                                    onKeyDown={handleKeyDown}
                                    autoFocus={true}
                                />
                            </Box>
                        </Box>

                    </Box>
                }

                {currentOrder !== null &&
                    <Box
                        sx={{
                            mt:3,
                            display:"flex",
                            justifyContent:"flex-end",
                            alignItems:"center",
                        }}
                    >
                        {/* <ButtonGroup
                            version={2}
                        >
                            <DropdownBase
                                transformOrigin={{
                                    vertical: "bottom"
                                }}
                                anchorOrigin={{
                                    vertical:"top"
                                }}
                                displayName={"name"} 
                                onChange={(selected) => selectManualCarrier(selected)}
                                noSelection={t("choose_carrier")} //Custom text when nothing is selected
                                options={carriersSelect}
                                selected={-1}
                                closeOnSelection
                                resetOnSelection
                            >
                                <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained">{t("choose_carrier")}</Shbutton>
                            </DropdownBase>

                            <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained" onClick={() => OwnCarrierClick()}>{t("own_carrier")}</Shbutton>
                        </ButtonGroup> */}
                            <ButtonGroup version={2}>
                                <Shbutton className={"group-btn"} isLoading={finalizeLoading || finalizeFetching} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained">Print pakbon</Shbutton>
                                
                                {/* <Shbutton className={"group-btn"} color={"primaryGrey"} variant="contained" onClick={() => JokerClick()}>Joker</Shbutton> */}
                                <Shbutton className={"group-btn"} isLoading={finalizeLoading || finalizeFetching} color={"green"} variant="contained" onClick={() => FinalizeClick()}>Afronden</Shbutton>
                            </ButtonGroup>
                    </Box>
                }
                
                {/* {CarriersView()} */}
            </Box>


            <MConfirmationWithValue
                open={confirmModal.open}
                handleClose={() => confirmModal.setOpen(false)}
                data={confirmModal.data}
                confirm={confirmModal.confirm}
                // barcodeView={barcodeView}
            />
            <MConfirmationWithValue
                open={productAmountModal.open}
                handleClose={() => productAmountModal.setOpen(false)}
                data={productAmountModal.data}
                confirm={productAmountModal.confirm}
            />
        </Box>
    )
}

export default ECheckView;

const ActualEchecking = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    return (

        <Box
            sx={{
                flex:1,
                flexDirection: "column",
                display:"flex",
                p:2,
                borderRadius:4,
                position:'relative',
                // borderTopLeftRadius:16,
                // borderTopRightRadius:16,
                background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],

            }}
        >

            <Box
                sx={{
                    display:"flex"
                }}
            >
                <Subheading>{props.totalAmount}</Subheading>&nbsp;
                <Subheading light>{props.totalAmount > 1 ? t("products") : t("product")} </Subheading>&nbsp;
                <Subheading semibold variant="purple">{t("processed")}</Subheading>&nbsp;
                <Subheading light>in</Subheading>&nbsp;
                <Subheading>{props.carriers?.length}</Subheading>&nbsp;
                <Subheading light>{t("carriers")}</Subheading>
            </Box>
            <Box
                sx={{
                    mt:2,
                    display:'flex',
                    gap:3,
                }}
            >
                <Box 
                    sx={{
                        flex:1,
                        display:'flex',
                        flexDirection:"column",
                        gap:2,
                    }}
                >
                    {/* {PickedView()} */}
                    {props.carriers && props.carriers?.map((carrier, carrierIndex) => (
                        <ContainerView
                            deliveryOptions={props.deliveryOptions}
                            selectLabel={props.selectLabel}
                            selectManualCarrier={props.selectManualCarrier}
                            OwnCarrierClick={props.OwnCarrierClick}
                            carriersSelect={props.carriersSelect}
                            carrier={carrier}
                            carrierIndex={carrierIndex}
                        />
                    ))}
                    {((props.carriers?.[props.carriers.length-1].rows.length !== 0 && props.carriers[props.carriers.length-1].carrierName !== "") && props.toScanRows.length > 0) &&
                        <Box
                            sx={{
                                // mt:3,
                                background: theme.palette.mode === "dark" ? colors.grey[500] : colors.bg["tertiary"],
                                borderRadius: 4,
                                overflow: "hidden",
                                display: "flex",
                                paddingBottom: 3,
                                postion:"relative",
                                px:2,
                                py:2,
                                gap:2,
                            }}
                        >
                            <DropdownBase
                                key={props.carriers?.length}
                                transformOrigin={{
                                    vertical: "bottom"
                                }}
                                anchorOrigin={{
                                    vertical:"top"
                                }}
                                displayName={"name"} 
                                onChange={(selected) => props.selectManualCarrier(selected)}
                                noSelection={t("choose_carrier")} //Custom text when nothing is selected
                                options={props.carriersSelect}
                                selected={-1}
                                closeOnSelection
                                resetOnSelection
                            >
                                <Shbutton className={"group-btn"} sx={{whiteSpace:"nowrap"}} color={"primaryGrey"} variant="contained">{t("choose_carrier")}</Shbutton>
                            </DropdownBase>

                            <Shbutton className={"group-btn"} sx={{whiteSpace:"nowrap"}} color={"primaryGrey"} variant="contained" onClick={() => props.OwnCarrierClick()}>{t("own_carrier")}</Shbutton>
                        </Box>
                    }
                </Box>
                

                <Box
                    sx={{
                        flex:1,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        border:`1px solid ${ theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                        boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
                        p:3,
                        borderRadius:4,
                        pb:props.showOptions?1.5:3,
                    }}
                >

                    <Box>
                        <Text light>{t("carrier_advice")}:</Text><br/>
                        <Text bold>
                            {props.currentOrder?.suggestedCarrier && props.currentOrder?.suggestedCarrier.id > 0 ? 
                                props.currentOrder?.suggestedCarrier.name : 
                                "Geen drageradvies"
                            }
                        </Text>
                    </Box>
                    <Box
                        sx={{
                            mt:2,
                        }}
                    >
                        
                        <Box
                            sx={{
                            }}
                        >
                            <Text light>{t("shipper")}:</Text>
                            <Box 
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:'space-between',
                                }}
                            >
                                <Text bold>{props.selectedShipper?.name}</Text>
                                {props.currentOrder?.deliveryMethod !== "vvb" &&
                                <ButtonBase
                                    sx={{
                                        ml:2,
                                        width:32,
                                        height:32,
                                        background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                                        color:"#fff",
                                        borderRadius:5,
                                    }}
                                >
                                    <DropdownBase
                                        transformOrigin={{
                                            vertical: "bottom"
                                        }}
                                        anchorOrigin={{
                                            vertical:"top"
                                        }}
                                        displayName={"name"} 
                                        onChange={(selected) => props.selectShipper(selected)}
                                        noSelection={""} //Custom text when nothing is selected
                                        options={props.shippers?.filter(shipper => shipper.type !== 'bol' || props.currentOrder?.webshopcredentials?.webshop_type === 'bol')}
                                        selected={-1}
                                        closeOnSelection
                                        resetOnSelection
                                    >
                                        <EditOutlinedIcon/>
                                    </DropdownBase>
                                </ButtonBase>
                                }
                            </Box>
                        </Box>
                        
                    </Box>
                    {props.selectedShipper?.type !== "bol" && (
                        <Box
                            sx={{
                                mt:2,
                            }}
                        >
                            <Box >
                                <Text light>{t("shipperprofile")}:</Text><br/>
                                <Box
                                    sx={{
                                        display:"flex",
                                        justifyContent:'space-between',
                                        alignItems:'center',
                                    }}
                                >
                                    <Text bold>{props.selectedShipperProfile?.name}</Text>
                                    <ButtonBase
                                        sx={{
                                            width:32,
                                            height:32,
                                            background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                                            color:"#fff",
                                            borderRadius:5,
                                        }}
                                    >
                                        <DropdownBase
                                            transformOrigin={{
                                                vertical: "bottom"
                                            }}
                                            anchorOrigin={{
                                                vertical:"top"
                                            }}
                                            displayName={"name"} 
                                            onChange={(selected) => props.selectShipperProfile(selected)}
                                            noSelection={""} //Custom text when nothing is selected
                                            options={props.selectedShipper?.shipperProfiles}
                                            selected={-1}
                                            closeOnSelection
                                            resetOnSelection
                                        >
                                            <EditOutlinedIcon/>
                                        </DropdownBase>
                                    </ButtonBase>
                                </Box>
                            </Box>
                        
                        </Box>
                    )}
                    {props.showOptions && 
                        <Box
                            sx={{
                                mt:2,
                                borderRadius:3,
                                mx:-1.5,
                                px:1.5,
                                py:1,
                                background:theme.palette.mode === "dark" ? colors.grey[400]: colors.grey[50],
                                display:"flex",
                                flexDirection:'column',
                                gap:1,
                                overflow:"hidden"
                            }}
                        >
                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:2
                                }}
                            >
                                <Text noBreak>Print pakbon per drager</Text>
                                <ShSwitch/>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    gap:2
                                }}
                            >
                                <Text noBreak>Print pallet pakbonnen</Text>
                                <ShSwitch/>
                            </Box>

                            <Box
                                sx={{
                                    display:"flex",
                                    // alignItems:"center",
                                    flexDirection:'column'
                                    // gap:2
                                }}
                            >
                                <Text noBreak>Print pakbon met draagnummers na voltooien orderr</Text>
                                <ShSwitch/>
                            </Box>
                        </Box> 
                    }

                </Box>
            </Box>

            <Box
                sx={{
                    width:1,
                    mt:"auto",
                    mx:"auto",
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    mb:4,
                }}
            >
                <Box
                    sx={{
                        position:'absolute',
                        width:"60%",
                    }}
                >
                    <Slide 
                        direction="up" 
                        // in={finalizeLoading || finalizeFetching}
                        in={props.showSlide}
                        // in
                    >
                        <Box
                            sx={{ 
                                borderRadius:8,
                                background:"#fff",
                                width:1,
                                height:65,
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:'center',
                                px:3,
                                position:'relative',
                                zIndex:1,
                                overflow:'visible',
                                "&:before" : {
                                    position:'absolute',
                                    content:"''",
                                    left:6,
                                    right:6,
                                    top:6,
                                    bottom:6,
                                    zIndex:-1,
                                    borderRadius:12,
                                    filter:"blur(12px)",
                                    background: 'linear-gradient(var(--gradient-angle), #31415f, #0D61FF, #C2D7FF)',
                                    animation: 'rotation 5s linear infinite'
                                },
                                "&:after" : {
                                    position:'absolute',
                                    content:"''",
                                    left:0,
                                    right:0,
                                    top:0,
                                    bottom:0,
                                    zIndex:-1,
                                    borderRadius:12,
                                    background:'#fff'
                                }
                            }}
                        >
                            <Box width={60} />
                            <Subheading>
                                {t('e_check_you_have_5_seconds')}
                            </Subheading>
                            <Box width={60} />
                        </Box>
                    </Slide>
                </Box>

                <Box
                    sx={{
                        position:'absolute',
                        width:"60%",
                    }}
                >
                    <Slide 
                        direction="up" 
                        in={props.finalizeLoading || props.finalizeFetching}
                    >
                        <Box
                            sx={{ 
                                borderRadius:8,
                                background:"#fff",
                                width:1,
                                height:65,
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:'center',
                                px:3,
                                position:'relative',
                                zIndex:1,
                                // left:"50%",
                                // transform:"translateX(-50%)",
                                overflow:'visible',
                                "&:before" : {
                                    position:'absolute',
                                    content:"''",
                                    left:6,
                                    right:6,
                                    top:6,
                                    bottom:6,
                                    zIndex:-1,
                                    borderRadius:12,
                                    filter:"blur(12px)",
                                    background: 'linear-gradient(var(--gradient-angle), #31415f, #0D61FF, #C2D7FF)',
                                    animation: 'rotation 5s linear infinite'
                                },
                                "&:after" : {
                                    position:'absolute',
                                    content:"''",
                                    left:0,
                                    right:0,
                                    top:0,
                                    bottom:0,
                                    zIndex:-1,
                                    borderRadius:12,
                                    background:'#fff'
                                }
                            }}
                        >
                            <Box width={60} />
                            <Subheading>
                                {t('echeck_processing_order')}
                            </Subheading>
                            <Truck/>
                        </Box>
                    </Slide>
                </Box>
            </Box>

        </Box>

    );
}