// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Subheading from '../../../../theme/text/Subheading';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { yupEmailRequired, yupRequired } from '../../../../../utils/validation';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { useGetRelationsSelectQuery } from '../../../../../newapi/global/relation/relationSlice';
import { InputDropdown } from '../../../../theme/dropdowns/InputDropdown';
import { useGetWebshopCredentialsSelectQuery } from '../../../../../newapi/webshop/webshopSlice';
import { ENV_TYPES } from '../../../Sidebar';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
    const [relation, setRelation] = useState(props.relation);
    const [showRelation, setShowRelation] = useState(true);
    const { data: relations } = useGetRelationsSelectQuery();
    const { data: webshopCredentials } = useGetWebshopCredentialsSelectQuery(relation,
        {
            skip: !relation
        }
    );
    
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const rel = useSelector((state) => state.settings.environment.selectedId);
    if(selectedEnvironmentType === ENV_TYPES[200]) {
        setRelation(rel);
        setShowRelation(false);
    }


    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        if(key === "relation") {
            props.handleDataUpdate({ webshopcredential: -1 });
            setRelation(val);
        }
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };
    

    return (
        <>
            <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("reference")}</Text>
                </Box>
                
                
                <ShValidatedInput
                    name="reference"
                    label={t("reference")}
                    value={data?.reference}
                    onChange={handleChange}
                    error={props.errors.reference}
                />
            </Box>
            {showRelation && (
                <Box 
                    sx={{
                        width:1,
                        paddingTop: 2,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("relation")}</Text>
                </Box>
                
                
                <InputDropdown
                        key={data?.relation}
                        name="relation"
                        displayName="name"
                        changeField="id"
                        valueKey="id"
                        selected={data?.relation ?? -1}
                        options={relations}
                        noSelection={t("choose_relation")} 
                        onChange={(val) => handleChange("relation",val)}
                    />
                </Box>
            )}
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7,
                    opacity: !relation ? 0.5 : 1
                }}
            >
                <Box
                    sx={{
                        width:120,
                    }}
                >
                    <Text>{t("channel")}</Text>
                </Box>
                
                
                <InputDropdown
                        key={data?.webshopcredential}
                        name="webshopcredential"
                        displayName="description"
                        changeField="id"
                        disabled={!relation}
                        valueKey="id"
                        selected={data?.relation ? data?.webshopcredential ?? -1 : -1}
                        options={webshopCredentials?.filter(credential => credential.webshop_type === 'bol') ?? []}
                        noSelection={t("choose_channel")} 
                        onChange={(val) => handleChange("webshopcredential",val)}
                    />
            </Box>
        </>
    );
};

const BasicDetails = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
         reference: yupRequired('reference', t).matches(/^[A-Z0-9]*$/, t('reference_invalid')).matches(/[0-9]/, t('reference_invalid')),
         relation: yupRequired('relation', t),
         webshopcredential: yupRequired('webshopcredential', t),
    });

    return (
        <ProgressionTab 
        tabId="BasicDetails" 
        subtitle={t('basic_details')} 
        step={1}
        nextTab={"tabProductDetails"}
        maxSteps={2}
        schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default BasicDetails;