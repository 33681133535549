// import { apiSlice } from '../api/apiSlice'
// import 

import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const STOCK_BASE = "/stocks";
const buildUrl = (endpoint) => STOCK_BASE + endpoint;

const stockApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStock: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'Stock' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getStock', 'Stock')
        }),
        getStockForProduct: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: 'StockForProduct'}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getStockForProduct', 'StockForProduct')
        }),
        
        getStockById: builder.query({
            query: stockId => buildUrl(`/${stockId}`),
            providesTags: (result, error, arg) => [{ type: 'Stock', id: arg }]
        }),
        getStockMutations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/mutations?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: 'StockMutations' }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getStockMutations', 'StockMutations')
        }),
        updateStock: builder.mutation({
            query: (stockObject) => ({
                url: buildUrl(`/${stockObject.id}`),
                method: 'PUT',
                body: stockObject,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Stock'}, { type: 'Products', id: arg.productId }, { type: 'StockForProduct'}],
        }),
        updateStockQuantity: builder.mutation({
            query: (stockObject) => ({
                url: buildUrl(`/${stockObject.id}/quantity`),
                method: 'PUT',
                body: stockObject,
            }),
            invalidatesTags: (result, error, arg) => [{ type: 'Stock'}, { type: 'Products', id: arg.productId }, { type: 'StockForProduct'}],
        }),
    })
});

export const { 
    useGetStockQuery,
    useGetStockForProductQuery, 
    useGetStockByIdQuery,
    useGetStockMutationsQuery,
    useUpdateStockQuantityMutation,
    useUpdateStockMutation
 } = stockApiSlice;
