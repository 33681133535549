import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../../theme";

import Text from "../../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RelationWebshopIntegrationCard } from "../../../../../../components/global/cards/other/WebshopIntegrationCard";
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";
import { useGetAccountingSoftwaresQuery } from "../../../../../../newapi/financial/accountingSoftwareSlice";
import { ReactComponent as MoneybirdLogo } from '../../../../../../styles/svg/moneybird.svg';

import MpAddAccountingSoftware from "../../../../../../components/global/ModalsProgression/MpAddAccountingSoftware/MpAddAccountingSoftware";
import ShTextSkeleton from "../../../../../../components/theme/skeleton/ShTextSkeleton";
import SidepanelLoader from "../../../../../../components/theme/sidepanel2/SidepanelLoader";
import WarehouseAccountingSoftwareCard from "../../../../../../components/global/cards/financial/AccountingSoftwareCard";
import { selectUserPermission } from "../../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from "../../../../../../utils/permissions";

const SpAccountingSoftware = SidepanelLoader(() => import("../../../../../../components/global/Sidepanels/SpAccountingSoftware/SpAccountingSoftware"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {},[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_accounting_software")}</Shbutton>
            )}
        </ButtonGroup>
    );
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [config, setConfig] = useState({"all_credentials": true});
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const [spLoading, setSpLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.ACCOUNTINGSOFTWARE_MANAGE])
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };


    const [accountingSoftwareId, setAccountingSoftwareId] = useState();
    const accountingSoftwareIdRef = useRef(accountingSoftwareId);
    
    const getAccountingSoftwareDetails = (params) => {
        if (!params) return;
    
        const newAccountingSoftwareId = parseInt(params.row.id);
        // Only update state if the webshopCredentialsId has actually changed
        if (accountingSoftwareIdRef.current !== newAccountingSoftwareId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setAccountingSoftwareId(newAccountingSoftwareId);
            accountingSoftwareIdRef.current = newAccountingSoftwareId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const columns = [
        {field: "description", headerName: t("description") , flex:2, cellClassName: "description", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Box sx={{display:"flex",alignItems:"center",gap:2, }}>
                    {cellValues.row.type === "moneybird" ? <MoneybirdLogo width={30} height={30}/> : null}
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
                </Box>
            )
        }},
        {field: "ledgerCount", headerName: t("ledgers") , flex:2, cellClassName: "ledgers", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.ledgers?.length ?? 0} {t("ledgers")}</Text>
            )
        }},
        {field: "taxRateCount", headerName: t("tax_rates") , flex:2, cellClassName: "tax_rates", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.taxRates?.length ?? 0} {t("tax_rates")}</Text>
            )
        }},
        {field: "relationCount", headerName: t("relations") , flex:2, cellClassName: "relations", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.row.relationReferences?.length ?? 0} {t("relations")}</Text>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <WarehouseAccountingSoftwareCard onClick={() => getAccountingSoftwareDetails(cellValues)} skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                )
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetAccountingSoftwaresQuery}
                config={config}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t("accounting_software_solutions")}
                gridOptions={gridOptions} 
                    
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns}
                onRowClick={getAccountingSoftwareDetails}>
            </ShDataGrid2>


            <MpAddAccountingSoftware onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
            
            {spLoading && 
                <SpAccountingSoftware
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    accountingSoftwareId={accountingSoftwareId}              
                /> 
            }
        </Box>
    )
};

export default All;