
import { Box } from '@mui/material';
import Text from '../text/Text';
import { ReactComponent as TableEmpty } from '../../../styles/svg/tableempty.svg'
import { useTranslation } from 'react-i18next';
import Subheading from '../text/Subheading';

const SearchEmpty = () => {
    const {t} = useTranslation();
    
    return (
        <Box 
            sx={{
                display:"flex",
                justifyContent:"center",
            }}
        >
            <Box
                sx={{
                    maxWidth:300,
                    display:"flex",
                    justifyContent:"center",
                    flexDirection:"column",
                    alignItems:"center"
                }}
            >
                <TableEmpty/>
                <Box
                    sx={{
                        mt:3,
                        display:"flex",
                        flexDirection:"column",
                        gap:0.5
                    }}
                >
                    <Subheading textAlign="center">{t('search_no_result_title')}</Subheading>
                    <Text align="center" light>{t('search_no_result_description')}</Text>
                </Box>
            </Box>
        </Box>
    );
}

export default SearchEmpty;