import { Button, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

const TopbarIconButton = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (

        <Button
            onClick={props.onClick}
            TouchRippleProps={{ 
                style: { 
                    color: theme.palette.mode === "dark" ? "rgba(255,255,255,.5)" : 'rgba(49, 65, 95, 0.5)' 
                } 
            }} // Directly styling the ripple here
            variant='contained'
            sx={{
                background:theme.palette.mode === "dark" ? colors.nav["linkBg"]  : "#fff",
                width:40,
                height:40,
                borderRadius:20,
                padding:0,
                minWidth:0,
                boxShadow:"none",
                display:"flex",
                alignItems:"center",
                transition:"250ms",
                color: colors.txt["primary"],
                opacity:1,
                "&:hover" : {
                    transition:"250ms",
                    background:theme.palette.mode === "dark" ? colors.grey[300] : "white",
                    boxShadow:"0px 2px 8px rgba(49, 65, 95, 0.25)"
                },
                "@media screen and (max-width: 47.9375em)" : {
                    width:32,
                    height:32,
                    borderRadius:16
                }
            }}
        >
            {props.children}

        </Button>
    )
}
export default TopbarIconButton;