import { Box, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../../theme";
import SHModal from "../../../theme/Modal/SHModal";
import Shbutton from "../../../theme/buttons/Shbutton";
import Subheading from "../../../theme/text/Subheading";
import Text from "../../../theme/text/Text";
import ButtonGroup from "../../../theme/buttons/ButtonGroup";
import NumberInput from "../../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import cx from "classnames"
import { useAddNewCarrierMutation } from "../../../../newapi/carrier/carrierSlice";
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../../theme/inputs/ShValidatedInput";
import ShValidatedNumberInput from "../../../theme/inputs/ShValidatedNumberInput";
import ShValidatedInputDropdown from "../../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired, yupNumberRequired } from "../../../../utils/validation";
import { InputDropdown } from "../../../theme/dropdowns/InputDropdown";
import { useGetCarriersTypesQuery } from "../../../../newapi/carrier/carrierSlice";

const MAddCarrier = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addCarrier,{isLoading}] = useAddNewCarrierMutation();
    const {data: carrierTypes} = useGetCarriersTypesQuery();

    const select = (selected) => {
        setSelected(selected);
    }

    const [carrierInfo, setCarrierInfo] = useState({
        name: '',
        width: 0,
        length: 0,
        height: 0,
        weight: 0,
        fitsPost: "false",
        type: ''
      });
      
      const handleClose = () => {
        setCarrierInfo({
            name: '',
            width: 0,
            length: 0,
            height: 0,
            weight: 0,
            fitsPost: "false",
            type: ''
        });
        props.handleClose();
    }

    const [fieldErrors, setFieldErrors] = useState({});
    const rules = {
        name: yupRequired('name', t),
        width: yupNumberRequired('width', t),
        length: yupNumberRequired('length', t),
        height: yupNumberRequired('height', t),
        weight: yupNumberRequired('weight', t),
        type: yupRequired('type', t),
    };
    const schema = yup.object().shape(rules);
      
    const SaveCarrier = async (carrierInfo, props) => {
        try {
            if (isLoading) return;

            setFieldErrors({});
            await schema.validate(carrierInfo, { abortEarly: false });

            const data = await addCarrier(carrierInfo).unwrap()
            showToastMessagePostRequest(t,data);

            handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                    ...acc,
                    [currentError.path]: currentError.message
                }), {});
            
                // Set the validation errors in the state
                setFieldErrors(errors);
            } else {
                showToastMessagePostRequest(t,err);
            }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setCarrierInfo(prevState => ({
            ...prevState,
            [key]: value
        }));
        setFieldErrors(prevState => ({
            ...prevState,
            [key]: ''
        }));
      };

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_carrier")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:132,
                        }}
                    >
                        <Text light>{t("name")}</Text>
                    </Box>
                    
                    
                        <ShValidatedInput
                        
                            name="name"
                            value={carrierInfo.name}
                            onChange={handleChange}
                            error={fieldErrors.name}
                        />
                        {/* <InputBase 
                            sx={{
                                px:2,
                                flex:1, 
                                color:colors.txt["primary"],
                                lineHeight:"32px",
                            }}
                            onChange={(e) => handleChange('name', e.target.value)}
                            /> */}
                            
                </Box>

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:132,
                        }}
                    >
                        <Text light>{t("width")}</Text>&nbsp;
                        <Text italic>({t("mm")})</Text>
                    </Box>
                    
                    
                        <ShValidatedNumberInput
                            name="width"
                            value={carrierInfo.width}
                            onChange={handleChange}
                            error={fieldErrors.width}
                        />
                        
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:132,
                        }}
                    >
                        <Text light>{t("length")}</Text>&nbsp;
                        <Text italic>({t("mm")})</Text>
                    </Box>
                    
                    
                        <ShValidatedNumberInput
                            name="length"
                            value={carrierInfo.length}
                            onChange={handleChange}
                            error={fieldErrors.length}
                        />
                        
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:132,
                        }}
                    >
                        <Text light>{t("height")}</Text>&nbsp;
                        <Text italic>({t("mm")})</Text>
                    </Box>
                    
                    
                        <ShValidatedNumberInput
                            name="height"
                            value={carrierInfo.height}
                            onChange={handleChange}
                            error={fieldErrors.height}
                        />
                        
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:132,
                        }}
                    >
                        <Text light>{t("weight")}</Text>&nbsp;
                        <Text italic>({t("gram")})</Text>
                    </Box>
                    
                    
                        <ShValidatedNumberInput
                            name="weight"
                            value={carrierInfo.weight}
                            onChange={handleChange}
                            error={fieldErrors.weight}
                        />
                        
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:132,
                        }}
                    >
                        <Text light>{t("type")}</Text>
                    </Box>
                    
                    
                        <ShValidatedInputDropdown
                            name="type"
                            useTranslation={true}
                            displayValue={"name"}
                            value={carrierInfo.type}
                            onChange={handleChange}
                            error={fieldErrors.type}
                            options={carrierTypes ?? null}
                            noSelection={t("choose_type")}
                            closeOnSelection
                        />
                        
                </Box>
                <Box 
                        
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >
                    <Box
                        sx={{
                            width:132,
                        }}
                    >
                        <Text light>
                        {t('fits_in_post')}
                        </Text>
                    </Box>
                    <ToggleButtonGroup
                        color={carrierInfo.fitsPost === "true" ? "green" : "red"}
                        value={carrierInfo.fitsPost}
                        exclusive
                        onChange={(e) => handleChange('fitsPost', e.target.value)}
                        aria-label="Active"
                        className={cx(`${carrierInfo.fitsPost}`, {
                            'active': carrierInfo.fitsPost === "true"
                        })}
                        sx={{
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('no')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('yes')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>

            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton isLoading={isLoading} className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveCarrier(carrierInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddCarrier;