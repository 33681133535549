// import { apiSlice } from '../api/apiSlice'
// import 

import { muiDataGridStarted } from "../../apiutils";
import { apiSlice } from "../../createApiSlice";


const RETURN_BASE = "/orders/dashboard/relation"; 
const buildUrl = (endpoint) => RETURN_BASE + endpoint;
const reportRelationSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDashboardRelationOrderTotals: builder.query({ 
            query: (body) => ({
                url: buildUrl(`/totals`),
                method: 'POST',
                body
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getDashboardRelationOrderChart: builder.query({ 
            query: (body) => ({
                url: buildUrl(`/chart`),
                method: 'POST',
                body
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
       
    })
});

export const {
    useGetDashboardRelationOrderTotalsQuery,
    useGetDashboardRelationOrderChartQuery,
  } = reportRelationSlice;

