import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
// import Text from "../../../../../components/theme/text/Text";

import { useEffect, useState } from "react";
import OrdersCard from "../../../../../components/global/cards/orders/OrdersCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShCopyButton from '../../../../../components/theme/ShCopy';
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetOrdersQuery } from "../../../../../newapi/order/ordersSlice";

import { useRef } from "react";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import { getOrderStateData } from "../../../../../utils/labelColorText";
import { general_states } from '../../../../../utils/staticEnums';
import { ORDER_FILTER_KEYS } from '../../../../../components/global/DataGrid/Filter';

const SpOrderDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} endIcon={<AddIcon/>} variant="contained">New</Shbutton>
            </ButtonGroup>
            {/* <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton> */}
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter: [ORDER_FILTER_KEYS.STATE],

};

const OutboundActionRequiredView = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [config,setConfig] = useState({[ORDER_FILTER_KEYS.STATE.backend_key]:[general_states.BLOCKED,general_states.BACKORDERED, general_states.MAINTENANCE]});


    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const [orderId, setOrderId] = useState();
    const orderIdRef = useRef(orderId);
    
    const getOrderDetails = (params) => {
        if (!params) return;
    
        const neworderId = parseInt(params.row.id);
        // Only update state if the orderId has actually changed
        if (orderIdRef.current !== neworderId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setOrderId(neworderId);
            orderIdRef.current = neworderId; // Update the ref to the new value
        }
        setIsExtended(true);
    };


    const columns = [
        {
            field: "barcode",
            headerName: "Order",
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box sx={{
                        minWidth:"100%",
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        whiteSpace:"nowrap",
                        wordBreak:"keep-all",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text noBreak>{params.row.barcode}</Text>
                        <ShCopyButton 
                            styles={{button:{
                                background:theme.palette.mode === "dark" ? colors.grey[600] :  colors.grey[200],
                                color: colors.txt["primary"],
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[500] :colors.grey[300],

                                }
                            }}} 
                            sx={{position:"absolute",right:0,zIndex:3000}} 
                            className={"copyel"} 
                            closeAfter={1500} 
                            value={params.row.barcode}
                        ></ShCopyButton>
                    </Box>
                );
            }
        },
        {field: "deliveryName", headerName: t("delivery_name"),flex:1, 
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.deliveryName
            }
        },
        {field: "webshopreference", headerName: t("webshopreference"),flex:2,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (<Text>{`${params.row.webshopcredentials?.description} - ${params.row.webshopreference}`}</Text>)
            }
        },
        {
            field: "date_planned", 
            headerName: t("date_planned_shipment"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let labelcolor = "grey";
                let planned = new Date(params.row.date_planned);
                let sent = params.row.date_sent ? new Date(params.row.date_sent) : null;
              
                if(sent) {
                    // when sent is filled and is equal to planned: green
                    if(sent.getTime() === planned.getTime()) {
                        labelcolor = "green";
                    }
                    // when sent is filled but later than planned: orange
                    else if(sent.getTime() > planned.getTime()) {
                        labelcolor = "orange";
                    }
                }
                else {
                    // When sent is not filled and planned >= now: blue
                    if(planned.setHours(0,0,0,0) >= new Date().setHours(0,0,0,0)) {
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
                    }
                    // When sent is not filled and planned < now: red
                    else if(planned.setHours(0,0,0,0) < new Date().setHours(0,0,0,0)) {
                        labelcolor = "red";
                    }
                }
              
                return (
                    <ShLabel size={32} fitted variant={labelcolor} palette="normal">
                        {!sent && planned.toLocaleDateString(i18n.language)}
                        {sent && sent.toLocaleDateString(i18n.language)}
                    </ShLabel>
                );
            }
        },
        {field: "orderlinesCount", headerName: t("orderlines_count"),flex:1,
            sortable:false,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.row.amountOfLines
        }},
        {field: "stream", headerName: t("stream"),flex:1,

            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.stream?.shortName
            }
        },
        {field: "shipper", headerName: t("shipper_name"),flex:2,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return `${params.row.shipper?.name ?? ""} - ${params.row.shipperprofile?.name ?? ""}`
        }},
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            filter: ORDER_FILTER_KEYS.STATE,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                
                const { labelcolor, labeltext } = getOrderStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {
            field: "createdAt", 
            headerName: t("date_created"),
            flex:1,
            filter: ORDER_FILTER_KEYS.STATE,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return new Date(params.row.createdAt).toLocaleString(i18n.language, {
                    dateStyle: 'short',
                    timeStyle: 'short'
                });
              }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrdersCard onClick={() => getOrderDetails(params)} skeleton={params.row.skeleton ?? false} {...params.row}  />
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrdersQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('action_required') }
                gridOptions={gridOptions} 
                 
                sortModel={{field: columns[0].field,type:"DESC"}}
                columns={columns}
                onRowClick={getOrderDetails}
            >
                    
            </ShDataGrid2>
            
            {spLoading && 
                <SpOrderDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    orderId={orderId}
                    // data={orderData}
                /> 
            }
        </Box>
    );
}
export default OutboundActionRequiredView;