import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";


import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Text from "../../../theme/text/Text";

//     {
//         "id": 5,
//         "state": "active",
//         "type": "invoice_product_zero_price",
//         "body": {
//             "message": "{invoice_product_zero_price} Sealen",
//             "externalId": 1
//         }
//     }
const InvoiceProductZeroPriceNotificationsComp = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const cleanedMessage = useMemo(() => {
        return props.body.message.replace(/{[^}]*}/g, '').trim();
    }, [props.body.message]); // Depend on props.body.message

    return(
        <Box
            onClick={props.onClick ?? null}
            sx={{
                cursor:"pointer",
                px:1.5,
                display:"flex",
                gap:2,
                width:1,
                borderRadius:4,
                py:1.5,
                "&:hover" : {
                    background:colors.grey[100],
                    borderBottomColor:'orange'
                }
            }}
        >
            <Box
                sx={{
                    height:32,
                    width:32,
                    flexShrink:0,
                    display:'flex',
                    alignItems:"center",
                    justifyContent:'center',
                    background:theme.palette.mode === "dark" ? colors.grey[100] : colors.grey[100],
                    color:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[400],
                    borderRadius:3,
                }}
            >
                <ReceiptLongIcon fontSize="small"/>
            </Box>
            <Box flex={1}>
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                        mb:0.5,
                    }}
                >
                    <Text semibold light>{t("invoice_product")}</Text> <Text semibold>€0,-</Text>
                </Box>
                <Box>
                    <Text bold>{cleanedMessage}</Text>
                    
                </Box>
            </Box>
        </Box>
    )
}

export default InvoiceProductZeroPriceNotificationsComp;