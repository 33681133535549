import { Box, useTheme } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useCreateConceptInvoiceMutation } from "../../../newapi/financial/invoiceSlice";
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupNumberRequired } from "../../../utils/validation";
import { useGetRelationsSelectQuery, useEditRelationMutation } from "../../../newapi/global/relation/relationSlice";
import { DropdownBase } from "../../theme/dropdowns/Dropdown";

const MAddConceptInvoice = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: relationData, relationIsLoading } = useGetRelationsSelectQuery();

    const [invoiceInfo, setInvoiceInfo] = useState({
        relationId: -1,
    });

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        relationId: yupNumberRequired('relation', t)
    };
    
    const schema = yup.object().shape(rules);

    // Use get query, the endpoint checks if the relation has an active concept invoice if not a new one will be created
    const [createConceptInvoice, { isLoading }] = useCreateConceptInvoiceMutation();
    
    const SaveInvoiceConcept = async () => {
        try {
            if (isLoading) return;

            setFieldErrors({});
            await schema.validate(invoiceInfo, { abortEarly: false });

            const data = await createConceptInvoice(invoiceInfo).unwrap();
            showToastMessagePostRequest(t, data);

            // reset object
            setInvoiceInfo({
                relationId: -1,
            });

           props.handleClose(data.id);
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("create_failed"), t, err);
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setInvoiceInfo({
          ...invoiceInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };

    return(
        <SHModal
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_relation")}</Subheading>
            </Box>

            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7,
                        alignItems:"flex-start"
                    }}
                >
                    
                    <Box
                        sx={{
                            minWidth:210,
                            pt:1,
                        }}
                    >
                        <Text>{t("relation")}</Text>
                    </Box>
                    <ShValidatedInputDropdown
                        key={invoiceInfo.relationId}
                        displayName={"name"} 
                        changeField={"id"}
                        valueKey={"id"}
                        closeOnSelection
                        searchOptions={{
                            enabled: true,
                            placeholder: t("search_relation_placeholder")
                        }}
                        styles={{
                            minWidth:300
                        }}

                        disallowDeselection
                        name="relationId"
                        selected={relationData?.find(item => item.id === invoiceInfo.relationId)?.id ?? -1}
                        options={relationData ? relationData : []}
                        noSelection={t("choose_option")} 
                        onChange={handleChange}
                        error={fieldErrors.relationId}
                    />
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    justifyContent:"space-between",
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton isLoading={isLoading} className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"}
                    variant="contained" 
                    onClick={() => SaveInvoiceConcept()}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddConceptInvoice;
