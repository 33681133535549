import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../components/theme/buttons/Shbutton";

import AddIcon from '@mui/icons-material/Add';
import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import MessageIcon from '@mui/icons-material/MessageOutlined';
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { RelationInboundLineCard } from "../../../../components/global/cards/inbounds/InboundLineCards";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../components/theme/label/ShLabel";
import SidepanelLoader from "../../../../components/theme/sidepanel2/SidepanelLoader";
import ShDoubleTextSkeleton from "../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import ShLabelSkeleton from "../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetAllInboundLinesQuery } from "../../../../newapi/inbound/inboundsSlice";
import { getInboundStateData } from '../../../../utils/labelColorText';

const SpInboundDetails = SidepanelLoader(() => import("../../../../components/global/Sidepanels/SpInboundDetails/SpInboundDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                // onClick={confirm} 
                endIcon={<AddIcon/>}
                variant="contained"
            >
                New
            </Shbutton>
        </ButtonGroup>
        // <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
        //     <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        // </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const InboundInboundLines = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [config, setConfig] = useState({"relationId": useSelector((state) => state.settings.environment.selectedId)});

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);
    

    const [inboundIsExtended, setInboundIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [inboundId, setInboundId] = useState();
    const inboundIdRef = useRef(inboundId);
    
    const getInboundDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.inbound.id);
        // Only update state if the productId has actually changed
        if (inboundIdRef.current !== newProductId) {
            if(!spLoading){
                setSpLoading(true);
            }
            setInboundId(newProductId);
            inboundIdRef.current = newProductId; // Update the ref to the new value
        }
        setInboundIsExtended(true);
    };

    const columns = [
        {field: "reference", headerName: t("inbound"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box>
                    <Text >{params.row.inbound.reference}</Text>
                </Box>
            )
        }},
        {
            field: "state", 
            headerName: t("status"),
            flex:3,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                const { labelcolor, labeltext } = getInboundStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {field: "sku", headerName: t("sku"), flex:3, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShDoubleTextSkeleton/>
            }
            return (
                <Box>
                    <Text semibold>{params.row.product.sku}</Text><br/>
                    <Text light>{params.row.product.description}</Text>
                </Box>
            )
        }},
        // {field: "product.description", headerName: t("description"), flex:1, renderCell : (params) => {
        //     if(params.row.skeleton) {
        //         return <ShTextSkeleton/>
        //     }
        //     return (
        //         <Box>
                    
        //         </Box>
        //     )
        // }},

        {field: "amountForecasted", headerName: t("forecasted"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        
        {field: "amountReceived", headerName: t("received"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {field: "amountMoved", headerName: t("moved"), flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.value;
            }
        },
        {
            field: "shipmentDate",
            headerName: t("shipment_date"),
            flex:2,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.inbound.shipmentDate);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {
            field: "receivedFromDate",
            headerName: t("received_date"),
            flex:2,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                let xdate = new Date(params.row.inbound.receivedFromDate);
              
                return (
                    <Text>
                         {xdate.toLocaleDateString(i18n.language)} 
                    </Text>
                );
            }
        },
        {field: "remark", headerName: t("remark"), flex:1,
        renderCell: (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.row.remark !== ""){
                return (
                    <Text light><MessageIcon/></Text>
                    
                  );
            } else {
                
            }
            
          }},
        {
            field: "phoneView", 
            headerName: "phoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <RelationInboundLineCard onClick={() => getInboundDetails(params)} skeleton={params.row.skeleton ?? false} {...params.row}/>
                )
            }
        }
    ];
    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetAllInboundLinesQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('all_inboundlines') }
                gridOptions={gridOptions} 
                sortModel={{field: columns[6].field,type:"DESC"}}
                columns={columns}
                onRowClick={getInboundDetails}>
            </ShDataGrid2>
            
            {spLoading && 
                <SpInboundDetails
                    isExtended={inboundIsExtended}
                    setIsExtended={setInboundIsExtended}
                    inboundId={inboundId}
                /> 
            }


        </Box>
    );
}

export default InboundInboundLines;