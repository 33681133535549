import { createSlice, current } from '@reduxjs/toolkit';
import { PURGE, REHYDRATE } from 'redux-persist';
import { produce } from "immer";
import { subDays } from 'date-fns';
import { format } from 'date-fns';

const today = new Date();
const thirtyDaysAgo = subDays(today, 30);

const formattedToday = format(today, 'yyyy-MM-dd'); // You can adjust the format as needed
const formattedThirtyDaysAgo = format(thirtyDaysAgo, 'yyyy-MM-dd'); // Adjust format

const initialState = {
  startDate: formattedThirtyDaysAgo,
  endDate: formattedToday,
};


const relationDashboardSlice = createSlice({
    name: 'relationdashboard',
    initialState: initialState,
    reducers: {
        setReset: () => initialState,
        
        setRelationDashboardDateFilter: (state, action) => {
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(PURGE, () => initialState)
          // ... your other matchers
          .addCase(REHYDRATE, (state, action) => {
            // Or if you want to update the state with the rehydrated state:
            if (action.payload) {
              return { ...state, ...action.payload.relationdashboard};
            }
            return state;
          })
      }
});

export const { 
    setReset,
    setRelationDashboardDateFilter,
} = relationDashboardSlice.actions;
export default relationDashboardSlice;
