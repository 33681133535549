import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import TopRight from "../../../theme/positionals/TopRight";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ShDoubleTextSkeleton from "../../../theme/skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";

const InboundsCard = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [statusColor, setStatusColor] = useState("blue");
    const [statusText, setStatusText] = useState("");

    useEffect(() => {
        if(!props.skeleton) {
            switch(props.state) {
                case "announced":
                    setStatusColor("blue");
                    setStatusText(t("announced"));
                    break;
                case "approved":
                    setStatusColor("green");
                    setStatusText(t("approved"));
                    break;
                case "declined":
                    setStatusColor("red");
                    setStatusText(t("declined"));
                    break;
                case "cancelled":
                    setStatusColor("pink");
                    setStatusText(t("cancelled"));
                    break;
                case "receivedwithoutnotice":
                    setStatusColor("purple");
                    setStatusText(t("receivedwithoutnotice"));
                    break;
                default:
                    setStatusColor("orange");
                    setStatusText(t("unknown"));
                    break;
            }
        }
    },[props.skeleton]);

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        {props.skeleton ? 
                            <ShDoubleTextSkeleton/> 
                            : 
                            <>
                                <Text bold>{props.reference}</Text>
                                <Text>{props.relation?.name}</Text>
                            </>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel 
                            variant={props.skeleton ? "grey" : statusColor}  
                            position="child-tr"
                        >
                            {props.skeleton ? null : statusText}
                        </CardLabel>
                    </TopRight>

                    {/* Holds tht date and batch */}
                    <Box marginTop={3} display={"flex"} justifyContent={"space-between"}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{ t("shipment_date")}</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{new Date(props.shipmentDate).toLocaleDateString(i18n.language)}</Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("received_date")}</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{new Date(props.receivedFromDate).toLocaleDateString(i18n.language)}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default InboundsCard;