// DateRangeFilter.js
// import { ORDER_FILTER_KEYS } from '../Filter';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../../../../theme';
import { orderStateMap } from '../../../../../utils/labelColorText';
import { ORDER_FILTER_KEYS } from '../../Filter';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import cx from 'classnames'; // Importing cx from classnames
import { useDispatch, useSelector } from 'react-redux';
import { useGridFilter } from '../../../../../contexts/GridFilterContext';
import { updateFilter } from '../../../../../newapi/global/filter/gridFilterSlice';

const OrderStatusFilter = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();
    const filterKey = ORDER_FILTER_KEYS.STATE.backend_key;
    const dispatch = useDispatch();

    const { index,id } = useGridFilter();
    const filterObject = useSelector((state) => {
        const instance = state.gridFilter.viewInstances[index];
        return instance ? instance[id] : undefined;
    });

    const allStates = Object.values(orderStateMap(theme));

    const handleStateToggle = (itemState) => {
        if (filterObject && filterObject.filter) {
            const currentStates = filterObject.filter[filterKey] || [];

            // Check if the clicked labeltext is already in the backendKey (active states)
            const updatedStates = currentStates.includes(itemState)
                ? currentStates.filter(state => state !== itemState) // Remove if already active
                : [...currentStates, itemState]; // Add if not active

            // Dispatch the action to update the filter with the new active states
            dispatch(updateFilter({ index, id, filterKey, value: updatedStates }));
        }
    };
  
    return (
        <Box
            sx={{
                display:"flex",
                flexDirection:"column",
                gap:1.5,
            }}
        >
            {allStates.map((stateData,index) => {
                return (
                    <Box
                        className={cx({
                            active: filterObject && filterObject.filter?.[filterKey]?.includes(stateData.state), // Add the 'active' class if state is active
                        })}
                        onClick={() => handleStateToggle(stateData.state)}
                        key={index + stateData.labeltext}
                        sx={{
                            height:40,
                            border: `1px solid ${theme.palette.mode === "dark" ? colors.grey[400] :colors.grey[100]}`,
                            p:0.5,
                            borderRadius:5,
                            display:"flex",
                            alignItems:'center',
                            "&.active " : {
                                background: colors[stateData.labelcolor][100],
                                border: `1px solid ${colors[stateData.labelcolor][100]}`,
                            }
                        }}
                    >
                        <Box
                            sx={{
                                height:32,
                                background: colors[stateData.labelcolor][100],
                                color: colors[stateData.labelcolor][400],
                                fontFamily:"Roboto, sans-serif",
                                fontSize:14,
                                fontWeight:500,
                                display:"inline-flex",
                                minWidth:120,
                                alignItems:"center",
                                justifyContent:"center",
                                borderRadius:4,
                            }}
                        >
                            {t(stateData.labeltext)}
                        </Box>

                    </Box>
                );
            })}
            
        </Box>
    );
};

export default OrderStatusFilter;