import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { authApiSlice } from './authApiSlice';
import { useNavigate } from 'react-router-dom';
import { CUSTOMERS, JWT_TOKEN, JWT_TOKEN_EXPIRES_AT, REFRESH_TOKEN } from '../../../storagekeys';
import { REHYDRATE } from 'redux-persist';
import { PURGE } from "redux-persist";
import { ENV_TYPES } from '../../../components/global/Sidebar';

export const UserState = {
  UNSET: 'unset',
  LOGGED_IN: 'loggedIn',
  LOGGED_OUT: 'loggedOut',
  LOGGING_IN: 'loggingIn',
  LOGGING_OUT: 'loggingOut'
};

const initialState = { 
  status: UserState.LOGGED_OUT, 
  token: null, 
  refreshToken: null, 
  relations: null,
  customers: null, 
  expiresAt: null, 
  permissions: null,
  user: null
}

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    updateToken: (state, action) => {
      console.log("token_stuff",action.payload);
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.expiresAt = action.payload.expires_at;
    },
    logout: (state, action) => {
      state.status = UserState.LOGGED_OUT;
        state.token = null;
        state.refreshToken = null;
        state.customers = null;
        state.relations = null;
        state.expiresAt = null;
        state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // ... your other matchers
      .addCase(REHYDRATE, (state, action) => {
        // Or if you want to update the state with the rehydrated state:
        if (action.payload) {
          return { ...state, ...action.payload.auth };
        }
        return state;
      })
      .addCase(PURGE, () => initialState) // THIS LINE
      .addCase(checkAndRedirect.fulfilled, (state) => {
        // You can keep this empty or do additional state modifications if required.
      })
      .addMatcher(authApiSlice.endpoints.login.matchPending, (state) => {
        state.status = UserState.LOGGING_IN;
      })
      // .addMatcher(authApiSlice.endpoints.refreshToken.matchPending, (state) => {
      //   console.log("kwakwastate",state);
      //   // state.status = UserState.LOGGING_IN;
      // })
      .addMatcher(authApiSlice.endpoints.login.matchFulfilled, (state, action) => {
        state.status = UserState.LOGGED_IN;
        state.token = action.payload.data.token;
        state.customers = action.payload.data.customers;
        state.relations = action.payload.data.relations;
        state.expiresAt = action.payload.data.expires_at;
        state.refreshToken = action.payload.data.refresh_token;
        state.permissions = action.payload.data.permissions;
        state.user = action.payload.data.user;
      })
      .addMatcher(authApiSlice.endpoints.logout.matchPending, (state) => {
        state.status = UserState.LOGGING_OUT;
      })
      .addMatcher(authApiSlice.endpoints.logout.matchFulfilled, (state) => {
        state.status = UserState.LOGGED_OUT;
        state.token = null;
        state.refreshToken = null;
        state.customers = null;
        state.relations = null;
        state.expiresAt = null;
        state.user = null;
      })
      .addMatcher(authApiSlice.endpoints.logout.matchRejected, (state) => {
        state.status = UserState.LOGGED_OUT;
        state.token = null;
        state.refreshToken = null;
        state.customers = null;
        state.relations = null;
        state.expiresAt = null;
        state.user = null;
      })
      .addMatcher(authApiSlice.endpoints.refreshToken.matchFulfilled, (state, action) => {
        state.token = action.payload.token;
      });
  },
});

// Async Thunk for checking auth status and redirecting
export const checkAndRedirect = createAsyncThunk(
  'auth/checkAndRedirect',
  async (permissions, { dispatch, getState }) => {
    const state = getState();
    const authStatus = state.auth.status;
    if(authStatus === UserState.LOGGED_OUT || authStatus === UserState.UNSET) {
      return {loggedOut: true, authorized: null};
    } else {
      if (permissions === undefined) {
        return {loggedOut: false, authorized: null};
      } else {
        return {loggedOut: false, authorized: selectUserPermission(state, permissions)};
      }
    }
  }
);


const selectUserPermission = (state, requiredPermission) => {
  const activeWarehouse = state.warehouse.activeId;
  const permissions = state.auth.permissions;

  const type = state.settings.environment.selectedEnvironmentType;
  const id = state.settings.environment.selectedId;

  let relation_id = 0;
  let customer_id = 0;
  
  if(type === ENV_TYPES[100]){
    customer_id = id;
  } else if(type === ENV_TYPES[200]) {
    relation_id = id;
  }

  if(!permissions) return true;

  let permissionObject = permissions.find(permission => ((customer_id > 0 && 
    permission.customerId === parseInt(customer_id) && permission.relationId === null &&
      permission.warehouseId === parseInt(activeWarehouse)) ||
      (relation_id > 0 && permission.relationId === parseInt(relation_id)))
  );


  if (permissionObject) {
      if (permissionObject.admin || requiredPermission.some(p => permissionObject.permissions.includes(p))) {
          return true;
      }
  } else {
      // Check general warehouse permission
      let permissionObject = permissions.find(permission => (customer_id > 0 && 
          permission.customerId === parseInt(customer_id) &&
          permission.warehouseId === -1)
      );

      if (permissionObject?.admin || requiredPermission.some(p => permissionObject?.permissions.includes(p))) {
          return true;
      }
  }

  return false;
};

export const selectAuth = state => state.auth;
export const selectAuthStatus = state => state.auth.status;
export const selectCustomers = (state) => state.auth.customers;
export const selectRelations = (state) => state.auth.relations;
export const selectUser = (state) => state.auth.user;

export const { updateToken, logout } = authSlice.actions;
export { selectUserPermission };
export default authSlice.reducer;
