import { Box, IconButton, useTheme } from "@mui/material"
import BaseCard from "../../../../theme/cards/BaseCard"
import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";
import CardLabel from "../../../../theme/label/CardLabel";
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import TopRight from "../../../../theme/positionals/TopRight";

export const WarehouseVirtualProductCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick} sx={{p:0,pb:2,}}>
            <Box>
                <Box>
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                pl:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.description}</Text>
                                    {/* <Text noBreak bold>{props.product?.description}</Text> */}
                                    {/* <Text >{props.product?.relation?.name}</Text> */}
                                    <Text>{props.relation ? props.relation.name : t('unknown')}</Text>
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                            }}
                        >
                            <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"}  position="child-tr">
                                {props.skeleton ? 
                                    <ShDoubleTextSkeleton/> 
                                    :
                                    props.sku ? props.sku : "N/A"
                                }
                            </CardLabel>
                        </Box>
                    </Box>

                    

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3} pl={2}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("products")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.childProducts ? props.childProducts : 0}    
                                </Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}



export const WarehouseVirtualProductDetailsCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick} sx={{p:0,pb:2,}}>
            <Box>
                <Box>
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                pl:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.product ? props.product.sku : t('unknown')}</Text>
                                    {/* <Text noBreak bold>{props.product?.description}</Text> */}
                                    {/* <Text >{props.product?.relation?.name}</Text> */}
                                    <Text sx={{pr:6}}>{props.product ? props.product.description : t('unknown')}</Text>
                                </>
                            }
                        </Box>
                        
                        <TopRight>
                            <CardLabel 
                                variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                                hasright 
                                icon
                                position="child-tr"
                                onClick={(e) =>{
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.setChildProduct({childProductId: props.id, parentProductId: props.parentProductId, quantity: props.quantity, description: props.product?.description})
                                }}
                            >
                                <EditIcon/>
                            </CardLabel>
                            <CardLabel 
                                hasleft 
                                variant={"red"} 
                                position="child-tr"
                                icon
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    props.setProductToDelete(props.product.id);
                                    props.handleDeleteClick();
                                }}
                            >

                                <DeleteIcon/>
                                {/* {props.skeleton ? 
                                    <ShDoubleTextSkeleton/> 
                                    :
                                    props.quantity
                                } */}
                            </CardLabel>
                        </TopRight>
                    </Box>
                    
                </Box>
                <Box marginTop={3} pl={2} display={"flex"} alignItems={"flex-start"} gap={3}>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Text light>{ t("amount")}</Text>
                        {props.skeleton ? 
                            <ShTextSkeleton/> 
                            :
                            <Text>{props.quantity}</Text>
                        }
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}
