import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import TopRight from "../../../theme/positionals/TopRight";
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import { ReactComponent as MoneybirdLogo } from '../../../../styles/svg/moneybird.svg';
const WarehouseAccountingSoftwareCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"} gap={props.skeleton ? 0.5 : null} width={props.skeleton ? "58%" : null}>
                        {props.skeleton ?
                            <ShTextSkeleton variant="orange"/>
                        : 
                            
                            <Box sx={{display:"flex",alignItems:"center",gap:2, }}>
                                {props.type === "moneybird" ? <MoneybirdLogo width={30} height={30}/> : null}
                                <Text semibold>{props.description}</Text>
                            </Box>
                        }
                    </Box>
                    {/* <TopRight>
                        <CardLabel 
                        variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}

                        background={theme.palette.mode === "dark" ? colors.grey[400] : null} 
                        color={theme.palette.mode === "dark" ? colors.txt["primary"] : null} 
                        position="child-tr">
                            {props.availableStock}
                        </CardLabel>
                    </TopRight> */}

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={4}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("ledgers")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.ledgers.length ?? 0}</Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("tax_rates")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.taxRates.length ?? 0}</Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("relations")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.relationReferences.length ?? 0}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default WarehouseAccountingSoftwareCard;