import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../../theme";
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import Subheading from "../../../../../../components/theme/text/Subheading";
import Text from "../../../../../../components/theme/text/Text";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Future from "./views/Future"
import PalletPicking from "./views/PalletPicking";
import PickBasis from "./views/PickBasis";
import Echeck from "./views/Echeck";
import Invoicing from "./views/Invoicing";

import { useGetSettingsQuery } from "../../../../../../newapi/global/generalApiSlice";
import Inbound from "./views/Inbound";
import Replenish from "./views/Replenish";
import { getSettingsWarehouseGeneralCoreConfig } from "./settingsWarehouseGeneralCoreConfig";
import TabView3 from "../../../../../../components/theme/TabView3/TabView3";
import Relation from "./views/Relation";

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: false,
    hide:true,
    

};

const Core = forwardRef((props, ref) => {
    const { t } = useTranslation();
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const config = getSettingsWarehouseGeneralCoreConfig(isFulfilment);

    const { data: incData} = useGetSettingsQuery("core");
    const [data, setData] = useState();
    
    useEffect(() => {
        setData(incData);
    },[incData]);

    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
    useImperativeHandle(ref, () => ({
    }));

    return (
        <Box
            sx={{
                padding:"0px 32px",
                paddingTop:"32px",
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <Box
            sx={{
                //padding:"0px 32px",
                marginBottom:"32px",
                //height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }}}>
                <Subheading>{t("core_settings_title")}</Subheading>
                <Text light>{t("core_settings_subtitle")}</Text>
            </Box>

            <TabView3
                 tabConfig={config}
                viewType="bodytabs"
                sx={{
                    position:"relative",
                    zIndex:2,
                    display: "flex",
                    flex:1,
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            // transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            // transition:"250ms"
                        },
                    }
                  
                }}
                tabClasses={"btn-body-tab"}
            >
               <>
                    <PickBasis config={config.pickBasis}  />
                    <Inbound config={config.inbound} />
                    <Replenish config={config.replenish}/>
                    <Echeck config={config.echeck}/>
                    {isFulfilment && <Invoicing config={config.invoicing}/>}
                    {isFulfilment && <Relation config={config.relation}/>}
                </>
            </TabView3>
        </Box>
    )
});

export default Core;