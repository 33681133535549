import { Box, ButtonBase, useTheme } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useLayoutEffect, useState } from "react";
import Text from "../text/Text";
import ChartText from "../text/ChartText";
// import Dropdown from "../Dropdown";
import { DropdownBase } from "./Dropdown";
import cx from 'classnames';
import { useTranslation } from "react-i18next";

export const InputDropdownContent = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const variant = props.variant ?? "default";


    const getTextForMultiple = () => {
        if(props.selected.length === 0) return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        if(props.selected.length === 1) {
            if(props.valueKey) {
                return props.options.find(obj => obj[props.valueKey] === props.selected[0])[props.displayName];
            }
            if(typeof props.options[props.selected[0]] === "object") {
                return props.options[props.selected[0]][props.displayName]
            }
        }
        
        return props.selected.length
    }

    const getBackground = () => {
        if(variant === "default") {
            return theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"];
        }
        if(variant=== "darker") {
            return theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200];
        }
    }

    const getPaddingRight = () => {
        if(variant === "default") return 1;
        if(variant=== "darker") return 0.5;

    }

    const getButtonBackground=  () => {
        if(variant === "default") {
            return null
        }
        if(variant === "darker") {
            return theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"];
        }
    }
    const getButtonColor = () => {
        if(variant === "default") {
            return colors.txt["secondary"];
        }
        if(variant === "darker") {
            return "#fff";
        }
    }

    const getSingleValue = () => {
        if(props.selected === -1 || !props.options || props.options?.length === 0) {
            return typeof props.noSelection !== "undefined" ? props.noSelection : t("choose");
        }
        if(props.valueKey) {
            if(props.useTranslation){
                return t(props.options.find(obj => obj[props.valueKey] === props.selected)[props.displayName]);
            }
            return props.options.find(obj => obj[props.valueKey] === props.selected)[props.displayName];
        }
        if(typeof props.options[props.selected] === "object") {
            if(props.useTranslation){
                return t(props.options[props.selected][props.displayName]);
            }
            return props.options[props.selected][props.displayName]
        }
        if(props.useTranslation){
            return t(props.options[props.selected]);
        }
        return props.options[props.selected]
    }

    return (
        <ButtonBase
            className={cx(props.className + " dsfsdjn", {
                'error': props.error,
                'disabled': props.disabled,
            })}
            aria-describedby={props.describedBy} 
            onClick={props.onClick}
            disabled={props.disabled ?? false}

            TouchRippleProps={{ 
                style: { 
                    color: theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[400]
                } 
            }} 

            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                background: getBackground(),
                height:props.height ?? 40,
                paddingRight: getPaddingRight(),
                paddingLeft: 2,
                borderRadius:5,
                width:1,
                "&.error" : {
                    // background: colors.red[100],
                    // // border: `1px solid ${colors.red[400]} !important`,
                    // borderColor: colors.red[400],
                    // borderStyle: 'solid',
                    // borderWidth: 1,
                    borderColor: `${colors.red[400]} !important`,
                    borderStyle: 'solid !important',
                    borderWidth: '1px !important',
                    background: `${colors.red[100]} !important`,
                },
                "&.disabled" : {
                    opacity:0.5,
                }
                // mr:2,
            }}
        >
            {props.multiple ?
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1
                    }}
                >
                    <Text noBreak variant={props.error ? "red" : null} bold>{getTextForMultiple()}</Text>
                    {/* <ChartText mode={mode} color={color} bold>{getTextForMultiple()}</ChartText> */}
                    {props.selected.length > 1 ?
                        <Text noBreak variant={props.error ? "red" : null} light>{t("selected")}</Text>
                    :null}
                    {/* <ChartText mode={mode} color={color} bold>{selected !== -1 ? props.options[selected][props.displayName] : "Alle"}</ChartText> */}
                </Box> 
            :
                <Box pr={2}
                    sx={{

                        display:"flex",
                        alignItems:"center",
                    }}
                >
                    <Text noBreak variant={props.error ? "red" : null}  bold>
                        {getSingleValue()}
                    </Text>
                </Box>
                
                
            }
            <Box
                sx={{
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    transform:"rotate(90deg)",
                    width: 32,
                    height:32,
                    flexShrink:0,
                    borderRadius:4,
                    color: getButtonColor(),
                    background: getButtonBackground()
                }}
            >
                <ChevronRightIcon/>
            </Box>
        </ButtonBase>
    )
}

export const InputDropdown = (props) => {
    return (
        <DropdownBase {...props}>
            <InputDropdownContent {...props} variant={props.variant}/>
        </DropdownBase>
    )
}