import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../theme/buttons/Shbutton";
import Text from "../../../theme/text/Text";
// import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeftAltIcon';
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "../../../../theme";

import SidepanelHeaderActiongroup from "../../../theme/sidepanel2/SidepanelHeaderActiongroup";

import Products from "./Views/Products";


import ImportExportIcon from '@mui/icons-material/ImportExport';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { getTranslatedDateAndTime } from "../../../../utils";

import Sidepanel2 from "../../../theme/sidepanel2/Sidepanel2";
import ShRowSkeleton from "../../../theme/skeleton/ShRowSkeleton";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import { ENV_TYPES } from "../../Sidebar";
import {SpBolReplenishmentDetailsConfig as SpBolReplenishmentDetailsConfig} from "./SpBolReplenishmentDetailsConfig";
import { getBolReplenishmentStateData, getInboundStateData } from '../../../../utils/labelColorText';
import { useFindBolreplenishmentByIdQuery, useGetPicklistQuery, useGetCarrierLabelQuery } from '../../../../newapi/webshop/bolreplenishmentSlice';
import { Check, Close, LinkOutlined } from '@mui/icons-material';
import MApproveBolReplenishment from "../../Modals/MApproveBolReplenishment";
import { LaunchOutlined } from '@mui/icons-material';
import SpOrderDetails from '../SpOrderDetails/SpOrderDetails';
const SidePanelActions = (props) => {
    const { t } = useTranslation();
    return (
        <SidepanelHeaderActiongroup>
            <Shbutton isCancel={true} 
                onClick={() => props.setIsExtended(false)} 
                color="primary" 
                variant="text" 
                startIcon={<ArrowRightAltIcon/>}
            >
                {t("close")}
            </Shbutton>
            {props.state === "open" && (
                <>
                <Shbutton
                className={"group-btn"}
                onClick={props.rejectAction}
                color={"red"}
                variant="contained"
                endIcon={<Close />}
            >
                {t('reject')}
            </Shbutton>
                <Shbutton
                    className={"group-btn"}
                    onClick={props.approveAction}
                    color={"green"}
                    variant="contained"
                    endIcon={<Check />}
                    sx={{marginLeft:1}}
                >
                    {t('approve')}
                </Shbutton>
                
            </>
            )}
            {props.state === "approved" && (
                <Shbutton
                    className={"group-btn"}
                    onClick={props.orderAction}
                    color={"blue"}
                    variant="contained"
                    endIcon={<LinkOutlined />}
                >
                    {t('open_order') + " " + props.order?.webshopreference}
                </Shbutton>
            )}
        </SidepanelHeaderActiongroup>
    )
}

const HeaderDateBox = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);
    const [from, setFrom] = useState({});
    const [to, setTo] =  useState({});
    
    useEffect(() => {
        setFrom(props.data?.expectedDeliveryDate && props.data?.expectedDeliveryDate !== null ? getTranslatedDateAndTime(props.data?.expectedDeliveryDate, props.t) : "-");
        setTo(props.data?.plannedShipmentDate && props.data?.plannedShipmentDate !== null ? getTranslatedDateAndTime(props.data?.plannedShipmentDate, props.t) : "-");
    },[props.data])

    return (
        <>
            {/* <Text light>Date</Text> */}
            <Box display={"flex"} alignItems={"center"} width={210}>
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>{t("expected")}</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{from?.date}</Text></Box>
                    </>
                }
            </Box>
            <Box  display={"flex"} alignItems={"center"} width={210}>
                
                <Box 
                    sx={{
                        width:66,
                        flexShrink:0,
                        mr:1,
                    }}
                >
                    <Text light>{t("planned")}</Text>
                </Box>
                {props.skeleton ?
                    <ShTextSkeleton color={theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200]} /> 
                : 
                    <>
                        <Box marginRight={1}><Text bold>{to?.date}</Text></Box>
                    </>
                }
            </Box>
                
        </>
    )

}

const SpBolReplenishmentDetails = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];
    const [spLoading, setSpLoading] = useState(false);

    const { data } = props;

    const { t, ready, i18n } = useTranslation();
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const { data: bolReplenishmentData, isLoading,isFetching,isError } = useFindBolreplenishmentByIdQuery(props.itemId,
    {
        skip: !props.itemId
    });

    useEffect(() => {
        console.log(bolReplenishmentData);
    }, [bolReplenishmentData]);

    const [open, setOpen] = useState(false);
    const [rejectOpen, setRejectOpen] = useState(false);
    const [orderOpen, setOrderOpen] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [retrievePicklistId, setRetrievePicklistId] = useState(null);
    const [retrieveCarrierId, setRetrieveCarrierId] = useState(null);
    const { data: picklist } = useGetPicklistQuery(retrievePicklistId, {skip: !retrievePicklistId});
    const { data: carrierLabel } = useGetCarrierLabelQuery(retrieveCarrierId, {skip: !retrieveCarrierId});

    const startDownload = (bolReplenishmentId) => {
        setRetrievePicklistId(bolReplenishmentId);
    }

    const startDownloadCarrier = (bolReplenishmentId) => {
        setRetrieveCarrierId(bolReplenishmentId);
    }

    const openOrder = (orderId) => {
        // Only update state if the orderId has actually changed
        if(!spLoading) {
            setOrderId(orderId);
            setSpLoading(true);
            setOrderOpen(true);
        }
    }

    useEffect(() => {
        
        if (picklist) {
            setRetrievePicklistId(null);
            // Convert Buffer object to Uint8Array
            const dataArray = new Uint8Array(picklist.data.data);

            // Create Blob from Uint8Array
            const blob = new Blob([dataArray], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(blob);

            // Open in a new tab
            window.open(pdfUrl, "_blank");
        }
    }, [picklist]);

    useEffect(() => {
        if (carrierLabel) {
            setRetrieveCarrierId(null);
            // Convert Buffer object to Uint8Array
            const dataArray = new Uint8Array(carrierLabel.data.data);

            // Create Blob from Uint8Array
            const blob = new Blob([dataArray], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(blob);

            // Open in a new tab
            window.open(pdfUrl, "_blank");
        }
    }, [carrierLabel]);

    const defaultIcon = useMemo(() => ({
        theme: theme.palette.mode === "dark" ? "blue" : "primary",
        content: <ImportExportIcon/>
    }), [theme.palette.mode]);

    const defaultOptions = useMemo(() => [
        { component: <HeaderDateBox skeleton t={i18n}/> },
        { component: <ShRowSkeleton heading={t("packing_list")} /> },
    ], [t]);

    
    const setIsExtendedCallback = useCallback(() => props.setIsExtended(false), [props.setIsExtended]);

    // Memoize the default state to avoid recalculating it on every render
    const defaultState = useMemo(() => ({
        title: "",
        subTitle: "",
        icon: defaultIcon,
        options: defaultOptions,
        actions: <SidePanelActions setIsExtended={setIsExtendedCallback} state={bolReplenishmentData?.state} approveAction={() => setOpen(true)} rejectAction={() => setRejectOpen(true)} downloadAction={() => startDownload(bolReplenishmentData?.id)} orderAction={() => openOrder(bolReplenishmentData?.order?.id)}/>,
    }), [defaultIcon, defaultOptions, setIsExtendedCallback, bolReplenishmentData]);

    const [panel, setPanel] = useState(defaultState);

    useEffect(() => {
        setPanel(defaultState);
    }, [isFetching,props.itemId,theme.palette.mode])
    
    useEffect(() => {
        if (!isLoading && !isFetching && bolReplenishmentData) {
            // let locations = productData.stocks.map(stock => stock.warehouseLocation.location);
            // let combinedLocations = locations.join(", ");
            const { labeltext, labelcolor } = getBolReplenishmentStateData(bolReplenishmentData.state, theme);

            setPanel(prevPanel => ({
                ...prevPanel,
                title: bolReplenishmentData.reference ?? t("reference_missing"),
                subTitle: t(labeltext),
                icon : defaultIcon,
                tabColor: labelcolor,
                options : [
                    ...isFulfilment ? [{
                        heading: t("relation"),
                        content: bolReplenishmentData.relation?.name
                    }] : [],
                    {
                        component: <HeaderDateBox data={bolReplenishmentData} t={i18n}/>
                    },
                    {
                        heading: t("packing_list"),
                        content: bolReplenishmentData.bolReplenishmentId ? <span style={{cursor: "pointer"}} onClick={() => startDownload(bolReplenishmentData.id)}>{`${t("packing_list")}-${bolReplenishmentData.bolReplenishmentId}.pdf`} <LaunchOutlined/></span> : t("available_after_picking")
                    },
                    {
                        heading: t("carrier_label"),
                        content: bolReplenishmentData.bolReplenishmentId ? <span style={{cursor: "pointer"}} onClick={() => startDownloadCarrier(bolReplenishmentData.id)}>{`${t("carrier_label")}-${bolReplenishmentData.bolReplenishmentId}.pdf`} <LaunchOutlined/></span> : t("available_after_picking")
                    }
                ],
                // topRight: <></>
            }));
        }
    }, [bolReplenishmentData, isLoading, isFetching, t,theme.palette.mode]);
   
    return (
        <>
        <Sidepanel2
            skeleton={isLoading || isFetching}
            tabConfig={SpBolReplenishmentDetailsConfig}
            content={panel}
            isExtended={props.isExtended} 
            setIsExtended={props.setIsExtended} 
        >
            <>
                <Products config={SpBolReplenishmentDetailsConfig.products} data={bolReplenishmentData}/>
                {/* <Details config={SpBolReplenishmentDetailsConfig.details} data={bolReplenishmentData} itemId={props.itemId} setIsExtended={props.setIsExtended}/>
                <Shipments config={SpBolReplenishmentDetailsConfig.shipments} itemId={props.itemId}/>
                <Logs config={SpBolReplenishmentDetailsConfig.logs} id={props.itemId}/>  */}
            </>
        </Sidepanel2>
        <MApproveBolReplenishment data={bolReplenishmentData} open={open} handleClose={() => setOpen(false)}/>
        {spLoading && 
                <SpOrderDetails
                    isExtended={orderOpen}
                    setIsExtended={setOrderOpen}
                    orderId={orderId}
                    // data={orderData}
                /> 
            }
        </>
    );
};

export default SpBolReplenishmentDetails;