import { Box, useTheme,ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import cx from "classnames"
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";

import { yupRequired, yupNumberRequired } from "../../../utils/validation";

import { useCreateReturnReasonMutation } from "../../../newapi/inbound/returnsSlice";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import { useGetAvailableTimeslotsQuery, useApproveBolreplenishmentMutation } from "../../../newapi/webshop/bolreplenishmentSlice";
import ShDateRangePicker from "../../theme/ShDateRangePicker";
const MApproveBolReplenishment = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selectedDateslot, setSelectedDateslot] = useState(null);

    // const shipperOptions = [
    //     "POSTNL","DHL","DPD","GLS","UPS","OTHER"
    // ];

    
    // const { data: dateslots } = useGetAvailableTimeslotsQuery({
    //     webshopcredential: props.data?.webshopcredentials?.id
    // }, {skip: !props.data?.webshopcredentials});

    useEffect(() => {
        console.log(props.data);
        setApproveBolReplenishmentInfo({ bolReplenishmentId: props.data?.id });
    }, [props]);

    const [approveBolReplenishment,{isLoading}] = useApproveBolreplenishmentMutation();

    const [approveBolReplenishmentInfo, setApproveBolReplenishmentInfo] = useState({
        pickDate: new Date(),
    });

    useEffect(() => {
        console.log(approveBolReplenishmentInfo);
    }, [approveBolReplenishmentInfo]);

    const handleClose = () => {
        setApproveBolReplenishmentInfo({
            pickDate: new Date(),
        });
        props.handleClose();
    }

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        pickDate: yupRequired('pickDate', t)
    };
    
    const schema = yup.object().shape(rules);
   
    const Approve = async () => {
        try {
            if (isLoading) return;

            // Reset field errors
            setFieldErrors({});
        
            await schema.validate(approveBolReplenishmentInfo, { abortEarly: false });
        
            // If validation is successful, proceed with the addSupplier call
            const data = await approveBolReplenishment(approveBolReplenishmentInfo).unwrap();
            showToastMessagePostRequest(t, data);

            handleClose();
        } catch (err) {
          if (err.name === 'ValidationError') {
            // Create an errors object where keys are field names and values are error messages
            const errors = err.inner.reduce((acc, currentError) => ({
              ...acc,
              [currentError.path]: currentError.message
            }), {});
      
            // Set the validation errors in the state
            setFieldErrors(errors);
          } else {
            // Handle other errors, such as network errors
            showToastMessageRequestError(t("create_failed"), t, err);
          }
        }
    };

    

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    const handleChange = (key, value) => {
        setApproveBolReplenishmentInfo(prevState => ({
            ...prevState,
            pickDate: value
        }));
        
        setFieldErrors(prevErrors => ({
            ...prevErrors,
            [key]: ''
        }));
    };

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("approve_bol_replenishment")}</Subheading>
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    pb:2,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"flex-start",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                            flexShrink:0,
                            display:"flex",
                            alignItems:"center",
                            pt:1,
                        }}
                    >
                        <Text>{t("choose_pick_date")}</Text>
                    </Box>
                    <Box
                    sx={{
                        flex:1,
                        backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    
                    <ShDateRangePicker 
                            singleSelect={true} 
                            startDate={approveBolReplenishmentInfo?.pickDate}
                            select={handleChange} 
                            size={32}
                        />
                </Box>
                </Box>
                <Box 
                    sx={{
                        borderRadius:4,
                        maxWidth:500,
                        background: colors["orange"][100],
                        color: colors["orange"][400],
                        padding:2,
                        width:1,
                        marginTop:2,
                        marginBottom:2,
                        fontSize:14,
                        fontFamily:"Roboto,sans-serif"
                    }}
                >
                    {t("choose_delivery_date_bol_explanation")}
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton isLoading={isLoading} className={"group-btn"} color={"green"}
                    block
                    variant="contained" 
                    onClick={() => Approve()}
                    >{t("approve")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MApproveBolReplenishment;
