
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import Topbar from "./components/theme/global/Topbar";

// import { RequireAuth } from "react-auth-kit";
import { Box, useTheme } from "@mui/material";

// import cx from classNames

import { useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { tokens } from "../../theme";
import Finished from "./Finished";
import NotFound from "./NotFound";
import Order from "./Order";
import RegisterReturn from "./RegisterReturn";
import RmaStatus from "./RmaStatus";

import { RmaNav } from "./RmaNav";

function RmaApp() {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const {relation} = useParams();

  const handleResize = () => {
    setWindowWidth(window.innerWidth); 
  }
   
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  },[])

  const [relation, setRelation] = useState(null);
  // localeSettings
  // localeSettings[language].flag
  
  const language = useSelector((state) => state.settings.language);

  const [navActive, setNavActive] = useState(false);
  const toggleNavActive = () => {
    setNavActive((active) => !active);
    // setMode((prev) => (prev === "light" ? "dark" : "light")
  }


  return (
    <Box 
      className="return-app"
      // component={"main"} 
      
      sx={{
        minHeight:"calc(100%)"
      }}
    >
      <RmaNav relation={relation}/>

      <Routes>
        <Route path="/:relation">
          <Route path="" element={<RegisterReturn setRelation={setRelation}/>}/>
          <Route path="order" element={<Order setRelation={setRelation}/>}/>
          <Route path="finished" element={<Finished setRelation={setRelation}/>}/>
          <Route path="status" element={<RmaStatus setRelation={setRelation}/>}/>
          {/* other nested routes can be defined here */}
        </Route>
        <Route path="notfound" element={<NotFound />}/>
      </Routes>
    </Box>
  );
}

export default RmaApp;
