import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";

export const OutboundDashboardCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"} gap={props.skeleton ? 0.5 : null} width={props.skeleton ? "58%" : null}>
                        {props.skeleton ?
                            <ShTextSkeleton/>
                            :
                            <Text bold>{props.name}</Text>
                        }
                    </Box>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("shipped")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.shipped}</Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("open")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.open}</Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("overdue")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>{props.overdue}</Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}