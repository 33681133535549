
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import InputIcon from '@mui/icons-material/Input';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const warehouseOutboundOrdersConfig = (isFulfilment) => {
    return {
        orders:  {
            title: 'order_can_be_picked',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        actionRequired:  {
            title: 'action_required',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        replenish:  {
            title: 'replenish',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        shipped:  {
            title: 'shipped',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        snoozed:  {
            title: 'snoozed',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        bol:  {
            title: 'bol_lvb',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        ...(isFulfilment && {
            customerOrders:  {
                title: 'customer_orders',
                icon: <WarehouseIcon/>,
                isSingleDataGrid: true,
            }
        })
    };
};
