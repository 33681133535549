import { Box, InputBase } from "@mui/material";
import Shbutton from "../buttons/Shbutton";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { useState, useEffect } from "react";
import { showToastMessage } from "../../../utils/toasts";
import { useTranslation } from "react-i18next";

const NumberInput = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const [value,setValue] = useState(props.value ?? null);
    const changeBy = props.changeBy ?? 1;
    const btnSize = props.btnSize ?? 32;
    const maxWidth = props.maxWidth ?? 1;

    useEffect(() => {
        setValue(props.value);
    },[props.value]);

    const handleChange = (num) => {
        const parsedNum = parseFloat(num) || 0; // Convert to number, default to 0 if NaN
        if(props.onlyPositive && parsedNum <= 0){
            showToastMessage("warning", t("quantity_must_be_greater_than_0"));
            return;
        }
        setValue(parsedNum);
        if(parsedNum !== null){
            if(typeof props.change !== "undefined") {
                props.change(parsedNum);
            }
        }
    }

    
    return (
        <Box display="flex"
            borderRadius="20px"
            backgroundColor={`${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`}
            sx={{
                paddingX:0.5,
                alignItems:"center",
                width:1,
                maxWidth:maxWidth,
            }}
        >

            <Shbutton 
                className={"s"+btnSize}
                tabindex={-1}
                // size={btnSize} 
                icon 
                variant="contained" 
                color="primaryGrey" 
                type="button"
                onClick={() => {
                    if(props.min) {
                        if((value-changeBy) >= props.min) {
                            handleChange(parseInt(value)-changeBy);
                        }
                    } else {
                        handleChange(parseInt(value)-changeBy);
                    }
                }}
            >
                <RemoveOutlinedIcon fontSize="small" />
            </Shbutton>
            <InputBase 
                sx={{
                    mx: 2, 
                    flex:1,
                    width:1, 
                    color:colors.txt["primary"],
                    height:40,
                    "& input" : {
                        textAlign:"center"
                    }
                }} 
                inputProps={{
                  min: props.min ?? null, // Set the minimum value
                }}
                value={value}
                type="number"
                autoFocus={props.autoFocus ?? false}
                // onChange={(e) => setValue(e.target.value)}
                onChange={(e) => handleChange(e.target.value)}
                />
            <Shbutton
                tabindex={-1}
                className={"s"+btnSize}
                // size={btnSize}
                icon 
                variant="contained" 
                color="primaryGrey" 
                type="button"
                // onClick={(e) => setValue((value) => value+changeBy)}
                onClick={() => {
                    handleChange(parseInt(value)+changeBy)
                }}
            >
                <AddOutlinedIcon fontSize="small" />
            </Shbutton>
        </Box>
    )
}

export default NumberInput;