import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import TopRight from "../../../theme/positionals/TopRight";
import ShDoubleTextSkeleton from "../../../theme/skeleton/ShDoubleTextSkeleton";

const UserCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // const solved = props.skeleton ? false : props.row.solved ?? false;
    // const solvedText = solved ? "Solved" : "Not solved";
    // const solvedColor = solved ? "green" : "red";

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} width={props.skeleton ? "50%" : null} flexDirection={"column"}>
                        {props.skeleton ?
                            <ShDoubleTextSkeleton/>
                            :
                            <>
                                <Text bold>{props.firstname} {} {props.insertion} {props.lastname}</Text>
                                <Text light>{props.email}</Text>
                            </>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel variant={theme.palette.mode === "dark" ? "blue" : "primary"}  position="child-tr">
                            {props.skeleton ? null : props.employee_code}
                        </CardLabel>
                    </TopRight>

                </Box>
            </Box>
        </BaseCard>
    )
}

export default UserCard;