import { Box, useTheme } from "@mui/material";
import BaseCard from "../../../theme/cards/BaseCard";
import Text from "../../../theme/text/Text";
import { tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";

export const WarehouseInvoiceCategoryCard = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t,i18n } = useTranslation();

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                    sx={{

                    }}
                >
                    {/* Top row */}
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            gap:3,
                        }}
                    >
                        <Box sx={{ flexGrow: 1, flexShrink: 1, overflow:"hidden" }}>
                            <Box>
                                <Box>
                                    <Text bold>{props.name}</Text>&nbsp;
                                </Box>
                            </Box>
                            {/* Rleation name */}
                            <Box
                                sx={{
                                    
                                }}
                            >
                                <Text>{props.relations?.length || 0}</Text>&nbsp;
                                <Text light>{t("relations")}</Text>
                            </Box>
                        </Box>
                        {/* <ShLabel borderless palette={"normal"} fitted size={32}>{formatPrice(i18n,props.totalPrice)}</ShLabel> */}
                    </Box>


                </Box>
            </Box>
        </BaseCard>
    )
}
