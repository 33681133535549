import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";

import JokerCard from "../../../../global/cards/orders/JokerCard";
import { useEffect, useState } from "react";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../theme/skeleton/ShDoubleTextSkeleton";
import { useTranslation } from "react-i18next";

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide: true,
    

};

const Jokers = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    

    const columns = [
        {field: "creationDate", headerName: t("date_created"),flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShDoubleTextSkeleton/>
            }
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                    }}
                >
                    <Text semibold>{params.row.creationDate}</Text>
                    <Text light>{params.row.creationTime}</Text>
                </Box>
            )
        }},
        {field: "by", headerName: t("by") ,flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text semibold>{params.value}</Text>
                </Box>
            )
        }},
        {field: "type", headerName: t("type") ,flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text semibold>{params.value}</Text>
                </Box>
            )
        }},
        {field: "description", headerName: t("description") ,flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box
                
                    sx={{
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text semibold>{params.value}</Text>
                </Box>
            )
        }},
        {field: "solved", headerName: t("solved") ,flex:0.5, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            if(params.value) {
                return (
                    <Box
                    
                        sx={{
                            display:"flex",
                            alignItems:"center"
                        }}
                    >
                        <Text semibold variant="green">Yes</Text>
                    </Box>
                )
            } else {
                return (
                    <Box
                    
                        sx={{
                            display:"flex",
                            alignItems:"center"
                        }}
                    >
                        <Text semibold variant="red">No</Text>
                    </Box>
                )
            }
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <JokerCard skeleton={params.row.skeleton ?? false} {...params}/>
                )
            }
        }
    ];

    const mockDataTeam = [
        {
            id: randomIntFromInterval(0, 99999999),
            creationDate: "21-05-2023",
            creationTime: "21:00",
            by: "Bryan Wessels",
            type: "NoShipperWithDrager",
            description : "Kan shipper niet bepalen",
            solved: true,
            phoneView: {}
        },
        {
            id: randomIntFromInterval(0, 99999999),
            creationDate: "21-05-2023",
            creationTime: "21:00",
            by: "Bryan Wessels",
            type: "NoShipperWithDrager",
            description : "Kan shipper niet bepalen",
            solved: false,
            phoneView: {}
        },
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <ShDataGrid  
                title="Jokers" 
                gridOptions={gridOptions} 
                isSidepanelGrid 
                apiRef={props.apiRef} 
                columns={columns} 
                rows={mockDataTeam}>
            </ShDataGrid> */}
        </Box>
    );
}

export default Jokers;