import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import { useAddNewRelationMutation } from "../../../../newapi/global/relation/relationSlice";
import ContactDetails from "./views/ContactDetails";
import LocationDetails from "./views/LocationDetails";
import OtherDetails from "./views/OtherDetails";
import AccountDetails from "./views/AccountDetails";

const MpAddRelation = (props) => {
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useAddNewRelationMutation} isModal title={t("add_relation")}>
                <ContactDetails/>
                <LocationDetails/>
                <AccountDetails/>
                <OtherDetails/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddRelation;