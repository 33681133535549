
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';


export const settingsWarehouseShippers = {
    all:  {
        title: "shippers",
        subTitle: "All shipper registrations",
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
    profiles: {
        title: "profiles",
        subTitle: "All shipper profiles",
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    }
    
};
