
import { muiDataGridStarted } from "../../apiutils";
import { apiSlice } from "../../createApiSlice";

const BASE = "/streams";
const buildUrl = (endpoint) => BASE + endpoint;
const ApiName = "Streams";

const streamsApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStreams: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}` }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getShippers', `${ApiName}`)
        }),
        getStreamsSelect: builder.query({
            query: () => ({
                url: buildUrl(`/`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}` }],
        }),
        getStream: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        addNewStream: builder.mutation({
            query: streamInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: streamInfo,
            }),
            invalidatesTags: [`${ApiName}`],
        }),
        editStream: builder.mutation({
            query: streamInfo => ({
                url: buildUrl(`/${streamInfo.id}`),
                method: 'PUT',
                body: streamInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
        getStreamRules: builder.query({
            query: id => ({
                url: buildUrl(`/${id}/rules`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Rules`, id: arg }]
        }),
        getPickingprocesstypes: builder.query({
            query: () => ({
                url: buildUrl(`/pickingprocesstypes`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Rules`, id: arg }]
        }),
        editStreamRules: builder.mutation({
            query: data => {
                return {
                    url: buildUrl(`/${data.streamId}/rules`),
                    method: 'PUT',
                    body: data.streamRules,
                };
            },
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.streamId }, {type: `${ApiName}`}, { type: `${ApiName}Rules`, id: arg.streamId }],
        }),
        updateIndex: builder.mutation({
            query: updateObject => {
                return {
                    url: buildUrl(`/${updateObject.id}/index`),
                    method: 'PUT',
                    body: updateObject,
                };
            },
            invalidatesTags: (result, error, arg) => [{type: `${ApiName}`}],
        }),
        getConditionValues: builder.query({
            query: (type) => ({
                url: buildUrl(`/conditions/values${type ? `?type=${type}` : ""}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Values` }]
        }),
    })
});

export const {
    useGetStreamsQuery,
    useGetStreamsSelectQuery,
    useGetStreamQuery,
    useAddNewStreamMutation,
    useEditStreamMutation,
    useGetStreamRulesQuery,
    useGetPickingprocesstypesQuery,
    useEditStreamRulesMutation,
    useGetConditionValuesQuery,
    useUpdateIndexMutation
  } = streamsApiSlice;