// TabContent1.js
import { useTheme } from '@emotion/react';
import { Box, ButtonBase, TextField, Typography } from '@mui/material';
import cx from "classnames";
import { t } from "i18next";
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { useGetReturnReasonsSelectQuery } from '../../../../../newapi/inbound/returnsSlice';
import { useGetOrderLinesQuery } from '../../../../../newapi/order/ordersSlice';
import { tokens } from '../../../../../theme';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { StyledDropdown } from '../../../../theme/dropdowns/StyledDropdown';
import ShSwitch from "../../../../theme/inputs/ShSwitch";
import Subheading from '../../../../theme/text/Subheading';
import Text from '../../../../theme/text/Text';
import { errorMapWithArrayProcessor } from '../../../../../utils/helpers';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';



const OrderDetailsContent = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);

    const [details, setDetails] = useState(null);

    const handleActiveChange = (selected) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            active: !prevDetails.active ?? false,
        }));
    }

    const handleReasonChange = (selected) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            retourreasonId: props.reasonOptions[parseInt(selected)].id
        }));
    }

    const handleAmountChange = (selected) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            qty: props.amountOptions[parseInt(selected)].name
        }));
    }

    const handleInputChange = (e,val) => {
        setDetails(prevDetails => ({
            ...prevDetails,
            remark: val
        }));
    }

    useEffect(() => {
        setDetails(props.data);
    }, [props.data]);

    useEffect(() => {
        
        if(details)
            props.onReturnChange(details);
    }, [details]);

    return (
        <Box
            className={cx('order-inner-content',{
                'active': props.active,
                'cantbereturned': props.cantbereturned
            })}
            TouchRippleProps={{
                style: {
                    color: "#31415f",
                }
            }}
            sx={{
                padding:1.5,
                borderRadius:4,
                position:"relative",
                
                opacity:1,
                display:"flex",
                flexDirection:"column",
                alignItems:"flex-start",
                background:colors.grey[100],
                "&.active": {
                    opacity:1,
                    background:colors.grey[50],
                },
                "&.cantbereturned" : {
                    opacity:0.5,
                }
            }}
        >
            <ButtonBase
                onClick={handleActiveChange}
                component="div"
                TouchRippleProps={{
                    style: {
                        color: "#31415f",
                    }
                }}
                sx={{
                    pr:8,
                }}
            >
                <Text fontWeight={props.active ? 500 : 400} bold allowOverflow>
                    {props.title}
                </Text>
            </ButtonBase>

            {/* Dropdowns */}
            {props.active &&
            <Box
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:2,
                    width:1,
                    mt:3,
                }}
            >
                <Box>
                    <Text>Aantal</Text>
                    <StyledDropdown
                        closeOnSelection
                        options={props.amountOptions}
                        selected={props.amountOptions.findIndex(option => option.name === props.data.qty) ?? -1}
                        onChange={(selected) => handleAmountChange(selected)}
                        noSelection={t("choose_amount")}
                        multiple={false}
                        displayName={"name"}
                        sx={{
                            base: {
                                background:props.fieldError !== "undefined" && typeof props.fieldError?.qty !== "undefined" ? colors.red[100] : "#ecf0f5",
                                paddingRight:0.5,
                                mt:1,
                            },
                            text: {
                                fontSize:14,
                                // color:"#31415f"
                                color:props.fieldError !== "undefined" && typeof props.fieldError?.qty !== "undefined" ? colors.red[400] : "#31415f",

                            },
                            button: {
                                background:props.fieldError !== "undefined" && typeof props.fieldError?.qty !== "undefined" ? colors.red[100] : "#ecf0f5",
                                color:props.fieldError !== "undefined" && typeof props.fieldError?.qty !== "undefined" ? colors.red[400] : "#31415f",
                            },
                            touchProps: {
                                style: { 
                                    color: "#31415f"
                                } 
                            }
                        }}
                    />
                </Box>

                <Box>
                    <Text>{t("reason")}</Text>
                    <StyledDropdown
                        key={props.data.retourreasonId}
                        closeOnSelection
                        onChange={(selected) => handleReasonChange(selected)}
                        options={props.reasonOptions}
                        selected={props.reasonOptions.findIndex(option => option.id === props.data.retourreasonId) ?? -1}
                        displayName={"name"}
                        noSelection={t("select_a_reason")}
                        multiple={false}
                        sx={{
                            base: {
                                background:props.fieldError !== "undefined" && typeof props.fieldError?.retourreasonId !== "undefined" ? colors.red[100] : "#ecf0f5",
                                paddingRight:0.5,
                                mt:1,
                            },
                            text: {
                                fontSize:14,
                                // color:"#31415f"
                                color:props.fieldError !== "undefined" && typeof props.fieldError?.retourreasonId !== "undefined" ? colors.red[400] : "#31415f",

                            },
                            button: {
                                background:props.fieldError !== "undefined" && typeof props.fieldError?.retourreasonId !== "undefined" ? colors.red[100] : "#ecf0f5",
                                color:props.fieldError !== "undefined" && typeof props.fieldError?.retourreasonId !== "undefined" ? colors.red[400] : "#31415f",
                            },
                            touchProps: {
                                style: { 
                                    color: "#31415f"
                                } 
                            }
                        }}
                    />
                </Box>

                <Box>
                    <Text>{t("remark")}</Text>
                    
                    <TextField

                        id="outlined-multiline-static"
                        multiline
                        value={props.data?.remark}
                        onChange={(event) => handleInputChange(event, event.target.value)}
                        rows={3}
                        variant="outlined"
                        sx={{
                            background:"#ecf0f5",
                            borderRadius:3,
                            width:1,
                            border:"none",
                            outlineColor:"transparent !important",
                            color: "#31415f",
                            padding:0,
                            " .MuiOutlinedInput-notchedOutline": {
                                border:"none !important"
                            },
                            " .MuiInputBase-root": {
                                paddingX:2,
                                paddingY:1.5
                            }
                        }}

                    ></TextField>
                </Box>
            </Box>
            }



            {/* Switch */}
            <Box
                sx={{
                    position:'absolute',
                    right:0,
                    top:0,
                }}
            >
                <ShSwitch onChange={handleActiveChange} checked={props.active}/>
            </Box>
        </Box>
    )
}
const OrderCantBeReturnedDetailsContent = (props) => {
 
    return (
        <>
        <Box
                className={cx('order-inner-content',{
                    'active': props.active,
                    'cantbereturned': props.cantbereturned
                })}
                sx={{
                    padding:1.5,
                    borderRadius:4,
                    position:"relative",
                    pr:7,
                    
                    opacity:1,
                    "&.cantbereturned" : {
                        opacity:0.55,
                    }
                }}
            >
                <Subheading allowOverflow fontWeight={400}>
                    {props.title}
                </Subheading>

            </Box>

            <Box
                sx={{
                    pl:1.5,
                    pb:1.5,
                    mt:"-8px",
                }}
            >
                <Typography
                    variant="span"
                    sx={{
                        color:"rgba(49,65,95,1)",
                        fontSize:16,
                        fontWeight:500,
                    }}
                >
                {t("item_cant_be_returned")}
                </Typography>
            </Box>
        </>
    )
}

const OrderDetails = (props) => {
    
    const amountOptions = [{id: -1, name: "Kies een aantal"}, ...Array.from({length: props.qty }, (_, index) => ({id: index +1 , name: index +1}))];
    const reasonOptions = [{id: -1, name: "Kies een reden"}, ...props.reasons?.map(reason => ({id: reason.id, name: reason.description}))];

    return (
        
        <Box
            className={cx({
                'active': props.data.active
            })}
            sx={{
            }}
        >
            {typeof props.cantbereturned === "undefined" || !props.cantbereturned ?
                <OrderDetailsContent
                    fieldError={props.fieldError}
                    active={props.data.active}
                    // onClick={onChange}
                    cantbereturned={props.cantbereturned}
                    title={props.title}
                    amountOptions={amountOptions}
                    reasonOptions={reasonOptions}
                    id={props.data?.id}
                    data={props.data}
                    onReturnChange={props.onReturnChange}
                />
            :
                <OrderCantBeReturnedDetailsContent
                    active={props.data.active}
                    cantbereturned={props.cantbereturned}
                    title={props.title}
                />
                // null
            }
        </Box>
    )
}

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.localData };
    const dispatch = useDispatch();
    const {data: reasons,isReturnReasonsSusccess} = useGetReturnReasonsSelectQuery({relationId:data.relationId});
    const {data: lines,isSuccess,isError} = useGetOrderLinesQuery(
        {
            args: {
                id: props.localData?.orderId,
                page:1,
                size:200,
            }
        },
        {
            skip: !props.localData?.orderId
        }
    );

    const [returnRequest, setReturnRequest] = useState({
        returnLines: []
    })
    const [validationErrors, setValidationErrors] = useState({});
    
   
    useEffect(() => {
        if (isSuccess && lines && lines.data ) {
            // console.log([...props.localData?.returnLines]);
            const newItems = props.localData?.returnLines?.length
            ? [...props.localData.returnLines] // Use the returnLines from props.localData if they exist
            : lines.data.map(orderline => ({
                id:orderline.id,
                productId: orderline.product.id,
                active: false,
                qty: -1,
                retourreasonId: -1,
                remark: ""
            }));

            
            setReturnRequest(prevState => ({
                ...prevState,
                returnLines: newItems
            }));
        }
    }, [lines, isSuccess, isError]);    
    
    const handleErrors = (errors) => {
        const { fieldErrors, generalErrors } = errorMapWithArrayProcessor(errors, /returnLines\[(\d+)\]\.(qty|retourreasonId)/);
        setValidationErrors({ fieldErrors, generalErrors });
      };

    const onReturnItemChange = (item) => {
        if(!item) return;
        setReturnRequest(prevState => {
            const existingItemIndex = prevState.returnLines.findIndex(i => i.id === item.id);
            const updatedItems = [...prevState.returnLines];
            updatedItems[existingItemIndex] = item;
            return { returnLines: updatedItems };
        });
    }

    useEffect(() => {
        dispatch(setTabErrors({ }));
        props.setLocalData({
            ...props.localData,
            returnLines: returnRequest.returnLines.filter(item => item.active === true) // Filter and spread the active items
        })
    }, [returnRequest])

    useEffect(() => {
        handleErrors(props.errors);
    }, [props.errors])

    return (
        <Box
            sx={{
                background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                borderTopLeftRadius:16,
                borderTopRightRadius:16,
                display:"flex",
                flexDirection:"column",
                gap:1,
            }}
        >
            {validationErrors.generalErrors && Object.keys(validationErrors.generalErrors).map((errorKey) => (
                errorKey === "returnLines" &&
                <Box 
                    sx={{
                        height:32,
                        width:1,
                        textAlign:"center",
                        lineHeight:"32px",
                        borderRadius:4,
                        border:`1px solid ${colors.red[400]}`,
                        background:colors.red[100],
                        mb:2,
                    }}
                >
                    <Text variant="red" key={errorKey}>{t(validationErrors.generalErrors[errorKey])}</Text>
                </Box>
            ))}
            {returnRequest?.returnLines.map((item, index) => (
                <OrderDetails 
                    fieldError={validationErrors.fieldErrors[index]}
                    key={item.id}
                    id={item.id} 
                    title={lines.data[index]?.product?.description} 
                    qty={lines.data[index]?.amount} 
                    reasons={reasons} 
                    cantbereturned={lines.data[index].product.returnable}
                    data={item}
                    onReturnChange={onReturnItemChange}
                />
            ))}

            
        </Box>
    );
};


const ReturnLines = (props) => {
    const { t } = useTranslation();

    const conditionsSchema = yup.array().of(
        yup.object({
          qty: yup.number()
          .moreThan(0, 'amount_must_be_greater_than_0')
            .required("amount_required"),
          retourreasonId: yup.number()
            .notOneOf([-1], 'reason_id_cannot_be_minus_one')
            .required("reason_id_required")
        })
      ).min(1, 'conditions_not_valid');
      
    const schema = yup.object({
        returnLines: conditionsSchema
      });

    const data = useSelector((state) => state.progressiontabs.data);
    const [localData, setLocalData] = useState(data);

    useEffect(() => {
        setLocalData(data);
    },[data]);

    
    
    return (
        <ProgressionTab 
            tabId="tabReturnLines" 
            subtitle={t("return_lines")} 
            // nextTab={"tabShipments"}
            step={2}
            maxSteps={2}
            schema={schema}
            updateDataWithValidation={localData} //updates pre-tab-switch
        >
            <Content localData={localData} setLocalData={setLocalData}/>
        </ProgressionTab>
    )
}

export default ReturnLines;
