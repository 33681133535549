
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const LOGS_BASE = "/logs";
const buildUrl = (endpoint) => LOGS_BASE + endpoint;


const logsApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getLogs: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `Logs${arg.args.body.type}`, id: arg.args.body.externalId }],
            onQueryStarted: (args, { dispatch, getState, queryFulfilled }) => {
                const queryName = `get${args.args.body.type}Logs`;
                const typeName = `${args.args.body.type}Logs`;
                return muiDataGridStarted(queryName, typeName, args, {
                  dispatch,
                  getState,
                  queryFulfilled,
                });
            },
        }),

    })
});

export const {
    useGetLogsQuery,
  } = logsApiSlice;
