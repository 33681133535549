import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const WEBSHOP_BASE = "/webshop";
const buildUrl = (endpoint) => WEBSHOP_BASE + endpoint;
const ApiName = "WebshopCredentials";

const webshopSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWebshopCredentials: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/credentials?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: ApiName }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getWebshopCredentials', ApiName),
        }),
        getWebshopCredentialsSelect: builder.query({
            query: (relationId) => ({
                url: buildUrl(`/credentials/select`),
                method: 'POST',
                body: {
                     relationId
                },
            }),
            providesTags: () => [{ type: ApiName }],
        }),
        getWebshopCredentialsSingle: builder.query({
            query: id => ({
                url: buildUrl(`/credentials/${id}`),
                method: 'GET',
                meta: { customContentType: true }, 
            }),
            providesTags: (result, error, id) => [{ type: 'WebshopCredentialsSingle', id: id }],
        }),
        editWebshopCredentials: builder.mutation({
            query: credentialsInfo => ({
                url: buildUrl(`/credentials/${credentialsInfo.id}`),
                method: 'PUT',
                body: credentialsInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `WebshopCredentialsSingle`, id: arg.id }, {type: ApiName}],
        }),
        deleteWebshopCredentials: builder.mutation({
            query: id => ({
                url: buildUrl(`/credentials/${id}`),
                method: 'DELETE'
            }),
            invalidatesTags: (result, error, id) => [{ type: `WebshopCredentialsSingle`, id: id }, {type: ApiName}],
        }),
        createWebshopCredentials: builder.mutation({
            query: credentialsInfo => ({
                url: buildUrl(`/credentials/new`),
                method: 'POST',
                body: credentialsInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: `${ApiName}`}],
        }),
        syncWebshopProducts: builder.mutation({
            query: args => ({
                url: buildUrl(`/syncproducts/${args.id}`),
                method: 'POST',
                body: args,
            }),
            invalidatesTags: (result, error, arg) => [{type: `Products`}],
        }),
        
    })
});

export const { 
    useGetWebshopCredentialsQuery,
    useGetWebshopCredentialsSelectQuery,
    useGetWebshopCredentialsSingleQuery,
    useEditWebshopCredentialsMutation,
    useCreateWebshopCredentialsMutation,
    useSyncWebshopProductsMutation,
    useDeleteWebshopCredentialsMutation
} = webshopSlice;
