export const page_keys = {
    EDITREGISTERRETURN: "EDITREGISTERRETURN",
    DEFAULTDATA: "DEFAULTDATA",
};

export const portal_keys = {
    SECTION: "section",
    HEADER_TEXT: "header_text",
    INPUT_CONTAINER: "input_container",
    INPUT_CONTAINER_TEXT: "input_container_text",
    INPUT_CONTAINER_INPUT: "input_container_INPUT",
    BUTTON: "button",
    TEXT: "text",
};

export const extraClasses = {
    NORMAL: "normal",
    AUTOFILL:"autofill",
    HOVER:"hover",
    ERROR:"error",
}

export const editableElements =  {
    SPIRAL: "spiral",
    BACKGROUNDCOLOR: "backgroundColor",
    COLOR: "color",
    BORDER: "border",
    PADDINGX:"paddingX",
    BACKGROUNDCLIP:"backgroundClip",
    WEBKITTEXTFILLCOLOR:"webkit-text-fill-color",
    BOXSHADOW:"boxShadow",

}

export const editableSettings = {
    [portal_keys.SECTION]: {
        editable: [
            editableElements.BACKGROUNDCOLOR,
            editableElements.SPIRAL,
        ],
    },
    [portal_keys.HEADER_TEXT]: {
        editable: [
            editableElements.COLOR,
        ],
    },
    [portal_keys.INPUT_CONTAINER]: {
        editable: [
            editableElements.BACKGROUNDCOLOR,
            editableElements.BORDER,
            editableElements.PADDINGX,
        ],
        extraClasses: {
            [extraClasses.AUTOFILL] : [
                editableElements.BACKGROUNDCLIP,
                editableElements.WEBKITTEXTFILLCOLOR,
                editableElements.BOXSHADOW,
            ]
        },
    },
    [portal_keys.INPUT_CONTAINER_TEXT]: {
        editable: [
            editableElements.COLOR,
            editableElements.BACKGROUNDCOLOR,
            editableElements.BORDER,
            editableElements.PADDINGX,
        ],
        extraClasses: {
            [extraClasses.ERROR] : [
                editableElements.COLOR,
                editableElements.BACKGROUNDCOLOR,
                editableElements.BORDER,
            ]
        }, // Only the error class
    },
    [portal_keys.INPUT_CONTAINER_INPUT]: {
        editable: [
            editableElements.COLOR,
            editableElements.BACKGROUNDCOLOR,
            editableElements.BORDER,
        ],
        extraClasses: {
            [extraClasses.AUTOFILL] : [
                editableElements.BACKGROUNDCLIP,
                editableElements.WEBKITTEXTFILLCOLOR,
                editableElements.BOXSHADOW,
            ],
            
            [extraClasses.ERROR] : [
                editableElements.COLOR,
                editableElements.BACKGROUNDCOLOR,
                // editableElements.BORDER,
            ]
        }, // Reference extra class keys
    },
    [portal_keys.BUTTON]: {
        editable: [
            editableElements.COLOR,
            editableElements.BACKGROUNDCOLOR,
            editableElements.BORDER,
        ],
        extraClasses: {
            [extraClasses.HOVER] : [
                editableElements.COLOR,
                editableElements.BACKGROUNDCOLOR,
                editableElements.BORDER,
            ]
        },  // Button has hover state
    },
    [portal_keys.TEXT]: {
        editable: [
            editableElements.COLOR,
        ],
    },
};

export const jsonSettings = Object.keys(editableSettings).reduce((settings, key) => {
    const config = editableSettings[key];
    const defaultSettings = {};

    // Loop through each editable property and check if it's set to true
    Object.keys(config).forEach((prop) => {
        if (config[prop] === true) {
            // Set default value as null or any placeholder for editable property
            defaultSettings[prop] = null; // You could replace null with an actual default if needed
        }
    });

    // Handle extraClasses dynamically if available
    if (config.extraClasses) {
        defaultSettings.extraClasses = config.extraClasses;
    }

    // Add to main settings object
    settings[key] = defaultSettings;
    return settings;
}, {});