import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";

import { useState } from "react";
import { showToastMessage, showToastMessagePostRequest } from "../../../../utils/toasts";
import BasicDetails from "./views/BasicDetails";
import Configuration from "./views/Configuration";
import { useAddNewShipperProfileMutation } from "../../../../newapi/shipper/shipperprofileSlice";
const MpAddBolReplenishment = (props) => {
    const {t} = useTranslation();
    const [saving, setSaving] = useState(false);


    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useAddNewShipperProfileMutation} isModal title={t("new_shipper_profile")}>
                <BasicDetails/>
                <Configuration/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddBolReplenishment;