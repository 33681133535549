import { useTheme, Box, Fade } from "@mui/material";
import { tokens } from"../../theme";
import { useRef, useState } from "react";
import { useHover } from "./HoverProvider";
import EditIcon from '@mui/icons-material/Edit';
import Text from "../theme/text/Text";
import { useTranslation } from "react-i18next";

export const Editable = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const { addHover, removeHover, getTopHover, activeElement, setActiveElement } = useHover();
    const elementRef = useRef(null);
    const [isClicked, setIsClicked] = useState(false);

    const handleMouseEnter = () => {
        addHover(elementRef.current);
    };

    const handleMouseLeave = () => {
        removeHover(elementRef.current);
    };
    // const handleClick = () => {
    //     setIsClicked(!isClicked);
    // };
    // const handleClick = (event) => {
    //     if (getTopHover() === elementRef.current) {
    //         setIsClicked(!isClicked);
    //         event.stopPropagation(); // Stop event propagation
    //     }
    // };    
    const handleClick = (event) => {
        setActiveElement(elementRef.current);
        event.stopPropagation(); // Stop event propagation

        if(props.onClick) {
            props.onClick();
        }
    };

    const isHovered = getTopHover() === elementRef.current;
    const isActive = activeElement === elementRef.current;


    return (
        <Box
            ref={elementRef}
            sx={{
                width:props.width ?? null,
                position: "relative",
                "&::before": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    top: 0,
                    height: '100%',
                    width: '100%',
                    borderRadius: props.radius ?? null, // Apply the captured borderRadius
                    background: isHovered ? "rgba(255, 255, 255, 0.2)" : "transparent", // More prominent background
                    border: isHovered || isActive ? "2px solid rgba(255, 255, 255, 0.8)" : "2px solid transparent", // More prominent border
                    zIndex: 1, // Ensure overlay is above the background
                    pointerEvents: "none", // Allow interactions to pass through
                    transition: "background 200ms cubic-bezier(0.4, 0, 0.2, 1), border 200ms cubic-bezier(0.4, 0, 0.2, 1)",
                },
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
        >
            {props.children}
            <Fade
                in={isActive}
                timeout={200}
                easing={{
                    enter: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    exit: 'cubic-bezier(0.4, 0, 0.2, 1)',
                }}
            >
                <Box
                    sx={{
                        top:"calc(100% + 12px)",
                        left:"50%",
                        transform:"translateX(-50%)",
                        // marginTop: 2,
                        height:40,
                        display:"flex",
                        pl:0.5,
                        alignItems:"center",
                        background: theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                        position:"absolute",
                        zIndex:18,
                        borderRadius: 5,
                        gap:1.5,
                        pr:2,
                        "& .popover-triangle" : {
                            position:"absolute",
                            left:"50%",
                            top:-11,
                            width:32,
                            height:12,
                            zIndex:200,
                            transform:"translateX(-50%)",
                            "& *" : {
                                fill: theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                            }
                        },
                    }}
                >
                    {/* <Triangle 
                        className="popover-triangle"
                    ></Triangle> */}
                    <Box
                        sx={{
                            height:32,
                            width:32,
                            borderRadius:4,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:'center',
                            background:theme.palette.mode === "dark" ? colors.pastel.light.blue[100] : colors.primary[100],
                            color: theme.palette.mode === "dark" ? colors.pastel.light.blue[600] : colors.primary[400]
                        }}
                    >
                        <EditIcon fontSize="small"/>
                    </Box>
                    <Text semibold>{t("editing")}</Text>
                </Box>
            </Fade>
        </Box>
    );
}