import { Box, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Subheading from "../../../../components/theme/text/Subheading";
import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import OverflowRow from "../../../../components/theme/OverflowRow";

// import { getOrderStatusCounts } from "../../../../api/orders/orders";
import { useTranslation } from 'react-i18next';
import WarehouseLocations from "./Views/WarehouseLocations";


import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import cx from 'classnames';
import { endOfMonth, format, startOfMonth } from "date-fns";
import AreaChart from "../../../../components/theme/charts/AreaChart";
import ShDateRangePicker from "../../../../components/theme/ShDateRangePicker";
import ShDoubleTextSkeleton from "../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import Heading from "../../../../components/theme/text/Heading";
import { useGetDashboardWarehouseLocationChartQuery, useGetDashboardWarehouseLocationTotalsQuery } from "../../../../newapi/warehouse/warehouselocationSlice";
import { warehouseStockLocationsConfig } from "./warehouseStockLocationsConfig";

const locationChartStates = {
    pickInUse: "pickInUse",
    pickGrade: "pickGrade",
    bulkInUse: "bulkInUse",
    bulkGrade: "bulkGrade"
}

const LocationDashboardCard = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    const calculateValueComp = () => {
        if(props.value && props.type.includes("InUse")) {
            const valueSplitted = props.value.split("/");

            return (
                <Box sx={{
                    display:"flex",
                }}>
                    <Heading 
                        className={cx('', {
                            'active':props.active
                        })} 
                        sx={{
                            pt:0.5,
                                color:`${colors.pastel.light[props.color][600]} !important`,
                            "&.active":{
                                color:`${colors.pastel.light[props.color][600]} !important`
                            }
                        }}
                    >
                        {valueSplitted[0].trim()}
                    </Heading>
                    <Heading 
                        className={cx('', {
                            'active':props.active
                        })} 
                        sx={{
                            pt:0.5,
                            color:colors.txt["secondary"],
                                color:`${colors.pastel.light[props.color][600]} !important`,
                            "&.active":{
                                fontWeight:"normal",
                                color:`${colors.pastel.light[props.color][600]} !important`
                            }
                        }}
                    >
                        &nbsp;/ {valueSplitted[1].trim()}
                    </Heading>
                </Box>
            )
        }
        return (

            <Heading 
                className={cx('', {
                    'active':props.active
                })} 
                sx={{
                    pt:0.5,
                                color:`${colors.pastel.light[props.color][600]} !important`,
                    "&.active":{
                        color:`${colors.pastel.light[props.color][600]} !important`
                    }
                }}
            >
                {props.value}
            </Heading>
        )
    };

    return (
        <Box
            component={"button"}
            onClick={() => props.changeChartType(props.locationChartType)}
            className={cx('',{
                'active': props.active,
            })}
            sx={{
                flex:1,
                cursor:"pointer",
                // background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                background: theme.palette.mode === "dark" ?  colors.pastel.light[props.color][100] :  colors.pastel.st[props.color][100],
                boxShadow: `0px 2px 4px ${colors.pastel.st[props.color][300]}`,
                border:"none",

                borderRadius:4,
                padding:3,
                display:"flex",
                alignItems:"center",

                ".logo-box" : {
                    width:40,
                    height:40,
                    borderRadius:2,
                    background: colors.pastel.st[props.color][200],
                    color:colors.txt["primary"],
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center",
                    "& .logo" : {
                        fill: colors.pastel.st[props.color][600],
                    }
                },

                // "&.active": {
                //     background: colors.pastel.light[props.color][100],
                //     ".logo-box" : {
                //         background: colors.pastel.light[props.color][200],
                //         "& .logo, & .logo path" : {
                //             fill: colors.pastel.light[props.color][600],
                //         }
                //     },
                // },
                "@media screen and (max-width: 47.9375em)" : {
                    p:2,
                    // flexDirection:"row",
                    // justifyContent:"space-between",
                    ".logo-box" : {
                        width:32,
                        height:32,
                    }

                }

            }}
        >
            <Box
                sx={{
                    // "@media screen and (max-width: 47.9375em)" : {
                    //     order:2
                    // }
                    
                }}
            >
                <Box className="logo-box">
                    {props.logo}
                </Box>
            </Box>
            {props.isLoading || !props.data ?
                <ShDoubleTextSkeleton/> 
            : 
                <Box 
                    sx={{
                        pl:2,
                        display:"flex",
                        flexDirection:"column",
                        "@media screen and (max-width: 47.9375em)" : {
                            // pl:1,
                        }
                    }}
                >
                    <Text light noBreak>{t(props.type)}</Text>
                    {calculateValueComp()}
                </Box>
            }
        </Box>
    )
}

const TopRow = () => { 
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chartType, setChartType] = useState(locationChartStates?.revenue);

    const changeChartType = (type) => {
        setChartType(type);
    }

    const currentDate = new Date(); // or any date you want to use

    const [totalsDates, setTotalsDates] =useState({
        startDate : format(startOfMonth(currentDate), 'yyyy-MM-dd'),
        endDate : format(endOfMonth(currentDate), 'yyyy-MM-dd'),
    });

    const [chartDates, setChartDates] =useState({
        startDate : format(startOfMonth(currentDate), 'yyyy-MM-dd'),
        endDate : format(endOfMonth(currentDate), 'yyyy-MM-dd'),
    });

    const [ chartDataData, setChartDataData] = useState([]);

    const { data : totalData, isLoading,isFetching } = useGetDashboardWarehouseLocationTotalsQuery();


    const {data: dashData, isLoading: chartIsLoading, isFetching: chartIsFetching, isError: isChartError, refetch: chartRefetch} = useGetDashboardWarehouseLocationChartQuery({
        type: chartType
    });

    useEffect(() => {
        if (!chartIsLoading && !chartIsFetching && dashData && dashData !== chartDataData) {
            setChartDataData(dashData.data); // Directly store the chart data
        }
    }, [dashData, chartIsLoading, chartIsFetching, chartDataData]);

    // Memoize chart data: directly use the data from the backend
    const chartData = useMemo(() => {
        if (!chartDataData) return []; // Return empty if no chart data is available
        return dashData?.data?.series; // Directly use the series returned by the backend
    }, [chartDataData, dashData]);

    return (
        <OverflowRow>
            <Box
                sx={{
                    // background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                    flex:2,
                    // boxShadow: '0px 1px 2px 1px rgba(49, 65, 95, 0.15)',
                    position:"relative", //Needed for absolutely positioned things
                    // width:1,
                    borderRadius:4,
                    display:"flex",
                    gap:3,
                    // "@media screen and (max-width: 47.9375em)" : {
                    //     width: "unset"
                    // }
                }}
            >

                
                        <LocationDashboardCard
                            logo={<TakeoutDiningOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.pickInUse}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            value={totalData?.pickInUse}
                            type={"pickInUse"}
                            displayType={"text"}
                            color={"brown"}

                        />

                        <LocationDashboardCard
                            logo={<PercentOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.pickGrade}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            value={totalData?.pickGrade}
                            type={"pickGrade"}
                            displayType={"text"}
                            color={"brown"}
                        />

                        <LocationDashboardCard
                            logo={<UnarchiveOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.bulkInUse}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={"bulkInUse"} 
                            value={totalData?.bulkInUse}
                            color={"lightblue"}
                            displayType={"text"}
                        />
                        
                        <LocationDashboardCard
                            logo={<PercentOutlinedIcon className="logo"/>}
                            changeChartType={changeChartType}
                            locationChartType={locationChartStates?.bulkGrade}
                            isLoading={isLoading || isFetching}
                            data={totalData}
                            type={"bulkGrade"}
                            value={totalData?.bulkGrade}
                            displayType={"text"}
                            color="lightblue"
                        />
                </Box>
           
            
        </OverflowRow>
    )
}

const StockLocation = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            <TopRow/>

            <TabView3
                tabConfig={warehouseStockLocationsConfig}
                sx={{
                    width:1,
                    background:"green",
                    minHeight:"calc(100% - 431px)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,

                    display:"flex",
                    flexDirection:"column",

                    "&:before" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        left:0,
                        top:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        top:"40px",
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        transition:"250ms"
                    },

                    "&:after" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        top:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        top:"40px",
                        right:0,
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        transform:"scaleX(-1)",
                        transition:"250ms"
                    },

                    "@media screen and (max-width: 47.9375em)" : {
                        "&:after, &:before" : {
                            top:"-0px"
                        }
                    }
                }}
                title={t("outbound")}
                
            >
                <>
                <WarehouseLocations config={warehouseStockLocationsConfig.warehouseLocations}/>

                </>
            </TabView3>
        </Box>
    );
};

export default StockLocation;