// Define a configuration object for different shipper types
export const shipperTypeConfigurations = {
    postnl: {
        fields: [
            { name: 'parcelType', type: 'dropdown', label: 'parcel_type', options: [
                { label: 'Parcel', value: 'parcel' },
                { label: 'Mailbox', value: 'mailbox' }
            ] },
            { name: 'productCode_NL_NL', type: 'dropdown', label: 'profile_productcode_nl_nl', 
                options: [
                    { label: '3085 - NL Standard', value: '3085' },
                    { label: '3090 - NL Standard + Delivery to neighbour + Return when not home', value: '3090' },
                    { label: '3385 - NL Standard + Deliver to stated address only', value: '3385' },
                    { label: '3390 - NL Standard + Deliver to stated address only + Return when not home', value: '3390' },
                    { label: '3189 - NL Signature on delivery', value: '3189' },
                    { label: '3089 - NL Signature on delivery + Deliver to stated address only', value: '3089' },
                    { label: '3096 - NL Signature on delivery + Deliver to stated address only + Return when not home', value: '3096' },
                    { label: '3389 - NL Signature on delivery + Return when not home', value: '3389' },
                    { label: '3087 - NL Extra cover', value: '3087' },
                    { label: '3094 - NL Extra cover + Return when not home', value: '3094' }
                ]
            },
            { name: 'productCode_NL_BE', type: 'dropdown', label: 'profile_productcode_nl_be', 
                options: [
                    { label: '4946 - Belgium Standard delivery', value: '4946' },
                    { label: '4912 - Belgium Parcel Signature on delivery', value: '4912' },
                    { label: '4914 - Belgium Parcel Extra cover', value: '4914' },
                    { label: '4941 - Belgium Parcel Deliver to stated address only', value: '4941' }
                ]
            },
            { name: 'productCode_BE_BE', type: 'dropdown', label: 'profile_productcode_be_be', 
                options: [
                    { label: '4960 - Belgium Deliver to stated address only', value: '4960' },
                    { label: '4961 - Belgium Delivery to neighbour', value: '4961' },
                    { label: '4962 - Belgium Signature on delivery + Deliver to stated address only', value: '4962' },
                    { label: '4963 - Belgium Signature on delivery', value: '4963' },
                    { label: '4965 - Belgium Extra cover + Deliver to stated address only', value: '4965' }
                ]
            },
            { name: 'productCode_BE_NL', type: 'dropdown', label: 'profile_productcode_be_nl', 
                options: [
                    { label: '4890 - Standard shipment', value: '4890' },
                    { label: '4891 - Signature on delivery', value: '4891' },
                    { label: '4893 - Delivery to stated address only', value: '4893' },
                    { label: '4894 - Signature on delivery + Delivery to stated address only', value: '4894' },
                    { label: '4896 - Signature on delivery + Delivery to stated address only + Return when not home', value: '4896' },
                    { label: '4897 - Extra cover', value: '4897' }
                ]
            },
            { name: 'productCode_EU_NL', type: 'dropdown', label: 'profile_productcode_eu_nl', 
                options: [
                    { label: '4907 - Parcel EU to consumer Track & Trace', value: '4907' },
                    { label: '4907 - Parcel EU to consumer Track & Trace Insured', value: '4907' },
                    { label: '4907 - Parcel EU to consumer Track & Trace Insured Plus', value: '4907' },
                    { label: '4907 - Parcel EU to business Track & Trace', value: '4907' },
                    { label: '4907 - Parcel EU to business Track & Trace Insured', value: '4907' },
                    { label: '4907 - Parcel EU to business Track & Trace Insured Plus', value: '4907' },
                    { label: '4999 - Parcel EU Standard', value: '4999' }
                ]
            },
            { name: 'productCode_Global_NL', type: 'dropdown', label: 'profile_productcode_global_nl', 
                options: [
                    { label: '4909 Non EU Track&Trace Insured Plus (voorheen 4947 GlobalPack)', value: '4909' },
                    { label: '4909 Non EU Track&Trace Insured Plus (voorheen 4945 GlobalPack)', value: '4909' }
                ]
            },
            { name: 'productCode_NL_Evening', type: 'dropdown', label: 'profile_productcode_nl_evening', tooltip: 'profile_postnl_tooltip',
                options: [
                    { label: '3085 - Evening Standard', value: '3085' },
                    { label: '3189 - Evening Signature on delivery', value: '3189' },
                    { label: '3087 - Evening + Extra cover', value: '3087' },
                    { label: '3089 - Evening + Signature on delivery + Deliver to stated address only', value: '3089' },
                    { label: '3094 - Evening + Extra cover + Return when not home', value: '3094' },
                    { label: '3096 - Evening + Signature on delivery + Deliver to stated address only + Return when not home', value: '3096' },
                    { label: '3385 - Evening + Deliver to stated address only', value: '3385' },
                    { label: '3390 - Evening + Deliver to stated address only + Return when not home', value: '3390' }
                ]
            },
            { name: 'productCode_NL_Pickup', type: 'dropdown', label: 'profile_productcode_nl_pickup',  tooltip: 'profile_postnl_tooltip',
                options: [
                    { label: '3533 - Ophalen bij PostNL locatie + Signature on Delivery', value: '3533' },
                    { label: '3534 - Ophalen bij PostNL locatie + Extra Cover', value: '3534' },
                    { label: '3543 - Ophalen bij PostNL locatie + Signature on Delivery + Notification', value: '3543' },
                    { label: '3544 - Ophalen bij PostNL locatie + Extra Cover + Notification', value: '3544' },
                    { label: '3571 - Ophalen bij PostNL locatie + Age Check 18+', value: '3571' },
                    { label: '3574 - Ophalen bij PostNL locatie + Age Check 18+ + Notification', value: '3574' }
                ]
            },
            { name: 'productCode_BE_Pickup', type: 'dropdown', label: 'profile_productcode_be_pickup',  tooltip: 'profile_postnl_tooltip',
                options: [
                    { label: '4880 - Ophalen bij PostNL locatie', value: '4880' },
                    { label: '4878 - Ophalen bij PostNL locatie + Extra cover', value: '4878' }
                ]
            },
            { name: 'productCode_NL_ParcelMachine', type: 'dropdown', label: 'profile_productcode_nl_parcelmachine',  tooltip: 'profile_postnl_tooltip',
                options: [
                    { label: '3553 - Parcel machine', value: '3553' }
                ]
            },
            { name: 'productCode_MailboxParcel', type: 'dropdown', label: 'profile_productcode_mailboxparcel',  tooltip: 'profile_postnl_tooltip',
                options: [
                    { label: '2928 - Brievenbuspakje Extra NL Bulk', value: '2928' },
                    { label: '2929 - Brievenbuspakje Extra NL Bulk (Sorted)', value: '2929' }
                ]
            },   
            { name: 'trustedShipperId', type: 'text', label: 'profile_trustedshipperid', tooltip: 'profile_postnl_specialid'},
            { name: 'importerReferenceCode', type: 'text', label: 'profile_importerreferencecode', tooltip: 'profile_postnl_specialid'},
            { name: 'multiColli', type: 'boolean', label: 'profile_multicolli' }
        ],
        saveObject: {
            parcelType: "parcel",
            productCode_NL_NL: "",
            productCode_NL_BE: "",
            productCode_BE_BE: "",
            productCode_BE_NL: "",
            productCode_EU_NL: "",
            productCode_Global_NL: "",
            productCode_NL_Evening: "",
            productCode_NL_Pickup: "",
            productCode_BE_Pickup: "",
            productCode_NL_ParcelMachine: "",
            productCode_MailboxParcel: "",
            trustedShipperId: "",
            importerReferenceCode: "",
            multiColli: false
        }
    },
    dhl: {
        fields: [
            { name: 'shipmentType', type: 'dropdown', label: 'shipment_type', translate: true, options: [
                { label: 'profile_dhl_send_to_customer', value: 'consumer' },
                { label: 'profile_dhl_send_to_business', value: 'business' },
            ] },
            { name: 'deliveryMethod', type: 'dropdown', label: 'delivery_method', translate: true, options: [
                { label: 'profile_dhl_doordelivery', value: 'doordelivery' },
                { label: 'profile_dhl_mailbox', value: 'mailbox' },
            ] },
            { name: 'sizeAndWeight', type: 'dropdown', label: 'size_and_weight', options: [
                { label: 'profile_dhl_size_auto', value: 'AUTO', deliveryMethod: 'both' },
                { label: 'Envelope (50-500g, 38×26.5×3.2 cm)', value: 'ENVELOPE', deliveryMethod: 'mailbox' },
                { label: 'Extra Small (500-2000g, 38×26.5×3.2 cm)', value: 'EXTRA_SMALL', deliveryMethod: 'mailbox' },
                { label: 'Small (0-10kg, 80×50×35 cm)', value: 'SMALL', deliveryMethod: 'doordelivery' },
                { label: 'Small-Medium (10-20kg, 80×50×35 cm)', value: 'SMALL_MEDIUM', deliveryMethod: 'doordelivery' },
                { label: 'Medium (20-31kg, 180×100×50 cm)', value: 'MEDIUM', deliveryMethod: 'doordelivery' },
                { label: 'Pallet (50-1000kg, 240×100×200 cm)', value: 'PALLET', deliveryMethod: 'doordelivery', shipmentType: "send_to_business" },
            ] },
            { name: 'extraServiceReference', type: 'dropdown', label: 'extra_service_reference', translate: true, options: [
            { label: 'no', value: 'no' },
            { label: 'profile_dhl_reference_default', value: 'default_reference' },
            { label: 'profile_dhl_reference_ordernumber', value: 'order_number' },
            { label: 'profile_dhl_reference_picklistnumber', value: 'picklist_number' }
            ] },
            { name: 'extraServiceReturnLabel', type: 'boolean', label: 'extra_service_return_label' },
            { name: 'extraServiceTransportInsurance', type: 'boolean', label: 'extra_service_transport_insurance' },
            { name: 'extraServiceSignatureOnDelivery', type: 'boolean', label: 'extra_service_signature_on_delivery' },
            { name: 'extraServiceNoNeighbourDelivery', type: 'boolean', label: 'extra_service_no_neighbour_delivery' },
            { name: 'extraServiceHideShipper', type: 'boolean', label: 'extra_service_hide_shipper' },
            { name: 'extraServiceAgeCheck18Plus', type: 'boolean', label: 'extra_service_age_check_18_plus' },
            { name: 'extraServiceSameDayDelivery', type: 'boolean', label: 'extra_service_same_day_delivery' },
            { name: 'extraServiceEveningDelivery', type: 'boolean', label: 'extra_service_evening_delivery' },
            { name: 'extraServiceSaturdayDelivery', type: 'boolean', label: 'extra_service_saturday_delivery' },
            { name: 'extraServiceExpressDeliveryBefore11AM', type: 'boolean', label: 'extra_service_express_delivery_before_11_am' },
            { name: 'extraServiceConstructionSite', type: 'boolean', label: 'extra_service_construction_site' },
            { name: 'extraServiceMessageToRecipient', type: 'boolean', label: 'extra_service_message_to_recipient' },
            { name: 'extraServiceSecureDeliveryByCode', type: 'boolean', label: 'extra_service_secure_delivery_by_code' }   
        ],
        saveObject: {
            accountnumbers: [],
            shipmentType: "",
            deliveryMethod: "",
            extraServiceReference: "",
            extraServiceReturnLabel: false,
            extraServiceTransportInsurance: false,
            extraServiceSignatureOnDelivery: false,
            extraServiceNoNeighbourDelivery: false,
            extraServiceHideShipper: false,
            extraServiceAgeCheck18Plus: false,
            extraServiceSameDayDelivery: false,
            extraServiceEveningDelivery: false,
            extraServiceSaturdayDelivery: false,
            extraServiceExpressDeliveryBefore11AM: false,
            extraServiceConstructionSite: false,
            extraServiceMessageToRecipient: "",
            extraServiceSecureDeliveryByCode: false,
            sizeAndWeight: null,
        }
    },
    bol: {
        fields: [
            { name: 'preferredTransporter', type: 'radio', label: 'preferred_transporter', options: [
                { label: 'PostNL', value: 'PostNL' },
                { label: 'PostNL Briefpost', value: 'postnl_briefpost' },
                { label: 'DHL Parcel', value: 'dhl_parcel' },
                { label: 'DHL Same Day', value: 'dhl_same_day' },
                { label: 'Budbee', value: 'budbee' }
            ] },
            { name: 'preferredLabelType', type: 'radio', label: 'preferred_label_type', options: [
                { label: 'Parcel: regulier pakket', value: 'parcel' },
                { label: 'Mailbox: brievenbuspakje met tracking/afleverscan', value: 'mailbox_tracking' },
                { label: 'Mailbox Light: brievenbuspakje zonder tracking/afleverscan', value: 'mailbox_light' }
            ] },
            { name: 'useRecommendedLabel', type: 'radio', label: 'use_recommended_label', options: [
                { label: 'Yes, use label recommended by bol.com', value: 'true' },
                { label: 'No, select based on preferred transporter and type', value: 'false' }
            ] }
        ],
        saveObject: {
            preferredTransporter: "",
            preferredLabelType: "",
            useRecommendedLabel: ""
        }
    },
    cirro: {
        fields: [
            // { name: 'shippingMethod', type: 'dropdown', label: 'shipping_method', options: [
            //     { label: 'G2G-GB local dispatch', value: 'G2G-GB local dispatch' },
            //     { label: 'CIRRO EU ECOMMERCE PARCEL POD SERVICE', value: 'CIRRO EU ECOMMERCE PARCEL POD SERVICE' },
            //     { label: 'CIRRO EU ECOMMERCE PARCEL', value: 'CIRRO EU ECOMMERCE PARCEL' },
            //     { label: 'CIRRO EU ECOMMERCE PARCEL (CLEARANCE)', value: 'CIRRO EU ECOMMERCE PARCEL (CLEARANCE)' },
            //     { label: 'CIRRO EU ECOMMERCE PARCEL (LIGHT)', value: 'CIRRO EU ECOMMERCE PARCEL (LIGHT)' },
            //     { label: 'CIRRO EU ECOMMERCE HIGH-VALUED PARCEL (SIGNATURE)', value: 'CIRRO EU ECOMMERCE HIGH-VALUED PARCEL (SIGNATURE)' },
            //     { label: 'CIRRO EU ECOMMERCE HIGH-VALUED PARCEL', value: 'CIRRO EU ECOMMERCE HIGH-VALUED PARCEL' },
            //     { label: 'G2GEU-OSM US EAST Parcel', value: 'G2GEU-OSM US EAST Parcel' },
            //     { label: 'G2GEU-OSM US WEST Parcel', value: 'G2GEU-OSM US WEST Parcel' },
            //     { label: '测试产品-欧洲下单预报', value: '测试产品-欧洲下单预报' },
            //     { label: 'JOOM-SPAIN TO EUROPE', value: 'JOOM-SPAIN TO EUROPE' },
            //     { label: 'EURO RO INTERNAL', value: 'EURO RO INTERNAL' },
            //     { label: 'JOOM-EU to UK', value: 'JOOM-EU to UK' },
            //     { label: 'EuropeCustomerReturn', value: 'EuropeCustomerReturn' },
            //     { label: 'Australia CIRRO Registered Standard', value: 'Australia CIRRO Registered Standard' },
            //     { label: 'EuropeCustomerInternationalReturn', value: 'EuropeCustomerInternationalReturn' },
            //     { label: 'Poczta Polska First Mile - Poland', value: 'Poczta Polska First Mile - Poland' },
            //     { label: 'Poczta POLAND TO EUROPE', value: 'Poczta POLAND TO EUROPE' },
            //     { label: 'DE-DHL-PUDO', value: 'DE-DHL-PUDO' },
            //     { label: 'DE-FLJ Ecommerce Parcel Misc', value: 'DE-FLJ Ecommerce Parcel Misc' },
            //     { label: 'DE-DHL-PUDO-TEST', value: 'DE-DHL-PUDO-TEST' },
            //     { label: 'AT-POST-PUDO-TEST', value: 'AT-POST-PUDO-TEST' },
            //     { label: 'CIRRO EU ECOMMERCE PARCEL (PEAK SEASON)', value: 'CIRRO EU ECOMMERCE PARCEL (PEAK SEASON)' },
            //     { label: 'DHL First Mile - German', value: 'DHL First Mile - German' },
            //     { label: 'JOOM-DE TO EUROPE', value: 'JOOM-DE TO EUROPE' },
            //     { label: 'JOOM-EU TO RU/KZ', value: 'JOOM-EU TO RU/KZ' },
            //     { label: 'JOOM-FRANCE TO EUROPE', value: 'JOOM-FRANCE TO EUROPE' },
            //     { label: 'JOOM-UK TO EUROPE', value: 'JOOM-UK TO EUROPE' },
            //     { label: 'RM First Mile-UK', value: 'RM First Mile-UK' },
            //     { label: 'YT&GC-DE TO EUROPE', value: 'YT&GC-DE TO EUROPE' },
            //     { label: 'CIRRO&GC-FR TO EUROPE', value: 'CIRRO&GC-FR TO EUROPE' },
            //     { label: 'Colissimo First Mile-France', value: 'Colissimo First Mile-France' },
            //     { label: 'EU-TEST', value: 'EU-TEST' },
            //     { label: 'G2G Spain to Europe', value: 'G2G Spain to Europe' }
            // ] },
            { name: 'includeVATPrepaidService', type: 'boolean', label: 'include_vat_prepaid_service' },
            { name: 'eoriNumber', type: 'text', label: 'eori_number' },
            { name: 'vatNumber', type: 'text', label: 'vat_number' },
            { name: 'iossNumber', type: 'text', label: 'ioss_number' }
        ],
        saveObject: {
            shippingMethod: "",
            includeVATPrepaidService: "",
            eoriNumber: "",
            vatNumber: "",
            iossNumber: ""
        }
    },
    parcel2send: {
        fields: [
            { name: 'shipmentMethod', type: 'dropdown', label: 'shipment_method', options: [
                { label: 'Standaard', value: 'default' },
                { label: 'DPD Home', value: 'dpd_home' },
                { label: 'DPD Home Saturday delivery', value: 'dpd_home_saturday' }
            ] },
            { name: 'insuredAmount', type: 'text', label: 'insured_amount' },
            { name: 'length', type: 'text', label: 'length' },
            { name: 'width', type: 'text', label: 'width' },
            { name: 'height', type: 'text', label: 'height' }
        ],
        saveObject: {
            shipmentMethod: "",
            insuredAmount: "",
            length: "",
            width: "",
            height: ""
        }
    },
    sendcloud: {
        fields: [
            { name: 'useRelationName', type: 'boolean', label: 'use_relation_name' },
            { name: 'insured', type: 'boolean', label: 'insured' },
            { name: 'includeParcelItems', type: 'dropdown', label: 'include_parcel_items', options: [
                { label: 'Only when required for customs', value: 'only_when_required_for_customs' },
                { label: 'Always', value: 'always' }
            ] }
        ],
        saveObject: {
            shippingMethod: "",
            senderAddress: "",
            contract: "",
            useRelationName: false,
            insured: false,
            includeParcelItems: "always"
        }
    }
    
};