import { useTranslation } from "react-i18next";

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal";
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper";
import { useAddAccountingSoftwareMutation } from "../../../../newapi/financial/accountingSoftwareSlice";
import Details from "./views/Details";
import ChooseType from "./views/ChooseType";
import Sync from "./views/Sync";

const MpAddAccountingSoftware = (props) => {
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useAddAccountingSoftwareMutation} isModal title={t("add_accountingsoftware")}>
                <ChooseType/>
                <Details/>
                <Sync/>
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddAccountingSoftware;