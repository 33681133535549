// TabContent1.js
import React, { useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import SelectGroup from '../../../../theme/Select/SelectGroup';
import { ReactComponent as MoneybirdLogo } from '../../../../../styles/svg/moneybird.svg';
import { useTranslation } from 'react-i18next';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    
        const columns = [
            {title: "Moneybird", logo: <MoneybirdLogo width={60} height={60}/>, key: "moneybird"},
        ]

        const handleOptionSelect = (option) => {
            const credentialsObj = getCredentialsFromType(columns[option].key);
            props.handleDataUpdate({ credentials: credentialsObj });
            props.handleDataUpdate({ selectedOption: option, type: columns[option].key });
            props.handleNext("tabDetails");
        };

        const getCredentialsFromType = (type) => {
            switch(type) {
                case "moneybird": 
                    return {administration_id: "", access_token: ""}; 
                default:
                    return {};
            }
        }
    
        return (
            <div>
                <SelectGroup selected={-1} setSelected={handleOptionSelect} options={columns} image={true} />
            </div>
        );
    };

const ChooseType = (props) => {

    return (
        <ProgressionTab 
            tabId="tabChooseType" 
            step={1}
            maxSteps={2}
        >
            <Content/>
        </ProgressionTab>
    )
}

export default ChooseType;