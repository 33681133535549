
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const warehouseFinancialInvoiceComponentsConfig = {
    products:  {
        title: 'invoice_products',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    categories:  {
        title: 'categories',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
    priceIncreases:  {
        title: 'price_adjustments',
        icon: <WarehouseIcon/>,
        isSingleDataGrid: true,
    },
};
