import { Box, IconButton, useTheme } from "@mui/material";
import React, { useState } from 'react';
// import { ColorModeContext, tokens } from '../../../theme';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from '../../../newapi/global/settingsSlice';
import { tokens } from '../../../theme';
import { GeneralSearch } from "../../theme/Search/GeneralSearch";
import TopbarActionModal from '../Modals/MTopbarAction';
import { ENV_TYPES } from '../Sidebar';
import WarehousePicker from '../WarehousePicker';
import TopbarIconButton from './components/TopbarIconButton';
import TopbarNotifications from './views/TopbarNotifications';
import { useTopbar } from "../../../contexts/TopbarProvider";
// import { MenuStateContext } from '../../../contexts/MenuState';



const Topbar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const { extraComponent } = useTopbar(); 



    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
        // setExportAnchorEl(null);
    };

    const changeMenuState = () => {
        dispatch(toggleMenu());
    }
    
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    return (
        <Box 
            className="topbar" 
            display="flex" 
            justifyContent="space-between" 
            paddingX={4} paddingY={2}
            sx={{
                background:colors.bg["tertiary"],
                "@media screen and (max-width: 47.9375em)" : {
                    paddingX:2,
                    paddingRight:isRelation ? 3 :0,
                }
            }}    
        >

            <IconButton type="button" onClick={changeMenuState} sx={{p:1}}>
                <MenuIcon/>
            </IconButton>


            {/* Icons */}
            <Box 
                display="flex"
                gap={2}
                sx={{
                    "@media screen and (max-width: 47.9375em)" : {
                        alignItems:"center",
                    }
                }}
            >
                {extraComponent !== null && extraComponent}
                <GeneralSearch/>
                

                {selectedEnvironmentType === ENV_TYPES[100] &&
                    <WarehousePicker phone={false} />
                }



                <TopbarNotifications/>

                <TopbarIconButton
                    onClick={handleFilterPopoverClick}
                >
                    <SettingsOutlinedIcon />
                </TopbarIconButton>

                <TopbarActionModal
                    id={filterPopoverId}
                    open={filterPopoverOpen}
                    anchorEl={filterAnchorEl}
                    onClose={handleFilterPopoverClose}
                >

                </TopbarActionModal>


                {selectedEnvironmentType === ENV_TYPES[100] &&
                    <WarehousePicker desktop={false} />
                }
            </Box>
            
        </Box>
    );
};

export default Topbar;