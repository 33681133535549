import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../../../theme";

import Text from "../../../../../../../components/theme/text/Text";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../../components/theme/buttons/Shbutton";

import { useTranslation } from "react-i18next";
import MAddPrinter from "../../../../../../../components/global/Modals/MAddPrinter";
import ShDataGrid2 from "../../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useFindAllPrintersQuery } from "../../../../../../../newapi/warehouse/printerSlice";
import SidepanelLoader from "../../../../../../../components/theme/sidepanel2/SidepanelLoader";
import { selectUserPermission } from "../../../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from "../../../../../../../utils/permissions";
import { useSelector } from "react-redux";

const SpPrinterDetails = SidepanelLoader(() => import("../../../../../../../components/global/Sidepanels/SpPrinterDetails/SpPrinterDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_printer")}</Shbutton>
            )}
        </ButtonGroup>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter : {
        name: "printerFilter",
        selectedFilter : null,
        groups : [
            {
                conditionExtensionType : 0,
                rules : [
                ]
            }
        ]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.PRINTER_MANAGE])
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };


    const [printerId, setPrinterId] = useState();
    const printerIdRef = useRef(printerId);
    
    const getPrinterDetails = (params) => {
        if (!params) return;
    
        const newprinterId = parseInt(params.row.id);
        // Only update state if the printerId has actually changed
        if (printerIdRef.current !== newprinterId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setPrinterId(newprinterId);
            printerIdRef.current = newprinterId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions hasCreatePermission={hasCreatePermission} handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const columns = [
        { field: "description", headerName: t("description"), flex: 2, cellClassName: "printer-description", 
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.row.description
            } 
        },
        { field: "internal_name", headerName: t("internal_name"), flex: 2, cellClassName: "printer-internal-name", 
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.row.internal_name
            }  
        },
        //{ field: "printnode_id", headerName: "PrintNode ID", flex: 1, cellClassName: "printer-printnode-id" },
        { field: "zpl_support", headerName: t("zpl_support"), flex: 1, cellClassName: "printer-zpl-support", 
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.value ? t('yes') : t('no') 
            }
        },
        { field: "format", headerName: t("format"), flex: 1, cellClassName: "printer-format", 
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.row.format
            }  
         },
        { field: "custom_width", headerName: t("custom_width"), flex: 1, cellClassName: "printer-custom-width", 
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.row.custom_width
            }  
         },
        { field: "custom_height", headerName: t("custom_height"), flex: 1, cellClassName: "printer-custom-height", 
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return cellValues.row.custom_height
            }  
         },
        { field: "warehouse", headerName: t("warehouse"), flex: 1, cellClassName: "printer-warehouse-id",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return(<Text>{cellValues.row.warehouse?.name}</Text>)
            }
        },
            {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // Custom cell rendering for phone view
            }
        }
    ];

    // const handleRowClick = (params) => {
    //     setIsExtended(true);
    // };
    
    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                content={useFindAllPrintersQuery}
                gridActions={<GridActions hasCreatePermission={hasCreatePermission} handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                columns={columns}
                sortModel={{field: columns[0].field,type:"ASC"}}
                
                onRowClick={getPrinterDetails}>
            </ShDataGrid2>
                
            <MAddPrinter onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpPrinterDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    printerId={printerId}
                    // data={printerData}   
                /> 
            }
        </Box>
    )
};

export default All;
