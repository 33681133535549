import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { relationReturnsDesignConfig } from "./settingsWarehouseGeneralCoreConfig";
import Home from "./views/Home";

import { useSelector } from "react-redux";
import TabView3 from "../../../../../components/theme/TabView3/TabView3";
import { useGetRelationForMenuQuery, useUpdatePortalstyleMutation } from "../../../../../newapi/global/relation/relationSlice";
import { tokens } from "../../../../../theme";
import { masterStyleMap, transformStyleMapIfNeeded } from "../../../../../components/portalbuilder/componentMaps";
import { page_keys, portal_keys } from "../../../../../components/portalbuilder/config";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";

const Design = (props) => {
    const { t } = useTranslation();
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const environment = useSelector((state) => state.settings.environment);

    useEffect(() => {
        props.replaceView({
            ...props.config,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    const {data, isLoading, isFetching} = useGetRelationForMenuQuery(environment.selectedId);

    const [transformedData, setTransformedData] = useState(null);

    useEffect(() => {
        
      // Only run the effect if data has finished loading and fetching
      if (!isLoading && !isFetching) {
        if (data !== null && data.portalStyle === null) {
            // alert(JSON.stringify(data));
            // alert("we zero bitch");
          // If data is null (backend hasn't set it yet), use masterMap
          setTransformedData(masterStyleMap);
        } else {
          // If data is available, transform it normally
          const transformed = transformStyleMapIfNeeded(data.portalStyle);
          setTransformedData(transformed);
        }
      }
    }, [data, isLoading, isFetching]);


    const [updatePortalstyle] = useUpdatePortalstyleMutation();
    const saveDesign = async (key,map) => {
        try{
            const newMap = {...transformedData};
            newMap[key] = map;

            const resp = await updatePortalstyle({
                style: newMap
            }).unwrap();
            console.log(resp);
            showToastMessageUpdateRequest(t,resp);
        }catch(error){
            showToastMessageRequestError(t("save_design_error"),t,error);
        }

    }
    

    return (
        <Box
            sx={{
                padding:"0px 32px",
                paddingTop:"32px",
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <TabView3
                tabConfig={relationReturnsDesignConfig}
                viewType="bodytabs"
                sx={{
                    position:"relative",
                    zIndex:2,
                    display: "flex",
                    flex:1,
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            // transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            // transition:"250ms"
                        },
                    }
                  
                }}
                tabClasses={"btn-body-tab"}
            >
                {transformedData !== null &&
                    <>
                        <Home 
                            saveDesign={saveDesign}
                            data={transformedData[page_keys.EDITREGISTERRETURN]} 
                            config={relationReturnsDesignConfig.home}
                        />
                        {/* <Future config={settingsWarehouseGeneralCoreConfig.future} settings={data}/> */}
                        {/* <PickBasis config={settingsWarehouseGeneralCoreConfig.pickBasis}  />
                        {/* <PalletPicking config={settingsWarehouseGeneralCoreConfig.palletPicking} settings={data} /> */}
                        {/* <Inbound config={settingsWarehouseGeneralCoreConfig.inbound} />
                        <Replenish config={settingsWarehouseGeneralCoreConfig.replenish}/>
                        <Echeck config={settingsWarehouseGeneralCoreConfig.echeck}/>
                        <Invoicing config={settingsWarehouseGeneralCoreConfig.invoicing}/>
                        <Relation config={settingsWarehouseGeneralCoreConfig.relation}/> */} 
                    </>
                }
            </TabView3>
        </Box>
    )
};

export default Design;