
import WidgetsIcon from '@mui/icons-material/Widgets';
import CropFreeIcon from '@mui/icons-material/CropFree';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

export const SpBolReplenishmentDetailsConfig = {
    products:  {
        title: 'products',
        icon: <CropFreeIcon/>,
    },
    // details: {
    //     title: 'details',
    //     icon: <FormatListBulletedIcon/>,
    //     isSingleDataGrid: true,
    // },
    // shipments: {
    //     title: 'shipments',
    //     icon: <FormatListBulletedIcon/>,
    //     isSingleDataGrid: true,
    // },
    // logs: {
    //     title: 'logs',
    //     icon: <FormatListBulletedIcon/>,
    //     isSingleDataGrid: true,
    // }
};
