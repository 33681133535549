import * as yup from "yup";

export const yupRequired = (name, t) => {
    return yup
    .string(t(name) + " " + t("validation_required"))
    .nullable()
    .required(t(name) + " " + t("validation_required"))
    .trim(t(name) + " " + t("validation_required"))
    .test('not-null', t(name) + " " + t("validation_required"), value => value !== null);
}

export const yupNumberRequired = (name,t, largerThanZero = true) => {
    let validator = yup.number()
        .typeError(`${t(name)} ${t("validation_required")}`)
        .required(t(name) + " " + t("validation_required"));
    
    // If true don't accept negative numbers
    if (largerThanZero) {
        validator = validator.min(1, t('amount_greater_than_zero'));
    }
    
    return validator;
}

export const yupDecimalRequired = (name, t, largerThanZero = true) => {
    let validator = yup.number()
    .typeError(`${t(name)} ${t("validation_required")}`)
    .required(t(name) + " " + t("validation_required"))
    
    if (largerThanZero) {
        validator = validator
        .positive(t('amount_greater_than_zero'))
        .test('is-decimal', t('invalid_decimal'), value => 
            (value && !/^\d+(\.\d{1,2})?$/.test(value)) ? false : true
        );
    } else {
        validator = validator
        .test('is-decimal', t('invalid_decimal'), value => 
            (value && !/^-?\d+(\.\d{1,2})?$/.test(value)) ? false : true
        );
    }
    
    return validator;
}

export const yupEmailRequired = (name,t) => {
    return yup
    .string()
    .nullable()
    .trim()
    .required(t(name) + " " + t("validation_required"))
    .email(t("invalid_email"));
    
}

export const yupArrayRequired = (name, t) => {
    return yup
    .array()
    .required(t(name) + " " + t("validation_required"))
    .min(1, t(name) + " " + t("validation_required"))
    .test('not-null', t(name) + " " + t("validation_required"), value => value !== null);
}

export const yupDateRequired = (name, t) => {
    return yup.date()
    .required(t(name) + " " + t("validation_required"))
    .test('not-null', t(name) + " " + t("validation_required"), value => value !== null);
}
