import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import TopRight from "../../../theme/positionals/TopRight";

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';

const AllCarriersCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    

    return (
        <BaseCard onClick={props.onClick}>
            <Box>
                <Box>
                    <Box display={"flex"} flexDirection={"column"}>
                        <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{props.name}</Text>
                    </Box>
                    <TopRight>
                        {
                            props.fits === 0 ?
                                <CardLabel variant="green" icon><CheckOutlinedIcon fontSize="small"/></CardLabel>:
                                props.fits === 1 ?
                                <CardLabel variant="red" icon><CloseOutlinedIcon fontSize="Small" /></CardLabel> 
                            :
                                <CardLabel variant="orange" icon><HorizontalRuleOutlinedIcon fontSize="Small"/></CardLabel>
                        }
                        {/* <CardLabel variant="red"  position="child-tr">X</CardLabel> */}
                    </TopRight>


                    {/* Holds tht date and batch */}
                    <Box marginTop={3} display={"flex"} gap={4}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>Weight</Text>
                            <Text bold>{props.weight}</Text>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>Channels</Text>
                            <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{props.channels}</Text>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light># Barcodes</Text>
                            <Text bold>{props.barcodes}</Text>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default AllCarriersCard;