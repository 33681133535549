import React, { createContext, useState, useContext } from 'react';

// Create a context to provide dynamic component for Topbar
const TopbarContext = createContext();

// Custom hook to access the context
export const useTopbar = () => {
  const context = useContext(TopbarContext);
  if (!context) {
    throw new Error('useTopbar must be used within a TopbarProvider');
  }
  return context;
};

// TopbarProvider component to provide the context
export const TopbarProvider = ({ children }) => {
  const [extraComponent, setExtraComponent] = useState(null);

  // Function to show the extra component in the Topbar
  const showExtraComponent = (component) => {
    setExtraComponent(component);
  };

  // Function to clear the extra component from the Topbar
  const clearExtraComponent = () => {
    setExtraComponent(null);
  };

  return (
    <TopbarContext.Provider value={{ extraComponent, showExtraComponent, clearExtraComponent }}>
      {children}
    </TopbarContext.Provider>
  );
};
