import { useTheme } from "@emotion/react";
import { StarOutlineOutlined } from "@mui/icons-material";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, ButtonBase } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../components/theme/buttons/Shbutton";
import { DropdownBase } from "../../../../components/theme/dropdowns/Dropdown";
import ShLabel from "../../../../components/theme/label/ShLabel";
import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";
import { formatNumber } from "../../../../utils/helpers";

const CustomComponent = (props) => {
    return (
    <Box sx={{display:"flex", justifyContent:"flex-start", alignItems:"flex-start", gap:1}}>
        {props.option.labelDisplayName}
        (€{props.option.labelPrice.totalPrice})
        {props.option.recommended ? <StarOutlineOutlined width={16} height={16}/> : <></>}
    </Box>
    );
};

const ContainerView = (props) => {
        
    const { t, ready,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 

    return (
        <Box
            key={props.carrierIndex}
            sx={{
                background: theme.palette.mode === "dark" ? colors.grey[500] : colors.bg["tertiary"],
                borderRadius: 4,
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                paddingBottom: 3,
                postion:"relative",
                px:2,
                py:2,
            }}
        >
            {props.selectedShipper?.type === "bol" && props.carrier.rows.length > 0 && 
                <Box
                    sx={{
                        height: 36,
                        display: "flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        boxShadow: ' 0px 1px 2px 0px rgba(49, 65, 95, 0.25)',
                        pr:0.5,
                        pl:3,
                        background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                        borderRadius:6,
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        },
                        mb:3,
                    }}
                >
                    <Box
                        sx={{
                            mr:3,
                            display:"flex",
                            alignItems:"center",
                            gap:2,
                        }}
                    >
                        <Text light>{t("label")}:</Text>
                        <Text semibold>{props.carrier.deliveryOption ? `${props.carrier.deliveryOption.labelDisplayName} (€ ${props.carrier.deliveryOption.labelPrice.totalPrice})` : t("no_label")}</Text>
                    </Box>
                    <ButtonBase
                        sx={{
                            width:32,
                            height:32,
                            background:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                            color:"#fff",
                            borderRadius:5,
                        }}
                    >
                        
                        
                        <DropdownBase
                            displayName={"labelDisplayName"} 
                            options={props.deliveryOptions[props.carrierIndex] || []}
                            onChange={(selected) => props.selectLabel(props.carrierIndex,selected)}
                            selected={props.carrier.deliveryOption ? props.deliveryOptions[props.carrierIndex].indexOf(props.carrier.deliveryOption) : -1}
                            noSelection={t("no_label")} 
                            maxHeight={"unset"}
                            useTranslation 
                            closeOnSelection
                            disallowDeselection
                            defaultStyling
                            shComponent={<CustomComponent/>} // new
                        >
                                
                            <EditOutlinedIcon/>
                        </DropdownBase>
                    </ButtonBase>
                </Box>
            }
            <Box
                sx={{
                    pb:2,
                }}
            >
                <Text semibold>
                    {props.carrier.carrierName === '' ? "Scan drager ..." : props.carrier.carrierName}
                </Text>
                <Box pt={0.5}>
                    <Text bold>{props.carrier.totalProducts}</Text> <Text light>Producten,</Text>&nbsp;
                    <Text bold>{formatNumber(i18n,props.carrier.totalWeight/1000)}</Text> <Text light>kg</Text>

                </Box>
            </Box>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:1.5,
                }}
            >
                {props.carrier.rows.map((row, index) => {
                    return (
                        <Box
                            key={index}
                            sx={{
                            }}
                        >
                            {/* Pulsate = true */}
                            <Box
                                sx={{
                                    flex:1,
                                    display:"flex",
                                    background:theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[0],
                                    border: theme.palette.mode === "light" ? `1px solid ${colors.grey[100]}` :null,
                                    boxShadow: theme.palette.mode === "light" ? "0 2px 4px rgba(49,65,95,.15)" : null,
                                    borderRadius:3,
                                    padding:1,
                                    alignItems:"center",
                                    justifyContent: "space-between",
                                    animation: "1s pulsate"
                                }}
                            >
                                <Box
                                    sx={{
                                        display:"flex",
                                        alignItems:"center",
                
                                    }}
                                >
                                    <ShLabel size={32} fitted palette="normal" variant={"orange"}>
                                        {row.amount}
                                    </ShLabel>
                                    
                                    <Box
                                        sx={{
                                            ml:2,
                                            display:"flex",
                                            flexDirection:'column',
                                        }}
                                    >
                                        <Text>
                                            {row.product.barcodes?.[0].barcode}
                                        </Text>
                                        <Text semibold>
                                            {row.product.description}
                                        </Text>
                                    </Box>
                                </Box>
                                        {/* <Text light>
                                            {sku}
                                        </Text> */}
                                {/* {scanlabel} */}
                            </Box>
                        </Box>
                    )
                })}
                {props.carrier.rows.length === 0 &&
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            background:theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[0],
                            border: theme.palette.mode === "light" ? `1px solid ${colors.grey[100]}` :null,
                            boxShadow: theme.palette.mode === "light" ? "0 2px 4px rgba(49,65,95,.15)" : null,
                            borderRadius:3,
                            padding:1,
                            alignItems:"center",
                            justifyContent: "center",
                            opacity:0.7,
                        }}
                    >
                        <Text semibold sx={{marginLeft: 2,
                            opacity:0.7,}}>
                            {t("scan_an_order")}
                        </Text>
                    </Box>
                }
            </Box>
            {props.carrier.carrierName === "" &&
                <Box sx={{pt:3,
                    display:'flex',
                    gap:2,
                }}>
                    <DropdownBase
                        transformOrigin={{
                            vertical: "bottom"
                        }}
                        anchorOrigin={{
                            vertical:"top"
                        }}
                        displayName={"name"} 
                        onChange={(selected) => props.selectManualCarrier(selected)}
                        noSelection={t("choose_carrier")} //Custom text when nothing is selected
                        options={props.carriersSelect}
                        selected={-1}
                        closeOnSelection
                        resetOnSelection
                    >
                        <Shbutton className={"group-btn"}  sx={{whiteSpace:"nowrap"}} color={"primaryGrey"} variant="contained">{t("choose_carrier")}</Shbutton>
                    </DropdownBase>

                    <Shbutton className={"group-btn"}  sx={{whiteSpace:"nowrap"}} color={"primaryGrey"} variant="contained" onClick={() => props.OwnCarrierClick()}>{t("own_carrier")}</Shbutton>
                </Box>
            }
        </Box>
    );
}

export default ContainerView;