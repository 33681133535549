import { Box, InputBase, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../theme";
import { hexToHsl, hslToHex, hslToHsb, hslToRgb, rgbToHsl } from "../utils/colorConverters";
import { DropdownBase } from "./theme/dropdowns/Dropdown";
import PopoverModal from "./theme/Modal/PopoverModal";

const ColorTypeMap = {
    HEX: "HEX",
    RGB: "RGB",
    HSL: "HSL",
    HSBV: "HSB/V",
}

const colorTypeArray = [ColorTypeMap.HEX,ColorTypeMap.RGB,ColorTypeMap.HSL,ColorTypeMap.HSBV];


export const ShColorPicker = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [colorType, setColorType] = useState(0);

    const colorCanvasRef = useRef(null);
    const huePuckCanvasRef = useRef(null);

    const lightnessCanvasRef = useRef(null);
    const lightnessPuckCanvasRef = useRef(null);

    const opacityCanvasRef = useRef(null);

    const valFieldRef = useRef(null);
    const testvalFieldRef = useRef(null);
    const opacitydivRef = useRef(null);
  
    const [currentHue, setCurrentHue] = useState(0);
    const [currentSaturation, setCurrentSaturation] = useState(100);
    const [currentLightness, setCurrentLightness] = useState(50);
    const [currentOpacity, setCurrentOpacity] = useState(1);
  
    const [isDraggingColor, setIsDraggingColor] = useState(false);
    const [isDraggingLightness, setIsDraggingLightness] = useState(false);
    const [isDraggingOpacity, setIsDraggingOpacity] = useState(false);
  
    const [lightnessPuckPosition, setLightnessPuckPosition] = useState({ x: 0, y: 0 });
  
    const puckRadius = 8;
  
    const drawColorPalette = () => {
      const colorCanvas = colorCanvasRef.current.getContext('2d');
      const width = colorCanvasRef.current.width;
      const height = colorCanvasRef.current.height;
      
      for (let i = 0; i <= height; i++) {
        colorCanvas.fillStyle = `hsl(${(i * 360 / height)}, 100%, 50%)`;
        colorCanvas.fillRect(0, i, width, 1);
      }
      drawColorPuck();
    };
  
    const drawColorPuck = () => {

        const puckCanvas = huePuckCanvasRef.current.getContext('2d');
    
        const width = huePuckCanvasRef.current.width;
        const height = huePuckCanvasRef.current.height;
      

        puckCanvas.clearRect(0, 0, width, height); // Clear puck canvas before drawing
        const yPos = (currentHue / 360) * height;

        puckCanvas.beginPath();
        puckCanvas.arc((width / 2), yPos, puckRadius, 0, Math.PI * 2);
        
        // Shadow properties
        puckCanvas.shadowColor = 'rgba(0, 0, 0, 1)';  // Black shadow with some transparency
        puckCanvas.shadowBlur = 6;  // Blur the shadow a bit
        puckCanvas.shadowOffsetX = 0;  // Horizontal offset of the shadow
        puckCanvas.shadowOffsetY = 2;  // Vertical offset of the shadow
        
        // Fill the puck with the current hue
        puckCanvas.fillStyle = `hsl(${currentHue}, 100%, 50%)`;
        puckCanvas.fill();
        
        // Reset the shadow for the stroke
        puckCanvas.shadowColor = 'transparent';  // Reset shadow color before stroke
        
        // Stroke the puck with a white outline
        puckCanvas.lineWidth = 4;
        puckCanvas.strokeStyle = 'white';
        puckCanvas.stroke();

    };
  
    const drawLightnessControl = () => {
      const lightnessCanvas = lightnessCanvasRef.current.getContext('2d');
      const width = lightnessCanvasRef.current.width;
      const height = lightnessCanvasRef.current.height;

      lightnessCanvas.clearRect(0, 0, width, height);
  
      const colorGradient = lightnessCanvas.createLinearGradient(0, 0, width, 0);
      colorGradient.addColorStop(0, 'white');
      colorGradient.addColorStop(0.05, 'white');
      colorGradient.addColorStop(0.95, `hsl(${currentHue}, 100%, 50%)`);
      colorGradient.addColorStop(1, `hsl(${currentHue}, 100%, 50%)`);
      lightnessCanvas.fillStyle = colorGradient;
      lightnessCanvas.fillRect(0, 0, width, height);
  
      const blacknessGradient = lightnessCanvas.createLinearGradient(0, 0, 0, height);
      blacknessGradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
      blacknessGradient.addColorStop(0.05, 'rgba(0, 0, 0, 0)');
      blacknessGradient.addColorStop(0.95, 'black');
      blacknessGradient.addColorStop(1, 'black');
      lightnessCanvas.fillStyle = blacknessGradient;
      lightnessCanvas.fillRect(0, 0, width, height);
    
    };
  
    
    const drawLightnessPuck = () => {
        const puckCanvas = lightnessPuckCanvasRef.current.getContext('2d');
        const width = lightnessPuckCanvasRef.current.width;
        const height = lightnessPuckCanvasRef.current.height;
      
        puckCanvas.clearRect(0, 0, width, height); // Clear puck canvas before drawing
      
        puckCanvas.beginPath();
        puckCanvas.arc(lightnessPuckPosition.x, lightnessPuckPosition.y, puckRadius, 0, Math.PI * 2);

        // Shadow properties
        puckCanvas.shadowColor = 'rgba(0, 0, 0, 0.5)';  // Black shadow with some transparency
        puckCanvas.shadowBlur = 4;  // Blur the shadow a bit
        puckCanvas.shadowOffsetX = 0;  // Horizontal offset of the shadow
        puckCanvas.shadowOffsetY = 0;  // Vertical offset of the shadow
        
        puckCanvas.lineWidth = 4;
        puckCanvas.strokeStyle = 'white';
        puckCanvas.stroke();
    };
    
  
    const drawOpacityControl = () => {
      const opacityCanvas = opacityCanvasRef.current.getContext('2d');
      const width = opacityCanvasRef.current.width;
      const height = opacityCanvasRef.current.height;
      opacityCanvas.clearRect(0, 0, width, height);
  
      drawRasterPattern(width, height);
  
      const baseColor = `hsl(${currentHue}, ${currentSaturation}%, ${currentLightness}%)`;
      const opacityGradient = opacityCanvas.createLinearGradient(0, 0, width, 0);
      opacityGradient.addColorStop(0, `${baseColor.slice(0, -1)}, 0)`);
      opacityGradient.addColorStop(1, baseColor);
      opacityCanvas.fillStyle = opacityGradient;
      opacityCanvas.fillRect(0, 0, width, height);
  
    //   drawOpacityPuck();
    };
  
    const drawRasterPattern = (width, height) => {
      const rasterSize = height / 2;
      const opacityCanvas = opacityCanvasRef.current.getContext('2d');
      for (let x = 0; x < width; x += rasterSize) {
        for (let y = 0; y < height; y += rasterSize) {
          opacityCanvas.fillStyle = (x / rasterSize + y / rasterSize) % 2 === 0 ? '#DDD' : '#FFF';
          opacityCanvas.fillRect(x, y, rasterSize, rasterSize);
        }
      }
    };
  
    // const drawOpacityPuck = () => {
    //   const opacityCanvas = opacityCanvasRef.current.getContext('2d');
    //   const xPos = currentOpacity * opacityCanvasRef.current.width;
    //   opacityCanvas.beginPath();
    //   opacityCanvas.arc(xPos, opacityCanvasRef.current.height / 2, puckRadius, 0, Math.PI * 2);
    //   opacityCanvas.fillStyle = `hsla(${currentHue}, ${currentSaturation}%, ${currentLightness}%, ${currentOpacity})`;
    //   opacityCanvas.fill();
    //   opacityCanvas.lineWidth = 4;
    //   opacityCanvas.strokeStyle = 'white';
    //   opacityCanvas.stroke();
    // };
  
    const redrawCanvases = () => {
        drawColorPalette();
        drawLightnessControl();
        drawOpacityControl();
    };
  
    const startDragging = (event, canvasType) => {
      if (canvasType === 'color') setIsDraggingColor(true);
      else if (canvasType === 'lightness') setIsDraggingLightness(true);
      else if (canvasType === 'opacity') setIsDraggingOpacity(true);
      updateSelection(event, canvasType);
    };
  
    const stopDragging = () => {
      setIsDraggingColor(false);
      setIsDraggingLightness(false);
      setIsDraggingOpacity(false);
    };
  
    const drag = (event, canvasType) => {
      if ((isDraggingColor && canvasType === 'color') ||
        (isDraggingLightness && canvasType === 'lightness') ||
        (isDraggingOpacity && canvasType === 'opacity')) {
        updateSelection(event, canvasType);
        redrawCanvases();  // Redrawing all canvases after drag
      }
    };
  
    const updateSelection = (event, canvasType) => {
      if (canvasType === 'color') {
        handleColorSelection(event);
      } else if (canvasType === 'lightness') {
        handleLightnessSelection(event);
      } else if (canvasType === 'opacity') {
        handleOpacitySelection(event);
      }
    };
  
    const handleColorSelection = (event) => {
        const mousePos = getMousePos(colorCanvasRef.current, event);

        setCurrentHue(Math.round(Math.max(0, Math.min(360, Math.round((mousePos.y / colorCanvasRef.current.height) * 360)))));
        if(props.setValue){
            props.setValue({hex: hslToHex(currentHue,currentSaturation,currentLightness),opacity: currentOpacity,puck:lightnessPuckPosition});
        }

        drawColorPuck();
    };
  
    const handleLightnessSelection = (event) => {
      const mousePos = getMousePos(lightnessCanvasRef.current, event);
  
      if (mousePos.x >= 0 && mousePos.x <= lightnessCanvasRef.current.width &&
            mousePos.y >= 0 && mousePos.y <= lightnessCanvasRef.current.height) {
            const lightnessCanvas = lightnessCanvasRef.current.getContext('2d');
            const imageData = lightnessCanvas.getImageData(mousePos.x, mousePos.y, 1, 1).data;
            const hsl = rgbToHsl(imageData[0], imageData[1], imageData[2]);
    
            // Preserve hue while changing saturation and lightness
            setCurrentSaturation(Math.round(hsl[1]));
            setCurrentLightness(Math.round(hsl[2]));
            const hex = hslToHex(currentHue,hsl[1],hsl[2]);

            if(props.setValue){
                props.setValue({hex,opacity: currentOpacity,puck:lightnessPuckPosition});
            }
            // if(hex === "#ffffff") {
            //     console.log("owo",`${hsl[0]}, ${hsl[1]}, ${hsl[2]}`)
            //     console.log("imageData",imageData)
            // }
            
            setLightnessPuckPosition({ x: mousePos.x, y: mousePos.y });
            drawOpacityControl();
            drawLightnessPuck();
            // drawLightnessPuck(mousePos.x,mousePos.y );
      } else {
            let xPos = Math.max(0, Math.min(lightnessCanvasRef.current.width-4, mousePos.x));
            let yPos = Math.max(0, Math.min(lightnessCanvasRef.current.height, mousePos.y));

            const lightnessCanvas = lightnessCanvasRef.current.getContext('2d');
            const imageData = lightnessCanvas.getImageData(xPos, yPos, 1, 1).data;
            
            let hsl = rgbToHsl(imageData[0], imageData[1], imageData[2]);

            setCurrentSaturation(Math.round(hsl[1]));
            setCurrentLightness(Math.round(hsl[2]));
            if(props.setValue){
                props.setValue({hex: hslToHex(currentHue,currentSaturation,currentLightness),opacity: currentOpacity,puck:lightnessPuckPosition});
            }

            setLightnessPuckPosition({ x: xPos, y: yPos });
            drawOpacityControl();
            drawLightnessPuck();
        }
    };
  
    const handleOpacitySelection = (event) => {
      const mousePos = getMousePos(opacityCanvasRef.current, event);
      setCurrentOpacity(Math.max(0, Math.min(1, mousePos.x / opacityCanvasRef.current.width)));
      if(props.setValue){
          props.setValue({hex: hslToHex(currentHue,currentSaturation,currentLightness),opacity: currentOpacity,puck:lightnessPuckPosition});
      }
    };
  
    const getMousePos = (canvas, event) => {
      const rect = canvas.getBoundingClientRect();
      return { x: event.clientX - rect.left, y: event.clientY - rect.top };
    };

    function hslToCanvasCoords(h, s, l, canvasWidth, canvasHeight) {
        // Normalize HSL values to be in range 0-1
        const normalizedH = (h % 360) / 360;
        const normalizedS = s / 100;
        const normalizedL = l / 100;
      
        // Directly map hue to the X-axis (horizontal position)
        const x = Math.floor(normalizedH * canvasWidth);
      
        // Saturation affects Y position in terms of intensity (top to bottom)
        const y = Math.floor((1 - normalizedS) * (canvasHeight * 0.5) + (normalizedL * canvasHeight * 0.5));
      
        return { x, y };
    }

    function findHSLColorPosition(canvas, targetHSL) {
        const { h, s, l } = targetHSL;
      
        // Map the target HSL value to canvas coordinates
        const { x, y } = hslToCanvasCoords(h, s, l, canvas.width, canvas.height);
      
        // Return the calculated position directly
        return { x, y };
      }


    useEffect(() => {
        const handleMouseMove = (e) => {
            drag(e, 'color');
            drag(e, 'lightness');
            drag(e, 'opacity');
        };
    
        const handleTouchMove = (e) => {
            drag(e.touches[0], 'color');
            drag(e.touches[0], 'lightness');
            drag(e.touches[0], 'opacity');
        };
    
        const handleMouseUp = () => stopDragging();
        const handleTouchEnd = () => stopDragging();
    
        // Listen for mouse/touch events globally
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('mouseup', handleMouseUp);
        window.addEventListener('touchend', handleTouchEnd);
    
        // Cleanup on unmount or state change
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('touchmove', handleTouchMove);
            window.removeEventListener('mouseup', handleMouseUp);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, []);
  

    
  // Check if refs are available after the popover is opened
    const [isRendered, setIsRendered] = useState(false);
    useEffect(() => {
        if (props.open) {
            // Wait for the next render cycle to check if refs are available
            const checkRefs = () => {
                if (colorCanvasRef.current && lightnessCanvasRef.current) {
                    setIsRendered(true);
                }
            };
            
            if(props.value && props.value.hex) {
                const val = hexToHsl(props.value.hex);
                setCurrentHue(val[0])
                setCurrentSaturation(val[1])
                setCurrentLightness(val[2])
                if(props.value.opacity) {
                    setCurrentOpacity(props.value.opacity);
                }
            }

            // Run the check after the popover opens
            const timeout = setTimeout(checkRefs, 1); // You can adjust the timeout if needed
            return () => clearTimeout(timeout); // Clean up on component unmount
        } else {
            setIsRendered(false); // Reset if popover is closed
        }
    }, [props.open]);
    

    useEffect(() => {
        if (isRendered) {
            if(lightnessCanvasRef.current) {
                
                const lightnessCanvas = lightnessCanvasRef.current;
                const container = lightnessCanvas.parentElement;

                lightnessCanvas.width = container.clientWidth;
                lightnessCanvas.height = 210; // Set height directly or calculate if needed
                

                // const {x,y} = findHSLColorPosition(lightnessCanvas, {h:currentHue,s:currentSaturation,l:currentLightness});
                // // if(props.value && props.)
                // setLightnessPuckPosition({x:x,y:y});
                // setHasBeenSet(true);
            }
            if(opacityCanvasRef.current) {
                const opacityCanvas = opacityCanvasRef.current;
                const container = opacityCanvas.parentElement;

                opacityCanvas.width = container.clientWidth-48;
                // lightnessCanvas.height = 210; // Set height directly or calculate if needed

            }
            redrawCanvases();
        }
    }, [isRendered]);
    

    return (
        <PopoverModal
            // onMouseEnter={handlePopoverEntered}
            {...props}
            triangle={{position:"center",color:theme.palette.mode === "dark" ? colors.grey[300] : "#fff"}}
        >
            <Box className="color-picker"
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1.5,
                    py:3,
                    background:"white",
                }}
            >
                <Box 
                    sx={{
                        display:"flex",
                        justifyContent:"flex-start",
                        alignItems:"stretch",
                        gap:1.5,
                        px:3,
                        // height:250,

                        "& .hue-canvas" : {
                            // width:12,
                            width:28,
                            overflow:"hidden",
                            borderRadius:2,
                            flexShrink:0,
                            marginLeft:"-8px",
                        },
                        "& .color-canvas" : {
                            flex:1,
                            overflow:"hidden",
                            borderRadius:2,
                            // maxWidth:320,
                        }
                    }}
                >
                    <Box className="hue-canvas"
                        sx={{
                            height:210,
                            position:'relative',
                            display:"flex",
                            justifyContent:"center",
                            // width:28,
                            "& .hue-canvas-actually" : {
                                overflow:"hidden",
                                borderRadius:2,

                            }
                        }}
                    >
                        <canvas
                            className="hue-canvas-actually"
                            ref={colorCanvasRef}
                            height="210"
                            width="12"
                            onMouseDown={(e) => startDragging(e, 'color')}
                            onTouchStart={(e) => startDragging(e.touches[0], 'color')}
                            onMouseMove={(e) => drag(e, 'color')}
                            onTouchMove={(e) => drag(e.touches[0], 'color')}
                        
                        />
                        <canvas
                          className="puck-canvas"
                          ref={huePuckCanvasRef}
                          height="210"
                          width="28"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            pointerEvents: 'none' // Disable interaction on this canvas
                          }}
                        />

                    </Box>
                    <Box className="color-canvas"
                        sx={{
                            position:'relative',
                            height:210,
                        }}
                    >
                        <canvas
                            className="color-canvas"
                            ref={lightnessCanvasRef}
                            // width="300"
                            height="210"
                            onMouseDown={(e) => startDragging(e, 'lightness')}
                            onTouchStart={(e) => startDragging(e.touches[0], 'lightness')}
                            onMouseMove={(e) => drag(e, 'lightness')}
                            onTouchMove={(e) => drag(e.touches[0], 'lightness')}
                        
                        />
                        <canvas
                          className="puck-canvas"
                          ref={lightnessPuckCanvasRef}
                          height="210"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            pointerEvents: 'none' // Disable interaction on this canvas
                          }}
                        />
                         
                    </Box>
                </Box>
                <Box
                    sx={{
                        height:18,
                        px:3,
                        "& canvas" : {
                            width:1,
                            // ml:3,
                            overflow:"hidden",
                            borderRadius:2,
                        }
                    }}
                >
                    <canvas
                        ref={opacityCanvasRef}
                        // width="300"
                        height="12"

                        onMouseDown={(e) => startDragging(e, 'opacity')}
                        onTouchStart={(e) => startDragging(e.touches[0], 'opacity')}
                        onMouseMove={(e) => drag(e, 'opacity')}
                        onTouchMove={(e) => drag(e.touches[0], 'opacity')}
                    
                    />
                </Box>

                <Box
                    sx={{
                        mx:3,
                        mt:1.5,
                        height:32,
                        background: colors.bg['tertiary'],
                        borderRadius:5,
                        p:0.5,
                        display:"flex",
                        justifyContent:"space-between",
                    }}
                >
                    <DropdownBase
                        key={colorType}
                        stopPropagation
                        displayName={"name"}
                        selected={colorType}
                        onChange={(selected) => {
                            setColorType(selected)
                        }}
                        options={colorTypeArray} // Only unlink when no stock on location
                        closeOnSelection
                        styles={{
                            height: 32
                        }}
                        disallowDeselection
                    >
                        <Box
                            sx={{
                                // px:1.5,
                                width:75,
                                textAlign:"center",
                                background: colors.grey[200],
                                height:24,
                                borderRadius:3,
                                color: colors.txt["primary"],
                                fontWeight:500,
                                fontFamily:"Roboto,sans-serif",
                                cursor:"pointer"
                            }}
                        >
                            {colorTypeArray[colorType]}
                        </Box>
                    </DropdownBase>
                    <Box
                        sx={{
                            marginRight:"auto",
                            flex:1,
                            pl:1.5,
                            overflow:"hidden",
                            whiteSpace:"nowrap",
                            wordBreak:"keep-all",
                            textOverflow:"ellipsis",
                            maxWidth:220,
                            
                            // background:"blue"
                        }}
                    >

                        {colorTypeArray[colorType] === ColorTypeMap.HEX && hslToHex(currentHue,currentSaturation,currentLightness)}
                        {colorTypeArray[colorType] === ColorTypeMap.HSL && `${currentHue}, ${currentSaturation}, ${currentLightness}`}

                        {colorTypeArray[colorType] === ColorTypeMap.RGB && (
                            (() => {
                                const rgb = hslToRgb(currentHue, currentSaturation, currentLightness);
                                return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`; // You can replace this with whatever you want to render
                            })()
                        )}

                        {colorTypeArray[colorType] === ColorTypeMap.HSBV && (
                            (() => {
                                const rgb = hslToHsb(currentHue, currentSaturation, currentLightness);
                                return `${rgb[0]}, ${rgb[1]}, ${rgb[2]}`; // You can replace this with whatever you want to render
                            })()
                        )}
                    </Box>
                    <Box>
                        <InputBase
                            sx={{
                                maxWidth:50,
                                // background:"orange",
                            }}
                            value={currentOpacity*100}
                        />
                        %
                    </Box>
                    
                </Box>
            </Box>
        </PopoverModal>
    );
};
  