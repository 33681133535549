import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import WarehouseProductCard from "../../../../../components/global/cards/products/warehouse/WarehouseProductCard";
import MpAddProduct from '../../../../../components/global/ModalsProgression/MpAddProduct/MpAddProduct';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShCopyButton from '../../../../../components/theme/ShCopy';
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../../components/theme/text/Text";
import { useGetProductsQuery } from "../../../../../newapi/warehouse/productSlice";
import { tokens } from "../../../../../theme";
import { selectUserPermission } from "../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../utils/permissions';

const SpStockDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails"));

const GridActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t("new_product")}
            </Shbutton>
            )}
        </ButtonGroup>
    );
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};


const ProductOverview = (props) => {
    const { t,i18n } = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [config, setConfig] = useState({isVirtual: false});
    const [isExtended, setIsExtended] = useState(false);
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const [spStockLoading, setSpStockLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.PRODUCT_MANAGE])
    );

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>,
        });

        return () => {
            
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            if(!spStockLoading){
                setSpStockLoading(true);
            }
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        
            setIsExtended(true);
    };
    

    const columns = [
        {
            field: "sku",
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
                }
                return (
                    // <Box>
                    //     <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.sku}</Text>
                    // </Box>

                    <Box sx={{
                        width:1,
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.sku}</Text>
                        <ShCopyButton className={"copyel"} closeAfter={1500} value={params.row.sku}></ShCopyButton>
                    </Box>
                );
            }
        },
        {
            field: "description",
            headerName: t("description"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Text noBreak>{params.row.description}</Text>
                )
            },
        },
        {
            field: "barcode",
            headerName: t("barcode"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box sx={{
                        width:1,
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text clickable >{params.row.barcode}</Text>
                        <ShCopyButton color={colors.txt["primary"]} className={"copyel"} closeAfter={1500} value={params.row.barcode}></ShCopyButton>
                    </Box>
                );
                // return params.row.barcode
            },
        },
        {
            field: "secondary_sku",
            headerName: t("secondary_sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.secondary_sku
            },
        },
        ...isFulfilment ? [{
            field: "relation",
            headerName: t("relation"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.relation ? params.row.relation.name : t('unknown'));
            },
        }] : []
        ,
        {
            field: "stock_quantity",
            headerName: t("stock_quantity"),
            flex:1,
            sortable:false,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.stock && params.row.stock.total_quantity ? params.row.stock.total_quantity : 0);
            },
        },
        {
            field: "stock_available",
            headerName: t("stock_available"),
            flex:1,
            sortable:false,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.stock && params.row.stock.total_available ? params.row.stock.total_available : 0);
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseProductCard onClick={() => getProductDetails(cellValues)} skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetProductsQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>} 
                onRowClick={getProductDetails}
                title={t('overview')} 
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns}>
            </ShDataGrid2>
            {spStockLoading && 
                <SpStockDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    productId={productId}
                />
            }
            
            <MpAddProduct onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}
export default ProductOverview;