import { Box, useTheme } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import ShLabel from "../../../../theme/label/ShLabel";
import Text from "../../../../theme/text/Text";

import React, { useEffect, useRef, useState } from "react";
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import { useTranslation } from "react-i18next";
import { useGetOrderLinesQuery, useReserverOrderLineMutation } from "../../../../../newapi/order/ordersSlice";
import { showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../../../utils/toasts";
import OrderLinesCard from "../../../../global/cards/orders/OrderLinesCard";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";
import ShLabelSkeleton from "../../../../theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
// import SPStockDetails from "../../SpStockDetails/SPStockDetails";
import { getOrderStateData } from "../../../../../utils/labelColorText";
import SidepanelLoader from "../../../../theme/sidepanel2/SidepanelLoader";

const SpStockDetails = SidepanelLoader(() => import("../../SpStockDetails/SPStockDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>{t("save")}</Shbutton>
        </ButtonGroup>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    

};

const OrderLines = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isExtended, setIsExtended] = useState(false);
    const [spStockLoading, setSpStockLoading] = useState();

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.product.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            if(!spStockLoading){
                setSpStockLoading(true);
            }
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
            setIsExtended(true);
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const [ReserveOrderLine,{syncIsLoading}] = useReserverOrderLineMutation();
    const handleReserveOrderLine = async (params) => {
        try {
            const response = await ReserveOrderLine(params).unwrap();
            showToastMessageUpdateRequest(t, response);
        } catch (err) {
            showToastMessageRequestError(t("reserve_failed"), t, err);
        }
    };

    const columns = [
        {field: "amount", headerName: t("quantity"), renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box paddingLeft={0}>
                    <Text semibold>{params.row.amount}</Text>
                </Box>
            )
        }},
        {field: "product.description", headerName: t("description"), flex:1, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Text noBreak semibold>{params.row.product.description}</Text>
            )
        }},
        {field: "product.sku", headerName: t("sku"),flex:1,renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Box onClick={() => getProductDetails(params.row)}>
                    <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product.sku}</Text>
                </Box>
            )
        }},
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                
                const { labelcolor, labeltext } = getOrderStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
    ];

    
    columns.push({
        field: "phoneView", 
        headerName: "phoneView",
        cellClassName: "phone-column--cell",
        renderCell: (params) => {
            return (
                <OrderLinesCard onClick={() => getProductDetails(params.row)} skeleton={params.row.skeleton ?? false} {...params.row}/>
            )
        }
    });
    
    return (
        <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrderLinesQuery}
                itemId={props.data}
                title={t("orderlines") }
                gridOptions={gridOptions} 
                isSidepanelGrid 
                columns={columns}
            />

            {spStockLoading && 
                <SpStockDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    productId={productId}
                />
            }
            
        </Box>
    );
}

export default OrderLines;