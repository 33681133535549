
import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const SEARCH_BASE = "/search";
const buildUrl = (endpoint) => SEARCH_BASE + endpoint;


const searchSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        search: builder.query({
            query: (body) => ({
                url: buildUrl(`/global`),
                method: 'POST',
                body: body,
            }),
            providesTags: (result, error, arg) => [{ type: `SEARCH` }],
        }),

    })
});

export const {
    useSearchQuery,
  } = searchSlice;
