import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const ACCOUNTINGSOFTWARE_BASE = "/accountingsoftware";
const buildUrl = (endpoint) => ACCOUNTINGSOFTWARE_BASE + endpoint;
const API_TAG = 'AccountingSoftware';
const API_TAG_SINGLE = 'AccountingSoftwareSingle';
const API_TAG_LEDGERS = 'AccountingSoftwareLedgers';
const API_TAG_TAXRATES = 'AccountingSoftwareTaxRates';
const API_TAG_RELATIONS = 'AccountingSoftwareRelations';

const accountingSoftwareSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAccountingSoftwares: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: () => [{ type: API_TAG }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getAccountingSoftwares', API_TAG)
        }),
        getAccountingSoftware: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: API_TAG_SINGLE, id: arg }]
        }),
        addAccountingSoftware: builder.mutation({
            query: accountingSoftwareInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: accountingSoftwareInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG}],
        }),
        updateAccountingSoftware: builder.mutation({
            query: accountingSoftwareInfo => ({
                url: buildUrl(`/${accountingSoftwareInfo.id}`),
                method: 'PUT',
                body: accountingSoftwareInfo,
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: API_TAG_SINGLE, id: arg.id }],
        }),
        deleteAccountingSoftware: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: API_TAG_SINGLE, id: arg.id }],
        }),
        syncAccountingSoftware: builder.mutation({
            query: syncInfo => ({
                url: buildUrl(`/${syncInfo.id}/sync/${syncInfo.type}`),
                method: 'POST',
            }),
            invalidatesTags: (result, error, arg) => [{type: API_TAG }, { type: API_TAG_SINGLE, id: arg.id }, { type: API_TAG_LEDGERS, id: arg.id }, { type: API_TAG_TAXRATES, id: arg.id }, { type: API_TAG_RELATIONS, id: arg.id }],
        }),
        getAccountingSoftwareLedgers: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/ledgers?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: API_TAG_LEDGERS }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getAccountingSoftwareLedgers', API_TAG_LEDGERS)
        }),
        getAccountingSoftwareLedgersSelect: builder.query({
            query: id => ({
                url: buildUrl(`/${id}/ledgers/select`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: API_TAG_LEDGERS }],
        }),
        getAccountingSoftwareTaxRates: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/taxrates?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: API_TAG_TAXRATES}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getAccountingSoftwareTaxRates', API_TAG_TAXRATES)
        }),
        getAccountingSoftwareTaxRatesSelect: builder.query({
            query: id => ({
                url: buildUrl(`/${id}/taxrates/select`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: API_TAG_TAXRATES }],
        }),
        getAccountingSoftwareRelations: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`/${args.id}/relations?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: API_TAG_RELATIONS }],
            onQueryStarted: muiDataGridStarted.bind(null, 'getAccountingSoftwareRelations', API_TAG_RELATIONS)
        }),
    })
});

export const {
    useGetAccountingSoftwaresQuery,
    useGetAccountingSoftwareQuery,
    useAddAccountingSoftwareMutation,
    useUpdateAccountingSoftwareMutation,
    useSyncAccountingSoftwareMutation,
    useDeleteAccountingSoftwareMutation,
    useGetAccountingSoftwareLedgersQuery,
    useGetAccountingSoftwareTaxRatesQuery,
    useGetAccountingSoftwareRelationsQuery,
    useGetAccountingSoftwareLedgersSelectQuery,
    useGetAccountingSoftwareTaxRatesSelectQuery,
  } = accountingSoftwareSlice;
