import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import { tokens } from "../../../../theme";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ShDoubleTextSkeleton from "../../../theme/skeleton/ShDoubleTextSkeleton";
import ShLabel from "../../../theme/label/ShLabel";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import { CheckCircleOutlined } from "@mui/icons-material";

export const WarehouseCountingStrategiesCard = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let labelTypeColor = "grey";
    if(props.type === "picking_locations"){
        labelTypeColor = "pink";
    }
    else if(props.type === "bulk_locations"){
        labelTypeColor = "orange";
    }
    else if(props.type === "all_locations"){
        labelTypeColor = "green";
    }
    else if(props.type === "inventory_turnover_ratio"){
        labelTypeColor = "purple";
    }
    let iDate = new Date(props.createdAt);
    let date = iDate.toLocaleDateString(i18n.language);


    let statusLabelColor = props.state === "active" ? theme.palette.mode === "dark" ? "blue" : "primary" : "green";
                
    let statusContent;
    if(props.state === "active") {
        statusContent = t(props.state);
    } else {
        if (!props.completedAt || props.completedAt === null) {
            statusContent = "-";
        }
        let completedDate = new Date(props.completedAt);
        statusContent = completedDate.toLocaleDateString(i18n.language);
    }

    return (
        <BaseCard sx={{
            pl:2,
            pr:0,
            pt:0,
            pb:2,
        }} onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                >
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.name}</Text>
                                    <Text>{t(props.frequency)}</Text>
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                            }}
                        >
                            <CardLabel
                                sx={{
                                    ...(!props.skeleton && props.state !== 'active' && {
                                        display:"flex",
                                        alignItems:"center",
                                        justifyContent:"center",
                                        pr:0,
                                        gap:1,
                                    }),
                                }}
                                variant={statusLabelColor}  
                                position="child-tr"
                            >
                                {props.skeleton ? 
                                    <ShTextSkeleton/> 
                                    :
                                    <>
                                        <Text bold sx={{ml:"auto"}} color={statusLabelColor} center>
                                            {statusContent}
                                        </Text>
                                        {props.state !== "active" &&
                                            <Box
                                                sx={{
                                                    color:colors['green'][400],
                                                    height:24,
                                                    width:24,
                                                    // color:"white",
                                                    mr:0.5,
                                                    borderRadius:4
                                                }}
                                            >
                                                <CheckCircleOutlined/>
                                            </Box>
                                        }
                                    </>
                                }
                            </CardLabel>
                        </Box>
                    </Box>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text noBreak light>{t("created_on")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text noBreak>
                                    {date}
                                </Text>
                            }
                        </Box>

                        <Box display={"flex"} flexDirection={"column"}>
                            <Text noBreak light>{t("amount")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text bold>
                                    {props.amountOfSteps}
                                    {/* {props.available} */}
                                    {/* {props.stock && props.stock.total_available ? props.stock.total_available : 0} */}
                                </Text>
                            }
                        </Box>

                        <Box 
                            sx={{
                                ml:"auto",
                                mt:"auto",
                                mr:2,
                            }}
                        >
                            <ShLabel borderless variant={labelTypeColor} palette="normal" fitted size={32}>
                                {/* {props.warehouseLocation?.location} */}
                                {t(props.type)}
                            </ShLabel>
                        </Box>
                    </Box>
                    
                </Box>
            </Box>
        </BaseCard>
    )
}


export const WarehouseCountingStrategyObjectsCard = (props) => {
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let status = props.state;
    let labelcolor = theme.palette.mode === "dark" ? "greyLight" : "grey";
    let labeltext = "";
    switch(status){
        case "active":
            labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
            labeltext = t('active');
            break;
        case "completed":
            labelcolor = "green"
            labeltext = t('completed');
            break;
        case "deleted":
            labelcolor = "red"
            labeltext = t('deleted');
            break;
        default:
            labelcolor = "orange"
            labeltext = t('unknown');
            break;
    }

    const date = useMemo(() => {
        return props?.createdAt && props.createdAt !== null
          ? new Date(props.createdAt).toLocaleDateString(i18n.language)
          : "-";
      }, [props?.createdAt, i18n.language]);

    return (
        <BaseCard sx={{
            pl:2,
            pr:0,
            pt:0,
            pb:2,
        }} onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                >
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                        }}
                    >
                        <Box
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.warehouseLocation ? props.warehouseLocation.location : props.product.sku}</Text>
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                            }}
                        >
                            <CardLabel
                                variant={labelcolor}  
                                position="child-tr"
                            >
                                {props.skeleton ? 
                                    <ShTextSkeleton/> 
                                    :
                                    labeltext
                                }
                            </CardLabel>
                        </Box>
                    </Box>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text noBreak light>{t("created_at")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text noBreak>
                                    {date}
                                </Text>
                            }
                        </Box>

                    </Box>
                    
                </Box>
            </Box>
        </BaseCard>
    )
}
