import { muiDataGridStarted } from "../../apiutils";
import { apiSlice } from "../../createApiSlice";

const SUPPLIERS_BASE = "/suppliers";
const buildUrl = (endpoint) => SUPPLIERS_BASE + endpoint;
const ApiName = "Suppliers";

const supplierApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSuppliers: builder.query({
            query: ({ args, invalidate }) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body,
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}`}],
            onQueryStarted: muiDataGridStarted.bind(null, 'getSuppliers', `${ApiName}`)
        }),
        getSuppliersSelect: builder.query({
            query: () => ({
                url: buildUrl(`/`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}`}],
        }),
        getSupplier: builder.query({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'GET'
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg }]
        }),
        addNewSupplier: builder.mutation({
            query: supplierInfo => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: supplierInfo,
            }),
            invalidatesTags: [{type: `${ApiName}`}],
        }),
        editSupplier: builder.mutation({
            query: supplierInfo => ({
                url: buildUrl(`/${supplierInfo.id}`),
                method: 'PUT',
                body: supplierInfo,
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
        deleteSupplier: builder.mutation({
            query: id => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, arg) => [{ type: `${ApiName}Single`, id: arg.id }, {type: `${ApiName}`}],
        }),
    })
});

export const {
    useGetSuppliersQuery,
    useGetSuppliersSelectQuery,
    useGetSupplierQuery,
    useAddNewSupplierMutation,
    useEditSupplierMutation,
    useDeleteSupplierMutation,
  } = supplierApiSlice;