import { Box, useTheme } from "@mui/material";
import BaseCard from "../../../theme/cards/BaseCard";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import { tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";
import ShDoubleTextSkeleton from "../../../theme/skeleton/ShDoubleTextSkeleton";
import { PhoneInTalk } from "@mui/icons-material";

export const WarehouseRelationsCard = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t,i18n } = useTranslation();
    // const color = props.isConcept ? "orange" : "green";

    // let date = props.startDate && props.startDate !== null ? new Date(props.startDate) : undefined;

    // let myDate = date ? date.toLocaleDateString(i18n.language) : "-";
              
    const street = props.street ? props.street : "";
    const house = props.house_nr ? props.house_nr : "";
    const extra = props.house_nr_extra ? props.house_nr_extra : "";
    const postal = props.postal ? props.postal : "";
    const city = props.city ? props.city : "";

    return (

        <BaseCard sx={{
            pl:2,
            pr:0,
            pt:0,
            pb:2,
        }} onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                >
                    <Box display={"flex"}
                        sx={{
                            gap:3,
                            overflow:"hidden",
                        }}
                    >
                        <Box
                            sx={{
                                // minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                pt:2,
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ? 
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    <Text noBreak bold>{props.name}</Text>
                                    <Text light>{props.email}</Text>
                                </>
                            }
                        </Box>
                        
                        <Box
                            sx={{
                                width:"fit-content",
                                display:"flex",
                            }}
                        >

                            <CardLabel hasright variant={theme.palette.mode === "dark" ? "greyLight" : "grey"} colorflip icon position="child-tr">
                                <PhoneInTalk fontSize="small"/>
                            </CardLabel>
                            <CardLabel hasleft variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}  position="child-tr">
                                {props.skeleton ? 
                                    <ShDoubleTextSkeleton/> 
                                    :
                                    props.phone
                                }
                            </CardLabel>
                        </Box>
                    </Box>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            {props.skeleton ?
                                <ShDoubleTextSkeleton />
                                :
                                <>
                                    <Text semibold>
                                        {street} {house}{extra}
                                    </Text>
                                    <Text>
                                        {postal}, {city}
                                    </Text>
                                </>
                            }
                        </Box>

                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("channels")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.webshopCredentials ? props.webshopCredentials.length : 0}
                                    {/* {props.stock && props.stock.total_available ? props.stock.total_available : 0} */}
                                </Text>
                            }
                        </Box>

                        {/* <Box 
                            sx={{
                                ml:"auto",
                                mt:"auto",
                                mr:2,
                            }}
                        >
                            <ShLabel variant={"yellow"} fitted size={32}>
                                {props.warehouseLocation?.location}
                            </ShLabel>
                        </Box> */}
                    </Box>
                    
                </Box>
            </Box>
        </BaseCard>
    )
}
