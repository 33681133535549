import { Box, useTheme } from "@mui/material"
import { useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import { useTranslation } from "react-i18next";
import { useAddWarehouseLocationTypeMutation } from "../../../newapi/warehouse/warehouseLocationTypeSlice";
import { showToastMessagePostRequest, showToastMessageRequestError } from "../../../utils/toasts";
import { InputDropdown } from "../../theme/dropdowns/InputDropdown";
import { useGetEnumsQuery } from "../../../newapi/global/generalApiSlice";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import ShValidatedInputDropdown from "../../theme/dropdowns/ShValidatedInputDropdown";
import { yupRequired } from "../../../utils/validation";
import NumberInput from "../../theme/inputs/NumberInput";

const MAddWarehouseLocationType = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [selected, setSelected] = useState(-1);
    const [addWarehouseLocationType,{isLoading}] = useAddWarehouseLocationTypeMutation();

    const select = (selected) => {
        setSelected(selected);
    }

    const [warehouseLocationTypeInfo, setWarehouseLocationTypeInfo] = useState({
        description: "",
        type: "",
        warehouseId: null,
        width: 0,
        length: 0,
        height: 0,
        maxWeight: 0
    });
      
    const handleClose = () => {
      setWarehouseLocationTypeInfo({
          description: "",
          type: "",
          warehouseId: null,
          width: 0,
          length: 0,
          height: 0,
          maxWeight: 0
      });
      props.handleClose();
  }

    const [fieldErrors, setFieldErrors] = useState({});

    const rules = {
        description: yupRequired('description', t),
        type: yupRequired('type', t)
    };
        
    const schema = yup.object().shape(rules);
      
    const { data: globalEnums, enumIsLoading } = useGetEnumsQuery();
   
    const SaveWarehouseLocationType = async (warehouseLocationTypeInfo, props) => {
        try {
            if (isLoading) return;
            setFieldErrors({});
            await schema.validate(warehouseLocationTypeInfo, { abortEarly: false });

            const data = await addWarehouseLocationType(warehouseLocationTypeInfo).unwrap();
            showToastMessagePostRequest(t, data);

            handleClose();
        } catch (err) {
            if (err.name === 'ValidationError') {
                // Create an errors object where keys are field names and values are error messages
                const errors = err.inner.reduce((acc, currentError) => ({
                  ...acc,
                  [currentError.path]: currentError.message
                }), {});
          
                // Set the validation errors in the state
                setFieldErrors(errors);
              } else {
                // Handle other errors, such as network errors
                showToastMessageRequestError(t("create_failed"), t, err);
              }
        }
    };
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    const handleChange = (key, value) => {
        setWarehouseLocationTypeInfo({
          ...warehouseLocationTypeInfo,
          [key]: value
        });
        setFieldErrors({
            ...fieldErrors,
            [key]: ''
          });
      };

    return(
        <SHModal
            open={props.open}
            onClose={handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}>
                <Subheading>{t("add_warehouse_location_type")}</Subheading>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    padding:3,
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2
                }}
            >
                

                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("description")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                            borderRadius:4,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInput
                            name="description"
                            value={warehouseLocationTypeInfo.description}
                            onChange={handleChange}
                            error={fieldErrors.description}
                        />
                        {/* <InputBase 
                            sx={{
                                px:2,
                                flex:1, 
                                color:colors.txt["primary"],
                                lineHeight:"32px",
                            }}
                            value={warehouseLocationTypeInfo.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                            /> */}
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("type")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            height:32
                        }}
                    >
                        <ShValidatedInputDropdown
                            name="type"
                            displayName="name"
                            changeField="value"
                            onChange={handleChange}
                            noSelection={t("choose_option")} 
                            options={globalEnums ? globalEnums["warehouselocationtype.type"].map((item, index) => ({ id: index, name: t(item), value: item })) : []}
                            selected={globalEnums ? globalEnums["warehouselocationtype.type"].findIndex(item => item === warehouseLocationTypeInfo.type) ?? -1 : -1}
                            closeOnSelection
                            error={fieldErrors.type}
                        />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("length")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            height:32
                        }}
                    >
                    <NumberInput
                        value={warehouseLocationTypeInfo?.length}
                        change={(e) => handleChange('length', e)}
                    />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("width")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            height:32
                        }}
                    >
                    <NumberInput
                        value={warehouseLocationTypeInfo?.width}
                        change={(e) => handleChange('width', e)}
                    />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("height")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            height:32
                        }}
                    >
                    <NumberInput
                        value={warehouseLocationTypeInfo?.height}
                        change={(e) => handleChange('height', e)}
                    />
                    </Box>
                </Box>
                <Box 
                    sx={{
                        width:1,
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        gap:7
                    }}
                >

                    <Box
                        sx={{
                            width:120,
                        }}
                    >
                        <Text light>{t("max_weight")}</Text>
                    </Box>
                    
                    <Box
                        sx={{
                            flex:1,
                            display:"flex",
                            height:32
                        }}
                    >
                    <NumberInput
                        value={warehouseLocationTypeInfo?.maxWeight}
                        change={(e) => handleChange('maxWeight', e)}
                    />
                    </Box>
                </Box>
            </Box>
            <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton isLoading={isLoading} className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveWarehouseLocationType(warehouseLocationTypeInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box>
        </SHModal>
    )
}

export default MAddWarehouseLocationType;
