// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import cx from "classnames"

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };

    return (
        <div>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:280,
                        flexShrink:0,
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text>{t("create_contacts_during_sync")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,
                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                        color={data.syncCreateContacts === 'true' ? "green" : "red"}
                        value={data.syncCreateContacts === 'true' ? "true" : "false"}
                        exclusive
                        onChange={(e,value) => handleInput("syncCreateContacts", value)}
                        aria-label="Active"
                        className={cx(`${data.syncCreateContacts}`, {
                            'active': data.syncCreateContacts === 'true'
                        })}

                        sx={{
                            width:1,
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                width:1,
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('inactive')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                width:1,
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('active')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                <Box
                    sx={{
                        width:280,
                        flexShrink:0,
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text>{t("auto_create_invoices")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,

                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                        color={data.autoCreateInvoices === 'true' ? "green" : "red"}
                        value={data.autoCreateInvoices === 'true' ? "true" : "false"}
                        exclusive
                        onChange={(e,value) => handleInput("autoCreateInvoices", value)}
                        aria-label="Active"
                        className={cx(`${data.autoCreateInvoices}`, {
                            'active': data.autoCreateInvoices === 'true'
                        })}
                        sx={{
                            width:1,
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                width:1,
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('inactive')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                width:1,
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('active')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Box 
                sx={{
                    width:1,
                    paddingTop: 2,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:7
                }}
            >
                
                <Box
                    sx={{
                        width:280,
                        flexShrink:0,
                        display:"flex",
                        alignItems:"center"
                    }}
                >
                    <Text>{t("sync_finalize_invoices")}</Text>
                </Box>
                
                <Box
                    sx={{
                        flex:1,

                        borderRadius:4,
                        display:"flex",
                        height:32
                    }}
                >
                    <ToggleButtonGroup
                        color={data.syncFinalizeInvoices === 'true' ? "green" : "red"}
                        value={data.syncFinalizeInvoices === 'true' ? "true" : "false"}
                        exclusive
                        onChange={(e,value) => handleInput("syncFinalizeInvoices", value)}
                        aria-label="Active"
                        className={cx(`${data.syncFinalizeInvoices}`, {
                            'active': data.syncFinalizeInvoices === 'true'
                        })}
                        sx={{
                            width:1,
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                padding:0,
                                width:1,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('inactive')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                width:1,
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('active')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            </Box>
    </div>
    );
};

const Sync = (props) => {
    const data = props.data;
    const { t } = useTranslation();

    return (
        <ProgressionTab 
        tabId="tabSync" 
        subtitle={t("synchronisations")} 
        step={3}
        maxSteps={3}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Sync;

