
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import AllInboxOutlinedIcon from '@mui/icons-material/AllInboxOutlined';
import RoomIcon from '@mui/icons-material/Room';

import CallReceivedOutlinedIcon from '@mui/icons-material/CallReceivedOutlined';
import {ReactComponent as ReturnLogo} from '../styles/svg/return.svg';
import { getInboundStateData, getInvoiceStateData, getLocationsStateData, getOrderStateData, getProductsStateData } from './labelColorText';
import { getTranslatedDateAndTime } from '../utils';
import i18n from 'i18next';

export const search_keys = {
    orders : {
        titleAddon: 'order',
        icon: <CallMadeOutlinedIcon/>,
        getStateColor: (data,theme) => getOrderStateData(data,theme).labelcolor,
        formatSubTitle: (subtitle) => {
            if (i18n.isInitialized) {
                return i18n.t('webshop_reference') + " " + subtitle;
            }
            return subtitle;
            // i18n.t("webshop_reference") + subtitle
        },
    },
    invoices : {
        titleAddon: 'invoice',
        icon: <ReceiptLongIcon/>,
        getStateColor: (data,theme) => getInvoiceStateData(data,theme).labelcolor,
        formatSubTitle: (subtitle) => {
            try{
                let date = new Date(subtitle);
                if (date.getTime() === 0) {
                    return subtitle; // Return the original subtitle string if it's the epoch time
                }
                if (i18n.isInitialized) {
                    return date.toLocaleDateString(i18n.language);

                } else {
                    return date.toDateString();
                }
            } catch {
                return subtitle;
            }
        },
    },
    products : {
        titleAddon: 'sku',
        icon: <AllInboxOutlinedIcon/>,
        getStateColor: (data,theme) => getProductsStateData(data,theme).labelcolor,
        formatSubTitle: (subtitle) => subtitle,
    },
    locations: {
        titleAddon: 'location',
        icon: <RoomIcon/>,
        getStateColor: (data,theme) => getLocationsStateData(data,theme).labelcolor,
        formatSubTitle: (subtitle) => subtitle,
    },
    inbounds : {
        titleAddon: 'inbound',
        icon: <CallReceivedOutlinedIcon/>,
        getStateColor: (data,theme) => getInboundStateData(data,theme).labelcolor,
        // formatSubTitle: (subtitle) => subtitle,
        formatSubTitle: (subtitle) => {
            try{
                let date = new Date(subtitle);
                if (date.getTime() === 0) {
                    return subtitle; // Return the original subtitle string if it's the epoch time
                }
                if (i18n.isInitialized) {
                    return date.toLocaleDateString(i18n.language);

                } else {
                    return date.toDateString();
                }
            } catch {
                return subtitle;
            }
        },
    },
    returns : {
        titleAddon: 'return',
        icon: <ReturnLogo/>,
        getStateColor: (data,theme) => getInboundStateData(data,theme).labelcolor,
        // formatSubTitle: (subtitle) => subtitle,
        formatSubTitle: (subtitle) => {
            try{
                let date = new Date(subtitle);
                if (date.getTime() === 0) {
                    return subtitle; // Return the original subtitle string if it's the epoch time
                }
                if (i18n.isInitialized) {
                    return date.toLocaleDateString(i18n.language);

                } else {
                    return date.toDateString();
                }
            } catch {
                return subtitle;
            }
        },
    }
}