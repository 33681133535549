import OrdersInBackorderNotificationsComp from "./views/OrdersInBackorderNotificationsComp";
import OrdersInMaintenanceNotificationsComp from "./views/OrdersInMaintenanceNotificationsComp";
import OrdersNotImportedNotificationsComp from "./views/OrdersNotImportedNotificationsComp";
import StockDifferenceNotificationsComp from "./views/StockDifferenceNotificationsComp";
import InvoiceProductZeroPriceNotificationsComp from "./views/InvoiceProductZeroPriceNotificationsComp";

export const NOTIFICATION_COMPONENTS = {
    orders_in_backorder: {
        notifications_comp: OrdersInBackorderNotificationsComp,
    },
    orders_in_maintenance: {
        notifications_comp: OrdersInMaintenanceNotificationsComp
    },
    orders_not_imported_missing_products: {
        notifications_comp: OrdersNotImportedNotificationsComp
    },
    stock_difference: {
        notifications_comp: StockDifferenceNotificationsComp
    },
    invoice_product_zero_price: {
        notifications_comp: InvoiceProductZeroPriceNotificationsComp
    },
}
