import { hexToRgb } from "../../utils/colorConverters";
import { editableElements, editableSettings, page_keys, portal_keys } from "./config";

export const edit_register_return_keys = {
    SECTION: "section",
    HEADER: "header",
    ORDERNUMBER: "orderNumber",
    ORDERNUMBERTEXT: "orderNumberText",
    ORDERNUMBERINPUT: "orderNumberInput",
    EMAIL: "email",
    EMAILTEXT: "emailText",
    EMAILINPUT: "emailInput",
    REQUESTRETURNBUTTON: "requestReturnButton",
}

export const editRegisterReturnMap = {
    [edit_register_return_keys.SECTION]: portal_keys.SECTION,

    [edit_register_return_keys.HEADER]: portal_keys.HEADER_TEXT,

    [edit_register_return_keys.ORDERNUMBER]: portal_keys.INPUT_CONTAINER,
    [edit_register_return_keys.ORDERNUMBERTEXT]: portal_keys.INPUT_CONTAINER_TEXT,
    [edit_register_return_keys.ORDERNUMBERINPUT]: portal_keys.INPUT_CONTAINER_INPUT,

    [edit_register_return_keys.EMAIL]: portal_keys.INPUT_CONTAINER,
    [edit_register_return_keys.EMAILTEXT]: portal_keys.INPUT_CONTAINER_TEXT,
    [edit_register_return_keys.EMAILINPUT]: portal_keys.INPUT_CONTAINER_INPUT,

    [edit_register_return_keys.REQUESTRETURNBUTTON]: portal_keys.BUTTON,
}

export const defaultData = {
    savedColors: Array.from({ length: 12 }, () => editableElements.BACKGROUNDCOLOR),
}

export const createMap = (givenMap) => {
    const map = {};
    Object.keys(givenMap).forEach(key => {
        const portalKey = givenMap[key];
        const editableElements = editableSettings[portalKey]?.editable || [];
        const extraClasses = editableSettings[portalKey]?.extraClasses || {};

        const portalValueMap = {
            // Generate editableValues for the elements (set default empty values)
            editableValues: editableElements.reduce((acc, element) => {
                acc[element] = '';  // Set default empty value for each editable element
                return acc;
            }, {}),
        };

        // Only add extraClasses to the map if they exist and are non-empty
        if (Object.keys(extraClasses).length > 0) {
            portalValueMap.extraClasses = Object.entries(extraClasses).reduce((acc, [extraClassKey, editableElementsForClass]) => {
                // For each extra class, assign a default state for each editable element
                acc[extraClassKey] = editableElementsForClass.reduce((classAcc, element) => {
                    classAcc[element] = '';  // Default state for each editable element
                    return classAcc;
                }, {});
                
                // Optionally add 'visible' key to each extra class if needed
                acc[extraClassKey]['visible'] = false;
                return acc;
            }, {});
        }

        // Assign the final object to the map
        map[key] = portalValueMap;
    });

    return map;
}
export const masterStyleMap = {
    [page_keys.EDITREGISTERRETURN]: createMap(editRegisterReturnMap),
    [page_keys.DEFAULTDATA]: defaultData
};

export const transformStyleMapIfNeeded = (data) => {
    let updatedData = {};
    for (let mapKey in masterStyleMap) {
        if (data.hasOwnProperty(mapKey)) {
            updatedData[mapKey] = data[mapKey];
        } else {
            updatedData[mapKey] = createMap(masterStyleMap[mapKey]); 
        }
    }
  
    return updatedData;
  };


export const transformValue = (key, value) => {
    if (value && value.hex !== undefined && value.opacity !== undefined) {
        const rgb = hexToRgb(value.hex);
        if (rgb) {
            return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${value.opacity})`;
        }
    }
    if(value.length === 0) {
        return null;
    }
    return value;
};

export const transformEditableValues = (editableValues) => {
    const transformedValues = {};

    for (const key in editableValues) {
        const value = editableValues[key];

        transformedValues[key] = transformValue(key, value);
    }

    return transformedValues;
};