import { Box, useTheme } from "@mui/material"
import { tokens } from "../../../theme";


const SpDesktopFooter = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    return (
        <Box
            sx={{
                position:"absolute",
                bottom:0,
                right:0,
                padding:3,
                width:1,
                zIndex:2,
                background:theme.palette.mode === "dark" ? colors.bg["tertiary"] : colors.grey[0],
                "@media screen and (max-width: 63.9375em)" : {
                    width:"fit-content",
                    zIndex:2222
                },
                "@media screen and (max-width: 47.9375em)" : {
                    display:"none",
                }
            }}
        >
            {props.children}
        </Box>
    )
}

export default SpDesktopFooter;