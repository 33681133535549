import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../components/theme/buttons/Shbutton";

import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../components/theme/label/ShLabel";
import { useGetReturnsQuery } from "../../../../newapi/inbound/returnsSlice"; // Changed from inboundsSlice to returnsSlice
// import ReturnsCard from "../../../../components/global/cards/returns/ReturnsCard"; // Changed from InboundsCard to ReturnsCard
import { PhonelinkOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RelationReturnCard } from "../../../../components/global/cards/inbounds/ReturnCards";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../components/theme/sidepanel2/SidepanelLoader";
import ShLabelSkeleton from "../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetRelationQuery } from "../../../../newapi/global/relation/relationSlice";
import { getInboundStateData } from '../../../../utils/labelColorText';

const SpReturnDetails = SidepanelLoader(() => import("../../../../components/global/Sidepanels/SpReturnDetails/SpReturnDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return <></>;
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};

const All = (props) => { // Changed from InboundInbounds to ReturnReturns
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const id = useSelector((state) => state.settings.environment.selectedId);
    const [spLoading, setSpLoading] = useState();

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);

    const [relationId, setRelationId] = useState();
    const { data: relation } = useGetRelationQuery(relationId, {
        skip: !relationId
    });
    
    const handlePortalOpen = () => {
        if(!relation){
            setRelationId(id);
        } else {
            window.open(`https://returns.stockhub.nl/${relation.portalCode}`, '_blank');
        }
    }

    const [returnId, setReturnId] = useState();
    const returnIdRef = useRef(returnId);
    
    const getReturnDetails = (params) => {
        if (!params) return;
    
        const newreturnId = parseInt(params.row.id);
        // Only update state if the returnId has actually changed
        if (returnIdRef.current !== newreturnId) {
            
            if(!spLoading) {
                setSpLoading(true);
            }
            setReturnId(newreturnId);
            returnIdRef.current = newreturnId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {field: "order", headerName: t("order"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.order?.barcode;
            }
        },
        {field: "name", headerName: t("name"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text>
                        {params.row.name}
                    </Text>
                )
            }
        },
        {field: "lineCount", headerName: t("sku_count"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.lineCount;
            }
        },        

        {field: "productCount", headerName: t("items"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.productCount;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                const { labelcolor, labeltext } = getInboundStateData(params.row.state, theme);
              
                return (
                    <ShLabel size={32} palette="normal" variant={labelcolor}>
                      {t(labeltext)}
                    </ShLabel>
                  );
              }
        },
        {
            field: "createdAt", 
            headerName: t("created_at"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.createdAt);
              
                return (
                    <Box>
                        <Text semibold>
                            {date.toLocaleDateString(i18n.language)}
                        </Text><br/>
                        <Text light>
                            {date.toLocaleTimeString(i18n.language,{timeStyle: 'short'})}
                        </Text>
                    </Box>
                );
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <RelationReturnCard onClick={() => console.log("hello")} skeleton={params.row.skeleton ?? false} {...params.row}/>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReturnsQuery}
                gridActions={<GridActions handlePortalOpen={handlePortalOpen}></GridActions>} 
                title={t('all_returns') }
                gridOptions={gridOptions} 
                sortModel={{field: columns[2].field,type:"DESC"}}
                columns={columns}
                onRowClick={getReturnDetails}> 
            </ShDataGrid2>

            {spLoading && 
                    <SpReturnDetails
                        isExtended={isExtended}
                        setIsExtended={setIsExtended}
                        returnId={returnId}
                    />
            }
            
        </Box>
    );
}

export default All; // Changed from InboundInbounds to ReturnReturns
