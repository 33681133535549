import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import WarehouseLocationCard from "../../../../../components/global/cards/stock/WarehouseLocationCard";
import MAddWarehouseLocation from "../../../../../components/global/Modals/MAddWarehouseLocation";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetWarehouseLocationsQuery } from "../../../../../newapi/warehouse/warehouselocationSlice";
import { tokens } from "../../../../../theme";
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import { selectUserPermission } from "../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../utils/permissions';
import { useSelector } from "react-redux";

const SPLocationDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpLocationDetails/SpLocationDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    return (
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_location')}
            </Shbutton>
            )}
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};

const WHLocations = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [locationIsExtended, setLocationIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.WAREHOUSELOCATION_MANAGE])
    );
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
        setLocationIsExtended(true);
    };
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>
        });

        return () => {
            
        }
    },[]);

    const columns = [
        {
            field: "location",
            headerName: t("location"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton variant={"yellow"}/>
                }
                return (
                    <ShLabel clickable={true} size={32} fitted variant={"yellow"}>
                        {params.row.location}
                    </ShLabel>
                );
            }
        },
        {
            field: "warehouseLevel",
            headerName: t("level"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.warehouseLevel?.description
            },
        },
        {
            field: "warehouseLocationType",
            headerName: t("location_type"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.warehouseLocationType?.description
            },
        },
        {
            field: "warehouseZone",
            headerName: t("zone"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.warehouseZone?.description
            }
        },
        {
            field: "reachType",
            headerName: t("reachtype"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.reachType?.description
            },
        },
        {
            field: "availableStock",
            headerName: t("available_stock"),
            sortable:false,
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return  params.row.availableStock//cellValues.row.product.relation.name
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            // hide: true,
            renderCell: (params) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseLocationCard onClick={() => getLocationDetails(params)} skeleton={params.row.skeleton ?? false} {...params.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWarehouseLocationsQuery}
                gridActions={<GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t('overview')}
                gridOptions={gridOptions} 
                onRowClick={getLocationDetails}
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns}>
            </ShDataGrid2>
            
            {spLoading && 
                <SPLocationDetails
                    isExtended={locationIsExtended}
                    setIsExtended={setLocationIsExtended}
                    locationId={locationId}
                /> 
            }
            <MAddWarehouseLocation onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}

 export default WHLocations;