// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import NumberInput from '../../../../theme/inputs/NumberInput';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, InputBase, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from '../../../../../theme';
import ShSwitch from "../../../../theme/inputs/ShSwitch";

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    
    const [newShipments, setNewShipments] = useState([{
        trackandtrace: "",
        index: 1
    }]);
    
    useEffect(() => {
        // Set the newShipments state to the data.shipments state
        if (data.shipments && data.shipments.length > 0) {
            const linesCopy = data.shipments.map((line, index) => ({
                ...line,
                index: index + 1
            }));
            
            linesCopy.push({
                trackandtrace: "",
                index: linesCopy[linesCopy.length - 1].index + 1
            })

            setNewShipments(linesCopy);
        }
    },[]);

    const handleChange = (index, key, value) => {
        const shipmentsCopy = [...newShipments];
        shipmentsCopy[index - 1][key] = value;

        if (shipmentsCopy[shipmentsCopy.length - 1].trackandtrace.length > 0) {
            shipmentsCopy.push({
                trackandtrace: "",
                index: shipmentsCopy[shipmentsCopy.length - 1].index + 1
            });
        }

        setNewShipments(shipmentsCopy);
    };
    
    useEffect(() => {
        const mappedShipments = newShipments
        .filter(x => x.trackandtrace.length > 0)
        .map(x => ({
            trackandtrace: x.trackandtrace,
        }));

        handleInput("shipments", mappedShipments);
    },[newShipments]);
      
    const handleDeleleteShipmentRow = (index) => {
        const updatedShipments = newShipments.filter(shipment => shipment.index !== index);
        setNewShipments(updatedShipments);
    }

    const handleInput = (key, val) => {
        props.handleDataUpdate({ [key]: val });
    };

    return (
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:1.5
                }}
            >
                {newShipments.map((shipment, index) => (
                <Box flex={1} key={"shipments"}
                    sx={{
                        display:"flex",
                        gap:1,
                        justifyContent:"flex-start",
                    }}
                >
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        <Box
                            sx={{
                                width:120,
                            }}
                        >
                            <Text light>{t("trackandtrace")}</Text>
                        </Box>
                        <Box
                            sx={{
                                flex:4,
                                backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                                borderRadius:4,
                                display:"flex",
                                height:32
                            }}
                        >
                            <InputBase 
                                sx={{
                                    px:2,
                                    minWidth: 200,
                                    color:colors.txt["primary"],
                                    lineHeight:"32px",
                                }}
                                value={shipment.trackandtrace}
                                onChange={(e) => handleChange(shipment.index, 'trackandtrace', e.target.value)}
                                />
                        </Box>
                    </Box>
                    <Box 
                        sx={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            gap:1
                        }}
                    >
                        { shipment.index > 1 ?
                        <Box sx={{width:40,display:"flex",justifyContent:"flex-end"}}>
                        <IconButton type="button" color="red" onClick={() => handleDeleleteShipmentRow(shipment.index)} sx={{p:1}}>
                            <DeleteIcon/>
                        </IconButton>
                        </Box> 
                        : 
                        
                        <Box sx={{width:40}}/>
                        }
                    </Box>
                </Box>
                ))}
            </Box>
    );
};

const Shipments = (props) => {
    const {t} = useTranslation();
    return (
        <ProgressionTab 
        tabId="tabShipments" 
        subtitle={t("shipments")} 
        step={3}
        maxSteps={3}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Shipments;

