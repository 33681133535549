import React from 'react';

const Truck = () => {
  return (
    <div className="truck-container">
      <svg
        className="truck"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 129.54 87.79"
      >
        <defs>
          <style>
            {`
              .cls-1{fill:#C2D7FF;}
              .cls-2{fill:#31415f;}
              .cls-3{fill:#fff;}
              .cls-4{fill:#31415f;}
              .cls-5{fill:#1e223e;}
              .cls-6{fill:#0D61FF;}
            `}
          </style>
        </defs>

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_2-2" data-name="Layer 2">
            <polyline
              className="cls-1"
              points="90.26 79.5 123.95 79.5 127.45 79.5 128.44 71.46 124.94 71.46 129.53 34 118.25 20 97.57 20"
            />
            <polyline
              className="cls-2"
              points="91.26 79.5 2.95 79.5 0 79.5 0.98 71.5 3.94 71.46 12.71 0 101.02 0 90.95 79.5"
            />
            <polyline
              className="cls-3"
              points="99.05 40.5 123.75 40.5 124.52 34.27 116.25 24 101.08 24"
            />
            <polygon className="cls-2" points="105.89 51.5 97.89 51.5 98.01 50.5 106.01 50.5 105.89 51.5" />
            <path
              className="cls-4"
              d="M48.35,56.09c-1.24-.76-2.51-1.47-3.78-2.18-1.43-.79-2.83-1.64-4.24-2.46l-3.06-1.76c-.47-.26-.94-.52-1.4-.79a.84.84,0,0,1-.43-.87c.15-1.29.35-2.57.51-3.85.11-.82.18-1.65.29-2.47.14-1.12.31-2.24.42-3.37.08-.81.19-1.61.29-2.42s.19-1.61.27-2.42c0-.2.17-.28.3-.35.85-.45,1.69-.89,2.54-1.32.61-.31,1.24-.59,1.85-.9l6.77-3.44c1.11-.57,2.24-1.1,3.36-1.67a.78.78,0,0,1,.83.05q2.45,1.43,4.91,2.83c2.45,1.41,4.9,2.83,7.35,4.23a.48.48,0,0,1,.26.51A.65.65,0,0,1,65,34l-6.52,3.25-.52.3c.9.46,1.74.87,2.57,1.3,1.13.58,2.26,1.19,3.41,1.76.55.28.52.84,0,1.13-1.13.63-2.31,1.18-3.47,1.76L57.54,45l3.56,1.82,1.8.9c.28.15.57.34.55.69s-.29.51-.56.65l-5,2.49c-.53.27-1.06.55-1.59.81q-3.42,1.71-6.82,3.45c-.21.11-.46.11-.64.28Zm14.16-7.64s0-.07-.07-.08l-5.52-2.84a.42.42,0,0,0-.42,0l-2.32,1.15-3.7,1.88a1.66,1.66,0,0,1-1.83,0c-2.38-1.44-4.82-2.78-7.24-4.16-.49-.27-1-.57-1.47-.86a1.74,1.74,0,0,1,.32-.62.8.8,0,0,1,.43.17l3.68,2.11C46,46.11,47.55,47,49.13,48A.81.81,0,0,0,50,48c1.11-.57,2.25-1.1,3.36-1.66l6.74-3.4c1.09-.56,2.2-1.1,3.34-1.67l-6.11-3.13a.5.5,0,0,0-.5,0c-1.83.91-3.67,1.79-5.47,2.74a1.64,1.64,0,0,1-1.79,0C47.37,39.56,45.15,38.31,43,37c-.71-.41-1.41-.83-2.1-1.25.08-.28.3-.42.45-.61l1.39.79,2.75,1.57,4.79,2.77a.66.66,0,0,0,.68,0c.6-.32,1.22-.63,1.83-.93l7.15-3.58L63.57,34l.89-.49a5.46,5.46,0,0,0-.45-.33l-6.47-3.71L52.59,26.6a.52.52,0,0,0-.54,0L47.1,29.08c-1.28.64-2.54,1.3-3.82,2l-5,2.51a.51.51,0,0,0-.33.43c-.11.71-.19,1.43-.27,2.15-.13,1.14-.28,2.28-.43,3.42-.1.8-.19,1.61-.29,2.41l-.42,3.38c-.1.82-.17,1.65-.31,2.46a.41.41,0,0,0,.23.53c1.94,1.1,3.87,2.22,5.81,3.33s4,2.25,5.92,3.41a1,1,0,0,0,1.11,0l7.62-3.83,4-2C61.43,49,62,48.73,62.51,48.45Z"
            />
          </g>
          <g id="Layer_3" data-name="Layer 3">
            <ellipse
              className="cls-5"
              cx="22.86"
              cy="78.16"
              rx="10.23"
              ry="9.05"
              transform="translate(-47.34 36.89) rotate(-43.24)"
            />
            <g>
              <ellipse
                className="cls-6"
                cx="22.86"
                cy="78.16"
                rx="6.88"
                ry="5.72"
                transform="translate(-57.78 66.82) rotate(-65.69)"
              />
            </g>
          </g>
          <g id="Layer_4" data-name="Layer 4">
            <ellipse
              className="cls-5"
              cx="89.86"
              cy="78.16"
              rx="10.23"
              ry="9.05"
              transform="translate(-29.15 82.79) rotate(-43.24)"
            />
            <g
              transform="translate(-18.37 127.87) rotate(-65.69)"
              style={{ position: 'relative' }}
            >
              <ellipse className="cls-6 inner-wheel" cx="89.86" cy="78.16" rx="6.88" ry="5.72" />
            </g>
          </g>
          </g>
        </svg>
        <div className="wind">
          <div className="p p1"></div>
          <div className="p p2"></div>
          <div className="p p3"></div>
        </div>
    </div>
  );
};

export default Truck;
