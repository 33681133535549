import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { BarChart } from "../../../theme/charts/HorizontalBarChart";
import { useGetDashboardInboundsQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { useTranslation } from "react-i18next";

export const InboundChart = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, isLoading,  isFetching, isError,refetch} = useGetDashboardInboundsQuery();

    // Function to handle retry
    const handleRetry = () => {
        refetch();
    };

    return (
        <Box
            sx={props.sx ?? {
                // overflow:'hidden',
                gridArea:"1 / 1 / 2 / 2",
                "@media screen and (max-width: 768px)" : {
                    // width:1,
                    // flex:"unset",
                    gridArea:"1 / 1 / 2 / 2"
                }
            }}
        >
            <BarChart
                {...props}
                hideFilter
                title={t("inbound")}
                subtitle={t("last_7_days_and_today")}
                type="bar"
                staticColors={[
                    colors.primary[200],
                    colors.primary[200],
                    colors.primary[200],
                    colors.primary[200],
                    colors.primary[200],
                    colors.primary[200],
                    colors.primary[200],
                    colors.primary[400],
                ]}
                isError={isError}
                isLoading={isLoading || isFetching}
                colors={['pink','purple','red']}
                categories={data?.categories}
                values={data?.values}
                color={"default"}
                onRetry={handleRetry}
            ></BarChart>
        </Box>
    );
}