import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";
import { BarChart } from "../../../theme/charts/HorizontalBarChart";
import { useGetDashboardReturnsQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { useTranslation } from "react-i18next";

export const ReturnChart = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {data, isLoading,  isFetching, isError,refetch} = useGetDashboardReturnsQuery();

    // Function to handle retry
    const handleRetry = () => {
        refetch();
    };

    return (
        <Box
            sx={props.sx ?? {
                // overflow:'hidden',
                gridArea:"1 / 2 / 2 / 3",
                "@media screen and (max-width: 768px)" : {
                    // width:1,
                    // flex:"unset",
                    gridArea:"2 / 1 / 3 / 2"
                }
            }}
        >
            <BarChart
                {...props}
                hideFilter
                title={t("returns")}
                subtitle={t("last_9_weeks")}
                type="bar"
                staticColors={[
                    colors.orange[200],
                    colors.orange[200],
                    colors.orange[200],
                    colors.orange[200],
                    colors.orange[200],
                    colors.orange[200],
                    colors.orange[200],
                    colors.orange[200],
                    colors.orange[400],
                ]}
                isError={isError}
                isLoading={isLoading || isFetching}
                colors={['pink','purple','red']}
                categories={data?.categories}
                values={data?.values}
                color={"default"}
                onRetry={handleRetry}
            ></BarChart>
        </Box>
    );
}