
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

export const settingsIntegrationsAccountingsoftware = {
    all:  {
        title: 'accounting_software',
        subtitle: 'accountingsoftware',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
};
