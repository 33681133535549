import Splabieblab from "./Filters/DateRangePicker";
import OrderStatusFilter from "./Filters/Order/OrderStatusFilter";

// filterKeys.js
export const ORDER_FILTER_KEYS = {
    STATE: {
        backend_key: 'state',
        component: OrderStatusFilter
    },
    DUE: {
        backend_key: 'isDue',
        component: Splabieblab
    },
    DATE_RANGE: {
        backend_key: 'date',
        component: Splabieblab
    },
    // CATEGORY: {
    //     backend_key: 'category'
    // },
}; 