import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import MpAddVirtualProduct from "../../../../../components/global/ModalsProgression/MpAddVirtualProduct/MpAddVirtualProduct";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { WarehouseVirtualProductCard } from "../../../../../components/global/cards/products/warehouse/WarehouseVirtualProductCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../../components/theme/text/Text";
import { useGetProductsQuery } from "../../../../../newapi/warehouse/productSlice";
import { tokens } from "../../../../../theme";
import ShCopyButton from '../../../../../components/theme/ShCopy';
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import { selectUserPermission } from "../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../utils/permissions';

const SpVirtualProductDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpVirtualProductDetails/SpVirtualProductDetails"));

const GridActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t("new_virtual_product")}
            </Shbutton>
            )}
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const VirtualProductOverview = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [config, setConfig] = useState({isVirtual: true});
    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.PRODUCT_MANAGE])
    );

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>,
        });

        return () => {
            
        }
    },[]);

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (params) => {
        if (!params) return;
    
        const newproductId = parseInt(params.row.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newproductId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setProductId(newproductId);
            productIdRef.current = newproductId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "sku",
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
                }
                return (
                    // <Box>
                    //     <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.sku}</Text>
                    // </Box>

                    <Box sx={{
                        width:1,
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.sku}</Text>
                        <ShCopyButton className={"copyel"} closeAfter={1500} value={params.row.sku}></ShCopyButton>
                    </Box>
                );
            }
        },
        {
            field: "description",
            headerName: t("description"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.description
            },
        },
        ...isFulfilment ? [{
            field: "relation",
            headerName: t("relation"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.relation ? params.row.relation.name : t('unknown'));
            },
        }] : [],
        {
            field: "products",
            headerName: t("products"),
            flex:1,
            sortable:false,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (params.row.childProducts ? params.row.childProducts : 0);
            },
        },
        {
            field: "available_stock",
            headerName: t("available_stock"),
            flex:1,
            sortable:false,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.amount;
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseVirtualProductCard onClick={() => getProductDetails(cellValues)} skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetProductsQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t('overview')} 
                onRowClick={getProductDetails}
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns}>
            </ShDataGrid2>
            {spLoading && 
                <SpVirtualProductDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    virtualProductId={productId}
                    // data={productData}
                    actionButtons={false}
                /> 
            }

            <MpAddVirtualProduct onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose} />
        </Box>
    );
}
export default VirtualProductOverview;