import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { tokens } from "../../../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../../../components/theme/text/Text";

import { WarehouseWorkPlaceCard } from "../../../../../../../components/global/cards/office/WorkPlaceCard";
import MAddWorkspace from "../../../../../../../components/global/Modals/MAddWorkspace";
import ShDataGrid2 from "../../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../../../components/theme/skeleton/ShTextSkeleton";
import { useFindAllWorkspacesQuery } from "../../../../../../../newapi/warehouse/workspaceSlice";
import SidepanelLoader from '../../../../../../../components/theme/sidepanel2/SidepanelLoader';
import { selectUserPermission } from "../../../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from "../../../../../../../utils/permissions";
import { useSelector } from "react-redux";

const SpWorkspaceDetails = SidepanelLoader(() => import("../../../../../../../components/global/Sidepanels/SpWorkspaceDetails/SpWorkspaceDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (      
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>{t("new_workspace")}</Shbutton>
            )}
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}
const gridOptions = {
    export: true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter: {
        name: "workspaceFilter",
        selectedFilter: null,
        groups: [{ conditionExtensionType: 0, rules: [] }]
    }
};

const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);    
    const [spLoading, setSpLoading] = useState();

    const [addModalOpen, setAddModalOpen] = useState(false);

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.WORKSPACE_MANAGE])
    );

    const [workspaceId, setWorkspaceId] = useState();
    const workspaceIdRef = useRef(workspaceId);
    
    const getWorkspaceDetails = (params) => {
        if (!params) return;
    
        const newworkspaceId = parseInt(params.row.id);
        // Only update state if the workspaceId has actually changed
        if (workspaceIdRef.current !== newworkspaceId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setWorkspaceId(newworkspaceId);
            workspaceIdRef.current = newworkspaceId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            topRight: <WareHouseDropdown {...props.warehouseModal}/>,
            mobileActions: <GridActions hasCreatePermission={hasCreatePermission} handleOpen={() => setAddModalOpen(true)} />
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[props]);

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const columns = [
        {
            field: "name",
            headerName: t("workspace") ,
            flex: 2,
            cellClassName: "workspace",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            }
        },
        {
            field: "packingListPrinter",
            headerName: t("packinglist_printer") ,
            flex: 1,
            cellClassName: "printer_packinglist",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                
                return <Text>{cellValues.row.packingListPrinter?.description}</Text>
            } 
        },
        {
            field: "labelPrinter",
            headerName: t("label_printer") ,
            flex: 1,
            cellClassName: "printer_label",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return <Text>{cellValues.row.labelPrinter?.description}</Text>
            }
        },
        {
            field: "documentsPrinter",
            headerName: t("documents_printer") ,
            flex: 1,
            cellClassName: "printer_documents",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return <Text>{cellValues.row.documentsPrinter?.description}</Text>
            }
        },
        {
            field: "autoPrint",
            headerName: t("auto_print") ,
            flex: 1,
            cellClassName: "autoPrint",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return <Text>{cellValues.row.autoPrint ? t("yes") : t("no")}</Text>
            }
        },
        { field: "warehouse", headerName: t("warehouse"), flex: 1, cellClassName: "printer-warehouse-id",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return(<Text>{cellValues.row.warehouse?.name}</Text>)
            }
        },
        {
            field: "phoneView",
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // if(cellValues.row.skeleton) {
                    
                    // return <ShTextSkeleton/>
                // }
                return (
                    <WarehouseWorkPlaceCard 
                        skeleton={cellValues.row.skeleton}
                        onClick={() => getWorkspaceDetails(cellValues)}
                        {...cellValues.row}
                    />
                )
                // Custom cell rendering
            }
        }
    ];

    return (
        <Box
            sx={{
                height: 1,
                "@media screen and (max-width: 47.9375em)": {
                    paddingTop: 0,
                    paddingX: 0,
                }
            }}
        >
            <ShDataGrid2 key={theme.palette.mode}
                content={useFindAllWorkspacesQuery}
                gridActions={<GridActions hasCreatePermission={hasCreatePermission} handleOpen={() => setAddModalOpen(true)} />}
                title={t("overview")}
                gridOptions={gridOptions}
                columns={columns}
                sortModel={{field: columns[0].field,type:"ASC"}}
                
                onRowClick={getWorkspaceDetails}
            />
            <MAddWorkspace onOpen={() => setAddModalOpen(true)} onClose={handleClose} open={addModalOpen} handleClose={handleClose} />
            {spLoading && 
                <SpWorkspaceDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    workspaceId={workspaceId}
                    // data={workspaceData}
                /> 
            }
        </Box>
    )
};

export default All;
