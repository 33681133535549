import { Slider, Switch, useTheme, useThemeProps } from '@mui/material';
import { tokens } from "../../../theme";

const ShThreeSwitch = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const values = [
        {
          value: 0,
          label: 'false',
        },
        {
          value: 1,
          label: 'unset',
        },
        {
          value: 2,
          label: 'true',
        },
      ];

    const getColor = (value) => {
        switch (value) {
            case 0:
                return colors.grey[500];
            case 1:
                return `rgba(${colors.grey[500]}, 0.5)`;
            case 2:
                return colors.green;
            default:
                return colors.grey[500];
        }
    };
    
    return (
        <Slider
        sx={{ 
            width: 50,
            '& .MuiSlider-thumb': {
                backgroundColor: getColor(props.value),
                border: '2px solid currentColor',
            },
            '& .MuiSlider-track': {
                backgroundColor: getColor(props.value),
                height: 4,
            },
            '& .MuiSlider-rail': {
                backgroundColor: colors.grey[300],
                opacity: 1,
                height: 4,
            },
            '& .MuiSlider-markLabel': {
                display: 'none',
            },
        }}
            disabled={props.disabled ? true : undefined}
            onChange={props.onChange}
            aria-label="Restricted values"
            defaultValue={1}
            step={null}
            max={2}
            min={0}
            value={props.value}
            valueLabelDisplay="off"
            marks={values}
        />
    )
}

export default ShThreeSwitch;