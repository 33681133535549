import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const BASE = "/pickupcart";
const ApiName = "PickupCart";
const buildUrl = (endpoint) => BASE + endpoint;

const pickupCartSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        createPickupCart: builder.mutation({
            query: pickupCartData => ({
                url: buildUrl(`/new`),
                method: 'POST',
                body: pickupCartData,
            }),
            invalidatesTags: (result, error, arg) => [{ type: ApiName }],
        }),
        findAllPickupCarts: builder.query({
            query: ({args}) => ({
                url: buildUrl(`?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body
            }),
            providesTags: (result, error, arg) => [{ type: ApiName }],
            onQueryStarted: muiDataGridStarted.bind(null, 'Pickupcarts', 'Pickupcarts')
        }),
        findOnePickupCart: builder.query({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
        }),
        updatePickupCart: builder.mutation({
            query: pickingCartInfo => ({
                url: buildUrl(`/${pickingCartInfo.id}`),
                method: 'PUT',
                body: pickingCartInfo.body,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }, { type: ApiName }],
        }),
        deletePickupCart: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }],
        }),
        // PickupCartLocation Endpoints
        createPickupCartLocation: builder.mutation({
            query: pickupCartLocationData => ({
                url: buildUrl(`/pickupcartlocation`),
                method: 'POST',
                body: pickupCartLocationData,
            }),
            invalidatesTags: (result, error, arg) => [{ type: ApiName }],
        }),
        findAllPickupCartLocations: builder.query({
            query: () => ({
                url: buildUrl(`/pickupcartlocation`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: ApiName }],
        }),
        findOnePickupCartLocation: builder.query({
            query: (id) => ({
                url: buildUrl(`/pickupcartlocation/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: ApiName, id }],
        }),
        updatePickupCartLocation: builder.mutation({
            query: ({ id, data }) => ({
                url: buildUrl(`/pickupcartlocation/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }, { type: ApiName }],
        }),
        deletePickupCartLocation: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/pickupcartlocation/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: ApiName, id }],
        }),
        findAllPickupCrates: builder.query({
            query: ({args}) => ({
                url: buildUrl(`/crate?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body
            }),
            providesTags: (result, error, arg) =>  [{ type: "Pickupcrates" }],
            onQueryStarted: muiDataGridStarted.bind(null, 'Pickupcrates', 'Pickupcrates')
        }),
        findOnePickupCrate: builder.query({
            query: (id) => ({
                url: buildUrl(`/crate/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: "Pickupcrates", id }],
        }),
        createPickupCrate: builder.mutation({
            query: pickupCrateData => ({
                url: buildUrl(`/crate/new`),
                method: 'POST',
                body: pickupCrateData,
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Pickupcrates" }],
        }),
        updatePickupCrate: builder.mutation({
            query: ( {id, data} ) => ({
                url: buildUrl(`/crate/${id}`),
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: "Pickupcrates", id }],
        }),
        deletePickupCrate: builder.mutation({
            query: (id) => ({
                url: buildUrl(`/crate/${id}`),
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: "Pickupcrates" }],
        }),
        getContainerSizes: builder.query({
            query: ({args}) => ({
                url: buildUrl(`/crate/size?page=${args.page}&size=${args.size}`),
                method: 'POST',
                body: args.body
            }),
            providesTags: (result, error, arg) => [{ type: "containerSizes" }],
            onQueryStarted: muiDataGridStarted.bind(null, 'containerSizes', 'containerSizes')
        }),
        getContainerSize: builder.query({
            query: (id) => ({
                url: buildUrl(`/crate/size/${id}`),
                method: 'GET',
            }),
            providesTags: (result, error, id) => [{ type: "containerSizes", id }],
        }),
    getContainerSizesSelect: builder.query({
        query: () => ({
            url: buildUrl(`/crate/sizes`),
            method: 'GET',
        }),
        providesTags: (result, error, id) => [{ type: "containerSizes" }],
    }),
    createContainerSize: builder.mutation({
        query: containerSizeData => ({
            url: buildUrl(`/crate/size/new`),
            method: 'POST',
            body: containerSizeData,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "containerSizes" }],
    }),
    updateContainerSize: builder.mutation({
        query: containerSizeData => ({
            url: buildUrl(`/crate/size/${containerSizeData.id}`),
            method: 'PUT',
            body: containerSizeData,
        }),
        invalidatesTags: (result, error, arg) => [{ type: "containerSizes" }],
    }),
    deleteContainerSize: builder.mutation({
        query: (id) => ({
            url: buildUrl(`/crate/size/${id}`),
            method: 'DELETE'
        }),
        invalidatesTags: (result, error, arg) => [{ type: "containerSizes" }],
    }),
    })
});

export const { 
    useCreatePickupCartMutation,
    useFindAllPickupCartsQuery,
    useFindOnePickupCartQuery,
    useUpdatePickupCartMutation,
    useDeletePickupCartMutation,
    useCreatePickupCartLocationMutation,
    useFindAllPickupCartLocationsQuery,
    useFindOnePickupCartLocationQuery,
    useUpdatePickupCartLocationMutation,
    useDeletePickupCartLocationMutation,
    useFindAllPickupCratesQuery,
    useFindOnePickupCrateQuery,
    useCreatePickupCrateMutation,
    useUpdatePickupCrateMutation,
    useDeletePickupCrateMutation,
    useGetContainerSizesSelectQuery,
    useCreateContainerSizeMutation,
    useGetContainerSizesQuery,
    useGetContainerSizeQuery,
    useUpdateContainerSizeMutation,
    useDeleteContainerSizeMutation,
} = pickupCartSlice;
