import { Box, ButtonBase, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import Subheading from "../../../../../components/theme/text/Subheading";
import { useTranslation } from "react-i18next";
import { ReactComponent as Monta } from '../../../../../styles/svg/monta.svg';
import { ReactComponent as Picqer } from '../../../../../styles/svg/picqer.svg';
import { ReactComponent as Lyra } from '../../../../../styles/svg/lyra.svg';
import { ReactComponent as Bizbloqs } from '../../../../../styles/svg/bizbloqs.svg';
import Heading from "../../../../../components/theme/text/Heading";
import MpMigrationImport from "../../../../../components/global/ModalsProgression/MpMigrationImport/MpMigrationImport";
import { useState } from "react";

const MigrationImport = (props) => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [source, setSource] = useState("");
    const [combined, setCombined] = useState("");

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };


    const handleSelected = (selected, combinedImport) => {
        setSource(selected);
        setCombined(combinedImport);
        setAddModalOpen(true);
    }


    return (
        <Box sx={{background:colors.bg["tertiary"], width:1,paddingTop:9}}>
            {/* {ready ?} */}
            <Box
                sx={{
                    display:"flex",
                    paddingX:4,
                    gap:3,
                    background:colors.bg["tertiary"],
                    paddingTop:2,
                    paddingBottom:3,
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"grid",
                        gridTemplateColumns:"repeat(2, 1fr)",
                        gridTemplateRows:"repeat(2, 1fr)",
                        columnGap:3,
                        rowGap:3,
                    }
                }}
            >
                {/* <ButtonBase
                    onClick={() => handleSelected("stockhub", false)}
                    sx={{
                        flex:1,
                        background:colors.txt["primary"],
                        color:"#fff",
                        minHeight:280,
                        borderRadius:6,
                        "@media screen and (max-width: 47.9375em)" : {
                            minHeight:"unset",
                            height:150
                        }
                    }}
                >
                    <Subheading>Algemeen</Subheading>
                </ButtonBase> */}
                <ButtonBase
                    onClick={() => handleSelected("Monta", false)}
                    sx={{
                        flex:1,
                        background:'rgba(0,157,224,.15)',
                        color:colors.txt["primary"],
                        minHeight:280,
                        borderRadius:6,
                        display:"flex",
                        flexDirection:"column",
                        gap:2,

                        "& .monta" : {
                            width:"40%",
                            // transform: "scale(0.75)"
                        },
                        "@media screen and (max-width: 47.9375em)" : {
                            minHeight:"unset",
                            height:150
                        }
                    }}
                >

                    <Monta className={`monta ${theme.palette.mode === "dark" ? "dark" : null}`} />
                    <Heading>Monta</Heading>
                    {/* <Subheading>Algemeen</Subheading> */}
                </ButtonBase>
                <ButtonBase
                    onClick={() => handleSelected("Picqer", false)}
                    sx={{
                        flex:1,
                        background:'rgba(3,121,191,.15)',
                        color:colors.txt["primary"],
                        minHeight:280,
                        borderRadius:6,
                        display:"flex",
                        flexDirection:"column",
                        gap:1.5,
                        "@media screen and (max-width: 47.9375em)" : {
                            minHeight:"unset",
                            height:150
                        }

                        // "& .picqer" : {
                        //     width:"35%",
                        //     // transform: "scale(0.75)"
                        // }
                    }}
                >

                    <Picqer className={`picqer ${theme.palette.mode === "dark" ? "dark" : null}`} />
                    <Heading>Picqer</Heading>
                    {/* <Subheading>Algemeen</Subheading> */}
                </ButtonBase>
                <ButtonBase
                    onClick={() => handleSelected("Lyra", false)}
                    sx={{
                        flex:1,
                        background:colors.txt["primary"],
                        background:'rgba(59,130,246,.15)',
                        color:"#fff",
                        minHeight:280,
                        borderRadius:6,
                        display:"flex",
                        flexDirection:"column",
                        gap:2,
                        "@media screen and (max-width: 47.9375em)" : {
                            minHeight:"unset",
                            height:150
                        }
                    }}
                >
                    <Lyra className={`picqer ${theme.palette.mode === "dark" ? "dark" : null}`} />
                    <Heading>Lyra</Heading>
                </ButtonBase>
                <ButtonBase
                    onClick={() => handleSelected("Bizbloqs", true)}
                    sx={{
                        flex:1,
                        background:colors.txt["primary"],
                        background:'rgba(234,128,56,.15)',
                        color:"#fff",
                        minHeight:280,
                        borderRadius:6,
                        display:"flex",
                        flexDirection:"column",
                        gap:2,
                        "@media screen and (max-width: 47.9375em)" : {
                            minHeight:"unset",
                            height:150
                        }
                    }}
                >
                    <Bizbloqs className={`bizbloqs ${theme.palette.mode === "dark" ? "dark" : null}`} />
                    <Heading>Bizbloqs</Heading>
                </ButtonBase>
            </Box>

            {/* <MpMigrationImport/> */}
                    {/* <MpMigrationImport onOpen={() => handleOpen} onClose={() => handleClose()} open={open} handleClose={handleClose}/> */}
                    <MpMigrationImport source={source} combined={combined} onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
        
        </Box>
    );
}

export default MigrationImport;