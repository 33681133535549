import { useTranslation } from "react-i18next"

import ProgressionModal from "../../../theme/ProgressionBuilder/ProgressionModal"
import ProgressionWrapper from "../../../theme/ProgressionBuilder/ProgressionWrapper"
import { useDispatch } from "react-redux"
import { setResetTab } from "../../../../newapi/global/progressionTabSlice"

import StreamBasicInfo from "./views/StreamBasicInfo"
import StreamConditions from "./views/StreamConditions"
import { useAddNewStreamMutation } from "../../../../newapi/global/stream/streamSlice"
import StreamPriorities from "./views/StreamPriorities"
import StreamShipper from "./views/StreamShipper"
import StreamReachType from "./views/StreamReachType"

const MpAddStream = (props) => {
    // Call the setResetTab action to reset the state
    const {t} = useTranslation();

    const onSave = () => {
        props.handleClose();
    }

    return (
        <ProgressionModal
            open={props.open}
            onClose={props.handleClose}
        >
            <ProgressionWrapper onSave={onSave} query={useAddNewStreamMutation} isModal title={t("add_stream")}>
                <StreamBasicInfo />
                <StreamConditions />
                <StreamPriorities />
                <StreamShipper />
                <StreamReachType />
            </ProgressionWrapper>
        </ProgressionModal>
    )
}

export default MpAddStream;