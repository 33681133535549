import { useTranslation } from "react-i18next";
import ExplainerModal from "../../../../theme/Modal/ExplainerModal/ExplainerModal";
import Subheading from "../../../../theme/text/Subheading";
import Text from "../../../../theme/text/Text";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../../theme";
import ExplainerBox from "../../../../theme/Modal/ExplainerModal/ExplainerBox";


const EMCountingStrategyAmount = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <ExplainerModal>
            <Box
                sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:2,
                }}
            >
                <ExplainerBox
                    title={t('em_countingstrategyamount_title')}
                    text={'em_countingstrategyamount_text'}
                />
                
            </Box>
        </ExplainerModal>
    );
}

export default EMCountingStrategyAmount;