import { ENV_TYPES } from "../../components/global/Sidebar";
import { apiSlice } from "../createApiSlice";

const API_BASE = "/import";
const buildUrl = (endpoint) => API_BASE + endpoint;

export const uploadApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        uploadImport: builder.mutation({
            query: (args) => {
                const { source, type, file } = args;
                const formData = new FormData();
                formData.append('file', file);

                return {
                    url: buildUrl(`/preprocess/${source}/${type}`),
                    method: 'POST',
                    body: formData,
                };
            },
        }),
        processImport: builder.mutation({
            query: (args) => {
                const { source, type, data } = args;
                return {
                    url: buildUrl(`/process/${source}/${type}`),
                    method: 'POST',
                    body: data,
                };
            },
        }),
        uploadLogo: builder.mutation({
            query: (args) => {
                const { file } = args;
                const formData = new FormData();
                formData.append('file', file);


                return {
                    url: buildUrl(`/logo`),
                    method: 'POST',
                    body: formData,
                    headers: args.headers
                };
            },

        }),
    })
});

export const { 
    useUploadImportMutation,
    useProcessImportMutation,
    useUploadLogoMutation,
} = uploadApiSlice;


// base: "#31415f",
// background: "#ecf0f5",
// streamrule: "#ced7e1",
// streamrulehover: "#8E96A7",
// streamrulevalue: "#ecf0f5",
// streamrulevaluetxtcolor: "#8E96A7",
// streamrulevaluehover: "#8E96A7",