import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

// import AdvancedFilterView from "./Filter/Views/AdvancedFilterView";
import PopoverModal from "../../Modal/PopoverModal";


import { filterModalConfig } from "../filterold/filterModalConfig";
import FilterModalView from "./FilterModalView";

const FilterModal = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <PopoverModal
            {...props}
            triangle={{position:"right", color: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]}}
        >
            <Box 
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                    // background:"orange",
                    width:520, //fix
                    "@media screen and (max-width: 768px)" : {
                        width:1,
                    }
                }}
            >
                <FilterModalView filterOptions={props.filterOptions} {...props}/>

            </Box>

        </PopoverModal>
    );
}
export default FilterModal;