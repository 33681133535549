
import WarehouseIcon from '@mui/icons-material/Warehouse';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

export const relationSettingsWebshopConfig = {
    all:  {
        title: 'saleschannels',
        subtitle: 'saleschannels',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    },
    integrator: {
        title: 'integrators',
        subtitle: 'integrators',
        icon: <Inventory2OutlinedIcon/>,
        isSingleDataGrid: true,
    }
};
