import { Box, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
// import { tokens } from "../../../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Text from "../../../../../../components/theme/text/Text";
import { tokens } from "../../../../../../theme";

import { QuestionMarkOutlined } from "@mui/icons-material";
import AddIcon from '@mui/icons-material/Add';
import { GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import ButtonGroup from "../../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../../components/theme/skeleton/ShTextSkeleton";
import ShDoubleTextSkeleton from "../../../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import { useGetShipperProfilesQuery } from "../../../../../../newapi/shipper/shipperprofileSlice";
import { ReactComponent as BolLogo } from '../../../../../../styles/svg/bol.svg';
import { ReactComponent as DhlLogo } from '../../../../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../../../../styles/svg/dpd.svg';
import { ReactComponent as ParcelLogo } from '../../../../../../styles/svg/parcel.svg';
import { ReactComponent as PostNLLogo } from '../../../../../../styles/svg/postnl.svg';
import { ReactComponent as CirroLogo } from '../../../../../../styles/svg/cirro.svg';
import { ReactComponent as SendcloudLogo } from '../../../../../../styles/svg/sendcloud.svg';
import SidepanelLoader from "../../../../../../components/theme/sidepanel2/SidepanelLoader";
import { selectUserPermission } from "../../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../../utils/permissions';
import { useSelector } from "react-redux";
import MpAddShipperProfile from "../../../../../../components/global/ModalsProgression/MpAddShipperProfile/MpAddShipperProfile";
const SpShipperProfileDetails = SidepanelLoader(() => import("../../../../../../components/global/Sidepanels/SpShipperProfileDetails/SpShipperProfileDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                onClick={() => props.handleOpen(true)}
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                variant="contained" 
                endIcon={<AddIcon/>}>
                    {t("new_shipperprofile")}
            </Shbutton>
            )}
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    

};


const All = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.SHIPPER_MANAGE])
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [shipperProfileId, setShipperProfileId] = useState();
    const shipperProfileIdRef = useRef(shipperProfileId);
    
    const getShipperProfileDetails = (params) => {
        if (!params) return;
    
        const newshipperId = parseInt(params.row.id);
        // Only update state if the shipperId has actually changed
        if (shipperProfileIdRef.current !== newshipperId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setShipperProfileId(newshipperId);
            shipperProfileIdRef.current = newshipperId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
    const columns = [
        // {field: "type", headerName: t("type"), flex:1, renderCell : (cellValues) => {
        //     return (
                
        //     )
        // }},
        {field: "name", headerName: t("name") , flex:1, cellClassName: "name", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text>{cellValues.value}</Text>
            )
        }},
        {field: "shipper.name", headerName: t("shipper"), flex:1, renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <>
                <Box sx={{ paddingRight: '10px' }}>
                    {cellValues.row.type === "postnl" ? <PostNLLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "dhl" ? <DhlLogo  width={30} height={30}/> : null}
                    {cellValues.row.type === "dpd" ? <DpdLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "bol" ? <BolLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "other" ? <QuestionMarkOutlined width={30} height={30}/> : null}
                    {cellValues.row.type === "parcel" ? <ParcelLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "cirro" ? <CirroLogo width={30} height={30}/> : null}
                    {cellValues.row.type === "sendcloud" ? <SendcloudLogo width={30} height={30}/> : null}
                </Box>
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.row.shipper?.name}</Text>
                </>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                sortModel={{field: columns[0].field,type:"ASC"}}
                content={useGetShipperProfilesQuery}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t("overview")}
                gridOptions={gridOptions} 
                
                columns={columns} 
                onRowClick={getShipperProfileDetails}>
            </ShDataGrid2>

            <MpAddShipperProfile onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpShipperProfileDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    shipperProfileId={shipperProfileId}              
                /> 
            }
        </Box>
    )
};


export default All;