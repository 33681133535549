import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { apiSlice } from './createApiSlice'
import authReducer from "./user/authentication/authSlice"
import { authApiSlice } from "./user/authentication/authApiSlice"

import { persistStore, persistReducer, createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import {encryptTransform} from 'redux-persist-transform-encrypt';
import gridFilterSlice from './global/filter/gridFilterSlice'
import gridFilterBuilderRuleSlice from './global/filter/builder/gridFilterBuilderRuleSlice'
import gridFilterBuilderSettingsSlice from './global/filter/builder/gridFilterBuilderSettingsSlice'
import {storeEncryptor} from './transforms/global';
import generalSlice from './global/generalSlice'
import { generalTransform } from './transforms/individual'
import { loginMiddleware } from './middleware/loginMiddleware'
import progressionTabSlice from './global/progressionTabSlice'
import warehouseSlice from './warehouse/warehouseSlice'
import { rootSlice } from './createRootSlice'
import settingsSlice from './global/settingsSlice'
import { reduxMigrations } from './migrations'
import tabViewSlice from './global/tabViewSlice'
import { etailizeApiSlice } from './webshop/etailizeSlice';
import relationDashboardSlice from './global/relation/state/relationDashboardSlice';

// storeEncryptor
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth','settings','views', 'gridFilter','general', 'warehouse', 'progressiontabs'], 

    version: 3, // Make sure this matches the highest migration version
    migrations: createMigrate(reduxMigrations, { debug: false }),

    //Order of operations important, the encryptor needs to be LAST
    transforms: [
      generalTransform,
      storeEncryptor
    ]
};

  
const rootReducer = combineReducers({
    [apiSlice.reducerPath] : apiSlice.reducer,
    [rootSlice.reducerPath] : rootSlice.reducer,
    [etailizeApiSlice.reducerPath] : etailizeApiSlice.reducer,

    // Api slice is seperate from authapislice, 
    // one interacts with the API, the other with the state
    auth: authReducer,

    //State of sidebar, what env is selected and what menu item is selected
    settings: settingsSlice.reducer,

    views: tabViewSlice.reducer,

    //general slice, saves the state or whatever is in it
    general: generalSlice.reducer,

    //Warehouse slice (warehouse selector)
    warehouse: warehouseSlice.reducer,

    //Progression tab slice
    progressiontabs: progressionTabSlice.reducer,

    //Relation dashboard
    relationDashboard: relationDashboardSlice.reducer,

    //gridfilter and the builder
    gridFilter: gridFilterSlice.reducer,
    gridFilterBuilderRule: gridFilterBuilderRuleSlice.reducer,
    gridFilterBuilderSettings: gridFilterBuilderSettingsSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);
  
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
              // Ignore these action types
              ignoredActions: ['persist/PERSIST']
            }
          })
            .concat(
                apiSlice.middleware,
                authApiSlice.middleware,
                etailizeApiSlice.middleware,
                loginMiddleware
            ),
},window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const persistor = persistStore(store);