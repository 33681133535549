// TabContent1.js
import React, { useEffect, useState } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch } from 'react-redux';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";
import cx from 'classnames';
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import { yupRequired } from '../../../../../utils/validation';
import { shipperConfigurations } from '../../../../../data/shipperConfigurations';
import EMGeneralExplainerBox from '../../../ModalsPopover/explainers/EMGeneralExplainerBox';
import { InputDropdown } from '../../../../theme/dropdowns/InputDropdown';


const Content = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const colors = tokens(theme.palette.mode);
    

    const data = {...props.data};
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
   
    const shipperType = props.data.selectedOption;
    const config = shipperConfigurations[shipperType] || { fields: [], saveFormat: () => ({}) };
    const [formData, setFormData] = useState(config.saveObject);

    const handleBoolChange = (name, value) => {
        if(value === "true"){
            setFormData(prevData => ({ ...prevData, [name]: true }));
        }else{
            setFormData(prevData => ({ ...prevData, [name]: false }));
        }
    };

    const handleInputChange = (name, value) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }
    
    const handleMultiDropdownChange = (baseList, name, values) => {
        setFormData(prevData => {
            const updatedArray = values.map(index => baseList[index].value);
            return { ...prevData, [name]: updatedArray };
        });
    };

    const handleDropdownChange = (baseList, name, value) => {
        const val = baseList[value]?.value;
        if(val) {
            setFormData(prevData => ({ ...prevData, [name]: val}));
        }
    };

    const selectedFieldIndex = (field) => {
        if(Array.isArray(formData[field.name])){
            const selectedIndexes = formData[field.name].map(item => field.options.findIndex(option => option.value === item));
            return selectedIndexes;
        } else {
            return [];
        }
    }


    useEffect(() => {
        console.log(formData);
        props.handleDataUpdate({ configuration: formData });
    }, [formData]);

    return (
        <>
        {config?.fields?.map(field => {
            switch (field.type) {
                case 'text':
                    return (
                        <Box key={field.name} sx={{
                            display:"flex",
                            flexDirection:"column",
                            gap:1,
                            paddingTop:3,
                        }}>
                            <Text>{t(field.label)}{field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}</Text>
                            <ShValidatedInput
                                name={field.name}
                                label={t(field.label)}
                                value={formData[field.name] || ''}
                                onChange={handleInputChange}
                                //error={props.errors.name}
                            />
                            {/* <InputBase
                                value={formData[field.name] || ''}
                                onChange={(e) => handleInputChange(field.name, e.target.value)}
                            /> */}
                        </Box>
                    );
                case 'boolean':
                    return (
                        <Box 
                    display={"flex"} 
                    flexDirection={"row"}
                    sx={{
                        gap:1,
                        paddingTop:3,
                        //maxWidth: 110,
                        justifyContent:"space-between",
                        alignItems:"center",
                        "@media screen and (max-width:47.9375em)" : {
                            width:1,
                            flexDirection:"row",
                            alignItems:"center",
                            justifyContent:"space-between",
                        }
                    }}
                    >
                    <Text>
                    {t(field.label)}
                    {field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}
                    </Text>
                    
                    <ToggleButtonGroup
                        color={formData[field.name] ? "green" : "red"}
                        value={formData[field.name] ? "true" : "false"}
                        exclusive
                        onChange={(e) => handleBoolChange(field.name, e.target.value)}
                        aria-label="Active"

                        className={cx(`${formData[field.name]}`, {
                            'active': formData[field.name]
                        })}
                        sx={{
                            background: colors.red[100],
                            padding:0.5,
                            borderRadius:5,
                            transition:"250ms",
                            "&.active" : {
                                background:colors.green[100],
                                transition:"250ms",
                            },
                        }}
                    >
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.green[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.red[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="false">
                            {t('no')}
                        </ToggleButton>
                        <ToggleButton 
                            sx={{
                                padding:0,
                                paddingX:2,
                                border:0,
                                lineHeight:"32px",
                                textTransform:"unset",
                                borderRadius:"32px !important",
                                color:colors.red[400],
                                transition:"250ms",
                                "&.Mui-selected,&.Mui-selected:hover" : {
                                    background:colors.green[400],
                                    color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                }
                            }}
                            value="true"
                        >
                            {t('yes')}
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                    );
                    case 'dropdown':
                        return (
                            <Box key={field.name} sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                                paddingTop:3,
                            }}>
                                <Text>{t(field.label)}{field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}</Text>
                                
                                <InputDropdown
                                    key={formData[field.name]}
                                    name={field.name}
                                    useTranslation={field.translate}
                                    noSelection={t('choose_option')}
                                    displayName="label"
                                    options={field.options ?? ""}
                                    selected={field.options.findIndex(option => option.value === formData[field.name]) ?? -1}
                                    onChange={(selected) => handleDropdownChange(field.options,field.name, selected)}
                                    closeOnSelection
                                />
                            </Box>
                        );
                    case 'multidropdown':
                        return (
                            <Box key={field.name} sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                                paddingTop:3,
                            }}>
                                <Text>{t(field.label)}{field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}</Text>
                                <InputDropdown
                                    key={formData[field.name]}
                                    name={field.name}
                                    multiple={true}
                                    noSelection={t('choose_option')}
                                    displayName="label"
                                    options={field.options ?? []}
                                    selected={selectedFieldIndex(field)}
                                    onChange={(selected) => handleMultiDropdownChange(field.options,field.name, selected)}
                                    closeOnSelection={false}
                                />
                            </Box>
                        );
                default:
                    return null;
            }
        })}
    </>
    );
};

const ShipperAuthorisation = (props) => {
    const { t } = useTranslation();

    return (
        <ProgressionTab
            tabId="tabAuthorisation"
            subtitle={t("authorisation")}
            step={6}
            maxSteps={6}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ShipperAuthorisation;

