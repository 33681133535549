import { Box, useTheme } from "@mui/material"
import Text from "../../text/Text"
import { tokens } from "../../../../theme";
import { Trans } from "react-i18next";

const ExplainerBox = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const background = typeof props.variant !== "undefined" && props.variant !== null ? colors[props.variant][100] : theme.palette.mode === "dark" ?colors.grey[300] :colors.grey[50];
    
    return (
        
        <Box
            sx={{
                display:"flex",
                flexDirection:"column",
                gap:1,
                background:background,
                padding:2,
                borderRadius:3,
            }}
        >
            <Text variant={typeof props.variant !== "undefined" && props.variant ? props.variant : null}  bold>
                {props.title}
            </Text>
            <Text sx={{whiteSpace:"pre-line",lineHeight:"130%"}} variant={typeof props.variant !== "undefined" && props.variant ? props.variant : null} light={typeof props.variant === "undefined" || props.variant === null}>
                {/* {props.text} */}
                <Trans
                    i18nKey={props.text}
                    components={{ 
                        italic: <i />, 
                        bold: <strong /> 
                    }}
                ></Trans>
            </Text>
        </Box>
    )
}

export default ExplainerBox;