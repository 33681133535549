import { useState } from "react";
import { useGetNotificationsQuery } from "../../../../newapi/global/notificationsSlice";
import TopbarIconButton from "../components/TopbarIconButton";
import { NotificationsOutlined } from "@mui/icons-material";
import MPONotifications from "../components/MPONotifications";

const TopbarNotifications = () => {
    const {data, isLoading} = useGetNotificationsQuery('',{
        pollingInterval: process.env.NODE_ENV !== "development" ? (15*60000) : false,
      });
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
        // setExportAnchorEl(null);
    };

    return (
        <>
            <TopbarIconButton
                onClick={handleFilterPopoverClick}
            >
                <NotificationsOutlined />
            </TopbarIconButton>
            <MPONotifications
                data={data}
                id={filterPopoverId}
                open={filterPopoverOpen}
                anchorEl={filterAnchorEl}
                onClose={handleFilterPopoverClose}
            />
        </>
    )
}
export default TopbarNotifications;
