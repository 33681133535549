import { apiSlice } from "../createApiSlice";

const NOTIFICATIONS_BASE = "/notifications";
const buildUrl = (endpoint) => NOTIFICATIONS_BASE + endpoint;
const ApiName = "Notifications";

const notificationsSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotifications: builder.query({
            query: () => ({
                url: buildUrl(`/`),
                method: 'GET',
            }),
            providesTags: (result, error, arg) => [{ type: `${ApiName}`}],
            
        }),
        
    })
});

export const {
    useGetNotificationsQuery,
    
  } = notificationsSlice;