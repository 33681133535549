import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import { tokens } from "../../../../../theme";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import AddIcon from '@mui/icons-material/Add';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MpAddUser from "../../../../../components/global/ModalsProgression/MpAddUser/MpAddUser";
import UserCard from "../../../../../components/global/cards/settings/UserCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../components/theme/sidepanel2/SidepanelLoader";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetUsersQuery } from "../../../../../newapi/user/userSlice";
import { selectUserPermission } from "../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from "../../../../../utils/permissions";

const SpUserDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpUserDetails/SpUserDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
                <Shbutton 
                    onClick={() => props.handleOpen(true)}
                    className={"group-btn"} 
                    color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                    variant="contained" 
                    endIcon={<AddIcon/>}>{t("new_user")}</Shbutton>
            )}
        </ButtonGroup>
    )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};


const All = (props, ) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const relation_id = useSelector((state) => state.settings.environment.selectedId);

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_USER_MANAGE])
    );

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };

    const [userId, setUserId] = useState();
    const userIdRef = useRef(userId);
    
    const getUserDetails = (params) => {
        if (!params) return;
    
        const newuserId = parseInt(params.row.id);
        // Only update state if the userId has actually changed
        if (userIdRef.current !== newuserId) {
            if(!spLoading) {
                setSpLoading(true);
            } 
            setUserId(newuserId);
            userIdRef.current = newuserId; // Update the ref to the new value
        }
            setIsExtended(true);
    };
    
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
            mobileActions:<GridActions hasCreatePermission={hasCreatePermission} handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    
   
    
    const columns = [
        {field: "employee_code", headerName: t("employee") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.value}</Text>
            )
        }},
        {field: "firstname", headerName: t("firstname") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },
        {field: "insertion", headerName: t("insertion") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },

        {field: "lastname", headerName: t("lastname") , flex:2, cellClassName: "user", renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },
        {field: "email", headerName: t("emailaddress") , flex:2, cellClassName: "user", 
        renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton/>
            }
            return params.value
            }   
        },
        // {field: "fitsPost", headerName: "Fits in dutch postorder", flex:1, renderCell : (cellValues) => {
        //     return (
        //         cellValues.value === "true" ?
        //             <Text variant="green"><CheckOutlinedIcon fontSize="small"/></Text>:
        //         cellValues.value === "false" ?
        //             <Text variant="red"><CloseOutlinedIcon fontSize="Small" /></Text> 
        //         :
        //             <Text variant="orange"><HorizontalRuleOutlinedIcon fontSize="Small"/></Text>
        //     )
        // }},

        // {field: "channels", headerName: "Channels", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "weight", headerName: "Weight including label", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        // {field: "length", headerName: t("dimensions_lwh"), flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{`${cellValues.row.length} x ${cellValues.row.width} x ${cellValues.row.height}`}</Text>
        //     )
        // }},
        // {field: "barcodes", headerName: "Nr. of barcodes", flex:1, renderCell : (cellValues) => {
        //     return (
        //         <Text>{cellValues.value}</Text>
        //     )
        // }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // return "hi";
                return (
                    <UserCard skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                );
                // console.log(cellValues.row);
               // return (
                    // <AllCarriersCard {...cellValues.row} />
               // )
            }
        }
    ];

    
    return (
            <Box
                sx={{
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingTop:0,
                        paddingX:0,
                    }
                }}
            >
                

                    <ShDataGrid2 key={theme.palette.mode}
                        itemId={{type:"relation",id:relation_id}}
                        content={useGetUsersQuery}
                        gridActions={<GridActions hasCreatePermission={hasCreatePermission} handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                        title={t("overview")}
                        gridOptions={gridOptions} 
                        columns={columns} 
                        sortModel={{field: columns[3].field,type:"ASC"}}
                        onRowClick={getUserDetails}
                        rows={data}>
                    </ShDataGrid2>
                        


                    <MpAddUser onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>
                    
                    {spLoading && 
                    <SpUserDetails 
                        isExtended={isExtended} 
                        setIsExtended={setIsExtended} 
                        userId={userId}
                    />
                    }
                </Box>
    )
};



export default All;