import { Box, ButtonBase, Typography, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";


import ShDateRangePicker from "../../../components/theme/ShDateRangePicker";
import Subheading from "../../../components/theme/text/Subheading";
import Text from "../../../components/theme/text/Text";
import { useTopbar } from "../../../contexts/TopbarProvider";
import { formatNumber, formatPrice } from "../../../utils/helpers";
import { ReactComponent as ArrowAlt } from '../../../styles/svg/arrow_alt.svg';
import { useNavigate } from "react-router-dom";
import SidepanelLoader from "../../../components/theme/sidepanel2/SidepanelLoader";
import { useGetRelationFullDashboardQuery } from "../../../newapi/order/ordersSlice";
import { differenceInDays, parseISO, startOfDay, subDays } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setRelationDashboardDateFilter } from "../../../newapi/global/relation/state/relationDashboardSlice";
import cx from 'classnames';
import { format } from "date-fns";
const SpStockDetails = SidepanelLoader(() => import("../../../components/global/Sidepanels/SpStockDetails/SPStockDetails"));
// global/Sidepanels/SpStockDetails/SPStockDetails


const TopBarFilter = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const relationDashboardDates = useSelector((state) => state.relationDashboard);
    const dispatch = useDispatch();

    const saveDate = (value) => {
        dispatch(setRelationDashboardDateFilter({
            startDate:value[0].formattedStartDate,
            endDate:value[0].formattedEndDate,
        }))
    }

    return (
        <ShDateRangePicker 
            select={saveDate}
            explicit
            startDate={relationDashboardDates.startDate} 
            endDate={relationDashboardDates.endDate} 
            darker 
            size={40}
            sx={{
                background: `${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]}`,
                border:`2px solid ${"transparent"}`,
                "&:hover" : {
                    background: `${theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100]}`,
                    border:`2px solid ${colors.grey[200]}`
                },
                '@media screen and (max-width: 47.9375em)': {
                    height:32,
                    px:2,
                },
                '@media screen and (max-width: 34.375em)': {
                    display:'none',
                },
            }}
        />
    )
}

const RelationDashboard = () => {
    
    const { t, ready,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { showExtraComponent, clearExtraComponent } = useTopbar();
    const navigate = useNavigate();

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);

    const [productsSpIsExtended, setProductsSpIsExtended] = useState(false);
    const [spStockLoading, setSpStockLoading] = useState(false);
    const spStockLoadingRef = useRef(spStockLoading);

    const relationDashboardDates = useSelector((state) => state.relationDashboard);
    const [previousRange, setPreviousRange] = useState(null);

    const {data: relationDashboardHeaderData ,isLoading, isFetching} = useGetRelationFullDashboardQuery(relationDashboardDates);
    const dispatch = useDispatch();

    const saveDate = (value) => {
        dispatch(setRelationDashboardDateFilter({
            startDate:value[0].formattedStartDate,
            endDate:value[0].formattedEndDate,
        }))
    }

    useEffect(() => {
        // Example: show different components based on user privileges
        showExtraComponent(<TopBarFilter/>);
  
        return () => {
            clearExtraComponent();
        };
    }, []);

    const getPreviousRange = (startDate, endDate) => {
        const start = parseISO(startDate);
        const end = parseISO(endDate);
    
        const dayDifference = differenceInDays(end, start);

        const newStartDate = subDays(start, dayDifference + 1); 

        const newEndDate = subDays(end, dayDifference + 1); 
    
        return {
            startDate: format(newStartDate, 'yyyy-MM-dd'),
            endDate: format(newEndDate, 'yyyy-MM-dd'),
        };
    };

    useEffect(() => {
        if(relationDashboardDates && relationDashboardDates.startDate){
            setPreviousRange(getPreviousRange(
                relationDashboardDates.startDate,
                relationDashboardDates.endDate,
            ));
        }
    },[relationDashboardDates])

        
    const getProductDetails = (productId) => {
        const newProductId = parseInt(productId);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {

            if (!spStockLoadingRef.current) {
                setSpStockLoading(true);
            }
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        setProductsSpIsExtended(true);
    };

    const calculatePercentageDifferenceAndClass = (current, previous) => {
        if (previous === null) {
            // Handle the case where previous is null (neutral state)
            return { percentage: 0, className: 'neutral' };
        }
      
        // Calculate the percentage difference
        if (previous === 0) return { percentage: 0, className: 'neutral' }; // Prevent division by zero
        
        const percentageDifference = ((current - previous) / previous) * 100;
      
        // Determine the class for styling based on the percentage difference
        let className = 'neutral';
        if (percentageDifference > 0) {
            className = 'positive';
        } else if (percentageDifference < 0) {
            className = 'negative';
        }
      
        return { percentage: percentageDifference, className };
    };


    return (
        <Box 
            sx={{
                background:colors.bg['tertiary'], 
                width:1,
                paddingTop:9,
            }}
        >

            <Box 
                sx={{
                    mt:2,
                    display:'flex',
                    justifyContent:"space-between",
                    alignItems:'center',
                    paddingX:4,
                    "@media screen and (max-width: 63.9735em)" :{
                        flexDirection:'column',
                        alignItems:'flex-start',
                        gap:0,
                    },
                    "@media screen and (max-width: 47.9735em)" :{
                        px:3,
                    },
                    '@media screen and (max-width: 34.375em)': {
                        mt:0,
                        gap:3,
                    },
                }}
            >
                <ShDateRangePicker 
                    startDate={relationDashboardDates.startDate} 
                    endDate={relationDashboardDates.endDate} 
                    select={saveDate}
                    explicit
                    darker 
                    size={40}
                    sx={{
                        width:1,
                        background: `${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[200]}`,
                        border:`2px solid ${"transparent"}`,
                        "&:hover" : {
                            background: `${theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100]}`,
                            border:`2px solid ${colors.grey[200]}`
                        },
                        // '@media screen and (min-width: 34.375em)': {
                        //     display:'none',
                        // },
                    }}
                    wrapperProps={{
                        '@media screen and (min-width: 34.375em)': {
                            display:'none',
                        },
                        width:1,
                    }}
                />
                <Box
                    sx={{
                        "@media screen and (max-width: 63.9735em)" :{
                            mb:4,
                        },
                        '@media screen and (max-width: 34.375em)': {
                            mb:0,
                        },

                    }}
                >
                    <Subheading
                        sx={{
                            fontWeight:"normal"
                        }}
                    >
                        {t("revenue")}
                    </Subheading>
                    <Box 
                        sx={{
                            mt:1,
                            display:'flex',
                            alignItems:"center",
                        }}
                    >
                        
                        <Typography
                            sx={{
                                lineHeight:"32px",
                                fontFamily:"Roboto, sans-serif",
                                fontSize:32,
                                fontWeight:"1000",
                                color:colors.txt["primary"]
                            }}
                        >
                            
                            {!isLoading && !isFetching && formatPrice(i18n, relationDashboardHeaderData?.totalRevenue.current)}
                        </Typography>

                        {!isLoading && !isFetching &&(() => {
                            const { percentage, className } = calculatePercentageDifferenceAndClass(
                                relationDashboardHeaderData.totalRevenue.current,
                                relationDashboardHeaderData.totalRevenue.previous
                            );
                            return (
                                <Box
                                    className={cx(className,{})}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        borderRadius: 3,
                                        height: 24,
                                        lineHeight: "24px",
                                        fontWeight: "bold",
                                        px: 1.5,
                                        background: colors.grey[200], // Keep your original background
                                        ml: 1.5,
                                        fontFamily: "Roboto, sans-serif",
                                        fontSize: 14,
                                        gap: 0.5,
                                        color: colors.txt["primary"],
                                        "& .arrow": {
                                            display:'none',
                                            width: 12,
                                            "& *": {
                                                transition: "250ms",
                                                // fill: theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["light"],
                                            },
                                        },

                                        "&.positive" : {
                                            color: theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["light"],
                                            background:colors.green[400],
                                            "& .arrow": {
                                                display:'block',
                                                width: 12,
                                                transform: "rotate(-90deg)",
                                                "& *": {
                                                    // transition: "250ms",
                                                    fill: theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["light"],
                                                },
                                            },
                                        },

                                        "&.negative" : {
                                            color: theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["light"],
                                            background:colors.red[400],
                                            "& .arrow": {
                                                display:'block',
                                                width: 12,
                                                transform: "rotate(90deg)",
                                                "& *": {
                                                    // transition: "250ms",
                                                    fill: theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["light"],
                                                },
                                            },
                                        },
                                    }}
                                >
                                    <ArrowAlt className="arrow" />
                                    {formatNumber(i18n,percentage.toFixed(2))}%
                                </Box>
                            );
                        })()}


                    </Box>
                    <Box 
                        sx={{
                            mt:0.5,
                            display:"flex",
                            gap:1,
                            alignItems:'center',
                        }}
                    >
                        <Text light>{t('vs')} {t("previous")}</Text>
                        <Box
                            sx={{
                                padding:0,
                                px:0.5,
                                borderRadius:1,
                                height:18,
                                background:"orange",
                                display:"flex",
                                gap:1,
                                alignItems:'center',
                                background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[50] ,
                                "& .arrow" : {
                                    height:16,
                                }
                            }}
                            
                        >
                            <Text>{previousRange?.startDate}</Text>
                            <ArrowAlt  className="arrow"/>
                            <Text>{previousRange?.endDate}</Text>
                        </Box>
                    </Box>
                </Box>

                <Box
                    sx={{
                        minWidth:200,
                        display:"flex",
                        gap:2,
                        '@media screen and (max-width: 47.9375em)': {
                            width:1,
                            display: 'grid',          // Change to grid on smaller screens
                            gridTemplateColumns: 'repeat(2, 1fr)', // Two columns
                            gridTemplateRows: 'repeat(2, auto)',  // Two rows
                            gap: 2,                   // Maintain the gap between items
                        },
                        '@media screen and (max-width: 25em)': {
                            display:'flex',
                            flexDirection:'column',
                        },
                    }}
                >
                    {/* Order amount box */}
                    {!isLoading && !isFetching && (() => {
                            const { percentage, className } = calculatePercentageDifferenceAndClass(
                                relationDashboardHeaderData.totalOrders.current,
                                relationDashboardHeaderData.totalOrders.previous
                            );
                            return (
                                <ButtonBase
                                    onClick={() => {
                                        navigate("/relation/outbound");
                                    }}
                                    className={cx(`rd-order-amount-card ${className}`,{})}
                                    sx={{
                                        minWidth:120,
                                        px:2.5,
                                        py:2,
                                        pb:1,
                                        display:"flex",
                                        alignItems:"center",
                                        flexDirection:"column",
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                                        border:`1px solid ${colors.grey[100]}`,
                                        boxShadow:`0px 2px 4px ${colors.grey[200]}`,
                                        borderRadius:4,
                                        gap:1,
                                        transition:"250ms",

                                        "&:hover" : {
                                            transition:"250ms",
                                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                                            boxShadow:`0px 4px 16px ${colors.grey[200]}`,
                                            border:`1px solid ${colors.grey[200]}`,
                                        },
                                        
                                        
                                    }}
                                    // Adding a custom TouchRipple color here
                                    TouchRippleProps={{
                                    style: {
                                        color: theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400],  // Change this to your desired ripple color
                                    },
                                    }}
                                >
                                    <Text>{t("orders")}</Text>
                                    <Box
                                        sx={{
                                            display:"flex",
                                            alignItems:"center",
                                            borderRadius:3,
                                            height:24,
                                            px:1.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100]
                                        }}
                                    >
                                        <Text>{relationDashboardHeaderData.totalOrders.current}</Text>
                                    </Box>

                                    <Box
                                        className={cx(`rd-order-amount-card ${className}`,{})}
                                        sx={{
                                            // mt:1,
                                            display:"flex",
                                            alignItems:'center',
                                            lineHeight:'24px',
                                            fontWeight:"bold",
                                            fontFamily:"Roboto, sans-serif",
                                            fontSize:14,
                                            gap:0.5,
                                            color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["primary"],
                                            "& .arrow" : {
                                                width:12,
                                                // transform:"rotate(-90deg)",
                                                
                                                "& *" : {
                                                    transition:"250ms",
                                                    fill: theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["secondary"],
                                                },
                                            },
                                            
    
                                            "&.positive" : {
                                                "& .arrow": {
                                                    transform: "rotate(-90deg)",
                                                    "& *": {
                                                        fill: colors.green[400],
                                                    },
                                                },
                                            },
    
                                            "&.negative" : {
                                                "& .arrow": {
                                                    transform: "rotate(90deg)",
                                                    "& *": {
                                                        fill: colors.red[400],
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <ArrowAlt className="arrow"/>
                                        {formatNumber(i18n,percentage.toFixed(2))}%
                                    </Box>
                                </ButtonBase>
                            );
                        }
                    )()}

                    {/* Highest order */}
                    {!isLoading && !isFetching && (() => {
                            const { percentage, className } = calculatePercentageDifferenceAndClass(
                                relationDashboardHeaderData.highestRevenue.current,
                                relationDashboardHeaderData.highestRevenue.previous
                            );
                            return (
                                <Box
                                    onClick={() => {
                                        // getProductDetails(1);
                                    }}
                                    className="rd-highest-order-card"
                                    sx={{
                                        minWidth:180,
                                        px:2,
                                        py:1.7,
                                        pb:1,
                                        display:"flex",
                                        flexDirection:"column",
                                        alignItems:'flex-start',
                                        background:theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400],
                                        border:`1px solid ${colors.grey[100]}`,
                                        boxShadow:`0px 2px 4px ${colors.grey[200]}`,
                                        borderRadius:4,
                                        gap:1,
                                    }}
                                >
                                    <Text color={theme.palette.mode === "dark" ? colors.txt["softened"] : colors.txt["white"]}>{t("highest_order")}</Text>

                                    <Box
                                        sx={{
                                            mt:"auto",
                                            display:"flex",
                                            flexDirection:"column",
                                            alignItems:'flex-start',
                                        }}
                                    >
                                        <Subheading color={theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"]}>
                                            {formatPrice(i18n,relationDashboardHeaderData.highestRevenue.current)}
                                        </Subheading>

                                        <Box
                                            className={cx(`rd-order-amount-card ${className}`,{})}
                                            sx={{
                                                // mt:1,
                                                display:"flex",
                                                alignItems:'center',
                                                lineHeight:'24px',
                                                fontFamily:"Roboto, sans-serif",
                                                fontSize:14,
                                                gap:0.5,
                                                color: theme.palette.mode === "dark" ? colors.txt["primary"] : tokens("dark").txt["primary"],
                                                "& .arrow" : {
                                                    // display:"none",
                                                    width:12,
                                                    // transform:"rotate(-90deg)",
                                                    
                                                    "& *" : {
                                                        transition:"250ms",
                                                        fill: theme.palette.mode === "dark" ? colors.txt["secondary"] : tokens("dark").txt["softened"],
                                                    },
                                                },
                                                
        
                                                "&.positive" : {
                                                    // display:"block",
                                                    color: tokens("dark").green[400],
                                                    "& .arrow": {
                                                        transform: "rotate(-90deg)",
                                                        "& *": {
                                                            fill: tokens("dark").green[400],
                                                        },
                                                    },
                                                },
        
                                                "&.negative" : {
                                                    // display:"block",
                                                    color: tokens("dark").red[400],
                                                    "& .arrow": {
                                                        transform: "rotate(90deg)",
                                                        "& *": {
                                                            fill: tokens("dark").red[400],
                                                        },
                                                    },
                                                },
                                            }}
                                        >
                                            <ArrowAlt className="arrow"/>
                                            {formatNumber(i18n,percentage.toFixed(2))}%
                                        </Box>
                                        {/* <Text color={theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["white"]}>O12312</Text> */}
                                    </Box>
                                    

                                </Box>
                            );
                        }
                    )()}

                    {/* average-order box */}
                    {!isLoading && !isFetching && (() => {
                            const { percentage, className } = calculatePercentageDifferenceAndClass(
                                relationDashboardHeaderData.averageRevenue.current,
                                relationDashboardHeaderData.averageRevenue.previous
                            );
                            return (
                                <ButtonBase
                                    onClick={() => {
                                        navigate("/relation/outbound");
                                    }}
                                    className="rd-average-order-card"
                                    sx={{
                                        px:2.5,
                                        py:2,
                                        pb:1,
                                        display:"flex",
                                        alignItems:"center",
                                        flexDirection:"column",
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                                        border:`1px solid ${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`,
                                        boxShadow:`0px 2px 4px ${theme.palette.mode === "dark" ? colors.blue[200] : colors.primary[200]}`,
                                        borderRadius:4,
                                        gap:1,
                                        transition:"250ms",

                                        "&:hover" : {
                                            transition:"250ms",
                                            background:theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[0],
                                            boxShadow:`0px 4px 16px ${theme.palette.mode === "dark" ? colors.blue[200] : colors.primary[200]}`,
                                        }
                                        
                                    }}
                                    // Adding a custom TouchRipple color here
                                    TouchRippleProps={{
                                    style: {
                                        color: theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[200],  // Change this to your desired ripple color
                                    },
                                    }}
                                >
                                    <Text>{t("avg_rev_shorthand")}</Text>
                                    <Box
                                        sx={{
                                            display:"flex",
                                            alignItems:'center',
                                            borderRadius:3,
                                            height:24,
                                            lineHeight:'24px',
                                            fontWeight:"bold",
                                            pr:1.5,
                                            pl:1,
                                            background:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                                            fontFamily:"Roboto, sans-serif",
                                            fontSize:14,
                                            gap:1,
                                            color: theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["light"],
                                        
                                        }}
                                    >

                                        {formatPrice(i18n,relationDashboardHeaderData.averageRevenue.current)}
                                    </Box>

                                    <Box
                                        className={cx(`${className}`,{})}
                                        sx={{
                                            display:"flex",
                                            alignItems:'center',
                                            lineHeight:'24px',
                                            fontWeight:"bold",
                                            fontFamily:"Roboto, sans-serif",
                                            fontSize:14,
                                            gap:0.5,
                                            color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["primary"],
                                            "& .arrow" : {
                                                width:12,
                                                
                                                "& *" : {
                                                    transition:"250ms",
                                                    fill: theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["secondary"],
                                                },
                                            },
                                            
    
                                            "&.positive" : {
                                                "& .arrow": {
                                                    transform: "rotate(-90deg)",
                                                    "& *": {
                                                        fill: colors.green[400],
                                                    },
                                                },
                                            },
    
                                            "&.negative" : {
                                                "& .arrow": {
                                                    transform: "rotate(90deg)",
                                                    "& *": {
                                                        fill: colors.red[400],
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <ArrowAlt className="arrow"/>
                                        {/* {formatNumber(i18n,8.12)} */}
                                        {formatNumber(i18n,percentage.toFixed(2))}%
                                    </Box>

                                </ButtonBase>
                            );
                        }
                    )()}

                    {/* Returns amount box */}
                    {!isLoading && !isFetching && (() => {
                            const { percentage, className } = calculatePercentageDifferenceAndClass(
                                relationDashboardHeaderData.returns.current,
                                relationDashboardHeaderData.returns.previous
                            );
                            return (
                                <ButtonBase
                                    onClick={() => {
                                        navigate("/relation/returns");
                                    }}
                                    className="rd-return-amount-card"
                                    sx={{
                                        minWidth:120,
                                        px:2.5,
                                        py:2,
                                        pb:1,
                                        display:"flex",
                                        alignItems:"center",
                                        flexDirection:"column",
                                        background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[0],
                                        border:`1px solid ${colors.grey[100]}`,
                                        boxShadow:`0px 2px 4px ${colors.grey[200]}`,
                                        borderRadius:4,
                                        gap:1,
                                        transition:"250ms",

                                        "&:hover" : {
                                            transition:"250ms",
                                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                                            boxShadow:`0px 4px 16px ${colors.grey[200]}`,
                                            border:`1px solid ${colors.grey[200]}`,
                                        }
                                        
                                    }}
                                    // Adding a custom TouchRipple color here
                                    TouchRippleProps={{
                                    style: {
                                        color: theme.palette.mode === "dark" ? colors.grey[800] : colors.grey[400],  // Change this to your desired ripple color
                                    },
                                    }}
                                >
                                    <Text>{t("returns")}</Text>
                                    <Box
                                        sx={{
                                            display:"flex",
                                            alignItems:"center",
                                            borderRadius:3,
                                            height:24,
                                            px:1.5,
                                            background:theme.palette.mode === "dark" ? colors.grey[600] : colors.grey[100]
                                        }}
                                    >
                                        <Text>{relationDashboardHeaderData.returns.current}</Text>
                                    </Box>

                                    <Box
                                    className={cx(`${className}`,{})}
                                        sx={{
                                            // mt:1,
                                            display:"flex",
                                            alignItems:'center',
                                            lineHeight:'24px',
                                            fontWeight:"bold",
                                            fontFamily:"Roboto, sans-serif",
                                            fontSize:14,
                                            gap:0.5,
                                            color: theme.palette.mode === "dark" ? colors.txt["primary"] : colors.txt["primary"],
                                            "& .arrow" : {
                                                width:12,
                                                
                                                "& *" : {
                                                    transition:"250ms",
                                                    fill: theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["secondary"],
                                                },
                                            },
                                            
    
                                            "&.positive" : {
                                                "& .arrow": {
                                                    transform: "rotate(-90deg)",
                                                    "& *": {
                                                        fill: colors.green[400],
                                                        // fill: colors.red[400],
                                                    },
                                                },
                                            },
    
                                            "&.negative" : {
                                                "& .arrow": {
                                                    transform: "rotate(90deg)",
                                                    "& *": {
                                                        fill: colors.red[400],
                                                        fill: colors.green[400],
                                                        // fill: colors.red[400],
                                                    },
                                                },
                                            },
                                        }}
                                    >
                                        <ArrowAlt className="arrow"/>
                                        {formatNumber(i18n,percentage.toFixed(2))}%
                                    </Box>
                                </ButtonBase>
                            );
                        }
                    )()}
                </Box>
            </Box>
            
            <Box
                sx={{
                    mt:5,
                    width:1,
                    minHeight:"calc(100% - 163.5px)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,

                    pt:4,
                    px:4,
                    display:"flex",
                    flexDirection:"column",

                    "&:before" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        left:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        top:"0px",
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        transition:"250ms"
                    },

                    "&:after" : {
                        content: "''",
                        display:"block",
                        width:"32px",
                        height:"32px",
                        position:"absolute",
                        top:0,
                        zIndex:1,
                        backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        right:0,
                        height:"48px",
                        width:"24px",
                        borderTopLeftRadius:"24px",
                        boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                        transform:"scaleX(-1)",
                        transition:"250ms"
                    },

                    "@media screen and (max-width: 47.9375em)" : {
                        "&:after, &:before" : {
                            top:"-0px"
                        }
                    }
                }}
            >
                {/* <Typography
                    sx={{
                        lineHeight:"32px",
                        fontFamily:"Roboto, sans-serif",
                        fontSize:21,
                        fontWeight:"700",
                        color:colors.txt["secondary"]
                    }}
                >
                    {t("product_statistics")}
                </Typography>

                <Box
                    sx={{
                        display:"flex",
                        gap:4,
                        mt:3,
                    }}
                >
                    <Box
                        sx={{
                            flex:1,
                            p:3,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100],
                            borderRadius:4,
                        }}
                    >
                        <Subheading>
                            {t("top_10_hardlopers")}
                        </Subheading>

                        <Box>

                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex:1,
                            p:3,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]
                        }}
                    >
                        <Subheading>
                            {t("top_10_hardlopers")}
                        </Subheading>

                        <Box></Box>
                    </Box>

                </Box> */}

                

            </Box>


            {spStockLoading && 
                <SpStockDetails
                    isExtended={productsSpIsExtended}
                    setIsExtended={setProductsSpIsExtended}
                    productId={productId}
                />
            }
        </Box>
    );
};

export default RelationDashboard;

