import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { useGetCarriersTypesQuery } from "../../../../../newapi/carrier/carrierSlice";
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { tokens } from '../../../../../theme';
import { countriesFromLocale } from "../../../../../utils/countries";
import ConditionRule from "../../../../Streams/Conditions/ConditionRule";
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import Text from '../../../../theme/text/Text';
import { errorMapWithArrayProcessor } from '../../../../../utils/helpers';





/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState({});
    

    const handleErrors = (errors) => {
      const { fieldErrors, generalErrors } = errorMapWithArrayProcessor(errors, /conditions\[(\d+)\]\.(key|value)/);
      setValidationErrors({ fieldErrors, generalErrors });
    };

    const {data: packageAdvices} = useGetCarriersTypesQuery();
    
    const language = useSelector((state) => state.settings.language);
    const countries = countriesFromLocale(language, true).map(country => ({
        id: country.alpha2,
        name: country.name
    }));

    const [conditions,setConditions] = useState(props.localData.conditions ?? []);

    useEffect(() => {
        props.handleDataUpdate({ conditions: conditions });
    }, [conditions]);

    // Load conditions from props
    useEffect(() => {
        if (props.data.conditions && props.data.conditions.length > 0) {
            setConditions(props.data.conditions);
        }
    }, []);

    return (
        <Box>
            {validationErrors.generalErrors && Object.keys(validationErrors.generalErrors).map((errorKey) => (
                errorKey === "conditions" &&
                <Box 
                    sx={{
                        height:32,
                        width:1,
                        textAlign:"center",
                        lineHeight:"32px",
                        borderRadius:4,
                        border:`1px solid ${colors.red[400]}`,
                        background:colors.red[100],
                        mb:2,
                    }}
                >
                    <Text variant="red" key={errorKey}>{t(validationErrors.generalErrors[errorKey])}</Text>
                </Box>
            ))}
            <ConditionRule
                countries={countries}
                packageAdvices={packageAdvices}
                setCondtions={setConditions}
                conditions={conditions}
                errorMap={validationErrors.fieldErrors}
            />
        </Box>
    );
};

const StreamConditions = (props) => {
    const { t } = useTranslation();

    const data = useSelector((state) => state.progressiontabs.data);
    const [localData, setLocalData] = useState(data);

    useEffect(() => {
        setLocalData(data);
    },[data])

    return (
        <ProgressionTab 
            tabId="tabConditions" 
            subtitle={t("conditions")} 
            step={2}
            nextTab={"tabShipper"}
            maxSteps={4}
            updateDataWithValidation={localData} //updates pre-tab-switch
        >
            <Content localData={localData} setLocalData={setLocalData}/>
        </ProgressionTab>
    )
}

export default StreamConditions;

