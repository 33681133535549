import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
// import { tokens } from "../../../../../../theme";

// import Text from "../../../../../../components/theme/text/Text";

import { GridCheckIcon, GridCloseIcon } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { RelationWebshopIntegrationCard } from "../../../../../components/global/cards/other/WebshopIntegrationCard";
import MpAddWebshop from "../../../../../components/global/ModalsProgression/MpAddVirtualProduct/MpAddVirtualProduct";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../components/theme/sidepanel2/SidepanelLoader";
import ShDoubleTextSkeleton from "../../../../../components/theme/skeleton/ShDoubleTextSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../../components/theme/text/Text";
import { useGetWebshopCredentialsQuery } from "../../../../../newapi/webshop/webshopSlice";
import { tokens } from "../../../../../theme";

const SpWebshopCredentialsDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpWebshopCredentialsDetails/SpWebshopCredentialsDetails"));

const GridActions = (props, i18n) => {
    const theme = useTheme();
    useEffect(() => {

    },[props.open]);
    const {t} = useTranslation();
    return (      
        <></>
    );
}


let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const Integrator = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [isExtended,setIsExtended] = useState(false);
    const [config, setConfig] = useState({"integrator": 1});
    const [spLoading, setSpLoading] = useState();
    
    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };


    const [webshopCredentialsId, setWebshopCredentialsId] = useState();
    const webshopCredentialsIdRef = useRef(webshopCredentialsId);
    
    const getWebshopCredentialDetails = (params) => {
        if (!params) return;
    
        const newwebshopCredentialsId = parseInt(params.row.id);
        // Only update state if the webshopCredentialsId has actually changed
        if (webshopCredentialsIdRef.current !== newwebshopCredentialsId) {
            
            if(!spLoading) {
                setSpLoading(true);
            }
            setWebshopCredentialsId(newwebshopCredentialsId);
            webshopCredentialsIdRef.current = newwebshopCredentialsId; // Update the ref to the new value
        }
        setIsExtended(true);
    };
    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            mobileActions:<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    const columns = [
        {field: "description", headerName: t("channel") , flex:2, cellClassName: "webshop", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShDoubleTextSkeleton/>
            }

            return (
                <Box>
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
                <br/>
                <Text>{cellValues.row.integrator?.name}</Text>
                </Box>
            )
        }},
        {field: "warehouse", headerName: t("warehouse") , flex:2, cellClassName: "warehouse", renderCell : (cellValues) => {
            if(cellValues.row.skeleton) {
                return <ShTextSkeleton/>
            }

            return (
                <Text  >{cellValues.value?.name}</Text>
            )
        }},
        {
            field: "syncOrders", 
            headerName: t('syncOrders'),
            flex:1, 
            cellClassName: "sku-cell", 
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.syncOrders ? <GridCheckIcon /> : <GridCloseIcon />;
                
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                if(cellValues.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <RelationWebshopIntegrationCard skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                )
            }
        }
    ];

    return (
        <Box
            sx={{
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetWebshopCredentialsQuery}
                config={config}
                gridActions={<GridActions handleOpen={() => setAddModalOpen(true)}></GridActions>} 
                title={t("saleschannels")}
                gridOptions={gridOptions} 
                    
            sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns}
                rows={data}
                onRowClick={getWebshopCredentialDetails}>
            </ShDataGrid2>
            <MpAddWebshop onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

            {spLoading && 
                <SpWebshopCredentialsDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    webshopCredentialsId={webshopCredentialsId}
                    // data={credentialData}   
                />
            }
            
        </Box>
    )
};

export default Integrator;