import { countriesFromLocale } from "../../../utils/countries";

export const DND_STREAM_RULE = "STREAM_RULE";
export const DND_STREAM_RULE_LINE = "STREAM_RULE_LINE";

export const getCountries = (language) => countriesFromLocale(language);

export const dropdownKeys = {
    destination: 'destination'
}

export const conditionKeys = [
    'colli',
    'weight',
    'length',
    'width',
    'height',
    'volume',
    'time'
]

export const condtionLineKeys = [
    ...conditionKeys,
    ...Object.values(dropdownKeys),
]

export const getConditionKey = (key) => {
    if(condtionLineKeys.includes(key)) {
        return key;
    }
    return "choose_key";
}

export const conditions = (key) => {
    if(conditionKeys.includes(key)) {
        return [
            'equalto',
            'notequalto',
            'greaterthan',
            'smallerthan',
            'greaterthanorequalto',
            'smallerthanorequalto',
        ]
    }
    return ['equalto','notequalto'];
}

export const getDisplayValue = (condition, countries) => {
    switch(condition.key) {
        case "deliverycountry": return countries.find(country => country.alpha2 === condition.value)?.name;
        default: return condition.value;
    }
}

export const getMobileCondition = (condition) => {
    switch (condition) {
        case "greaterthan" : return ">";
        case "smallerthan" : return "<";
        case "equalto" : return "=";
        case "notequalto" : return "!=";
        case "greaterthanorequalto" : return ">=";
        case "smallerthanorequalto" : return "<=";
        case "isbefore": return "<"; 
        case "isafterorequalto": return ">="; 
        case "allproductsat": return "@";
        case "anyproductat": return "#";  
        case "isinselection": return "^"; 
        case "isnotinselection": return "!^"; 
        case "is": return "=";  
        case "isnot": return "!="; 
        case "contains": return "~";  
        case "doesnotcontain": return "!~"; 
        case "startswith": return "<<";  
        case "doesnotstartswith": return "!<<";  
        case "endswith": return ">>";  
        case "doesnotendwith": return "!>>";  
        default: return "?";  
    }
}


export const textValueTypes = ["string", "time", "date"];
export const numberValueTypes = ["number"];
export const dropdownValueTypes = ["country_list", "day_with_additional_list", "relation_list", "warehouse_list", "warehouse_location_list", "invoice_category_list"];

export const getListOptions = (valueType, objects, t) => {
    switch(valueType) {
        case "country_list":
            return objects;
        case "day_with_additional_list":
            return [{
                id: 1,
                name: t("monday")
            },
            {
                id: 2,
                name: t("tuesday")
            },
            {
                id: 3,
                name: t("wednesday")
            },  
            {
                id: 4,
                name: t("thursday")
            },
            {
                id: 5,
                name: t("friday")
            },
            {
                id: 6,
                name: t("saturday")
            },  
            {
                id: 0,
                name: t("sunday")
            },
            {
                id: "workingdays",
                name: t("workingdays")
            },
            {
                id: "weekend",
                name: t("weekend")
            }
        ];
        case "delivery_method_list":
            return [{
                id: "vvb",
                name: t("verzenden_via_bol")
            },
            {
                id: "regular",
                name: t("regular_shipment")
            },
            {
                id: "pickup",
                name: t("pickup")
            },
        ];
        case "relation_list":
            return [];
        case "warehouse_list":
            return [];
        case "warehouse_zone_list":
            return [];
        case "invoice_category_list":
            return objects;
        default:
            return [];
    }
}