const { general_states } = require("./staticEnums");
const orderStateMap = (theme) => ({
    [general_states.ACTIVE]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "active",
        state: general_states.ACTIVE, 
    },
    [general_states.OPEN]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "order_can_be_picked",
        state: general_states.OPEN, 
    },
    [general_states.SHIPPED]: {
        labelcolor: "green",
        labeltext: "shipped",
        state: general_states.SHIPPED, 
    },
    [general_states.FULLYPICKED]: {
        labelcolor: "green",
        labeltext: "fullypicked",
        state: general_states.FULLYPICKED, 
    },
    [general_states.PICKED]: {
        labelcolor: "green",
        labeltext: "picked",
        state: general_states.PICKED, 
    },
    [general_states.BLOCKED]: {
        labelcolor: "red",
        labeltext: "blocked",
        state: general_states.BLOCKED, 
    },
    [general_states.MAINTENANCE]: {
        labelcolor: "red",
        labeltext: "maintenance",
        state: general_states.MAINTENANCE, 
    },
    [general_states.CANCELLED]: {
        labelcolor: theme.palette.mode === "dark" ? "white" : "grey",
        labeltext: "cancelled",
        state: general_states.CANCELLED, 
    },
    [general_states.REPLENISH]: {
        labelcolor: "purple",
        labeltext: "replenish",
        state: general_states.REPLENISH, 
    },
    [general_states.BACKORDERED]: {
        labelcolor: "pink",
        labeltext: "backordered",
        state: general_states.BACKORDERED, 
    },
    [general_states.SNOOZE]: {
        labelcolor: "pink",
        labeltext: "snooze",
        state: general_states.SNOOZE, 
    },
    [general_states.PICKING_IN_PROGRESS]: {
        labelcolor: "purple",
        labeltext: "picking_in_progress",
        state: general_states.PICKING_IN_PROGRESS, 
    },
    [general_states.UNKNOWN]: {
        labelcolor: "orange",
        labeltext: "unknown",
        state: general_states.UNKNOWN, 
    },
});

const getOrderStateData = (state, theme) => {
    // Retrieve the stateMapping with the current theme
    const states = orderStateMap(theme);

    // Return the value corresponding to the state, or a default
    return states[state] || states[general_states.UNKNOWN];
};


const inboundStateMap = (theme) => ({
    [general_states.ACTIVE]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "active",
        state: general_states.ACTIVE,
    },
    [general_states.CANCELLED]: {
        labelcolor: "red",
        labeltext: "cancelled",
        state: general_states.CANCELLED,
    },
    [general_states.PARTLYRECEIVED]: {
        labelcolor: "orange",
        labeltext: "partly_received",
        state: general_states.PARTLYRECEIVED,
    },
    [general_states.FULLYRECEIVED]: {
        labelcolor: "pink",
        labeltext: "fully_received",
        state: general_states.FULLYRECEIVED,
    },
    [general_states.COMPLETED]: {
        labelcolor: "green",
        labeltext: "completed",
        state: general_states.COMPLETED,
    },
    [general_states.UNKNOWN]: {
        labelcolor: theme.palette.mode === "dark" ? "greyLight" : "grey",
        labeltext: "unknown",
        state: general_states.UNKNOWN,
    },
});

const getInboundStateData = (state, theme) => {
    const states = inboundStateMap(theme);
    return states[state] || states[general_states.UNKNOWN];
};

const bolReplenishmentStateMap = (theme) => ({
    [general_states.OPEN]: {
        labelcolor: theme.palette.mode === "dark" ? "greyLight" : "grey",
        labeltext: "open",
        state: general_states.OPEN,
    },
    [general_states.CANCELLED]: {
        labelcolor: "red",
        labeltext: "cancelled",
        state: general_states.CANCELLED,
    },
    [general_states.APPROVED]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "approved",
        state: general_states.APPROVED,
    },
    [general_states.COMPLETED]: {
        labelcolor: "green",
        labeltext: "completed",
        state: general_states.COMPLETED,
    },
    [general_states.UNKNOWN]: {
        labelcolor:theme.palette.mode === "dark" ? "greyLight" : "grey",
        labeltext: "unknown",
        state: general_states.UNKNOWN,
    },
});

const getBolReplenishmentStateData = (state, theme) => {
    const states = bolReplenishmentStateMap(theme);
    return states[state] || states[general_states.UNKNOWN];
};

// const getInboundStateData = (state, theme) => {
//     let labelcolor = "";
//     let labeltext = "";

//     switch(state){
//         case general_states.ACTIVE:
//             labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
//             labeltext = "active";
//             break;
//         case general_states.CANCELLED:
//             labelcolor = "red"
//             labeltext = "cancelled";
//             break;
//         case general_states.PARTLYRECEIVED:
//             labelcolor = "orange"
//             labeltext = "partly_received";
//             break;
//         case general_states.FULLYRECEIVED:
//             labelcolor = "pink"
//             labeltext = "fully_received";
//             break;
//         case general_states.COMPLETED:
//             labelcolor = "green"
//             labeltext = "completed";
//             break;
//         default:
//             labelcolor = "grey"
//             labeltext = "unknown";
//             break;
//     }

//     return {
//         labelcolor,
//         labeltext
//     }
// }


const invoiceStateMap = (theme) => ({
    [general_states.CONCEPT]: {
        labelcolor: theme.palette.mode === "dark" ? "greyLight" : "grey",
        labeltext: "concept",
        state: general_states.CONCEPT,
    },
    [general_states.FINALIZED]: {
        labelcolor: "green",
        labeltext: "final",
        state: general_states.FINALIZED,
    },
    [general_states.EXPORTED]: {
        labelcolor: "orange",
        labeltext: "exported",
        state: general_states.EXPORTED,
    },
    [general_states.SENT]: {
        labelcolor: "pink",
        labeltext: "sent",
        state: general_states.SENT,
    },
    [general_states.PAID]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "paid",
        state: general_states.PAID,
    },
    [general_states.UNKNOWN]: {
        labelcolor: theme.palette.mode === "dark" ? "greyLight" : "grey",
        labeltext: "unknown",
        state: general_states.UNKNOWN,
    },
});

const getInvoiceStateData = (state, theme) => {
    const states = invoiceStateMap(theme);
    return states[state] || states[general_states.UNKNOWN];
};

// const getInvoiceStateData = (state, theme) => {
//     let labelcolor = "";
//     let labeltext = "";

//     switch(state){
//         case general_states.CONCEPT:
//             labelcolor = "grey"
//             labeltext = "concept";
//             break;
//         case general_states.FINALIZED:
//             labelcolor = "green";
//             labeltext = "final";
//             break;
//         case general_states.EXPORTED:
//             labelcolor = "orange"
//             labeltext = "exported";
//             break;
//         case general_states.SENT:
//             labelcolor = "pink"
//             labeltext = "sent";
//             break;
//         case general_states.PAID:
//             labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
//             labeltext = "paid";
//             break;
//         default:
//             labelcolor = "grey"
//             labeltext = "unknown";
//             break;
//     }

//     return {
//         labelcolor,
//         labeltext
//     }
// }


const productsStateMap = (theme) => ({
    [general_states.ACTIVE]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "active",
        state: general_states.ACTIVE,
    },
    [general_states.UNKNOWN]: {
        labelcolor: theme.palette.mode === "dark" ? "greyLight" : "grey",
        labeltext: "unknown",
        state: general_states.UNKNOWN,
    },
});

const getProductsStateData = (state, theme) => {
    const states = productsStateMap(theme);
    return states[state] || states[general_states.UNKNOWN];
};

// const getProductsStateData = (state, theme) => {
//     let labelcolor = "";
//     let labeltext = "";

//     switch(state){
//         case general_states.ACTIVE:
//             labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
//             labeltext = "active";
//             break;
//         default:
//             labelcolor = "grey"
//             labeltext = "unknown";
//             break;
//     }

//     return {
//         labelcolor,
//         labeltext
//     }
// }


const locationsStateMap = (theme) => ({
    [general_states.ACTIVE]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "active",
        state: general_states.ACTIVE,
    },
    [general_states.UNKNOWN]: {
        labelcolor: theme.palette.mode === "dark" ? "greyLight" : "grey",
        labeltext: "unknown",
        state: general_states.UNKNOWN,
    },
});

const getLocationsStateData = (state, theme) => {
    const states = locationsStateMap(theme);
    return states[state] || states[general_states.UNKNOWN];
};



const pickingJobStateData = (theme) => ({
    [general_states.FULLYPICKED]: {
        labelcolor: "purple",
        labeltext: "picked",
        state: general_states.FULLYPICKED,
    },
    [general_states.PICKING_IN_PROGRESS]: {
        labelcolor: "urbangreen",
        labeltext: "in_progress",
        state: general_states.PICKING_IN_PROGRESS,
    },
    [general_states.IN_PROGRESS]: {
        labelcolor: "urbangreen",
        labeltext: "in_progress",
        state: general_states.IN_PROGRESS,
    },
    [general_states.ECHECK]: {
        labelcolor: theme.palette.mode === "dark" ? "blue" : "primary",
        labeltext: "echeck",
        state: general_states.ECHECK,
    },
    [general_states.COMPLETED]: {
        labelcolor: "cyan",
        labeltext: "picked",
        state: general_states.COMPLETED,
    },
});

const getPickingJobStateData = (state, theme) => {
    console.log('state',state);
    const states = pickingJobStateData(theme);
    return states[state] || states[general_states.UNKNOWN];
};


// const getLocationsStateData = (state, theme) => {
//     let labelcolor = "";
//     let labeltext = "";

//     switch(state){
//         case general_states.ACTIVE:
//             labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
//             labeltext = "active";
//             break;
//         default:
//             labelcolor = "grey"
//             labeltext = "unknown";
//             break;
//     }

//     return {
//         labelcolor,
//         labeltext
//     }
// }

module.exports = {
    orderStateMap,
    getOrderStateData,
    getInboundStateData,
    getInvoiceStateData,
    getProductsStateData,
    getLocationsStateData,
    getBolReplenishmentStateData,
    getPickingJobStateData
}
