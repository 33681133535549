import React, { useEffect, useMemo } from 'react';
import { InputBase, Box, Typography, useTheme } from "@mui/material";
import { tokens } from '../../../theme';
import cx from 'classnames';
import Text from '../text/Text';

const ShValidatedInput = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const height = useMemo(() => props.height || 32,[props.height]);

    return (
        <Box 
            sx={{width:1,
                ...props.sx?.masterBox
            }}
        >
            <Box
                className={cx(props.className, {
                    'error': typeof props.error !== "undefined" && props.error.length > 0,
                })}
                sx={{
                    flex:1,
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:height/8,
                    display:"flex",
                    height:`${height}px`,
                    "&.error" : {
                        background: colors.red[100],
                        border: `1px solid ${colors.red[400]}`
                    },
                    ...props.sx?.inputBox
                }}
            >
                <InputBase 
                    disabled={props.disabled ?? false}
                    readOnly={props.readOnly ?? false}
                    type={props.type ?? "text"}
                    sx={{
                        px:2,
                        flex:1, 
                        color:colors.txt["primary"],
                        lineHeight:`${height}px`,
                        "&:focus" : {
                            border:`1px solid ${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`
                        },
                        ...props.sx?.input,
                    }}
                    value={props.value ?? ""}
                    onChange={(e) => props.onChange(props.name, e.target.value)}
                    error={Boolean(props.error)}
                />
            </Box>
            {typeof props.error !== "undefined" && props.error.length > 0 &&
                <Box
                    sx={{
                        pl:2,
                    }}
                >

                    <Text bold variant="red">{props.error}</Text>    
                </Box>
            }
        </Box>
    );
};

export default ShValidatedInput;



export const ShValidatedArea = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const height = useMemo(() => props.height || 32,[props.height]);

    return (
        <>
            <Box
                className={cx(props.className, {
                    'error': typeof props.error !== "undefined" && props.error.length > 0,
                })}
                sx={{
                    backgroundColor : `${theme.palette.mode === "dark" ?colors.grey[300] : colors.grey[100]}`,
                    borderRadius:height/8,
                    "&.error" : {
                        background: colors.red[100],
                        border: `1px solid ${colors.red[400]}`
                    },
                    ...props.sx?.inputBox
                }}
            >
                <InputBase 
                    disabled={props.disabled ?? false}
                    readOnly={props.readOnly ?? false}
                    type={props.type ?? "text"}
                    multiline
                    sx={{
                        px:2,
                        color:colors.txt["primary"],
                        overflow:'auto',
                        maxHeight:132,
                        "& textarea": {
                            height: '100%', // Ensure the textarea inside also takes the full height
                            padding: '8px', // Add padding as needed
                            overflow: 'auto', // Allow scroll if content overflows
                            textAlignVertical: 'top', // Make sure text aligns at the top
                        },
                        "&:focus" : {
                            border:`1px solid ${theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400]}`
                        },
                        ...props.sx?.input,
                    }}
                    value={props.value ?? ""}
                    onChange={(e) => props.onChange(props.name, e.target.value)}
                    error={Boolean(props.error)}
                />
            </Box>
            {typeof props.error !== "undefined" && props.error.length > 0 &&
                <Box
                    sx={{
                        pl:2,
                    }}
                >

                    <Text bold variant="red">{props.error}</Text>    
                </Box>
            }
        </>
    );
};
