import { Box, Button, Fade, InputBase, Typography, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchQuery } from '../../../newapi/global/searchSlice';
import { ReactComponent as Triangle } from "../../../styles/svg/triangle.svg";
import { tokens } from "../../../theme";
import { search_keys } from '../../../utils/searchKeys';
import { ENV_TYPES } from "../../global/Sidebar";
import PopoverModal from "../Modal/PopoverModal";
import ShTextSkeleton from '../skeleton/ShTextSkeleton';
import Text from "../text/Text";
import SearchEmpty from './SearchEmpty';
import SearchError from './SearchError';
// import SpInboundDetails from "../../global/Sidepanels/SpInboundDetails/SpInboundDetails";
// import SpReturnDetails from "../../global/Sidepanels/SpReturnDetails/SpReturnDetails";
import Search from "@mui/icons-material/Search";
import SidepanelLoader from "../sidepanel2/SidepanelLoader";
import MSearch from "./MSearch";

const SpInvoiceDetails = SidepanelLoader(() => import("../../global/Sidepanels/SpInvoiceDetails/SpInvoiceDetails"));
const SpOrderDetails = SidepanelLoader(() => import("../../global/Sidepanels/SpOrderDetails/SpOrderDetails"));
const SPStockDetails = SidepanelLoader(() => import("../../global/Sidepanels/SpStockDetails/SPStockDetails"));
const SpLocationDetails = SidepanelLoader(() => import("../../global/Sidepanels/SpLocationDetails/SpLocationDetails"));
const SpInboundDetails = SidepanelLoader(() => import("../../global/Sidepanels/SpInboundDetails/SpInboundDetails"));
const SpReturnDetails = SidepanelLoader(() => import("../../global/Sidepanels/SpReturnDetails/SpReturnDetails"));

export const GeneralSearch = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const [value,setValue] = useState("");
    const [valueToSearchWith,setValueToSearchWith] = useState("");

    const inputRef = useRef(null);
    const overlayRef = useRef(null);

    const [shouldShowDesktopOverlay, setShouldShowDesktopOverlay] = useState(false);

    const {data,isLoading,isFetching,isError,error,refetch} = useSearchQuery({searchTerm:valueToSearchWith},{skip: valueToSearchWith.length === 0})
    
    const selectedEnvironmentType = useSelector((state) => state.settings.environment.selectedEnvironmentType);
    const isRelation = selectedEnvironmentType === ENV_TYPES[200];

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };

    // Debounced search term handler
    const debouncedSetSearchTerm = useCallback(
        debounce((searchValue) => {
            // setValue(searchValue); // This will trigger the API call automatically via useSearchQuery
            setValueToSearchWith(searchValue);
        }, 750),
        [] // Dependency array ensures this function is only created once
    );
        
    // Debounced function for updating the Redux search term
    // const debouncedSetSearchTerm = useCallback(debounce((searchValue) => console.log(searchValue), 750), []);
    const handleSearchChange = (event) => {
        const { value } = event.target;
        setValue(value);
        debouncedSetSearchTerm(value);
        if(value.length > 0){
            setShouldShowDesktopOverlay(true);
        } else {
            setShouldShowDesktopOverlay(false);
        }
    };

    // Close overlay when clicking outside the input or overlay
    const handleClickOutside = (event) => {
        // Check if the click is outside of the input field or overlay
        if (
            inputRef.current && !inputRef.current.contains(event.target) && 
            overlayRef.current && !overlayRef.current.contains(event.target)
        ) {
        setShouldShowDesktopOverlay(false); // Close overlay if click is outside
        }
    };

    // Handle click inside InputBase (if overlay is visible, it should remain visible)
    const handleInputClick = () => {
        if (value.length > 0 && !shouldShowDesktopOverlay) {
            // If the input has text and the overlay is not showing, make it visible
            setShouldShowDesktopOverlay(true);
        }
    };

    // Attach event listener on mount and clean up on unmount
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Group the data by 'key' (products or locations)
    const groupedData = data?.reduce((acc, item) => {
        if (!acc[item.key]) {
            acc[item.key] = [];
        }
        acc[item.key].push(item);
        return acc;
    }, {}) || {}; // default to an empty object if `data` is undefined

    const groupedKeys = Object.keys(groupedData);
    
    const [open, setOpen] = useState(false);
    const createContainerSizeModal = {
        open: open,
        setOpen: setOpen,
    }

    return (
        <Box>


            <Box 
                sx={{
                    position:'relative',
                    display:"flex",
                    borderRadius:5,
                    background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[200],
                    // border:`1px solid ${colors.grey[200]}`,
                    // boxShadow:"0px 2px 4px rgba(45,65,95,0.15)",
                    "@media screen and (max-width:47.9375em)" : {
                        display:"none"
                    }
                }}
            >
                <InputBase 
                    ref={inputRef}
                    sx={{
                        px:2,
                        lineHeight:"40px",
                        minWidth:300,
                        height:40,
                    }} 
                    placeholder={t("search")} 
                    value={value}
                    onChange={handleSearchChange}
                    onClick={handleInputClick}
                />
                <Fade
                    in={shouldShowDesktopOverlay} // Control fade-in visibility
                    timeout={250} // Adjust the duration of the fade animation (500ms)
                >

                    <Box
                        ref={overlayRef} // Attach ref to overlay
                        sx={{
                            right: isRelation ? "0" : "50%",
                            transform: isRelation ? null : "translateX(50%)",
                            // transform:"translateX(50%)",
                            width:550,
                            position:'absolute',
                            display:"flex",
                            maxHeight:400,
                            background:theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                            top:50,
                            borderRadius:5,
                            boxShadow:"0px 4px 8px 2px rgba(45,65,95,0.15)",
                            "& .popover-triangle" : {
                                position:"absolute",
                                left: isRelation ? null : "50%",
                                right: isRelation ? "24px" : null,
                                // left:"50%",
                                top:-11,
                                width:32,
                                height:12,
                                zIndex:200,
                                transform: isRelation ? null: "translateX(-50%)",
                                "& *" : {
                                    fill: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                                }
                            },
                        }}
                    >
                        <Triangle 
                            className="popover-triangle"
                        ></Triangle>

                        <SearchContainer
                            groupedKeys={groupedKeys}
                            groupedData={groupedData}
                            isLoading={isLoading}
                            isFetching={isFetching}
                            isError={isError}
                            error={error}
                            setShouldShowDesktopOverlay={setShouldShowDesktopOverlay}
                        />

                    </Box>
                </Fade>
            </Box>

            
            <Box 
                onClick={() => setOpen(true)}
                sx={{
                    display:"none",
                    position:'relative',
                    borderRadius:5,
                    height:32,
                    width:32,
                    alignItems:"center",
                    justifyContent:"center",
                    background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[200],
                    // border:`1px solid ${colors.grey[200]}`,
                    // boxShadow:"0px 2px 4px rgba(45,65,95,0.15)",
                    "@media screen and (max-width:47.9375em)" : {
                        display:"flex"
                    }
                }}
            >
                <Search/>
            </Box>
            <MSearch
                open={createContainerSizeModal.open}
                handleClose={() => {createContainerSizeModal.setOpen(false)}}
                inputRef={inputRef}
                handleSearchChange={handleSearchChange}
                value={value}
                searchContainer={
                    <SearchContainer
                        onResultClick={() => createContainerSizeModal.setOpen(false)}
                        groupedKeys={groupedKeys}
                        groupedData={groupedData}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        isError={isError}
                        error={error}
                        setShouldShowDesktopOverlay={setShouldShowDesktopOverlay}
                    />
                }
            
            />
        </Box>
    );
}

// Function to get state color for an item once
const getItemStateColor = (item, key, theme, searchKeys) => {
    return searchKeys[key]?.getStateColor(item.state, theme);
  };
  
export const SearchContainer = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const {t} = useTranslation();

    const envType = useSelector((state) => state.settings.environment.selectedEnvironmentType);

    const [orderId,setOrderId] = useState();
    const [invoiceId,setInvoiceId] = useState();
    const [productId,setProductId] = useState();
    const [locationId,setLocationId] = useState();
    const [inboundId,setInboundId] = useState();
    const [returnId,setReturnId] = useState();

    const [ordersSpIsExtended, setOrdersSpIsExtended] = useState(false);
    const [startLoadingOrderDetails, setStartLoadingOrderDetails] = useState();

    const [invoicesSpIsExtended, setInvoicesSpIsExtended] = useState(false);
    const [spInvoiceLoading, setSpInvoiceLoading] = useState();

    const [productsSpIsExtended, setProductsSpIsExtended] = useState(false);
    const [spProductLoading, setSpProductLoading] = useState();

    const [locationsSpIsExtended, setLocationsSpIsExtended] = useState(false);
    const [spLocationLoading, setSpLocationLoading] = useState();

    const [inboundsSpIsExtended, setInboundsSpIsExtended] = useState(false);
    const [spInboundLoading, setSpInboundLoading] = useState();

    const [returnsSpIsExtended, setReturnsSpIsExtended] = useState(false);
    const [spReturnLoading, setSpReturnLoading] = useState();

    const handleResultClick = (key,item) => {
        setOrderId(null);
        setInvoiceId(null);
        setProductId(null);
        setLocationId(null);
        setInboundId(null);
        setReturnId(null);

        switch(key) {
            case "orders" :
                setOrderId(item.id); 
                if(!startLoadingOrderDetails){
                    setStartLoadingOrderDetails(true);
                } 
                setOrdersSpIsExtended(true);
                break;
            case "invoices" : 
                setInvoiceId(item.id); 
                if(!spInvoiceLoading){
                    setSpInvoiceLoading(true);
                }
                setInvoicesSpIsExtended(true);
                break;
            case "products" : 
                setProductId(item.id); 
                if(!spProductLoading) {
                    setSpProductLoading(true);
                } 
                setProductsSpIsExtended(true);
                break;
            case "locations" :
                setLocationId(item.id); 
                if(!spLocationLoading) {
                    setSpLocationLoading(true);
                } 
                setLocationsSpIsExtended(true);
                break;
            case "inbounds" : 
                setInboundId(item.id); 
                if (!spInboundLoading) {
                    setSpInboundLoading(true);
                }
                    setInboundsSpIsExtended(true);
                break;
            case "returns" : 
                setReturnId(item.id); 
                if(!spReturnLoading){
                    setSpReturnLoading(true);
                } 
                setReturnsSpIsExtended(true);
                break;
            default :
                // What drugs are u on mate?
                return;
        }
        props.setShouldShowDesktopOverlay();
    }

    // Compute the state colors upfront, just before rendering the component
    const stateColorsCache = {}; // Cache for state colors

    const getStateColor = (item, key) => {
        // Check if we've already computed the color for this item
        const cacheKey = `${key}-${item.state}`;
        if (stateColorsCache[cacheKey]) {
          return stateColorsCache[cacheKey];
        }
    
        // If not cached, calculate and store it
        const color = search_keys[key]?.getStateColor(item.state, theme) || "grey";
        stateColorsCache[cacheKey] = color;
        return color;
      };

    return (
        <Box 
            sx={{
                width:1,
                overflow:'auto',
            }}
        >
            {props.groupedKeys?.length > 0 && !props.isLoading && !props.isFetching  && props.groupedKeys.map((key,secarrindex) => {
                const items = props.groupedData[key];

                return (
                    <Box 
                        key={"keylist"+secarrindex}
                        sx={{
                            py:3,
                            pb:3,
                            borderBottom:`1px solid ${theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[100]}`
                        }}
                    >
                        <Box
                            sx={{
                                mb:1,
                                px:3,
                                display:"flex",
                                alignItems:'center',
                                justifyContent:"space-between"
                            }}
                        >

                            <Text light color={colors.txt["secondary"]}>{t(key)}</Text>

                            {/* <Button 
                                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                                type={"button"}
                                sx={{
                                    textTransform:"uppercase",
                                    fontSize:14,
                                    borderRadius:3,
                                }}
                            >
                                {t("view_all")}
                            </Button> */}
                            {/* <Text bold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>view all</Text> */}
                        </Box>
                        <Box px={1.5}>
                            {/* Line */}

                            {items.map((item,index) => {
                                const stateColor = getStateColor(item, key);



                                return (
                                    <Button
                                        onClick={() => handleResultClick(key,item)}
                                        color={stateColor ?? "grey"}
                                        // color={"grey"}
                                        component={"button"}
                                        key={"list-search-item"+secarrindex+index}
                                        sx={{
                                            width:1,
                                            px:1.5,
                                            display:"flex",
                                            alignItems:"center",
                                            gap:2,
                                            py:1,
                                            borderRadius:4,
                                            cursor:"pointer",
                                            transition:"250ms",
                                            textTransform:"unset",
                                            fontWeight:"unset",
                                            "& .search-item-actions" : {
                                                opacity:0,
                                                visibility:"hidden",
                                                transition:"250ms",
                                            },
                                            "&:hover": {
                                                // background:theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[50],
                                                "& .search-item-actions" : {
                                                    opacity:1,
                                                    visibility:"visible",
                                                    transition:"250ms",
                                                },
                                            },
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width:40,
                                                height:40,
                                                borderRadius:3,
                                                display:"flex",
                                                // background:colors.pastel.light.green[100],
                                                background: theme.palette.mode === "dark" && stateColor === "grey" ?   colors.grey[500] :colors[stateColor][100],
                                                // color:colors.pastel.light.green[400],
                                                color: theme.palette.mode === "dark" && stateColor === "grey" ?   colors.txt["secondary"] :colors[stateColor][400],
                                                // color:colors[stateColor][400],
                                                border:`1px solid ${colors[stateColor][400]}`,
                                                alignItems:"center",
                                                justifyContent:"center",
                                                flexShrink:0,
                                                mb:item?.subtitle?.length > 0 ? "auto" : null,
                                                mt:item?.subtitle?.length > 0 ? 0.5 : null,
                                            }}
                                        >
                                            {search_keys[key].icon}
                                            {/* <UpdateOutlinedIcon/> */}
                                        </Box>
                                        <Box
                                            sx={{
                                                display:"flex",
                                                flexDirection:"column"
                                            }}
                                        >
                                            <Text semibold>{t(search_keys[key].titleAddon)} {item.title}</Text>
                                            <Text light>{search_keys[key].formatSubTitle(item.subtitle)}</Text>
                                        </Box>

                                        <Box
                                            className={"search-item-actions"}
                                            sx={{
                                                mb:item?.subtitle?.length > 0 ? "auto" : null,
                                                pt:item?.subtitle?.length > 0 ? 0.5 : null,
                                                ml:"auto",
                                                display:"flex",
                                                flexDirection:"column",
                                                gap:1,
                                            }}
                                        >
                                                <Typography 
                                                    // color={theme.palette.mode === "dark" ? "secondaryGrey" : "primaryGrey"}
                                                    sx={{
                                                        textTransform:"uppercase",
                                                        fontSize:14,
                                                        borderRadius:3,
                                                        color:theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"],
                                                        px:1.5,
                                                        fontWeight:500,
                                                        fontFamily:"Roboto,sans-serif"
                                                    }}
                                                >
                                                    {t("open")}
                                                </Typography>
                                            {/* <Link to={props.link} style={{display:"flex",width:"100%",textDecoration:"none"}}> */}
                                                {/* <Button 
                                                    color={theme.palette.mode === "dark" ? "secondaryGrey" : "primaryGrey"}
                                                    type={"button"}
                                                    sx={{
                                                        textTransform:"uppercase",
                                                        fontSize:14,
                                                        borderRadius:3,
                                                    }}
                                                >
                                                    {t("new_tab")}
                                                </Button> */}
                                            {/* </Link> */}
                                        </Box>
                                    </Button>
                                );
                            })}
                        </Box>

                    </Box>
                );
            })}
            {props.groupedKeys?.length === 0 && !props.isLoading && !props.isFetching && 
                <Box
                    sx={{
                        pt:3,
                        pb:6,
                    }}
                >
                    <SearchEmpty/>
                </Box>
            }
            {props.isLoading || props.isFetching ? 
                <Box
                    sx={{
                        pt:3,
                        pb:3,
                        display:"flex",
                        flexDirection:"column",
                        gap:2,
                        mx:3,
                    }}
                >
                    <ShTextSkeleton sx={{height:40,borderRadius:3}}/>
                    <ShTextSkeleton  sx={{height:40,borderRadius:3}}/>
                    <ShTextSkeleton sx={{height:40,borderRadius:3}}/>
                    <ShTextSkeleton  sx={{height:40,borderRadius:3}}/>
                </Box>
            :null}
            {props.isError && !props.isLoading && !props.isFetching && props.error && props.error.length > 0 && 
                <Box
                    sx={{
                        pt:3,
                        pb:6,
                    }}
                >
                    <SearchError/>
                </Box>
            }

            {startLoadingOrderDetails && 
                <SpOrderDetails
                    isExtended={ordersSpIsExtended}
                    setIsExtended={setOrdersSpIsExtended}
                    orderId={orderId}
                    relation={envType === ENV_TYPES[200]}
                    canReserveOrderLines={envType === ENV_TYPES[200]}
                />
            }

            {spInvoiceLoading && 
                <SpInvoiceDetails
                    isExtended={invoicesSpIsExtended}
                    setIsExtended={setInvoicesSpIsExtended}
                    invoiceId={invoiceId}
                />
            }
            
            {spProductLoading && 
                <SPStockDetails
                    isExtended={productsSpIsExtended}
                    setIsExtended={setProductsSpIsExtended}
                    productId={productId}
                />
            }

            {/* spLocationLoading */}

            {spLocationLoading && 
                <SpLocationDetails
                    isExtended={locationsSpIsExtended}
                    setIsExtended={setLocationsSpIsExtended}
                    locationId={locationId}
                />
            }

            {spInboundLoading && 
                <SpInboundDetails
                    isExtended={inboundsSpIsExtended}
                    setIsExtended={setInboundsSpIsExtended}
                    inboundId={inboundId}
                />
            }

            {spReturnLoading && 
                <SpReturnDetails
                    isExtended={returnsSpIsExtended}
                    setIsExtended={setReturnsSpIsExtended}
                    returnId={returnId}
                />
            }
            
        </Box>
    )
}



// export default MPOChooseLanguage;