// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';

import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";

import { yupRequired } from '../../../../../utils/validation';
import { useGetProductsSelectQuery } from '../../../../../newapi/warehouse/productSlice';
import NumberInput from '../../../../theme/inputs/NumberInput';
import Shbutton from '../../../../theme/buttons/Shbutton';
import { Delete } from '@mui/icons-material';
import { useGetAvailableWarehousesMutation } from '../../../../../newapi/webshop/bolreplenishmentSlice';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import cx from 'classnames';
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';  
import { shipperTypeConfigurations } from '../../../../../data/shipperTypeConfigurations';
import { InputGroupDropdown } from '../../../../theme/dropdowns/InputGroupDropdown';
import EMGeneralExplainerBox from "../../../ModalsPopover/explainers/EMGeneralExplainerBox";
import { useGetConfigInfoQuery } from '../../../../../newapi/shipper/shipperSlice';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();
    const [configShipperId, setConfigShipperId] = useState(null);
    const [transformedConfigInfo, setTransformedConfigInfo] = useState([]);
    const { data: configInfo } = useGetConfigInfoQuery(configShipperId, { skip: !configShipperId });

    const shipperType = props.data.shipperType;
    const config = shipperTypeConfigurations[shipperType] || { fields: [], saveFormat: () => ({}) };
    const isPostNL = shipperType === 'postnl';

    useEffect(() => {
        if(props.data.shipperType === 'dhl' || props.data.shipperType === 'cirro' || props.data.shipperType === 'sendcloud'){
            setConfigShipperId(props.data.shipperId);
        }
    }, []);

    useEffect(() => {
        if(configInfo){
            if(props.data.shipperType === 'dhl'){
                const transformedConfigInfo = configInfo ? configInfo.configInfo.map(item => ({ key: item, value: item })) : [];
                setTransformedConfigInfo(transformedConfigInfo);
            } else if(props.data.shipperType === 'cirro'){
                const transformedConfigInfo = configInfo ? configInfo.configInfo.map(item => ({ key: item.EName, value: item.Code })) : [];
                setTransformedConfigInfo(transformedConfigInfo);
            } else if(props.data.shipperType === 'sendcloud'){
                setTransformedConfigInfo(configInfo?.configInfo);
            }
        }
    }, [configInfo]);
    

    const [formData, setFormData] = useState(config.saveObject);

    const handleBoolChange = (name, value) => {
        if(value === "true"){
            setFormData(prevData => ({ ...prevData, [name]: true }));
        }else{
            setFormData(prevData => ({ ...prevData, [name]: false }));
        }
    };

    const handleInputChange = (name, value) => {
        setFormData(prevData => ({ ...prevData, [name]: value }));
    }
    
    const handleMultiDropdownChange = (baseList, name, values) => {
        setFormData(prevData => {
            const updatedArray = values.map(index => baseList[index].value);
            return { ...prevData, [name]: updatedArray };
        });
    };

    const handleDropdownChange = (baseList, name, value) => {
        const val = baseList[value]?.value;
        if(val) {
            setFormData(prevData => ({ ...prevData, [name]: val}));
        }
    };

    const selectedFieldIndex = (field) => {
        if(Array.isArray(formData[field.name])){
            const selectedIndexes = formData[field.name].map(item => field.options.findIndex(option => option.value === item));
            return selectedIndexes;
        } else {
            return [];
        }
    }

    const filteredSizeAndWeightOptions = config.fields.filter(field => {
        if(isPostNL && field.name.includes("productCode_")){
            if (formData.parcelType === 'mailbox') {
                return field.name === 'productCode_MailboxParcel';
            } else {
                return field.name !== 'productCode_MailboxParcel';
            }
        }
        return true;
    });
    const filteredFields = config.fields.filter(field => {
        if (shipperType === 'postnl' && field.name.includes("productCode_")) {
            if (formData.parcelType === 'mailbox') {
                return field.name === 'productCode_MailboxParcel';
            } else {
                return field.name !== 'productCode_MailboxParcel';
            }
        }
    
        if (shipperType === 'dhl' ){
            if (field.name === 'sizeAndWeight') {
                return {
                    ...field,
                    options: field.options.filter(option => {
                        if(formData.shipmentType === 'business'){
                            return option.deliveryMethod === 'doordelivery' || option.deliveryMethod === 'both';
                        } else {
                            if(formData.deliveryMethod === 'mailbox'){
                                return (option.deliveryMethod === 'mailbox' || option.deliveryMethod === 'both') && option.value !== 'PALLET';
                            } else {
                                return option.deliveryMethod === 'doordelivery' && option.value !== 'PALLET';
                            }
                        }
                    })
                };
            } else if (field.name === 'deliveryMethod') {
                return {
                    ...field,
                    options: field.options.filter(option => formData.shipmentType !== 'business' || option.value !== 'mailbox')
                };
            }
        }
    
        return field;
    });

    useEffect(() => {
        props.handleDataUpdate({ configuration: formData });
    }, [formData]);

    return (
        <>
            {transformedConfigInfo && shipperType === 'dhl' && (
                <Box key={"accountnumbers"} sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                    }}>
                        <Box
                            sx={{
                                display:'flex',
                            }}
                        >
                        <Text>{t("accountnumbers")}</Text>
                        <EMGeneralExplainerBox title={t("accountnumbers")} text={t("profile_accountnumbers_tooltip")}/>
                        </Box>
                        <InputDropdown
                            key={formData["accountnumbers"]}
                            name={"accountnumbers"}
                            multiple={true}
                            noSelection={t('choose_option')}
                            displayName="key"
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search")
                            }}
                            options={transformedConfigInfo ?? []}
                            selected={selectedFieldIndex({"name":"accountnumbers", "options":transformedConfigInfo})}
                            onChange={(selected) => handleMultiDropdownChange(transformedConfigInfo,"accountnumbers", selected)}
                            closeOnSelection={false}
                        />
                    </Box>
            )}
            {transformedConfigInfo && shipperType === 'cirro' && (
                <Box key={"accountnumbers"} sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                    }}>
                        <Text>{t("shipping_method")}<EMGeneralExplainerBox title={t("accountnumbers")} text={t("profile_accountnumbers_tooltip")}/></Text>
                        <InputDropdown
                            key={formData["shippingMethod"]}
                            name={"shippingMethod"}
                            noSelection={t('choose_option')}
                            displayName="key"
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search")
                            }}
                            options={transformedConfigInfo ?? []}
                            selected={transformedConfigInfo.findIndex(option => option.value === formData["shippingMethod"]) ?? -1}
                            onChange={(selected) => handleDropdownChange(transformedConfigInfo,"shippingMethod", selected)}
                        />
                    </Box>
            )}
            {transformedConfigInfo && shipperType === 'sendcloud' && (
                <>
                <Box key={"shippingMethod"} sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                    paddingTop:3,
                    }}>
                        <Text>{t("shipment_method")}</Text>
                        <InputDropdown
                            key={formData["shippingMethod"]}
                            name={"shippingMethod"}
                            noSelection={t('choose_option')}
                            displayName="key"
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search")
                            }}
                            options={transformedConfigInfo?.shippingOptions ?? []}
                            selected={transformedConfigInfo?.shippingOptions?.findIndex(option => option.value === formData["shippingMethod"]) ?? -1}
                            onChange={(selected) => handleDropdownChange(transformedConfigInfo?.shippingOptions,"shippingMethod", selected)}
                        />
                    </Box>
                    <Box key={"senderAddresses"} sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                    paddingTop:3,
                    }}>
                        <Text>{t("senderAddresses")}</Text>
                        <InputDropdown
                            key={formData["senderAddress"]}
                            name={"senderAddress"}
                            noSelection={t('choose_option')}
                            displayName="key"
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search")
                            }}
                            options={transformedConfigInfo?.senderAddresses ?? []}
                            selected={transformedConfigInfo?.senderAddresses?.findIndex(option => option.value === formData["senderAddress"]) ?? -1}
                            onChange={(selected) => handleDropdownChange(transformedConfigInfo?.senderAddresses,"senderAddress", selected)}
                        />
                    </Box>
                    <Box key={"contracts"} sx={{
                    display:"flex",
                    flexDirection:"column",
                    gap:1,
                    paddingTop:3,
                    }}>
                        <Text>{t("contracts")}</Text>
                        <InputDropdown
                            key={formData["contract"]}
                            name={"contract"}
                            noSelection={t('choose_option')}
                            displayName="key"
                            searchOptions={{
                                enabled: true,
                                placeholder: t("search")
                            }}
                            options={transformedConfigInfo?.contracts ?? []}
                            selected={transformedConfigInfo?.contracts?.findIndex(option => option.value === formData["contract"]) ?? -1}
                            onChange={(selected) => handleDropdownChange(transformedConfigInfo?.contracts,"contract", selected)}
                        />
                    </Box>
                </>
            )}
            {filteredFields.map(field => {
                switch (field.type) {
                    case 'text':
                        return (
                            <Box key={field.name} sx={{
                                display:"flex",
                                flexDirection:"column",
                                gap:1,
                                paddingTop:3,
                            }}>
                                <Text>{t(field.label)}{field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}</Text>
                                <ShValidatedInput
                                    name={field.name}
                                    label={t(field.label)}
                                    value={formData[field.name] || ''}
                                    onChange={handleInputChange}
                                    //error={props.errors.name}
                                />
                                {/* <InputBase
                                    value={formData[field.name] || ''}
                                    onChange={(e) => handleInputChange(field.name, e.target.value)}
                                /> */}
                            </Box>
                        );
                    case 'boolean':
                        return (
                            <Box 
                        display={"flex"} 
                        flexDirection={"row"}
                        sx={{
                            gap:1,
                            paddingTop:3,
                            //maxWidth: 110,
                            justifyContent:"space-between",
                            alignItems:"center",
                            "@media screen and (max-width:47.9375em)" : {
                                width:1,
                                flexDirection:"row",
                                alignItems:"center",
                                justifyContent:"space-between",
                            }
                        }}
                        >
                        <Text>
                        {t(field.label)}
                        {field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}
                        </Text>
                        
                        <ToggleButtonGroup
                            color={formData[field.name] ? "green" : "red"}
                            value={formData[field.name] ? "true" : "false"}
                            exclusive
                            onChange={(e) => handleBoolChange(field.name, e.target.value)}
                            aria-label="Active"

                            className={cx(`${formData[field.name]}`, {
                                'active': formData[field.name]
                            })}
                            sx={{
                                background: colors.red[100],
                                padding:0.5,
                                borderRadius:5,
                                transition:"250ms",
                                "&.active" : {
                                    background:colors.green[100],
                                    transition:"250ms",
                                },
                            }}
                        >
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.green[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.red[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="false">
                                {t('no')}
                            </ToggleButton>
                            <ToggleButton 
                                sx={{
                                    padding:0,
                                    paddingX:2,
                                    border:0,
                                    lineHeight:"32px",
                                    textTransform:"unset",
                                    borderRadius:"32px !important",
                                    color:colors.red[400],
                                    transition:"250ms",
                                    "&.Mui-selected,&.Mui-selected:hover" : {
                                        background:colors.green[400],
                                        color:`${theme.palette.mode === "dark" ? "rgba(0,0,0,.75)" : "#fff"}`
                                    }
                                }}
                                value="true"
                            >
                                {t('yes')}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                        );
                        case 'dropdown':
                            if(field.translate){
                                const translatedOptions = field.options.map(option => ({
                                    ...option,
                                    label: t(option.label)
                                }));
                                field.options = translatedOptions;
                            }
                            return (
                                <Box key={field.name} sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:1,
                                    paddingTop:3,
                                }}>
                                    <Text>{t(field.label)}{field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}</Text>
                                    
                                    <InputDropdown
                                        key={formData[field.name]}
                                        name={field.name}
                                        useTranslation={field.translate}
                                        noSelection={t('choose_option')}
                                        displayName="label"
                                        options={field.options ?? ""}
                                        selected={field.options.findIndex(option => option.value === formData[field.name]) ?? -1}
                                        onChange={(selected) => handleDropdownChange(field.options,field.name, selected)}
                                        closeOnSelection
                                    />
                                </Box>
                            );
                        case 'multidropdown':
                            if(field.translate){
                                const translatedOptions = field.options.map(option => ({
                                    ...option,
                                    label: t(option.label)
                                }));
                                field.options = translatedOptions;
                            }
                            return (
                                <Box key={field.name} sx={{
                                    display:"flex",
                                    flexDirection:"column",
                                    gap:1,
                                    paddingTop:3,
                                }}>
                                    <Text>{t(field.label)}{field.tooltip && <EMGeneralExplainerBox title={t(field.label)} text={t(field.tooltip)}/>}</Text>
                                    <InputDropdown
                                        key={formData[field.name]}
                                        name={field.name}
                                        multiple={true}
                                        noSelection={t('choose_option')}
                                        displayName="label"
                                        options={field.options ?? []}
                                        selected={selectedFieldIndex(field)}
                                        onChange={(selected) => handleMultiDropdownChange(field.options,field.name, selected)}
                                        closeOnSelection={false}
                                    />
                                </Box>
                            );
                    default:
                        return null;
                }
            })}
        </>
            
                
            
    );
};

const Configuration = (props) => {
    const { t } = useTranslation();

    // const schema = yup.object({
    //     street: yupRequired('street',t),
    //     house_nr: yupRequired('house_nr',t),
    //     city: yupRequired('city',t),
    //     postal: yupRequired('postal',t),
    // });

    return (
        <ProgressionTab 
        tabId="tabConfiguration" 
        subtitle={t('configuration')} 
        step={2}
        maxSteps={2}
        //schema={schema}
    >
        <Content />
    </ProgressionTab>
    )
}

export default Configuration;