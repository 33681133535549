import { Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next"
import { tokens } from "../../../theme";
import Text from "../../theme/text/Text";

export const SHPBBorder = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const {t} = useTranslation();
    return (
        <Box>
            <Text>
            {t("border")}
            </Text>
            <Box
                sx={{
                    pt:1,
                    height:40,
                    background:"blue",
                    width:1,
                }}
            ></Box>
        </Box>
    )
}