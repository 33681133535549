import { Box, Button, Collapse, useTheme } from "@mui/material";
import Subheading from "../../text/Subheading";


import { useTranslation } from "react-i18next";
import { useState } from "react";
import { tokens } from "../../../../theme";
import { useDispatch } from "react-redux";
import { updateFilter } from "../../../../newapi/global/filter/gridFilterSlice";
import { useGridFilter } from "../../../../contexts/GridFilterContext";

const FilterModalView = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const { index,id } = useGridFilter();

    const [openFilters, setOpenFilters] = useState({}); // Store open/close state for each filter

    // Function to toggle a specific filter's collapse state
    const handleToggle = (backend_key) => {
        setOpenFilters(prevState => ({
            ...prevState,
            [backend_key]: !prevState[backend_key], // Toggle the state for the given backend_key
        }));
    };


    const resetFilter = (key) => {

        dispatch(updateFilter({
            index,
            id,
            filterKey: key,
            value: null
        }));
    }

    return (
        <Box
            sx={{
                // padding:1.5,
                width: "520px",
                // maxHeight:"400px",
                display:"flex",
                flexDirection:"column",
                overflow:"auto",
                p:3,
                "@media screen and (max-width: 47.9375em)" : {
                    width:1,
                }
            }}
        >
            <Subheading sx={{mb:3,}}>Filters</Subheading>
            {props.filterOptions.map((filter, index) => {
                const { component: FilterComponent, backend_key } = filter;
                if (!FilterComponent) return null;

                const open = openFilters[backend_key];

                return (
                    <Box key={backend_key}>
                        <Box 
                            sx={{
                                height:40,
                                cursor: 'pointer',
                                fontWeight: 'bold',
                                color:open ? colors.txt["primary"] : colors.txt["secondary"],
                                display:"flex",
                                justifyContent:"space-between",
                                alignItems:"center",
                            }}
                            onClick={() => handleToggle(backend_key)} // Toggle the collapse when clicked
                        >
                            {t(backend_key)}
                            <Button
                                onClick={() => resetFilter(backend_key)}
                                component="button"
                                color={theme.palette.mode === "dark" ? "blue" : "primary"}
                                
                                type={"button"}
                                sx={{
                                    textTransform:"none",
                                    height:32,
                                    fontSize:14, 
                                    fontFamily:"Roboto, sans-serif",
                                    fontWeight:500,
                                    mr:-2,
                                    borderRadius:4,
                                }}
                            >
                                {t("reset")}
                            </Button>
                        </Box>
                        <Collapse sx={{pb:0,}} in={open} timeout="auto" unmountOnExit>
                            <FilterComponent />
                        </Collapse>
                    </Box>
                );
            })}
                
        </Box>
    )
}
export default FilterModalView;