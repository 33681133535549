import { Box, InputBase, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { tokens } from "../../../theme";
import { hexToRgb, isValidHex } from "../../../utils/colorConverters";
import { ShColorPicker } from "../../ShColorPicker";
import { useTranslation } from "react-i18next";
import Text from "../../theme/text/Text";

export const SHPBColor = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);
    
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const filterPopoverOpen = Boolean(filterAnchorEl);
    const filterPopoverId = filterPopoverOpen ? 'simple-popover' : undefined;

    const handleFilterPopoverClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };
    const handleFilterPopoverClose = () => {
        setFilterAnchorEl(null);
    };

    const [localValue, setLocalValue] = useState(props.value.hex);
    useEffect(() => {
        if(props.value  && props.value.hex){
            setLocalValue(props.value.hex);
        }
    },[props.value])

    return (
        <Box
            sx={{
            }}
        >
            <Text>
            color
            </Text>

            <Box
                sx={{
                    display:"flex",
                    height:40,
                    background: colors.bg['tertiary'],
                    alignItems:"center",
                    pl:3,
                    pr:1,
                    borderRadius:5,
                }}
            >
                <InputBase 
                    value={localValue}
                    // onChange={}
                    sx={{flex:1}}
                />
                <Box
                    onClick={handleFilterPopoverClick}
                    sx={{
                        borderRadius:3,
                        height:24,
                        width:24,
                        flexShrink:0,
                        background:localValue ? `rgba(${hexToRgb(localValue)},${props.value.opacity})` : "#fff",
                    }}
                >

                </Box>
            </Box>
            <ShColorPicker
                value={props.value}
                setValue={props.setValue}
                id={filterPopoverId}
                open={filterPopoverOpen}
                anchorEl={filterAnchorEl}
                onClose={handleFilterPopoverClose}
            
            />
        </Box>
    )
}