import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetInboundStatusCountsQuery } from "../../../../../newapi/reports/fulfillment/reportFulfillmentSlice";

const GridActions = (props) => {
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const Inbounds = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions:gridOptions,
            options : [],
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    

    const columns = [

        {field: "name", headerName: t("customer"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (<Text bold>{params.row.name}</Text>);
            }
        },
        {field: "yesterday", headerName: t("yesterday"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.yesterday;
            }
        },
        {field: "today", headerName: t("today"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return params.row.today;
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                return (<Box></Box>
                    
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetInboundStatusCountsQuery}
                // gridActions={<GridActions></GridActions>} 
                title={t('overview') }
                gridOptions={gridOptions} 
                columns={columns}
                rows={data}>
            </ShDataGrid2>
        </Box>
    );
}


export default Inbounds;