import { Box, ButtonBase, useTheme, MenuItem, ListSubheader, Select } from "@mui/material";
import { chartTokens, tokens } from "../../../theme";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, { useLayoutEffect, useState } from "react";
import Text from "../text/Text";
import ChartText from "../text/ChartText";
// import Dropdown from "../Dropdown";
import { DropdownBase } from "./Dropdown";
import cx from 'classnames';
import { useTranslation } from "react-i18next";

export const InputGroupDropdownContent = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const variant = props.variant ?? "default";


    const getTextForMultiple = () => {
        if(props.selected.length === 0) return typeof props.noSelection !== "undefined" ? props.noSelection : "Kies";
        if(props.selected.length === 1) {
            if(props.valueKey) {
                return props.options.find(obj => obj[props.valueKey] === props.selected[0])[props.displayName];
            }
            if(typeof props.options[props.selected[0]] === "object") {
                return props.options[props.selected[0]][props.displayName]
            }
        }
        
        return props.selected.length
    }

    const getBackground = () => {
        if(variant === "default") {
            return theme.palette.mode === "dark" ? colors.grey[400] : colors.bg["tertiary"];
        }
        if(variant=== "darker") {
            return theme.palette.mode === "dark" ? colors.grey[500] : colors.grey[200];
        }
    }

    const getPaddingRight = () => {
        if(variant === "default") return 1;
        if(variant=== "darker") return 0.5;

    }

    const getButtonBackground=  () => {
        if(variant === "default") {
            return null
        }
        if(variant === "darker") {
            return theme.palette.mode === "dark" ? colors.txt["secondary"] : colors.txt["primary"];
        }
    }
    const getButtonColor = () => {
        if(variant === "default") {
            return colors.txt["secondary"];
        }
        if(variant === "darker") {
            return "#fff";
        }
    }

    const getSingleValue = () => {
        
        if(props.selected === -1 || !props.options || props.options?.length === 0) {
            return typeof props.noSelection !== "undefined" ? props.noSelection : t("choose");
        }
        if(props.valueKey) {
            return props.options.find(obj => obj[props.valueKey] === props.selected)[props.displayName];
        }
        if(typeof props.options[props.selected] === "object") {
            return props.options[props.selected][props.displayName]
        }
        if(props.useTranslation){
            return t(props.options[props.selected]);
        }
        return props.options[props.selected]
    }

    const renderOptions = () => {
        return props.options.map((group, index) => (
            <React.Fragment key={index}>
                <ListSubheader>{group.groupLabel}</ListSubheader>
                {group.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </React.Fragment>
        ));
    };

    return (
        <Select
            value={props.selected}
            onChange={(e) => props.onChange(e.target.value)}
            displayEmpty
            renderValue={(selected) => {
                if (selected.length === 0) {
                    return <em>{props.noSelection || t("choose")}</em>;
                }
                return props.options.flatMap(group => group.options).find(option => option.value === selected)?.label || "";
            }}
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"space-between",
                background: getBackground(),
                height:props.height ?? 40,
                paddingRight: getPaddingRight(),
                paddingLeft: 2,
                borderRadius:5,
                width:1,
                "&.error" : {
                    borderColor: `${colors.red[400]} !important`,
                    borderStyle: 'solid !important',
                    borderWidth: '1px !important',
                    background: `${colors.red[100]} !important`,
                },
                "&.disabled" : {
                    opacity:0.5,
                }
            }}
        >
            {renderOptions()}
        </Select>
    );
}

export const InputGroupDropdown = (props) => {
    return (
        <DropdownBase {...props}>
            <InputGroupDropdownContent {...props} variant={props.variant}/>
        </DropdownBase>
    )
}