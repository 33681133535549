// import { apiSlice } from '../api/apiSlice'
// import 

import { muiDataGridStarted } from "../apiutils";
import { apiSlice } from "../createApiSlice";

const RETURN_BASE = "/return"; 
const buildUrl = (endpoint) => RETURN_BASE + endpoint;

const returnsApiSlice =  apiSlice.injectEndpoints({
    endpoints: builder => ({
        findOrderForReturn: builder.query({ 
            query: (obj) => ({
                url: buildUrl(`/portal/${obj.relation}/find`),
                method: 'POST',
                body: obj.body
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getRelationForReturn: builder.query({ 
            query: (obj) => ({
                url: buildUrl(`/portal/${obj.relation}/relation`),
                method: 'GET'
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        getOrderForReturn: builder.query({ 
            query: (obj) => ({
                url: buildUrl(`/portal/${obj}/order`),
                method: 'GET'
            }),
            //providesTags: (obj) => [{ type: `Returns-Order-${obj.body?.ordernumber}` }]
        }),
        createReturnForReturn: builder.mutation({
            query: (obj) => ({
                url: buildUrl(`/portal/${obj.relation}/create`),
                method: 'POST',
                body: obj.body,
            }),
        }),
        createReturn: builder.mutation({
            query: (body) => ({
                url: buildUrl(`/create`),
                method: 'POST',
                body
            }),

        }),
        getFinalize: builder.query({ 
            query: (obj) => ({
                url: buildUrl(`/portal/${obj.relation}/finalize`),
                method: 'GET'
            }),

        }),
        getStatus: builder.query({ 
            query: (obj) => ({
                url: buildUrl(`/portal/${obj}/status`),
                method: 'GET'
            }),

        }),
        getReturnLabel: builder.query({ 
            query: (obj) => ({
                url: buildUrl(`/portal/${obj}/label`),
                method: 'GET'
            }),

        }),
    })
});

export const {
    useFindOrderForReturnQuery,
    useGetRelationForReturnQuery,
    useGetOrderForReturnQuery,
    useCreateReturnForReturnMutation,
    useCreateReturnMutation,
    useGetFinalizeQuery,
    useGetStatusQuery,
    useGetReturnLabelQuery
  } = returnsApiSlice;

