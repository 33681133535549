// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { tokens } from '../../../../../theme';
import * as yup from "yup";
import ShValidatedInput from '../../../../theme/inputs/ShValidatedInput';
import ShValidatedInputDropdown from '../../../../theme/dropdowns/ShValidatedInputDropdown';
import { yupRequired, yupNumberRequired } from '../../../../../utils/validation';
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import { useDispatch } from 'react-redux';
import { useGetReachTypesSelectQuery } from '../../../../../newapi/warehouse/reachTypeSlice';

/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const [fieldErrors, setFieldErrors] = useState({});
    const dispatch = useDispatch();
    const { data: reachTypes, isLoading } = useGetReachTypesSelectQuery();

    const handleChange = (key, val) => {
        props.handleDataUpdate({ [key]: val });
        dispatch(setTabErrors({ ...props.errors, [key]: '' }));
    };
    
    return (
        <>
            <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('barcode')}
                    </Text>
                    
                </Box>
                <ShValidatedInput
                            name="barcode"
                            value={data.barcode ?? ""}
                            onChange={handleChange}
                            error={props.errors.barcode}
                        />
    </Box>
            <Box 
            display={"flex"} 
            flexDirection={"column"}
            sx={{
                //maxWidth: 160,
                width:1,
                paddingTop:3,
                "@media screen and (max-width:47.9375em)" : {
                    width:1,
                    flexDirection:"row",
                    gap:4,
                    alignItems:"center",
                    justifyContent:"space-between",
                }
            }}
            >
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        mb:0.5,
                        "@media screen and (max-width:47.9375em)" : {
                            mb:0
                        }
                    }}
                >
                    <Text semiBold>
                        {t('reachtype')}
                    </Text>
                    
                </Box>
                <ShValidatedInputDropdown
                    name="reachTypeId"
                    displayName="description"
                    changeField="id"
                    selected={reachTypes?.data.findIndex(reachType => reachType.id === data.reachTypeId) ?? -1}
                    options={reachTypes?.data?? []}
                    noSelection={t("choose_reachtype")} 
                    onChange={handleChange}
                    error={props.errors.reachTypeId}
                />
    </Box>
        </>
    );
};

const PickupcartBasicInfo = (props) => {
    const { t } = useTranslation();
    const schema = yup.object({
        barcode: yupRequired('barcode',t),
        reachTypeId: yupNumberRequired('reachtype', t)
    });

    return (
        <ProgressionTab 
            tabId="tabBasicInfo" 
            subtitle={"Info"} 
            step={1}
            nextTab={"tabLocations"}
            maxSteps={2}
            schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default PickupcartBasicInfo;

