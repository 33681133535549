import { Box, IconButton, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState, useRef } from "react";
import Text from "../../../../../components/theme/text/Text";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { tokens } from "../../../../../theme";
import Heading from "../../../../../components/theme/text/Heading";
import Core from "./core/Core";

import MSelectWarehouses from "../../../../../components/global/Modals/MSelectWarehouses";

import { settingsWarehouseGeneralConfig } from "./settingsWarehouseGeneralConfig";
import TabView3 from "../../../../../components/theme/TabView3/TabView3";

const General = forwardRef((props, ref) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [open, setOpen] = useState(false);
    const warehouseModal = {
        open: open,
        setOpen: setOpen
    }

    useImperativeHandle(ref, () => ({
    }));
    return (
        <Box 
        sx={{
            background:"", 
            width:1,
            paddingTop:9,
            position:"relative",
            "@media screen and (max-width:47.9375em)" : {
                    background:"", 
                width:1,
                marginTop:9,
                height: "calc(100% - 72px)",
                // position:"relative",
                paddingX:4,
                paddingTop:4,
                overflow:"hidden",
                display:"flex",
                flexDirection:"column",
                
            },
            "@media screen and (max-width: 47.9375em)" : {
                paddingX:0,
                paddingTop:3,
                background:colors.bg["tertiary"],
                "&:before,&:after":{
                    display:"none"
                }
            },
        }}
        >

            <Box
                sx={{
                    display:"none",
                    "@media screen and (max-width: 47.9375em)" : {
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        width:1,
                        marginBottom:4,
                        paddingX:3,
                        // marginBottom:6.5,
                    }
                }}
            >
                <Box
                
                    sx={{
                        display: "flex",
                        alignItems:"flex-start",
                        flexDirection:"column",
                        gap:0.5,
                    }}
                >
                    <Heading>Picking settings</Heading>
                    <Text light>All settings in this category pertain to picking and orderlines</Text>
                </Box>

                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"center",
                        gap:2,
                        height:40,
                        background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                        borderRadius:5,
                        paddingX:2,
                        color:"#fff",
                        "@media screen and (max-width: 47.9375em)" : {
                            display:"none"
                        }
                    }}
                    onClick={() => warehouseModal.setOpen(true)}
                >
                    <Box>
                        <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
                    </Box>
                    <KeyboardArrowDownIcon color="white"/>
                </Box>
            </Box>

                    
            <TabView3
                tabConfig={settingsWarehouseGeneralConfig}
                // viewType="bodytabs"
                sx={{
                    minHeight:"calc(100%)",
                    background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    position:"relative",
                    zIndex:2,
                    height:1,
                    display:"flex",
                    flexDirection:"column",
                    // "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"48px",
                            position:"absolute",
                            left:0,
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"48px",
                            position:"absolute",
                            top:"40px",
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            transition:"250ms"
                        },
                        "@media screen and (max-width: 47.9375em)" : {

                            "&:before, &:after" : {
                                top:0,
                                width:"24px",
                                // height:"24px",
                            }
                        }
                    // }
                  
                }}
                // title={panel.title}
                // views={panel.views}
                // tabClasses={"btn-body-tab"}
            >
                <>
                    <Core config={settingsWarehouseGeneralConfig.core} warehouseModal={warehouseModal}/>
                </>
            </TabView3>

            <MSelectWarehouses
                open={warehouseModal.open}
                handleClose={() => warehouseModal.setOpen(false)}
            />

        </Box>
    )
});

export default General;