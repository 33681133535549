import { Box, useTheme } from "@mui/material";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetCountingStrategiesQuery } from "../../../../../newapi/countingstrategy/countingStrategySlice";
import { general_states } from "../../../../../utils/staticEnums";

import { CheckCircleOutlined } from "@mui/icons-material";
import { WarehouseCountingStrategiesCard } from "../../../../../components/global/cards/other/CountingStrategiesCard";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../../components/theme/sidepanel2/SidepanelLoader";
const SpCountingStrategyDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpCountingStrategyDetails/SpCountingStrategyDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1} gap={2}>
            <ButtonGroup version={2}>
            </ButtonGroup>
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,

};

const All = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const ref = useRef();
    const [config, setConfig] = useState({"state": general_states.ACTIVE});

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
        }
    },[]);
    // pickingJobListId

    const [strategyId, setStrategyId] = useState();
    const strategyIdRef = useRef(strategyId);
    
    const getCountingStrategy = (params) => {
        if (!params) return;
    
        const newStrategyId = parseInt(params.row.id);
        // Only update state if the pickingJobListId has actually changed
        if (strategyIdRef.current !== newStrategyId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setStrategyId(newStrategyId);
            strategyIdRef.current = newStrategyId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {
            field: "createdAt",
            headerName: t("created_on"),
            flex:1,
            shFilter : {
                type: "date",
            },
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                let date = new Date(params.row.createdAt);
              
                return (
                    <Text>
                        {date.toLocaleDateString(i18n.language)}
                    </Text>
                );
            }
        },
        {
            field: "name", 
            headerName: t("name"),
            flex:1,
            renderCell: (params) => {
                //TODO: brownie, implement
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                
                return params.row.name;
              }
        },
        {
            field: "type", 
            headerName: t("type"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                let labelColor = "grey";
                if(params.row.type === "picking_locations"){
                    labelColor = "pink";
                }
                else if(params.row.type === "bulk_locations"){
                    labelColor = "orange";
                }
                else if(params.row.type === "all_locations"){
                    labelColor = "green";
                }
                else if(params.row.type === "inventory_turnover_ratio"){
                    labelColor = "purple";
                }

                return (
                    <ShLabel 
                        clickable
                        variant={labelColor}
                        fitted
                        size={32} 
                        palette="normal" 
                    >
                        {t(params.row.type)}
                    </ShLabel>
                );
            }
        },
        {
            field: "frequency", 
            headerName: t("frequency"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return t(params.row.frequency);
            }
        },
        {
            field: "amountOfSteps", 
            headerName: t("amount_of_objects"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return params.row.amountOfSteps;
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let labelColor = params.row.state === "active" ? theme.palette.mode === "dark" ? "blue" : "primary" : "green";
                
                let content;
                if(params.row.state === "active") {
                    content = t(params.row.state);
                } else {
                    if (!params.row.completedAt || params.row.completedAt === null) {
                        content = "-";
                    }
                    let date = new Date(params.row.completedAt);
                    content = date.toLocaleDateString(i18n.language);
                }

                return (
                    <ShLabel
                        clickable
                        sx={{
                            ...(params.row.state !== 'active' && {
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                pr:0,
                            }),
                        }}
                        size={32} 
                        palette="normal" 
                        variant={labelColor}
                    >
                        <Text bold sx={{ml:"auto"}} color={labelColor} center>
                            {content}
                        </Text>
                        {params.row.state !== "active" &&
                            <Box
                                sx={{
                                    color:colors['green'][400],
                                    height:24,
                                    width:24,
                                    // color:"white",
                                    ml:"auto",
                                    mr:0.5,
                                    borderRadius:4
                                }}
                            >
                                <CheckCircleOutlined/>
                            </Box>
                        }
                    </ShLabel>
                  );
              }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WarehouseCountingStrategiesCard onClick={() => getCountingStrategy(params)} {...params.row} skeleton={params.row.skeleton ?? false}/>
                )
            }
        }
    ];

    return (
        <Box height={1} ref={ref} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetCountingStrategiesQuery}
                config={config}
                // gridActions={<GridActions></GridActions>} 
                title={t('all')}
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"DESC"}}
                columns={columns}
                onRowClick={getCountingStrategy}
                >
            </ShDataGrid2>

            {spLoading && 
                <SpCountingStrategyDetails
                    strategyId={strategyId}
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    // data={pickingJobData}
                /> 
            }
        </Box>
    );
}

export default All;