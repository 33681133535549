import React, { useContext, useEffect, useRef, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import Topbar from "./components/theme/global/Topbar";

import Sidebar from './components/global/Sidebar';
import Dashboard from "./environments/warehouse/dashboard";


import Forgotpassword from "./environments/global/ForgotPassword";
import Login from "./environments/global/Login";
import Logout from "./environments/global/Logout";
import ResetPassword from "./environments/global/ResetPassword";

// import { RequireAuth } from "react-auth-kit";
import { Box, useTheme } from "@mui/material";
import { MenuStateContext } from "./contexts/MenuState";
import { tokens } from "./theme";

// import cx from classNames
import cx from "classnames";
import SHTransition from "./components/theme/SHTransition";
import StockLocation from "./environments/warehouse/stock/locations/Index";
import StockProduct from "./environments/warehouse/stock/products/Index";
import StockManagement from "./environments/warehouse/stock/stock/Index";
import StockReplenish from "./environments/warehouse/stock/replenish/Index";

//Outbound
import OutboundBatches from "./environments/warehouse/outbound/batches/index";
import OutboundDashboard from "./environments/warehouse/outbound/dashboard";
import OutboundOrders from "./environments/warehouse/outbound/orders/index";

// Automation
import AutomationWorkflows from "./environments/warehouse/automation/workflows/index";
import AutomationCountingStrategies from "./environments/warehouse/stock/countingstrategies";

// Warehouse Relation
import WarehouseRelation from "./environments/warehouse/relation";

// Financial
import FinancialInvoiceComponents from "./environments/warehouse/financial/components";
import FinancialInvoices from "./environments/warehouse/financial/invoices/index";

// Outbound setting
import OutBoundSettingsCarriers from "./environments/warehouse/settings/warehouse/outbound/carriers/Carriers";
import OutBoundSettingsPicking from "./environments/warehouse/settings/warehouse/outbound/picking/Picking";
import OutboundSettingsStock from "./environments/warehouse/settings/warehouse/outbound/stock/Stock";
import OutBoundSettingsStreams from "./environments/warehouse/settings/warehouse/picking/streams/Streams";

//Settings
import SettingsWebshop from "./environments/warehouse/settings/integrations/webshop/Index";
import SettingsGeneral from "./environments/warehouse/settings/warehouse/general/General";
import Shippers from "./environments/warehouse/settings/warehouse/shippers/Shippers";

// Financial
import SettingsAccountingSoftware from "./environments/warehouse/settings/integrations/accountingsoftware";

//Inbound
import InboundDashboard from "./environments/warehouse/inbound/dashboard/index";
import InboundInbounds from "./environments/warehouse/inbound/inbounds/index";

// Returns
import WarehouseReturns from "./environments/warehouse/returns/index";

// Relation
import RelationDashboard from "./environments/relation/dashboard";
import RelationFinancialInvoices from "./environments/relation/financial/invoices";
import RelationSettingsIntegrator from "./environments/relation/integrations/integrator/Index";
import RelationSettingsWebshop from "./environments/relation/integrations/webshop/Index";

import Echeck from "./environments/warehouse/echeck2/index";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RequireAuth } from "./authentication/RequireAuth";
import Topbar from "./components/global/Topbar/Topbar";
// import { AxiosBackendWrapper, AxiosWrapper } from "./api/Settings";
import { useDispatch, useSelector } from "react-redux";
import RelationInbound from "./environments/relation/inbounds";
import RelationOutbound from "./environments/relation/orders";
import RelationProducts from "./environments/relation/products/Index";
import SettingsSupplier from "./environments/relation/settings/supplier/Index";
import AccountGeneral from "./environments/user/account/General";
import Usergroups from "./environments/warehouse/settings/access/usergroups/Usergroups";
import Users from "./environments/warehouse/settings/access/users/Users";
import MigrationImport from "./environments/warehouse/settings/warehouse/import/MigrationImport";
import { resetFilter } from "./newapi/global/filter/gridFilterSlice";
import { UserPrivileges } from './utils/permissions';

import SettingsProductFields from "./environments/warehouse/settings/products/customfields/Index";
import SettingsCountingStrategies from "./environments/warehouse/settings/warehouse/countingstrategies/CountingStrategies";
import Printers from "./environments/warehouse/settings/warehouse/office/printers/Printers";
import Workspaces from "./environments/warehouse/settings/warehouse/office/workspaces/Workspaces";
import Resources from "./environments/warehouse/settings/warehouse/picking/resources/Resources";
import Warehouses from "./environments/warehouse/settings/warehouse/warehouses/Warehouses";
import SettingsWorkflowTemplates from "./environments/warehouse/settings/warehouse/workflowtemplates/WorkflowTemplates";

import RelationUsergroups from "./environments/relation/access/usergroups/Usergroups";
import RelationUsers from "./environments/relation/access/users/Users";
import RelationReturns from "./environments/relation/returns";
import SettingsIntegrator from "./environments/warehouse/settings/integrations/integrator/Index";
import { toggleMenu } from "./newapi/global/settingsSlice";
import { TopbarProvider } from "./contexts/TopbarProvider";

// import { DndProvider } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';
// import { TouchBackend } from 'react-dnd-touch-backend';
// import  {MultiBackend, TouchTransition, MouseTransition } from 'react-dnd-multi-backend';
// import { Preview } from 'react-dnd-multi-backend';

// // Define your backends
// const HTML5toTouch = {
//   backends: [
//     {
//       backend: HTML5Backend,
//       transition: MouseTransition,
//     },
//     {
//       backend: TouchBackend, // Note that you can pass options to the TouchBackend here
//       options: { enableMouseEvents: true },
//       preview: true,
//       transition: TouchTransition,
//     },
//   ],
// };

const AppMenu = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  
  const collapsed = useSelector((state) => state.settings.environment.collapsed);

  const backdropRef = useRef(null);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  // const breakpoint = props.breakpoint ?? 767
  const handleResize = (event) => {
      setWindowSize(window.innerWidth);
  }

  useEffect(() => {
      window.addEventListener("resize", handleResize);

      return () => {
          window.removeEventListener("resize", handleResize);
      }
  }, [])

  useEffect(() => {
      if(collapsed && windowSize <= 1369) {
        backdropRef.current.classList.add("menu-backdrop-enter-done");
      }
  },[theme.palette.mode,windowSize]);

  return (
    <React.Fragment>
      <SHTransition
        className={"menu-backdrop"}
        nodeRef={backdropRef}
        in={collapsed && windowSize <= 1369}
        // in={true}
        classNames="menu-backdrop"
        appear
        
        timeout={500}
      >
        <Box className={`uywuwu ${theme.palette.mode}`} onClick={() => dispatch(toggleMenu())} ref={backdropRef} sx={{position:"fixed", left:0,top:0,width:1,height:1,zIndex:"-1"}}></Box> 
      </SHTransition>
      
      <ToastContainer autoClose={1200} />
    </React.Fragment>
  )

}

function App() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode)
  const location = useLocation();
  const dispatch = useDispatch();
  const isLoginPage = location.pathname === "/login" || location.pathname === "/forgot" || location.pathname === "/reset";
   
  useEffect(() => {
    dispatch(resetFilter());
  },[])

  return (
    <TopbarProvider>
    {/* // <DndProvider backend={MultiBackend} options={HTML5toTouch}> */}
      <div className="app">
        {!isLoginPage && <Sidebar/>}
        <Box 
          component={"main"} 
          className={cx(`content`, {
            'hide-side' : isLoginPage
          })}
          sx={{
            background:theme.palette.mode === "dark" ? colors.grey["200"] : colors.grey["0"]
          }}
        >
          {!isLoginPage && <Topbar/>}
          <Routes>
            <Route
              path="/"
              element={<Navigate to="/login" />}
            ></Route>

            <Route path="/warehouse">
              {/* Echeck Route */}
              <Route path="dashboard" 
                element={
                  <RequireAuth path="/login">
                    <Dashboard />
                  </RequireAuth>
                }
              />

              {/* Outbound Routes */}
              <Route path="outbound">
                <Route path="dashboard" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.OUTBOUND_READ, UserPrivileges.OUTBOUND_MANAGE]} path="/login">
                      <OutboundDashboard />
                    </RequireAuth>
                  }
                />
                <Route path="orders" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.OUTBOUND_READ, UserPrivileges.OUTBOUND_MANAGE]} path="/login">
                      <OutboundOrders />
                    </RequireAuth>
                  }
                />
                <Route path="batches" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.OUTBOUND_READ, UserPrivileges.OUTBOUND_MANAGE]} path="/login">
                      <OutboundBatches />
                    </RequireAuth>
                  }
                />
              </Route>

              {/* Automation Routes */}
              <Route path="automation">
                <Route path="workflows" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.WORKFLOW_READ]} path="/login">
                      <AutomationWorkflows />
                    </RequireAuth>
                  }
                />
              </Route>

              {/* Relations Routes */}
              <Route path="relations">
                <Route path="" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.RELATION_READ, UserPrivileges.RELATION_MANAGE]} path="/login">
                      <WarehouseRelation />
                    </RequireAuth>
                  }
                />
              </Route>

              {/* Financial Routes */}
              <Route path="financial">
                <Route path="invoices" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.INVOICE_READ, UserPrivileges.INVOICE_MANAGE]} path="/login">
                      <FinancialInvoices />
                    </RequireAuth>
                  }
                />
                <Route path="components" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.INVOICEPRODUCT_READ, UserPrivileges.INVOICEPRODUCT_MANAGE, UserPrivileges.INVOICECATEGORY_READ, UserPrivileges.INVOICECATEGORY_MANAGE]} path="/login">
                      <FinancialInvoiceComponents />
                    </RequireAuth>
                  }
                />
              </Route>

              {/* Inbound Routes */}
              <Route path="inbound">
                <Route path="dashboard" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.INBOUND_READ, UserPrivileges.INBOUND_MANAGE]} path="/login">
                      <InboundDashboard />
                    </RequireAuth>
                  }
                />
                <Route path="inbounds" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.INBOUND_READ, UserPrivileges.INBOUND_MANAGE]} path="/login">
                      <InboundInbounds />
                    </RequireAuth>
                  }
                />
              </Route>
              {/* Returns Routes */}
              <Route path="returns">
                <Route path="" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.RETURN_READ, UserPrivileges.RETURN_MANAGE]} path="/login">
                      <WarehouseReturns />
                    </RequireAuth>
                  }
                />
              </Route>

              {/* Stock Routes */}
              <Route path="stock">
                <Route path="stock" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.STOCK_READ, UserPrivileges.STOCK_MANAGE]} path="/login">
                      <StockManagement />
                    </RequireAuth>
                  }
                />
                <Route path="locations" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.WAREHOUSELOCATION_READ, UserPrivileges.WAREHOUSELOCATION_MANAGE]} path="/login">
                      <StockLocation />
                    </RequireAuth>
                  }
                />
                <Route path="products" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.PRODUCT_READ, UserPrivileges.PRODUCT_MANAGE]} path="/login">
                      <StockProduct />
                    </RequireAuth>
                  }
                />
                <Route path="replenish" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.REPLENISHLINE_READ, UserPrivileges.REPLENISHLINE_MANAGE]} path="/login">
                      <StockReplenish />
                    </RequireAuth>
                  }
                />
                
                <Route path="countingstrategies" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.COUNTINGSTRATEGY_READ, UserPrivileges.COUNTINGSTRATEGY_MANAGE]} path="/login">
                      <AutomationCountingStrategies />
                    </RequireAuth>
                  }
                />
              </Route>

              {/* Echeck Route */}
              <Route path="echeck" 
                element={
                  <RequireAuth permissions={[UserPrivileges.ECHECK]} path="/login">
                    <Echeck />
                  </RequireAuth>
                }
              />

              {/* Settings */}
              <Route
                path="settings"
              >
                <Route path="outbound/carriers" 
                  element={
                    <RequireAuth
                      permissions={[UserPrivileges.CARRIER_MANAGE, UserPrivileges.SETTINGS_ALL]}
                      path="/login"
                    >
                      <OutBoundSettingsCarriers/>
                    </RequireAuth>
                  }
                />
                <Route path="outbound/picking" 
                  element={
                    <RequireAuth
                      path="/login"
                    >
                      <OutBoundSettingsPicking/>
                    </RequireAuth>
                  }
                />
                <Route path="outbound/stock" 
                  element={
                    <RequireAuth
                      path="/login"
                    >
                      <OutboundSettingsStock/>
                    </RequireAuth>
                  }
                />
                <Route path="general" 
                  element={
                    <RequireAuth
                      permissions={[UserPrivileges.SETTINGS_GENERAL, UserPrivileges.SETTINGS_ALL]}
                      path="/login"
                    >
                      <SettingsGeneral/>
                    </RequireAuth>
                  }
                />
                <Route path="shippers" 
                  element={
                    <RequireAuth
                      permissions={[UserPrivileges.SHIPPER_READ, UserPrivileges.SHIPPER_MANAGE, UserPrivileges.SETTINGS_ALL]}
                      path="/login"
                    >
                      <Shippers/>
                    </RequireAuth>
                  }
                />
                <Route path="warehouses" 
                  element={
                    <RequireAuth
                      permissions={[UserPrivileges.WAREHOUSE_READ, UserPrivileges.WAREHOUSE_MANAGE, UserPrivileges.SETTINGS_ALL]}
                      path="/login"
                    >
                      <Warehouses/>
                    </RequireAuth>  
                  }
                />
                <Route path="picking/streams" 
                  element={
                    <RequireAuth
                      permissions={[UserPrivileges.STREAM_READ, UserPrivileges.STREAM_MANAGE, UserPrivileges.SETTINGS_ALL]}
                      path="/login"
                    >
                      <OutBoundSettingsStreams/>
                    </RequireAuth>
                  }
                />
                <Route path="picking/resources" 
                  element={
                    <RequireAuth
                      permissions={[UserPrivileges.PICKINGRESOURCE_READ, UserPrivileges.PICKINGRESOURCE_MANAGE, UserPrivileges.SETTINGS_ALL]}
                      path="/login"
                    >
                      <Resources/>
                    </RequireAuth>
                  }
                />
                <Route path="migratie" element={
                    <RequireAuth
                      path="/login"
                    >
                      <MigrationImport/>
                    </RequireAuth>
                  }
                />
                <Route path="products/customfields" 
                  element={
                    <RequireAuth
                      permissions={[UserPrivileges.PRODUCTFIELD_MANAGE, UserPrivileges.SETTINGS_ALL]}
                      path="/login"
                    >
                      <SettingsProductFields/>
                    </RequireAuth>
                  }
                />
                  
                <Route path="automation/countingstrategies" 
                  element={
                    <RequireAuth 
                      permissions={[UserPrivileges.COUNTINGSTRATEGYTEMPLATE_MANAGE, UserPrivileges.SETTINGS_ALL]}
                    path="/login"
                    >
                      <SettingsCountingStrategies />
                    </RequireAuth>
                  }
                />
                <Route path="automation/workflowtemplates" 
                  element={
                    <RequireAuth path="/login">
                      <SettingsWorkflowTemplates />
                    </RequireAuth>
                  }
                />
                <Route path="integrations/saleschannels" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.WEBSHOPCREDENTIALS_MANAGE, UserPrivileges.SETTINGS_ALL]} path="/login">
                      <SettingsWebshop />
                    </RequireAuth>
                  }
                />
                <Route path="integrations/integrators" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.WEBSHOPCREDENTIALS_MANAGE, UserPrivileges.SETTINGS_ALL]} path="/login">
                      <SettingsIntegrator />
                    </RequireAuth>
                  }
                />
                <Route path="integrations/accountingsoftware" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.ACCOUNTINGSOFTWARE_MANAGE, UserPrivileges.SETTINGS_ALL]} path="/login">
                      <SettingsAccountingSoftware />
                    </RequireAuth>
                  }
                />
                
                <Route path="office/workspaces" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.WORKSPACE_READ, UserPrivileges.WORKSPACE_MANAGE, UserPrivileges.SETTINGS_ALL]} path="/login">
                      <Workspaces />
                    </RequireAuth>
                  }
                />
                <Route path="office/printers" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.PRINTER_READ, UserPrivileges.PRINTER_MANAGE, UserPrivileges.SETTINGS_ALL]} path="/login">
                      <Printers />
                    </RequireAuth>
                  }
                />
                <Route path="access/users" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.SETTINGS_USERS, UserPrivileges.SETTINGS_ALL]} path="/login">
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route path="access/usergroups" 
                  element={
                    <RequireAuth permissions={[UserPrivileges.SETTINGS_USERPRIVILEGES, UserPrivileges.SETTINGS_ALL]} path="/login">
                      <Usergroups />
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>

            <Route path="/relation">

              <Route path="dashboard" 
                element={
                  <RequireAuth path="/login">
                    <RelationDashboard />
                  </RequireAuth>
                } 
              />
              <Route path="outbound" 
                element={
                  <RequireAuth path="/login" permissions={[UserPrivileges.RP_ORDER_READ, UserPrivileges.RP_ORDER_MANAGE]}>
                    <RelationOutbound />
                  </RequireAuth>
                } 
              />
              <Route path="products" 
                element={
                  <RequireAuth path="/login" permissions={[UserPrivileges.RP_PRODUCT_READ, UserPrivileges.RP_PRODUCT_MANAGE]}>
                    <RelationProducts />
                  </RequireAuth>
                } 
              />
              <Route path="inbound" 
                element={
                  <RequireAuth path="/login" permissions={[UserPrivileges.RP_INBOUND_READ, UserPrivileges.RP_INBOUND_MANAGE]}>
                    <RelationInbound />
                  </RequireAuth>
                } 
              />
              <Route path="returns" 
                element={
                  <RequireAuth path="/login" permissions={[UserPrivileges.RP_RETURN_READ, UserPrivileges.RP_RETURN_MANAGE]}>
                    <RelationReturns />
                  </RequireAuth>
                } 
              />

              <Route path="financial/invoices" 
                element={
                  <RequireAuth path="/login" permissions={[UserPrivileges.RP_INVOICE_READ]}>
                    <RelationFinancialInvoices />
                  </RequireAuth>
                } 
              />
              <Route path="settings">
                <Route path="access/users" 
                  element={
                    <RequireAuth path="/login" permissions={[UserPrivileges.RP_ACCESS_READ, UserPrivileges.RP_ACCESS_MANAGE]}>
                      <RelationUsers />
                    </RequireAuth>
                  } 
                />
                <Route path="access/usergroups" 
                  element={
                    <RequireAuth path="/login" permissions={[UserPrivileges.RP_ACCESS_READ, UserPrivileges.RP_ACCESS_MANAGE]}>
                      <RelationUsergroups />
                    </RequireAuth>
                  } 
                />
                <Route path="suppliers" 
                  element={
                    <RequireAuth path="/login" permissions={[UserPrivileges.RP_SUPPLIER_READ, UserPrivileges.RP_SUPPLIER_MANAGE]}>
                      <SettingsSupplier />
                    </RequireAuth>
                  } 
                />
                
              </Route>
              <Route path="integrations">
                <Route path="saleschannels" 
                    element={
                      <RequireAuth path="/login" permissions={[UserPrivileges.RP_WEBSHOPCREDENTIALS_MANAGE]}>
                        <RelationSettingsWebshop />
                      </RequireAuth>
                    }
                  />
                <Route path="integrators" 
                  element={
                    <RequireAuth path="/login" permissions={[UserPrivileges.RP_WEBSHOPCREDENTIALS_MANAGE]}>
                      <RelationSettingsIntegrator />
                    </RequireAuth>
                  }
                />
              </Route>
            </Route>
            <Route
              path="/user"
            >
              <Route path="account/general" element={
                  <RequireAuth
                    path="/login"
                  >
                    <AccountGeneral/>
                  </RequireAuth>
                }
              />
              
            </Route>

            <Route
              path="/*"
              element={<Navigate to="/login" />}
            ></Route>

            <Route path="/logout" element={<Logout/>}></Route>
            <Route path="/login" element={<Login/>}></Route>
            <Route path="/forgot" element={<Forgotpassword/>}></Route>
            <Route path="/reset" element={<ResetPassword/>}></Route>
          </Routes>

          {!isLoginPage && <AppMenu/>}
        </Box>
      </div>
    </TopbarProvider>
    // </DndProvider>
  );
}

export default App;
