import { Box, useTheme } from "@mui/material";
import { GridCheckIcon } from "@mui/x-data-grid";
import Shbutton from "../../../../components/theme/buttons/Shbutton";

import AddIcon from '@mui/icons-material/Add';
import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import { ClearIcon } from "@mui/x-date-pickers";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import MAddReturnReason from "../../../../components/global/Modals/MAddReturnReason";
import ButtonGroup from "../../../../components/theme/buttons/ButtonGroup";
import ShDataGrid2 from "../../../../components/theme/datagrid2/ShDataGrid2";
import SidepanelLoader from "../../../../components/theme/sidepanel2/SidepanelLoader";
import ShTextSkeleton from "../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetReturnReasonsQuery } from "../../../../newapi/inbound/returnsSlice"; // Changed from inboundsSlice to returnsSlice
import { useSelector } from "react-redux";
import { selectUserPermission } from "../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from "../../../../utils/permissions";

const SpReturnReasonDetails = SidepanelLoader(() => import("../../../../components/global/Sidepanels/SpReturnReasonDetails/SpReturnReasonDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t('new_return_reason')}
            </Shbutton>
            )}
        </ButtonGroup>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};

const ReturnReasons = (props) => { // Changed from InboundInbounds to ReturnReturns
    const { t,i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isExtended, setIsExtended] = useState(false);

    const [spLoading, setSpLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.RP_RETURN_MANAGE])
    );

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);

    const handleOpen = (open) => {
        addModal.setOpen(true);
    }

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
      };
    

    const [selectedRowId, setSelectedRowId] = useState();
    const selectedRowIdRef = useRef(selectedRowId);
    
        
    const getReturnReasonDetails = (params) => {
        if (!params) return;
        // setSelectedRowId(params.row.id);

        const newReturnReasonId = parseInt(params.row.id);
        // Only update state if the userGroupId has actually changed
        if (selectedRowIdRef.current !== newReturnReasonId) {
            setSpLoading(true);
            setSelectedRowId(newReturnReasonId);
            selectedRowIdRef.current = newReturnReasonId; // Update the ref to the new value
        }
        
        setIsExtended(true);
    };

    

    const columns = [
        {field: "description", headerName: t("description"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return(
                    <Text>
                        {params.row.description}
                    </Text>
                )
            }
        },
        {field: "requireImageUpload", headerName: t("requireimageupload"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box>
                        <Text>
                            {params.value ? (
                                <GridCheckIcon sx={{ color: 'green' }} />
                            ) : (
                                <ClearIcon sx={{ color: 'rgba(128, 128, 128, 0.5)' }} />
                            )}
                        </Text>
                    </Box>
                )
            }
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    // <RelationReturnCard skeleton={params.row.skeleton ?? false} {...params.row}/>
                    // <InboundsCard skeleton={params.row.skeleton ?? false} {...params.row} />
                // <Box padding={3} sx={{background:"orange"}}></Box>
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {params.row.description}
                        <br/>
                        <Text>
                            {t("requireimageupload")}: 
                        </Text>
                        <Text>
                            {params.row.requireImageUpload ? (
                                <GridCheckIcon sx={{ color: 'green' }} />
                            ) : (
                                <ClearIcon sx={{ color: 'rgba(128, 128, 128, 0.5)' }} />
                            )}
                        </Text>
                    </Box>
                    
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReturnReasonsQuery} // Changed from useGetInboundsQuery to useGetReturnsQuery
                gridActions={<GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>} 
                title={t('all_returns') } // Changed from all_inbounds to all_returns
                gridOptions={gridOptions} 
                sortModel={{field: columns[2].field,type:"DESC"}}
                columns={columns}
                onRowClick={getReturnReasonDetails}> 
            </ShDataGrid2>


            {spLoading && 
                <SpReturnReasonDetails // Changed from SpInboundDetails to SpReturnDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    returnReasonId={selectedRowId}
                    // data={returnData} // Changed from inboundData to returnData
                />
            }

            <MAddReturnReason onOpen={() => addModal.setOpen(true)} onClose={() => addModal.setOpen(false)} open={addModal.open} handleClose={handleClose}/>

        </Box>
    );
}

export default ReturnReasons; // Changed from InboundInbounds to ReturnReturns
