/**
 * Formats a price according to the given locale with EUR currency
 * @param {object} i18n - Internationalization object containing language preference
 * @param {number} price - The price value to format
 * @returns {string} - Formatted price string with currency symbol
 */
exports.formatPrice = (i18n,price) => {
        // Step 1: Format the price using Intl.NumberFormat with the locale and currency
    const formattedPrice = new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: 'EUR',
        currencyDisplay: 'symbol',  // Removes space between symbol and number
    }).format(price);

    // Step 2: Remove the space between the currency symbol and the number if it exists
    const noSpacePrice = formattedPrice.replace(/\s?(€|₤|₹|¥|₣)/, '$1');  // Adjust for common currency symbols

    return noSpacePrice;
}

/**
 * Formats a number to local number
 * @param {*} i18n 
 * @param {*} number 
 * @returns 
 */
exports.formatNumber = (i18n,number) => {
    return new Intl.NumberFormat(i18n.language, {}).format(number);
}


/**
 * Removes unnecessary trailing zeros from a number
 * @param {number|string} input - The number to format
 * @returns {string} - Formatted number without trailing zeros
 *                     (e.g., "1.20" becomes "1.2", "1.00" becomes "1")
 */
exports.removeTrailingZeros = (input) => {
    // Format amount to remove trailing zeros and only show necessary decimals
    const num = Number(input);
    return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2).replace(/\.?0+$/, '');
}

/**
 * Checks if a given date is in the future, comparing dates only (ignoring time)
 * @param {string} date - Date string in YYYY-MM-DD format
 * @returns {boolean} - Returns true if the date is in the future, false otherwise
 */
exports.isFutureDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);
    
    return compareDate > today;
}


exports.errorMapWithArrayProcessor = (errors,toMatchTo) => {
    const fieldErrors = {};
    const generalErrors = {};

    Object.keys(errors).forEach((errorKey) => {
        const match = errorKey.match(toMatchTo);
        if (match) {
            const index = parseInt(match[1], 10); 
            const field = match[2]; 

            if (!fieldErrors[index]) {
            fieldErrors[index] = {};
            }
            
            fieldErrors[index][field] = errors[errorKey];
        } else {
            generalErrors[errorKey] = errors[errorKey];
        }
    });

    return { fieldErrors, generalErrors };
};