import { Box, useTheme } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
// import { tokens } from "../../../../../theme";
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";

import Subheading from "../../../../../../../components/theme/text/Subheading";
import Text from "../../../../../../../components/theme/text/Text";
import { useGetSettingsQuery } from "../../../../../../../newapi/global/generalApiSlice";
import { tokens } from "../../../../../../../theme";
import BulkXL from "./views/BulkXL";
import Core from "./views/Core";
import OrderSizing from "./views/OrderSizing";
import TabView3 from "../../../../../../../components/theme/TabView3/TabView3";
import { settingsWarehouseOutboundPickingStreamsConfig } from "./settingsWarehouseOutboundPickingStreamsConfig";

function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
} 


const GridActions = (props) => {
    const theme = useTheme();
    
    // return (
    //     <ButtonGroup version={2}>
    //         <Shbutton 
    //             onClick={() => props.handleOpen(true)}
    //             className={"group-btn"} 
    //             color={theme.palette.mode === "dark" ? "blue" : "primary"} 
    //             variant="contained" 
    //             endIcon={<AddIcon/>}>Add standard</Shbutton>
    //     </ButtonGroup>
    // )
}

const WareHouseDropdown = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return(
        <Box
            sx={{
                display:"flex",
                alignItems:"center",
                justifyContent:"center",
                gap:2,
                height:40,
                background:theme.palette.mode === "dark" ? colors.grey["400"] : colors.txt["primary"],
                borderRadius:5,
                paddingX:2,
                paddingRight:1,
                borderTopRightRadius:0,
                borderBottomRightRadius:0,
                color:"#fff"
            }}
            onClick={() => props.setOpen(true)}
        >
            <Box>
                <Text bold variant="blue">t("all")</Text>&nbsp;<Text variant="white">t("warehouses")</Text>
            </Box>
            <KeyboardArrowDownIcon/>
        </Box>
    )
}

const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: false,
    hide:true,
    

};

const Streams = forwardRef((props, ref) => {
    const { t } = useTranslation();
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { data: incData} = useGetSettingsQuery("streams");
    const [data, setData] = useState();

    useEffect(() => {
        setData(incData);
    },[incData]);


    useEffect(() => {
        props.replaceView({
            ...props.config,
            topRight:<WareHouseDropdown {...props.warehouseModal}/>,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);

    


    const columns = [
        {field: "carrier", headerName: t("carrier") , flex:5, cellClassName: "carrier", renderCell : (cellValues) => {
            return (
                <Text semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{cellValues.value}</Text>
            )
        }},
        {field: "condition", headerName: t("condition") ,flex:1},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <Box
                        sx={{
                            background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                            paddingX:2,
                            paddingY:1.5,
                            borderRadius:2
                        }}
                    >
                        {cellValues.row.id + " " + cellValues.row.name}
                        <br/>
                        {cellValues.row.age}
                        <br/>
                        {cellValues.row.phone}
                    </Box>
                )
            }
        }
    ];

    useImperativeHandle(ref, () => ({
    }));
    return (
        <Box
            sx={{
                padding:"0px 32px",
                paddingTop:"32px",
                height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }
            }}
        >
            <Box
            sx={{
                //padding:"0px 32px",
                marginBottom:"32px",
                //height:1,
                "@media screen and (max-width: 47.9375em)" : {
                    paddingTop:0,
                    paddingX:0,
                }}}>
                <Subheading>{t("streams_settings_title")}</Subheading>
                <Text light>{t("streams_settings_subtitle")}</Text>
            </Box>

            <TabView3
                tabConfig={settingsWarehouseOutboundPickingStreamsConfig}
                viewType="bodytabs"
                sx={{
                    position:"relative",
                    zIndex:2,
                    display: "flex",
                    flex:1,
                    height:1,
                    "@media screen and (max-width: 47.9375em)" : {
                        "&:before" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            left:0,
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            // transition:"250ms"
                        },
        
                        "&:after" : {
                            content: "''",
                            display:"block",
                            width:"32px",
                            height:"32px",
                            position:"absolute",
                            top:0,
                            zIndex:1,
                            backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            right:0,
                            height:"48px",
                            width:"24px",
                            borderTopLeftRadius:"24px",
                            boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                            transform:"scaleX(-1)",
                            // transition:"250ms"
                        },
                    }
                  
                }}
                tabClasses={"btn-body-tab"}
            >
                <>
                    <Core config={settingsWarehouseOutboundPickingStreamsConfig.core} settings={data}/>
                    <OrderSizing config={settingsWarehouseOutboundPickingStreamsConfig.orderSizing} settings={data} />
                    <BulkXL config={settingsWarehouseOutboundPickingStreamsConfig.bulkXl} settings={data} />
                </>
            </TabView3>
        </Box>
    )
});

export default Streams;