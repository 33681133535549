import { createContext, useContext, useState } from "react";

const HoverContext = createContext();
export const HoverProvider = ({ children }) => {
    const [hoverStack, setHoverStack] = useState([]);
    const [activeElement, setActiveElement] = useState(null);

    const addHover = (element) => {
        setHoverStack((prevStack) => [...prevStack, element]);
    };

    const removeHover = (element) => {
        setHoverStack((prevStack) => prevStack.filter((el) => el !== element));
    };

    const getTopHover = () => {
        return hoverStack[hoverStack.length - 1];
    };

    return (
        <HoverContext.Provider value={{ addHover, removeHover, getTopHover, activeElement, setActiveElement }}>
            {children}
        </HoverContext.Provider>
    );
};

export const useHover = () => useContext(HoverContext);