import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";


import { useTranslation } from "react-i18next";
import PopoverModal from "../../../theme/Modal/PopoverModal";
import NotificationsContent from "../../Notifications/Notifications";


const MPONotifications = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    
    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }

    return (
        <PopoverModal
            zIndex={1199}
            triangle={{position:"right"}}
            {...props}
        >
            <Box 
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[100] : "#fff",
                    // mb:"-12px",
                    paddingBottom: isStandAlone ? 1 : 0,
                    display:"flex",
                    flexDirection:"column",
                    borderRadius:"24px",
                    borderBottomLeftRadius:0,
                    borderBottomRightRadius:0,
                    "@media screen and (max-width: 48em)" : {
                        borderRadius:"32px",
                        borderBottomLeftRadius:0,
                        borderBottomRightRadius:0,

                    }
                }}
            >
                <NotificationsContent {...props} data={props.data}/>
            </Box>

        </PopoverModal>
    );
}

export default MPONotifications;