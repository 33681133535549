import { Box } from "@mui/material";
import { useTheme } from "@mui/material";

import { tokens } from "../../../../../theme";
import Shbutton from "../../../../theme/buttons/Shbutton";
import AddIcon from '@mui/icons-material/Add';
import Text from "../../../../theme/text/Text";

import CropFreeIcon from '@mui/icons-material/CropFree';
import ButtonGroup from "../../../../theme/buttons/ButtonGroup";

import WriteOffsCard from "../../../../global/cards/orders/WriteOffsCard";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { useGetStockMutationsQuery } from "../../../../../newapi/warehouse/stockSlice";
import { useGetOrderMutationsQuery } from "../../../../../newapi/order/ordersSlice";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";
import ShDataGrid2 from "../../../../theme/datagrid2/ShDataGrid2";

// const GridActions = (props) => {
//     const theme = useTheme();
//     return (
//         <ButtonGroup version={2}>
//             <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} variant="contained" endIcon={<AddIcon/>}>Save</Shbutton>
//         </ButtonGroup>
//     )
// }


const gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    hide:true,
    

};

const WriteOffs = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    
    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            
        }
    },[]);
    

    // useEffect(() => {
    //     if(props.isActive && !dataHasBeenSet) {
    //         getMutations(props.data.id,(result, data) => {
    //             if(result){
    //                 setData(data);
    //                 setDataHasBeenSet(true);
    //             }
    //         })
    //     }
    // },[props.isActive]);

    const columns = [
        {field: "mutation", headerName: t("quantity") , renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton />
            }
            return (
                <Box paddingLeft={0}>
                    <Text semibold>{params.value}</Text>
                </Box>
            )
        }},
        {field: "stock.product.description", headerName: t("description"), flex:3, renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton />
            }
            return (
                <Box>
                    <Text>{params.row.stock?.product?.description}</Text>
                </Box>
            )
        }},
        {field: "stock.product.sku", headerName: t("sku"),flex:1,renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
            }
            return (
                <Box>
                    <Text variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.product?.sku}</Text>
                </Box>
            )
        }},
        {field: "stock.warehouseLocation.location", headerName: t("location"),flex:1,renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
            }
            return (
                <Box>
                    <Text variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.warehouseLocation?.location}</Text>
                </Box>
            )
        }},
        
        {field: "stock.product.barcode", headerName: t("barcode"), flex:1,renderCell : (params) => {
            if(params.row.skeleton) {
                return <ShTextSkeleton variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
            }
            return (
                <Box>
                    <Text variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.stock?.product?.barcode}</Text>
                </Box>
            )
        }},
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <WriteOffsCard skeleton={params.row.skeleton ?? false} {...params}/>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"} overflow={"auto"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetOrderMutationsQuery}
                itemId={props.data.id}
                // gridActions={<GridActions></GridActions>} 
                title={t("write_offs")}
                gridOptions={gridOptions} 
                isSidepanelGrid 
                apiRef={props.apiRef} 
                columns={columns}>
            </ShDataGrid2>
        </Box>
    );
}

export default WriteOffs;