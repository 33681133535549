import { Box, InputBase, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { tokens } from "../../../theme";
import SHModal from "../Modal/SHModal";
import Subheading from "../text/Subheading";

const MSearch = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }


    return(
        <SHModal
            className={"search-modal"}
            open={props.open}
            onClose={props.handleClose}
        >
            <Box 
                sx={{
                    textAlign:"center",
                    paddingY:4,
                    paddingX:5,
                    display:"flex",
                    flexDirection:"column",
                    gap:1
                }}
            >
        
                <Box 
                    sx={{
                        position:'relative',
                        display:"flex",
                        borderRadius:5,
                        background: theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[200],
                        // border:`1px solid ${colors.grey[200]}`,
                        // boxShadow:"0px 2px 4px rgba(45,65,95,0.15)",
                    }}
                >
                    <InputBase 
                        ref={props.inputRef}
                        sx={{
                            px:2,
                            lineHeight:"40px",
                            minWidth:300,
                            height:40,
                        }} 
                        placeholder={t("search")} 
                        value={props.value}
                        onChange={props.handleSearchChange}
                    />
                </Box>
                {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
            </Box>

            {/* BODY */}
            <Box
                sx={{
                    background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                    borderTopLeftRadius:16,
                    borderTopRightRadius:16,
                    display:"flex",
                    flexDirection:"column",
                    gap:2,
                    maxHeight:400,
                }}
            >
                {props.searchContainer}
            </Box>
            {/* <Box
                component="footer"
                sx={{
                    
                    display:"block",
                    width:1,
                    paddingBottom: isStandAlone ? "32px" : "16px",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    paddingRight:3,
                    zIndex:1058,
                    position:"relative",
                    paddingTop:2,
                    background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                    borderBottomLeftRadius:24,
                    borderBottomRightRadius:24,
                    "@media screen and (max-width: 47.9375em)" : {
                        paddingRight:0,
                    }
                }}
            >
                <Shbutton isCancel={true} className={"always-visible"} onClick={props.handleClose} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                <ButtonGroup version={2}>
                    <Shbutton isLoading={isLoading} className={"group-btn"} color={"green"}
                    variant="contained" 
                    onClick={() => SaveRelationContact(relationContactInfo, props)}
                    >{t("add")}</Shbutton>
                </ButtonGroup>
            </Box> */}
        </SHModal>
    )
}

export default MSearch;
