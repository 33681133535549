import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../../theme/cards/BaseCard"
import { tokens } from "../../../../../theme";
import Text from "../../../../theme/text/Text";
import CardLabel from "../../../../theme/label/CardLabel";
import TopRight from "../../../../theme/positionals/TopRight";
import { useTranslation } from "react-i18next";
import ShTextSkeleton from "../../../../theme/skeleton/ShTextSkeleton";

const RelationProductCard = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t } = useTranslation();
    
    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box 
                        display={"flex"} 
                        flexDirection={"column"} 
                        gap={props.skeleton ? 0.5 : null} 
                        width={props.skeleton ? "58%" : null}
                    >
                        {props.skeleton ?
                            <ShTextSkeleton/>
                            :
                            <Text noBreak bold>{props.description}</Text>
                        }
                    </Box>
                    <TopRight>
                        <CardLabel 
                            variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                            // variant={"grey"}  
                            // background={theme.palette.mode === "dark" ? colors.grey[400] : null} 
                            // color={theme.palette.mode === "dark" ? colors.txt["primary"] : null} 
                            position="child-tr"
                        >
                            {props.sku ? props.sku : "N/A"}
                        </CardLabel>
                    </TopRight>

                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("stock_quantity")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.stock && props.stock.total_quantity ? props.stock.total_quantity : 0}    
                                </Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("stock_available")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.stock && props.stock.total_available ? props.stock.total_available : 0}
                                </Text>
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>
        </BaseCard>
    )
}

export default RelationProductCard;