import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";

// import AdvancedFilterView from "./Filter/Views/AdvancedFilterView";
import PopoverModal from "../../Modal/PopoverModal";


import { filterModalConfig } from "../filterold/filterModalConfig";
import GridcolumnSettingsView from "./GridcolumnSettingsView";

const GridcolumnSettings = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <PopoverModal
            {...props}
            triangle={{position:"right", color: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0]}}
        >
            <Box 
                sx={{
                    background: theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[0],
                    // background:"orange",
                    width:520, //fix
                    "@media screen and (max-width: 768px)" : {
                        width:1,
                    }
                }}
            >
                
                <GridcolumnSettingsView config={filterModalConfig.settings} {...props}/>
                {/* <TabView3
                    tabConfig={filterModalConfig}
                    // tabConfig=(filtermoda)
                    title={title}
                    // views={views}
                    tabClasses={"s56 as-tab"}
                    lighterBody
                    viewType={"popup"}
                    asTab
                >
                    <> */}
                        {/* <AdvancedFilterView {...props} apiRef={props.apiRef}/> */}
                        {/* <SortView {...props}/> */}
                        {/* <FilterSettingsView config={filterModalConfig.settings} {...props}/>
                    </>
                </TabView3> */}
            </Box>

        </PopoverModal>
    );
}

export default GridcolumnSettings;