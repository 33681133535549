import { Box, useTheme } from "@mui/material"
import BaseCard from "../../../theme/cards/BaseCard"
import Text from "../../../theme/text/Text";
import CardLabel from "../../../theme/label/CardLabel";
import TopRight from "../../../theme/positionals/TopRight";
import ShDoubleTextSkeleton from "../../../theme/skeleton/ShDoubleTextSkeleton";
import { useTranslation } from "react-i18next";

const WarehouseReplenishLinesCard = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <BaseCard>
            <Box>
                <Box>
                    <Box
                        sx={{
                            display:"flex",
                            gap:2,
                            // alignItems:"center"
                        }}
                    >
                        <Box 
                            sx={{
                                minWidth:170,
                                display:"flex",
                                flexDirection:"column",
                                overflow:"hidden",
                                flex:1,
                            }}
                        >
                            {props.skeleton ?
                                <ShDoubleTextSkeleton/> 
                                :
                                <>
                                    
                                    <Text bold>{props.product?.sku}</Text>
                                    <Text noBreak light>{props.product?.description}</Text>
                                </>
                            }
                        </Box>
                    
                        <TopRight>
                            {/* <CardLabel variant="grey" colorflip position="child-tr">
                                {props.stock?.warehouseLocation?.location}
                            </CardLabel> */}

                            <CardLabel hasright position="child-tr">
                                {props.stock?.warehouseLocation?.location}
                            </CardLabel>
                            <CardLabel 
                                variant={theme.palette.mode === "dark" ? "greyLight" : "grey"}
                                hasleft 
                                colorflip={theme.palette.mode !== "dark"}
                                position="child-tr"
                            >
                                {props.skeleton ? props.amount : ""}
                            </CardLabel>
                        </TopRight>
                    </Box>

                    {/* <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{ t("amount")}</Text>
                            {props.skeleton ? 
                                <ShTextSkeleton/> 
                                :
                                <Text>{props.params.row.amount}</Text>
                            }
                        </Box>
                    </Box> */}
                </Box>
            </Box>
        </BaseCard>
    )
}

export default WarehouseReplenishLinesCard;