import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import Text from "../../../../../components/theme/text/Text";
import { tokens } from "../../../../../theme";

import { useTranslation } from 'react-i18next';
import ShLabel from "../../../../../components/theme/label/ShLabel";
// import Text from "../../../../../components/theme/text/Text";

import { useEffect, useState } from "react";
import OrdersCard from "../../../../../components/global/cards/orders/OrdersCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import ShCopyButton from '../../../../../components/theme/ShCopy';
import ShLabelSkeleton from "../../../../../components/theme/skeleton/ShLabelSkeleton";
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import { useGetOrdersQuery } from "../../../../../newapi/order/ordersSlice";

import { useRef } from "react";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import { getOrderStateData } from "../../../../../utils/labelColorText";
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import { general_states } from '../../../../../utils/staticEnums';
import { ORDER_FILTER_KEYS } from '../../../../../components/global/DataGrid/Filter';
import MpAddBolReplenishment from '../../../../../components/global/ModalsProgression/MpAddBolReplenishment/MpAddBolReplenishment';
import { useFindAllBolreplenishmentsQuery } from '../../../../../newapi/webshop/bolreplenishmentSlice';
import ShDoubleTextSkeleton from '../../../../../components/theme/skeleton/ShDoubleTextSkeleton';
import  SpBolReplenishmentDetails  from '../../../../../components/global/Sidepanels/SpBolReplenishmentDetails/SpBolReplenishmentDetails';

const SpOrderDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails"));

const GridActions = (props) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box display="flex" alignItems="center" justifyContent="space-between" flex={1}>
            <ButtonGroup version={2}>
                <Shbutton className={"group-btn"} color={theme.palette.mode === "dark" ? "blue" : "primary"} endIcon={<AddIcon/>} variant="contained" onClick={() => props.setOpen(true)}>{t("new_replenishment")}</Shbutton>
            </ButtonGroup>
            {/* <Shbutton isSidepanel color="primary" variant="contained" endIcon={<AddIcon/>}>New</Shbutton> */}
        </Box>
    )
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
    filter: [ORDER_FILTER_KEYS.STATE],
};

const OutboundBolView = (props) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isExtended, setIsExtended] = useState(false);
    const [spLoading, setSpLoading] = useState();

    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    const [config,setConfig] = useState({[ORDER_FILTER_KEYS.STATE.backend_key]:[general_states.SNOOZE]});


    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
        });

        return () => {
            // console.log("unmounting warehouse");
        }
    },[]);
    
    const [itemId, setItemId] = useState();
    const itemIdRef = useRef(itemId);
    
    const getReplenishmentDetails = (params) => {
        if (!params) return;
    
        const newItemId = parseInt(params.row.id);
        // Only update state if the orderId has actually changed
        if (itemIdRef.current !== newItemId) {
            if(!spLoading) {
                setSpLoading(true);
            }
            setItemId(newItemId);
            itemIdRef.current = newItemId; // Update the ref to the new value
        }
        setIsExtended(true);
    };

    const columns = [
        {field: "reference", headerName: t("reference"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (
                    <Box sx={{
                        minWidth:"100%",
                        height:"100%",
                        display:"flex",
                        alignItems:"center",
                        position:"relative",
                        whiteSpace:"nowrap",
                        wordBreak:"keep-all",
                        textOverflow:"ellipsis",
                        overflow:"hidden",
                        ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text noBreak>{params.row.reference}</Text>
                        <ShCopyButton 
                            styles={{button:{
                                background:theme.palette.mode === "dark" ? colors.grey[600] :  colors.grey[200],
                                color: colors.txt["primary"],
                                "&:hover" : {
                                    background:theme.palette.mode === "dark" ? colors.grey[500] :colors.grey[300],

                                }
                            }}} 
                            sx={{position:"absolute",right:0,zIndex:3000}} 
                            className={"copyel"} 
                            closeAfter={1500} 
                            value={params.row.reference}
                        ></ShCopyButton>
                    </Box>
                );
            }
        },
        {
            field: "state", 
            headerName: t("status"),
            flex:1,
            filter: ORDER_FILTER_KEYS.STATE,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }
                
                if(params.row.state === "open") {
                    return <ShLabel size={32} palette="normal" variant="grey">{t("open")}</ShLabel>
                } else if(params.row.state === "approved") {
                    return <ShLabel size={32} palette="normal" variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{t("approved")}</ShLabel>
                } else if(params.row.state === "rejected") {
                    return <ShLabel size={32} palette="normal" variant="red">{t("rejected")}</ShLabel>
                } else if(params.row.state === "completed") {
                    return <ShLabel size={32} palette="normal" variant="green">{t("completed")}</ShLabel>
                }
              
                
              }
        },
        {field: "relation", headerName: t("relation"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (<Text>{params.row.relation?.name}</Text>)
            }
        },
        {field: "webshopcredentials", headerName: t("channel_name"),flex:1,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }
                return (<Text>{params.row.webshopcredentials?.description}</Text>)
            }
        },
        {
            field: "expectedDeliveryDate", 
            headerName: t("deliverydates"),
            flex:1,
            shFilter: {
                type: "date"
            },
            renderCell: (params) => {

                if(params.row.skeleton) {
                    return <ShLabelSkeleton/>
                }

                let labelcolor = "grey";
                let expected = params.row.expectedDeliveryDate ? new Date(params.row.expectedDeliveryDate) : null;
                let planned = params.row.plannedShipmentDate ? new Date(params.row.plannedShipmentDate) : null;
              
                if(planned) {
                    // when sent is filled and is equal to planned: green
                    if(planned.getTime() === expected.getTime() - 86400000) {
                        labelcolor = "green";
                    }
                    // when sent is filled but later than planned: orange
                    else if(planned.getTime() > expected.getTime() - 86400000) {
                        labelcolor = "orange";
                    }
                }
                else if(expected) {
                    // When sent is not filled and planned >= now: blue
                    if(expected.getTime() >= Date.now()) {
                        labelcolor = theme.palette.mode === "dark" ? "blue" : "primary";
                    }
                    // When sent is not filled and planned < now: red
                    else if(expected.getTime() < Date.now()) {
                        labelcolor = "red";
                    }
                } else {
                    labelcolor = "grey";    
                }
              
                return !planned && !expected ? (<Box></Box>) 
                : (
                    <ShLabel size={32} fitted variant={labelcolor} palette="normal">
                        {!planned && expected.toLocaleDateString(i18n.language)}
                        {planned && planned.toLocaleDateString(i18n.language)}
                    </ShLabel>
                );
            }
        },
        {field: "amountOfLines", headerName: t("lines_count"),flex:1,
            sortable:false,
            renderCell: (params) => {
                if(params.row.skeleton) {
                    return <ShDoubleTextSkeleton/>
                }
                return (
                    <Box display="flex"  flexDirection="column" gap={1}>
                        <Text>{params.row.amountOfLines} {t("products")}</Text>
                        <Text>{params.row.amountOfItems} {t("items")}</Text>
                    </Box>
                );
            }
        },
        
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (params) => {
                return (
                    <OrdersCard onClick={() => getReplenishmentDetails(params)} skeleton={params.row.skeleton ?? false} {...params.row}  />
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            {/* <GridActions></GridActions> */}
            <ShDataGrid2 key={theme.palette.mode}
                content={useFindAllBolreplenishmentsQuery}
                config={config}
                gridActions={<GridActions setOpen={setOpen}></GridActions>} 
                title={t('action_required') }
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"DESC"}}
                columns={columns}
                onRowClick={getReplenishmentDetails}
            >
                    
            </ShDataGrid2>
            

            {spLoading && 
                <SpBolReplenishmentDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    itemId={itemId}
                    // data={orderData}
                /> 
            }

            <MpAddBolReplenishment open={open} handleClose={handleClose}/>
        </Box>
    );
}
export default OutboundBolView;