import WarehouseIcon from '@mui/icons-material/Warehouse';

export const warehouseReturnsConfig = (isFulfilment) => {
    return {
        inbounds:  {
            title: 'all_returns',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        ...(!isFulfilment && {
            // design: {
            //     title: 'design',
            //     icon: <WarehouseIcon/>,
            //     isSingleDataGrid: false,
            // },
            // returnReasons: {
            //     title: 'return_reasons',
            //     icon: <WarehouseIcon/>,
            //     isSingleDataGrid: true,
            // }
        })
    };
};
