import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";


import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Text from "../../../theme/text/Text";


//     {
//         "id": 3,
//         "state": "active",
//         "type": "orders_not_imported_missing_products",
//         "body": {
//             "message": "{orders_not_imported_missing_products} Order-1223",
//             "webshopCredentialsId": 1,
//             "externalId": 2
//         }
//     },
const OrdersNotImportedNotificationsComp = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const cleanedMessage = useMemo(() => {
        return props.body.message.replace(/{[^}]*}/g, '').trim();
    }, [props.body.message]); // Depend on props.body.message

    return(
        <Box
            onClick={props.onClick ?? null}
            sx={{
                cursor:"pointer",
                px:1.5,
                display:"flex",
                gap:2,
                width:1,
                py:1.5,
                transition:"200ms",
                borderRadius:4,
                // "&:hover" : {
                //     background:colors.grey[100],
                //     transition:"200ms",
                // }
            }}
        >
            <Box
                sx={{
                    height:32,
                    width:32,
                    flexShrink:0,
                    display:'flex',
                    alignItems:"center",
                    justifyContent:'center',
                    background:theme.palette.mode === "dark" ? colors.red[100] : colors.red[100],
                    color:theme.palette.mode === "dark" ? colors.red[400] : colors.red[400],
                    borderRadius:3,
                }}
            >
                <ProductionQuantityLimitsIcon fontSize="small"/>
            </Box>
            <Box flex={1}>
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                    }}
                >
                    <Text semibold light>{t("orders_missing_products")}</Text>
                </Box>
                <Box>
                    <Text bold>{cleanedMessage}</Text>
                    
                </Box>
            </Box>
        </Box>
    )
}

export default OrdersNotImportedNotificationsComp;