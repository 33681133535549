// DateRangeFilter.js
import { Box } from '@mui/material';

const Splabieblab = () => {
    // const filterKey = ORDER_FILTER_KEYS.DATE_RANGE.backend_key;

    // const dispatch = useDispatch();

    // const {index, id} = useGridFilter();
    // const value = useSelector((state) => state.gridFilter.filters[filterKey]);
//   const value = useSelector((state) => state.filter.filters[filterKey]);

//   const handleChangeStart = (e) => {
//     dispatch(setFilter({ filterKey, value: { ...value, start: e.target.value } }));
//   };

//   const handleChangeEnd = (e) => {
//     dispatch(setFilter({ filterKey, value: { ...value, end: e.target.value } }));
//   };

    return (
        <div>
            <Box>idsufhdisibifbdsifubhds</Box>
            {/* <input type="date" value={value?.start || ''} onChange={handleChangeStart} />
            <input type="date" value={value?.end || ''} onChange={handleChangeEnd} /> */}
        </div>
    );
};

export default Splabieblab;