import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../../theme";


import CallMadeOutlinedIcon from '@mui/icons-material/CallMadeOutlined';
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ShLabel from "../../../theme/label/ShLabel";
import SidepanelLoader from "../../../theme/sidepanel2/SidepanelLoader";
import Text from "../../../theme/text/Text";

//     {
//         "id": 4,
//         "state": "active",
//         "type": "stock_difference",
//         "body": {
//             "message": "{stock_difference for} Playstation controller",
//             "expectedValue": 20,
//             "currentValue": 14,
//             "externalId": 42
//         }
//     },
const StockDifferenceNotificationsComp = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const cleanedMessage = useMemo(() => {
        return props.body.message.replace(/{[^}]*}/g, '').trim();
    }, [props.body.message]); // Depend on props.body.message

    return(
        <Box
            onClick={props.onClick ?? null}
            sx={{
                cursor:"pointer",
                px:1.5,
                display:"flex",
                gap:2,
                width:1,
                py:1.5,
                transition:"200ms",
                borderRadius:4,
                "&:hover" : {
                    background:colors.grey[100],
                    transition:"200ms",
                }
            }}
        >
            <Box
                sx={{
                    height:32,
                    width:32,
                    flexShrink:0,
                    display:'flex',
                    alignItems:"center",
                    justifyContent:'center',
                    background:theme.palette.mode === "dark" ? colors.blue[100] : colors.primary[100],
                    color:theme.palette.mode === "dark" ? colors.blue[400] : colors.primary[400],
                    borderRadius:3,
                }}
            >
                <CallMadeOutlinedIcon fontSize="small"/>
            </Box>
            <Box flex={1}>
                <Box
                    sx={{
                        display:"flex",
                        alignItems:"center",
                        gap:1,
                        mb:1,
                    }}
                >
                    <Text semibold>{t(props.type)}</Text> 
                    <ShLabel variant={"lightblue"} square size={24} fitted borderless>{cleanedMessage}</ShLabel> 
                </Box>
                <Box
                    sx={{
                        p:2,
                        background:colors.grey[50],
                        borderRadius:3,
                        display:"flex",
                        gap:4,
                    }}
                >
                    <Box>
                        <Text light>{t("expected")}</Text><br/>
                        <Text>{props.body.expectedValue}</Text>
                    </Box>
                    <Box>
                        <Text light>{t("current_value")}</Text><br/>
                        <Text>{props.body.currentValue}</Text>
                    </Box>

                </Box>
            </Box>
        </Box>
    )
}

export default StockDifferenceNotificationsComp;