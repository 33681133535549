// TabContent1.js
import React, { useState, useEffect } from 'react';
import ProgressionTab from '../../../../theme/ProgressionBuilder/ProgressionTab';
import { useTheme } from '@emotion/react';
import Text from '../../../../theme/text/Text';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, InputBase } from '@mui/material';
import { tokens } from '../../../../../theme';
import { useDispatch, useSelector } from 'react-redux';

import { InputDropdown } from "../../../../theme/dropdowns/InputDropdown";
import { setTabErrors } from '../../../../../newapi/global/progressionTabSlice';
import * as yup from "yup";

import { yupRequired } from '../../../../../utils/validation';
import { useGetProductsSelectQuery } from '../../../../../newapi/warehouse/productSlice';
import NumberInput from '../../../../theme/inputs/NumberInput';
import Shbutton from '../../../../theme/buttons/Shbutton';
import { Delete } from '@mui/icons-material';
import { useGetAvailableWarehousesMutation } from '../../../../../newapi/webshop/bolreplenishmentSlice';
import ShLabel from '../../../../theme/label/ShLabel';


/**
 * TabContent1 Component
 * 
 * Props:
 * - handleDataUpdate: Function, updates the data in the Redux state.
 * - handleNext: Function, navigates to the next tab.
 */
const Content = (props) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const colors = tokens(theme.palette.mode);
    const data = { ...props.data };
    const dispatch = useDispatch();
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [getAvailableWarehouses, {isLoading: isLoadingWarehouses}] = useGetAvailableWarehousesMutation();
    const [destinationValid, setDestinationValid] = useState(false);
    const [destinationsLoading, setDestinationsLoading] = useState(false);

    const { data: products } = useGetProductsSelectQuery({webshopcredentialsId: props.data?.webshopcredential},
        {
            skip: !props.data?.webshopcredential
        }
    );

    const addProduct = (val) => {
        setSelectedProducts(prevProducts => {
            if (!prevProducts.includes(val)) {
                return [...prevProducts, {...val, quantity: 0}];
            }
            return prevProducts;
        });
    };

    const updateQuantity = (id, e) => {
        setSelectedProducts(prevProducts => {
            return prevProducts.map(product => product.id === id ? {...product, quantity: e} : product);
        });
    };

    const handleDeleleteLineRow = (id) => {
        setSelectedProducts(prevProducts => prevProducts.filter(product => product.id !== id));
    };

    useEffect(() => {
        if(selectedProducts.length > 0){
            setDestinationsLoading(true);
            getAvailableWarehouses({webshopcredential: props.data?.webshopcredential, products: selectedProducts}).unwrap().then(res => {
                if(res.data?.productDestinations){
                    const allEans = selectedProducts.map(product => product.sku);
                    const destination = res.data?.productDestinations?.find(dest => dest.eans.every(eanObj => allEans.includes(eanObj.ean)));
                    if (destination) {
                        setDestinationValid(true);
                        props.handleDataUpdate({ destination: destination.destinationWarehouse});
                    } else {
                        setDestinationValid(false);
                        props.handleDataUpdate({ destination: null});
                    }
                    setDestinationsLoading(false);
                } else {
                    setDestinationsLoading(false);
                    setDestinationValid(false);
                    props.handleDataUpdate({ destination: null});
                }
            }).catch(error => {
                setDestinationsLoading(false);
                setDestinationValid(false);
                props.handleDataUpdate({ destination: null});
            });
        }
        props.handleDataUpdate({ products: selectedProducts }); 
    }, [selectedProducts.length]);

    useEffect(() => {
        console.log(products);
    }, [products]);
    
    return (
        <>

            
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    // alignItems:"center",
                    justifyContent:"space-between",
                    flexDirection:'column',
                    gap:1,
                    pb:selectedProducts.length > 0 ? 4 : 0,
                    // pb:5,
                }}
            >
                
                <Box
                    sx={{
                        width:200,
                    }}
                >
                    <Text>{t("choose_product")}</Text>
                </Box>
                
                <InputDropdown
                    key={selectedProducts.length}
                    resetOnSelection
                    searchOptions={{
                        enabled: true,
                        placeholder: t("search_for_product")
                    }}
                    displayName={"combined"} 
                    onChange={(selected) => addProduct(products[selected])}
                    noSelection={t("select_product")} //Custom text when nothing is selected
                    options={products ?? null}
                    selected={-1}
                    closeOnSelection
                />
                
            </Box>
            <Box
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    gap:2,
                    pb:selectedProducts.length > 0 ? 4 : 2,
                }}
            >
                {selectedProducts.map((product) => (
                    <Box 
                        sx={{
                            width:1,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            flexDirection:"column",
                            gap:1,
                            maxWidth:500,
                            background: theme.palette.mode === "dark" ? colors.grey[400] : colors.grey[50],
                            p:2,
                            borderRadius:4,
                        }}
                    >
                        <Box
                            sx={{
                                display:"flex",
                            }}
                        >
                            <Box
                                sx={{
                                    flex:1,
                                    borderRadius:5,
                                }}
                            >
                                <Text semibold>{product.combined}</Text>
                            </Box>

                            <Box>
                                <IconButton type="button" color="red" onClick={() => handleDeleleteLineRow(product.id)} sx={{p:1}}>
                                    <Delete/>
                                </IconButton>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                width:1,
                                mt:2,
                                // width:200,
                            }}
                        >
                            <NumberInput
                                value={product.quantity ?? 0}
                                change={(e) => updateQuantity(product.id, e)}/>
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box 
                sx={{
                    width:1,
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"space-between",
                    gap:1
                }}
            >
                {destinationsLoading ? 
                    <ShLabel 
                        variant={theme.palette.mode === "dark" ? "greyLight" : "grey"} 
                        borderless 
                        palette={"normal"} 
                        size={32}
                    > 
                        {t("loading")} ...
                    </ShLabel> 
                    : 
                    <ShLabel 
                        variant={destinationValid ? "green" : "red"}
                        borderless 
                        palette={"normal"} 
                        
                        size={32}
                    >
                        {destinationValid ? t("destination_valid") : t("destination_invalid")} 
                    </ShLabel>
                }
            </Box>

        </>
    );
};

const ProductDetails = (props) => {
    const { t } = useTranslation();

    const schema = yup.object({
        destination: yupRequired('destination',t),
    });

    return (
        <ProgressionTab 
            tabId="tabProductDetails" 
            subtitle={t('product_details')} 
            step={2}
            maxSteps={2}
            //schema={schema}
        >
            <Content />
        </ProgressionTab>
    )
}

export default ProductDetails;