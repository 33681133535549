import { useNavigate } from "react-router-dom";
import { checkAndRedirect, selectAuthStatus, UserState } from "../newapi/user/authentication/authSlice";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";

export const RequireAuth = React.memo((props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector((state) => state.auth);
    useEffect(() => {
        if(props.permissions){
            dispatch(checkAndRedirect(props.permissions)) 
          .unwrap() // <-- async Thunk returns a promise, that can be 'unwrapped')
          .then(({loggedOut, authorized}) => {
            if(loggedOut) {
                navigate("/login");
            } else if (authorized !== null && !authorized) {
                navigate(-1);
            }
          });
        } 
        else {
            //No permission but we still need to be logged in.
            if(auth.status === UserState.LOGGED_OUT) {
                navigate('/login');
            }
        }
        // dispatch(checkAndRedirect()).unwrap()
    }, [dispatch, navigate,auth]);

    return props.children;
});