
// import { useGetShippersSelectQuery } from "../../../../../newapi/shipper/shipperSlice";
import CloseIcon from '@mui/icons-material/Close';
import { Box, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { DropdownBase } from '../../theme/dropdowns/Dropdown';
import Text from '../../theme/text/Text';
// import { conditionKeys, conditions, condtionLineKeys, dropdownKeys, getMobileCondition, getShipperColorSettings, getStreamRuleKey } from "./ConditionSettings";

import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../theme';
import TextInput from '../../theme/inputs/TextInput';
import { getMobileCondition, getConditionKey, textValueTypes, numberValueTypes, dropdownValueTypes, getListOptions } from './ConditionSettings';
import { useGetConditionValuesQuery } from '../../../newapi/global/stream/streamSlice';

const ConditionRule = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const handleResize = (event) => {
        setWindowSize(window.innerWidth);
    }

    const nominatorListValues = ["isinselection", "isnotinselection"];

    const { data: conditionValues, isLoading: conditionValuesLoading } = useGetConditionValuesQuery();
    const [conditionValueSelect, setConditionValueSelect] = useState([]);

    useEffect(() => {
        if(conditionValues) {
            setConditionValueSelect(conditionValues.conditions.map(value => ({
                name: t(`condition_${value.key}`),
                key: value.key
            })));

            if (props.conditions) {
                setConditionsFromLoad();
            }
        }
    }, [conditionValues]);

    // Fallback for reload tab, conditions are not loaded in time
    useEffect(() => {
        if (props.conditions.length > 0 && !props.conditions[0].valueType && conditionValues) {
            setConditionsFromLoad();
        }
    }, [props.conditions]);

    const setConditionsFromLoad = () => {
        const conditionsCopy = props.conditions.map(condition => {
            // Get nominator options and value type for the new key
            const conditionFollowUpOptions = getConditionFollowUpOptions(condition.key);

            // Create a new object with all properties
            return {
                ...condition,
                nominatorOptions: conditionFollowUpOptions.nominatorOptions,
                valueType: conditionFollowUpOptions.valueType
            };
        });
        
        props.setCondtions(conditionsCopy);
    }

    const getConditionFollowUpOptions = (key) => {
        const condition = conditionValues?.conditions?.find(value => value.key === key);
        return {
            nominatorOptions: condition?.values ?? [],
            valueType: condition?.valueType ?? "string"
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, []);

    const updateStreamRuleLine = (lineIndex, newRuleLine) => {
        props.setCondtions(currentStreamRules => {
            const updatedStreamRules = [...currentStreamRules];

            updatedStreamRules[lineIndex] = {...updatedStreamRules[lineIndex], ...newRuleLine};

            return updatedStreamRules; // Return the updated array
        });
    };

    const addStreamRuleLine = () => {
        props.setCondtions(currentStreamRules => {
            const updatedStreamRules = [...currentStreamRules];
            updatedStreamRules.push({
                key: -1,
                nominator: "equalto",
                value: -1
            })
            
            return updatedStreamRules;
        });
    };

    const removeStreamRuleLine = (lineIndex) => {
        props.setCondtions(currentStreamRules => {
            let updatedStreamRules = [...currentStreamRules];
            
            updatedStreamRules.splice(lineIndex,1);
    
            return updatedStreamRules;
        });
    }

    const selectRuleLineKey = (selectedKey, index) => {
        const ruleLine = { ...props.conditions[index] }; // Create a copy of the ruleLine
        ruleLine.key = conditionValueSelect[selectedKey].key;

        // Get nominator options and value type for the new key
        const conditionFollowUpOptions = getConditionFollowUpOptions(ruleLine.key);

        // Set nominator options for dropdown
        ruleLine.nominatorOptions = conditionFollowUpOptions.nominatorOptions;

        // Set value type for input element key
        // Possible values:
        // string, number, date, time, country_list, day_with_additional_list, relation_list, warehouse_list, warehouse_location_list
        ruleLine.valueType = conditionFollowUpOptions.valueType; 

        ruleLine.nominator = ruleLine.nominatorOptions[0];

        if (textValueTypes.includes(ruleLine.valueType)) {
            ruleLine.value = "";
        } else if (numberValueTypes.includes(ruleLine.valueType)) {
            ruleLine.value = 0;
        } else if (dropdownValueTypes.includes(ruleLine.valueType)) {
            if (nominatorListValues.includes(ruleLine.nominator)) {
                ruleLine.value = [];
            } else {
                ruleLine.value = -1;
            }
        }

        // Update the stream rule in the parent component's state
        updateStreamRuleLine(index, ruleLine);
    };

    const selectRuleLineCondition = (selectedNominator, index) => {
        const ruleLine = { ...props.conditions[index] }; // Create a copy of the ruleLine.
        ruleLine.nominator = ruleLine.nominatorOptions[selectedNominator];
        updateStreamRuleLine(index, ruleLine);
    };

    const selectRuleLineValue = (selectedValue, index) => {
        const ruleLine = { ...props.conditions[index] }; // Create a copy of the ruleLine.
        ruleLine.value = selectedValue;

        updateStreamRuleLine(index, ruleLine);
    };

    return (
        <Box
            sx={{
                display:"flex",
                flexDirection:"column",
                gap:1,
                width:1
            }}
        >
             <Box
                onClick={addStreamRuleLine}
                sx={{
                    height:40,
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    borderRadius:5,
                    background: colors.grey[400],
                    cursor:"pointer",
                    "&:hover" : {
                        background: colors.bg["menu"],
                        
                    }
                }}
            >
                <Text color={tokens("dark").txt["primary"]} semibold>Nieuwe voorwaarde</Text>
            </Box>

            {props.conditions.map((ruleline,index) => (
                <Box key={index+"pcs"}>
                    <Box
                        key={index}
                        sx={{
                            position:'relative',
                            height:40,
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            p:0.5,
                            // background:"#FFE9D6",
                            background:colors.grey[100],
                            borderRadius:5,
                        }}
                    >
                        {/* Colli */}
                        <DropdownBase
                            displayName={"name"} 
                            onChange={(selected) => selectRuleLineKey(selected,index)}
                            noSelection={t("choose_key")} 
                            options={conditionValueSelect}
                            useTranslation 
                            selected={conditionValueSelect.findIndex(value => value.key === ruleline.key)}
                            closeOnSelection
                            maxHeight={"unset"}
                            disallowDeselection
                            // shComponent={<CustomComponent></CustomComponent>} // new
                        >
                            <Box
                                className={cx('',{
                                    'error': props.errorMap && props.errorMap[index] && props.errorMap[index].key,
                                })}
                                sx={{
                                    width:120,
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    height:32,
                                    borderRadius:4,
                                    "&:hover" : {
                                        background:colors.grey[200],
                                        cursor:"pointer",
                                    },
                                    "@media screen and (max-width: 768px)" : {
                                        // px:0.5,
                                    },
                                    "&.error" : {
                                        background:colors.red[100]
                                    }
                                }}
                            >
                                {/* {ru} */}
                                <Text noBreak>{conditionValueSelect?.find(value => value.key === ruleline.key) ? t(conditionValueSelect?.find(value => value.key === ruleline.key)?.name) : t("choose_condition")}</Text>
                            </Box>
                        </DropdownBase>

                        {/* Nominator */}
                        <DropdownBase
                            displayName={"name"} 
                            onChange={(selected) => selectRuleLineCondition(selected,index)}
                            noSelection={""} //Custom text when nothing is selected
                            options={ruleline.nominatorOptions ?? []} // array
                            useTranslation // conditions uses translation keys as the array,
                            selected={ruleline && conditionValues?.conditions?.find(value => value.key === ruleline.key)?.nominators?.findIndex(value => value === ruleline.nominator) ?
                                conditionValues?.conditions?.find(value => value.key === ruleline.key)?.nominators?.findIndex(value => value === ruleline.nominator) : -1
                            }
                            
                            closeOnSelection
                            disallowDeselection
                        >
                            <Box
                                sx={{
                                    position:'absolute',
                                    left:"50%",
                                    top:"50%",
                                    transform:"translate(-50%, -50%)",
                                    width:155,
                                    pl:2,
                                    pr:2,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    height:32,
                                    borderRadius:4,
                                    "&:hover" : {
                                        background:colors.grey[200],
                                        cursor:"pointer",
                                    },
                                    "@media screen and (max-width: 768px)" : {
                                        position:"sticky",
                                        transform:"unset",
                                        top:'unset',
                                        left:'unset',
                                        width:"32px",
                                        p:0,
                                        ml:0,
                                        mr:0,
                                    }
                                }}
                            >
                                {windowSize > 768?
                                    typeof ruleline.nominator === 'Number' || ruleline.nominator === -1 ?
                                        <Text noBreak >{t('choose_condition')}</Text>
                                    :
                                        <Text noBreak bold>{t(ruleline.nominator)}</Text>
                                :
                                    <Text bold>{getMobileCondition(ruleline.nominator)}</Text>
                                }
                            </Box>
                        </DropdownBase>

                        {/* Value */}
                        {textValueTypes.includes(ruleline.valueType) || numberValueTypes.includes(ruleline.valueType) ?
                            <Box
                                className={cx('',{
                                    'error': props.errorMap && props.errorMap[index] && props.errorMap[index].value,
                                })}
                                sx={{
                                    // flex:1,
                                    display:"inline-flex",
                                    // alignItems:"center",
                                    // justifyContent:"center",
                                    ml:"auto",
                                    background:"#FFF8F2",
                                    background:colors.grey[100],
                                    height:32,
                                    borderRadius:4,
                                    width:120,
                                    "&:hover" : {
                                        background:colors.grey[200],
                                        cursor:"pointer",
                                    },
                                    "@media screen and (max-width: 768px)" : {
                                        // ml:1,
                                    },
                                    "&.error" : {
                                        background:colors.red[100]
                                    }
                                }}
                            >
                                <TextInput
                                    type={textValueTypes.includes(ruleline.valueType) ? "text" : "number"}
                                    value={ruleline.value}
                                    background={"transparent"}
                                    onChange={(e) => selectRuleLineValue(e.target.value,index)}
                                    // onChange={(e) => handleChange("firstname", e.target.value)}
                                />
                            </Box>
                        :
                            nominatorListValues.includes(ruleline.nominator) ? 
                                <DropdownBase
                                    displayName={"name"} 
                                    valueKey={"id"}
                                    multiple={true}
                                    onChange={(selected) => selectRuleLineValue(selected,index)}
                                    noSelection={""} //Custom text when nothing is selected
                                    options={getListOptions(ruleline?.valueType, props.countries, t)} // array
                                    selected={ruleline.value}
                                    searchOptions={{
                                        enabled: true,
                                        placeholder: t("search")
                                    }}
                                    styles={{
                                        minWidth:300
                                    }}
                                >
                                    <Box
                                        className={cx('',{
                                            'error': props.errorMap && props.errorMap[index] && props.errorMap[index].value,
                                        })}
                                        sx={{
                                            ml:"auto",
                                            display:"inline-flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            background:colors.grey[100],
                                            height:32,
                                            minWidth:120,
                                            borderRadius:4,
                                            "&:hover" : {
                                                background:colors.grey[200],
                                                cursor:"pointer",
                                            },
                                            "@media screen and (max-width: 768px)" : {
                                                // ml:1,
                                            },
                                            "&.error" : {
                                                background:colors.red[100]
                                            }
                                        }}
                                    >
                                        <Text noBreak>
                                            {ruleline.value.length > 1 ? 
                                            ruleline.value.length + " " + t("selected") : getListOptions(ruleline?.valueType, props.countries, t)?.find(value => value.id === ruleline.value[0])?.name}
                                            {/* {getListOptions(ruleline?.valueType, props.countries, t)?.find(value => value.id === ruleline.value)?.name} */}
                                        </Text>
                                    </Box>
                                </DropdownBase>
                            :
                                <DropdownBase
                                    displayName={"name"} 
                                    valueKey={"id"}
                                    multiple={false}
                                    onChange={(selected) => selectRuleLineValue(selected,index)}
                                    noSelection={""} //Custom text when nothing is selected
                                    options={getListOptions(ruleline?.valueType, props.countries, t)} // array
                                    selected={getListOptions(ruleline?.valueType, props.countries, t)?.find(value => value.id === ruleline.value)?.id}
                                    closeOnSelection
                                    searchOptions={{
                                        enabled: true,
                                        placeholder: t("search")
                                    }}
                                    styles={{
                                        minWidth:300
                                    }}
                                    disallowDeselection
                                >
                                    <Box
                                        className={cx('',{
                                            'error': props.errorMap && props.errorMap[index] && props.errorMap[index].value,
                                        })}
                                        sx={{
                                            ml:"auto",
                                            display:"inline-flex",
                                            alignItems:"center",
                                            justifyContent:"center",
                                            background:colors.grey[100],
                                            height:32,
                                            minWidth:120,
                                            borderRadius:4,
                                            "&:hover" : {
                                                background:colors.grey[200],
                                                cursor:"pointer",
                                            },
                                            "@media screen and (max-width: 768px)" : {
                                                // ml:1,
                                            },
                                            "&.error" : {
                                                background:colors.red[100]
                                            }
                                        }}
                                    >
                                        <Text noBreak>{getListOptions(ruleline?.valueType, props.countries, t)?.find(value => value.id === ruleline.value)?.name}</Text>
                                    </Box>
                                </DropdownBase>
                        }

                        <Box
                            onClick={() => removeStreamRuleLine(index)}
                            sx={{
                                flexShrink:0,
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"center",
                                height:32,
                                width:32,
                                borderRadius:4,
                                ml:1,
                                mr:0.5,
                                color: colors.txt["secondary"],
                                "&:hover" : {
                                    // background:"#FFDBBD",
                                    background:colors.grey[200],
                                    cursor:"pointer",
                                }
                            }}
                        >
                            <CloseIcon fontSize='small'/>
                        </Box>

                    </Box>
                    {props.errorMap && props.errorMap[index] && (
                        <Box sx={{

                            position:'relative',
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"space-between",
                            p:0.5,
                            // background:"#FFE9D6",
                            borderRadius:5,
                        }}>
                            <Box
                                sx={{
                                    width:120,
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    borderRadius:4,
                                    "@media screen and (max-width: 768px)" : {
                                        px:1,
                                    }
                                }}
                            >
                                {props.errorMap[index] && props.errorMap[index].key && (
                                    <div style={{ color: 'red' }}>{t(props.errorMap[index].key)}</div>
                                )}
                                {/* {
                                    Object.keys(props.errorMap[index]).map((errorKey) => (
                                        <p key={errorKey}>{props.errorMap[index][errorKey]}</p>
                                    ))
                                } */}
                            </Box>

                            {/* Simulated nominator */}
                            <Box
                                sx={{
                                    position:'absolute',
                                    left:"50%",
                                    top:"50%",
                                    transform:"translate(-50%, -50%)",
                                    width:155,
                                    pl:2,
                                    pr:2,
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    borderRadius:4,
                                    "@media screen and (max-width: 768px)" : {
                                        position:"sticky",
                                        transform:"unset",
                                        top:'unset',
                                        left:'unset',
                                        width:"32px",
                                        p:0,
                                        ml:0,
                                        mr:0,
                                    }
                                }}
                            >
                                
                            </Box>

                            <Box
                                sx={{
                                    ml:"auto",
                                    // flex:1,
                                    // flexGrow:0,
                                    display:"inline-flex",
                                    alignItems:"center",
                                    justifyContent:"center",
                                    width:120,
                                    px:1,
                                    borderRadius:4,
                                }}
                            >
                            {props.errorMap[index] && props.errorMap[index].value && (
                                <div style={{ color: 'red' }}>{t(props.errorMap[index].value)}</div>
                            )}
                            </Box>

                            {/* Simulated end btn */}
                            <Box
                                sx={{
                                    flexShrink:0,
                                    
                                    width:32,
                                    ml:1,
                                    mr:0.5,
                                }}
                            >
                            </Box>
                        </Box>
                    )}
                </Box>
            ))}
            
        </Box>
    );
}

export default ConditionRule;