import { Box, useTheme } from "@mui/material";
import BaseCard from "../../../theme/cards/BaseCard";
import ShTextSkeleton from "../../../theme/skeleton/ShTextSkeleton";
import Text from "../../../theme/text/Text";
import ShLabel from "../../../theme/label/ShLabel";
import { tokens } from "../../../../theme";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { GridCheckIcon } from "@mui/x-data-grid";
import { ClearIcon } from "@mui/x-date-pickers";

export const InvoicePriceIncreaseCard = (props) => {
    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { t,i18n } = useTranslation();

    // State to hold the formatted date, label color, and label text
    const [formattedDate, setFormattedDate] = useState("-");
    const [labelColor, setLabelColor] = useState("grey");
    const [labelText, setLabelText] = useState("");

    useEffect(() => {
        // Format the date if available, otherwise set default to "-"
        const formatted = props.date ? new Date(props.date).toLocaleDateString(i18n.language) : "-";
        setFormattedDate(formatted);

        // Get status from params or default to "unknown"
        const status = props.state || "unknown";

        // Define label configuration based on status
        const labelConfig = {
            active: {
                color: theme.palette.mode === "dark" ? "blue" : "primary",
                text: t('active'),
            },
            completed: {
                color: "green",
                text: t('completed'),
            },
            unknown: {
                color: "orange",
                text: t('unknown'),
            },
        };

        // Update state for label based on status
        const { color, text } = labelConfig[status] || labelConfig.unknown;
        setLabelColor(color);
        setLabelText(text);

    }, [props]);  // Dependencies to re-run when any of these change

    return (
        <BaseCard onClick={props.skeleton ? null : props.onClick}>
            <Box>
                <Box
                    sx={{

                    }}
                >
                    {/* Top row */}
                    <Box
                        sx={{
                            display:"flex",
                            justifyContent:"space-between",
                            gap:3,
                        }}
                    >
                        <Box sx={{ flexGrow: 1, flexShrink: 1, overflow:"hidden" }}>
                            <Box>
                                <Box>
                                    <Text bold>{props.name}</Text>&nbsp;
                                </Box>
                            </Box>
                            {/* Rleation name */}
                            <Box
                                sx={{
                                    
                                }}
                            >
                                <Text>{formattedDate}</Text>
                            </Box>
                        </Box>
                        <ShLabel variant={labelColor} borderless palette={"normal"} fitted size={32}>{labelText}</ShLabel>
                    </Box>
                    <Box marginTop={3} display={"flex"} alignItems={"flex-start"} gap={3}
                    >
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("increase")}</Text>

                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.percentage}%
                                    {/* {props.stock && props.stock.total_quantity ? props.stock.total_quantity : 0}     */}
                                </Text>
                            }
                        </Box>
                        <Box display={"flex"} flexDirection={"column"}>
                            <Text light>{t("products")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text>
                                    {props.invoiceProducts.length}
                                    {/* {props.stock && props.stock.total_available ? props.stock.total_available : 0} */}
                                </Text>
                            }
                        </Box>
                        <Box sx={{ flexGrow: 1, flexShrink: 1, overflow:"hidden",display:"flex",flexDirection:"column" }}>
                            <Text noBreak light>{t("edit_changed_prices")}</Text>
                            {props.skeleton ?
                                <ShTextSkeleton />
                                :
                                <Text variant={props.modifyCustomerPrices ? "green" : "red"}>
                                    {props.modifyCustomerPrices ? (
                                        <GridCheckIcon fontSize="small" />
                                    ) : (
                                        <ClearIcon fontSize="small"/>
                                    )}
                                </Text>
                            }
                        </Box>
                        
                    </Box>

                </Box>
            </Box>
        </BaseCard>
    )
}
