import AddIcon from '@mui/icons-material/Add';
import { Box, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import WarehouseProductCard from "../../../../../components/global/cards/products/warehouse/WarehouseProductCard";
import ButtonGroup from "../../../../../components/theme/buttons/ButtonGroup";
import Shbutton from "../../../../../components/theme/buttons/Shbutton";
import ShDataGrid2 from "../../../../../components/theme/datagrid2/ShDataGrid2";
import ShLabel from "../../../../../components/theme/label/ShLabel";
import SidepanelLoader from '../../../../../components/theme/sidepanel2/SidepanelLoader';
import ShTextSkeleton from "../../../../../components/theme/skeleton/ShTextSkeleton";
import Text from "../../../../../components/theme/text/Text";
import { useGetReplenishLinesQuery } from "../../../../../newapi/replenish/replenishLineSlice";
import { tokens } from "../../../../../theme";
import { selectUserPermission } from "../../../../../newapi/user/authentication/authSlice";
import { UserPrivileges } from '../../../../../utils/permissions';

const SpStockDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpStockDetails/SPStockDetails"));
const SPLocationDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpLocationDetails/SpLocationDetails"));
const SPOrderDetails = SidepanelLoader(() => import("../../../../../components/global/Sidepanels/SpOrderDetails/SpOrderDetails"));

const GridActions = (props) => {
    const { t } = useTranslation();
    const theme = useTheme();

    return <></>;

    return (
        <ButtonGroup version={2}>
            {props.hasCreatePermission && (
            <Shbutton 
                className={"group-btn"} 
                color={theme.palette.mode === "dark" ? "blue" : "primary"} 
                onClick={() => props.handleOpen(true)}
                endIcon={<AddIcon/>}
                variant="contained"
            >
                {t("new_product")}
            </Shbutton>
            )}
        </ButtonGroup>
    );
}

let gridOptions = {
    export:true,
    disableCustomActionsOnPhone: true,
    isSidepanel: true,
};


const ProductOverview = (props) => {
    const { t,i18n } = useTranslation();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [config, setConfig] = useState({isVirtual: false});
    const [isExtended, setIsExtended] = useState(false);
    const isFulfilment = useSelector((state) => state.settings.environment.isFulfilment);
    const [spStockLoading, setSpStockLoading] = useState();

    const [locationIsExtended, setLocationIsExtended] = useState(false);
    const [spLocationLoading, setSpLocationLoading] = useState();

    const [orderIsExtended, setOrderIsExtended] = useState(false);
    const [spOrderLoading, setSpOrderLoading] = useState();

    const hasCreatePermission = useSelector((state) => 
        selectUserPermission(state, [UserPrivileges.PRODUCT_MANAGE])
    );

    useEffect(() => {
        props.replaceView({
            ...props.config,
            gridOptions: gridOptions,
            mobileActions: <GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>,
        });

        return () => {
            
        }
    },[]);

    const handleOpen = () => {
        setAddModalOpen(true);
    }

    const [addModalOpen, setAddModalOpen] = useState(false);
    const addModal = {
        open: addModalOpen,
        setOpen: setAddModalOpen
    }

    const handleClose = () => {
        setAddModalOpen(false);
    };

    const [productId, setProductId] = useState();
    const productIdRef = useRef(productId);
    
    const getProductDetails = (product) => {
        if (!product) return;
    
        const newProductId = parseInt(product.id);
        // Only update state if the productId has actually changed
        if (productIdRef.current !== newProductId) {
            if(!spStockLoading){
                setSpStockLoading(true);
            }
            setProductId(newProductId);
            productIdRef.current = newProductId; // Update the ref to the new value
        }
        
            setIsExtended(true);
    };
    
    const [locationId, setLocationId] = useState();
    const locationIdRef = useRef(locationId);
    
    const getLocationDetails = (params) => {
        if (!params) return;
    
        const newProductId = parseInt(params.id);
        // Only update state if the productId has actually changed
        if (locationIdRef.current !== newProductId) {
            if(!spLocationLoading){
                setSpLocationLoading(true);
            }
            setLocationId(newProductId);
            locationIdRef.current = newProductId; // Update the ref to the new value
        }
            setLocationIsExtended(true);
    };
    
    const [orderId, setOrderId] = useState();
    const orderIdRef = useRef(orderId);
    
    const getOrderDetails = (order) => {
        if (!order) return;
    
        const newOrderId = parseInt(order.id);
        // Only update state if the productId has actually changed
        if (orderIdRef.current !== newOrderId) {
            if(!spOrderLoading){
                setSpOrderLoading(true);
            }
            setOrderId(newOrderId);
            orderIdRef.current = newOrderId; // Update the ref to the new value
        }
            setOrderIsExtended(true);
    };

    const columns = [
        {
            field: "amount",
            headerName: t("amount"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton palette={theme.palette.mode === "dark" ? "pastel" : "normal"} variant={theme.palette.mode === "dark" ? "blue" : "primary"} />
                }

                return (
                    <Text>{params.row.amount}</Text>
                );
            }
        },
        {
            field: "sku",
            headerName: t("sku"),
            flex:1,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                const handleClick = (event) => {
                    event.stopPropagation(); // Prevent click from propagating to the row
                    getProductDetails(params.row.product);
                };

                return (
                    <Box 
                        onClick={handleClick}
                        sx={{
                            width:1,
                            height:"100%",
                            display:"flex",
                            alignItems:"center",
                            ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.product?.sku ?? ""}</Text>
                    </Box>
                );
            },
        },
        {
            field: "description",
            headerName: t("description"),
            flex:2,
            renderCell : (params) => {
                if(params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                return (
                    <Text>{params.row.product?.description ?? ""}</Text>
                );
            },
        },
        {
            field: "order",
            headerName: t("order"),
            flex:1,
            renderCell : (params) => {
                if (params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                const handleClick = (event) => {
                    event.stopPropagation(); // Prevent click from propagating to the row
                    getOrderDetails(params.row.order);
                };

                return (
                    <Box 
                        onClick={handleClick}
                        sx={{
                            width:1,
                            height:"100%",
                            display:"flex",
                            alignItems:"center",
                            ".copyel": {
                            opacity:0,
                        },
                        "&:hover .copyel": {
                            opacity:1,
                        }
                    }}>
                        <Text clickable semibold variant={theme.palette.mode === "dark" ? "blue" : "primary"}>{params.row.order?.barcode ?? ""}</Text>
                    </Box>
                );
            },
        },
        {
            field: "location",
            headerName: t("location"),
            flex:1,
            sortable:false,
            renderCell : (params) => {
                if (params.row.skeleton) {
                    return <ShTextSkeleton/>
                }

                const handleClick = (event) => {
                    event.stopPropagation(); // Prevent click from propagating to the row
                    getLocationDetails(params.row.stock?.warehouseLocation);
                };
                return (
                    <Box onClick={handleClick}>
                        <ShLabel clickable size={32} fitted variant={"yellow"}>
                            {params.row.stock?.warehouseLocation?.location ?? ""}
                        </ShLabel>
                    </Box>
                )
            },
        },
        {
            field: "phoneView", 
            headerName: "PhoneView",
            cellClassName: "phone-column--cell",
            renderCell: (cellValues) => {
                // console.log(cellValues.row);
                return (
                    <WarehouseProductCard onClick={() => getProductDetails(cellValues)} skeleton={cellValues.row.skeleton ?? false} {...cellValues.row}/>
                    // <Box
                    //     sx={{
                    //         background:`${theme.palette.mode === "dark" ? colors.grey[300] : colors.grey[100]}`,
                    //         paddingX:2,
                    //         paddingY:1.5,
                    //         borderRadius:2
                    //     }}
                    // >

                    // </Box>
                )
            }
        }
    ];

    return (
        <Box height={1} display={"flex"} flexDirection={"column"}>
            <ShDataGrid2 key={theme.palette.mode}
                content={useGetReplenishLinesQuery}
                config={config}
                gridActions={<GridActions handleOpen={handleOpen} hasCreatePermission={hasCreatePermission}></GridActions>} 
                onRowClick={getProductDetails}
                title={t('overview')} 
                gridOptions={gridOptions} 
                sortModel={{field: columns[0].field,type:"ASC"}}
                columns={columns}>
            </ShDataGrid2>
            {spStockLoading && 
                <SpStockDetails
                    isExtended={isExtended}
                    setIsExtended={setIsExtended}
                    productId={productId}
                />
            }
            {spLocationLoading && 
                <SPLocationDetails
                    isExtended={locationIsExtended}
                    setIsExtended={setLocationIsExtended}
                    locationId={locationId}
                />
            }
            {spOrderLoading && 
                <SPOrderDetails
                    isExtended={orderIsExtended}
                    setIsExtended={setOrderIsExtended}
                    orderId={orderId}
                />
            }
        </Box>
    );
}
export default ProductOverview;