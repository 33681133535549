import { Box, ButtonBase, InputBase, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFindPickingJobMutation, useFindPickingJobsQuery, useStartFindOrdersMutationMutation } from "../../../newapi/global/echeckSlice";
import { useGetSettingsQuery } from "../../../newapi/global/generalApiSlice";
import { updateMenuState } from '../../../newapi/global/settingsSlice';
import { tokens } from "../../../theme";
import OrdersEcheckView2 from './orders/OrdersEcheckView2';
import Subheading from "../../../components/theme/text/Subheading";
import ShLabel from "../../../components/theme/label/ShLabel";
import Text from "../../../components/theme/text/Text";
import { getPickingJobStateData } from "../../../utils/labelColorText";
import { useTopbar } from "../../../contexts/TopbarProvider";
import cx from 'classnames';

const Echeck = () => {
    const { t, ready } = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode); 
    const dispatch = useDispatch();
    const [showEcheck, setShowEcheck] = useState(false);
    const [scannedValue, setScannedValue] = useState('none');
    const [highlightColor, setHighlightColor] = useState('none');
    const { showExtraComponent, clearExtraComponent } = useTopbar();
    const [incData, setIncData] = useState();

    // Use mutation instead of query
    const [startFindOrders, { data: startFindOrdersData, isLoading,isFetching, isError }] = useStartFindOrdersMutationMutation();
    const { data: eCheckSettings} = useGetSettingsQuery("echeck");
    const autoFinalize = eCheckSettings?.find(setting => setting.key === 'echeck_auto_finalize')?.value === "true";
    const carrierMandatory = eCheckSettings?.find(setting => setting.key === 'echeck_carrier_mandatory')?.value === "true";

    const selectedWorkSpace = useSelector((state) => state.settings.workSpace);

    const {data: jobsData, isLoading: jobsLoading, isFetching:jobsFetching} = useFindPickingJobsQuery({
        workspaceId: selectedWorkSpace.id
    },{skip: !selectedWorkSpace});

    const [getMyJob, {data: individualJob, isLoading: invididualJobLoading}] = useFindPickingJobMutation();

    const [stateVisibility, setStateVisibility] = useState({
        completed: false, // Initially echeck is hidden
        fullypicked: true, // Show completed by default
        in_progress: true, // Show in_progress by default
      });

    const stateOrder = ['completed', 'fullypicked', 'in_progress'];  // Define the desired order of states

    // Grouping based on state
    const groupedData = useMemo(() => {
        return jobsData?.pickingJobs.reduce((acc, item) => {
            // Check if the state group exists, if not, create an empty array for that state
            if (!acc.groups[item.state]) {
                acc.groups[item.state] = [];
            }

            // Push the item to the appropriate state group
            acc.groups[item.state].push(item);

            // If the state is not already in the secondary array, push it
            if (!acc.states.includes(item.state)) {
                acc.states.push(item.state);
            }

            return acc;
        }, { groups: {}, states: [] });
    }, [jobsData]);


    // Sort states based on the predefined order (you can modify it according to your needs)
    const sortedStates = groupedData?.states.sort((a, b) => {
        return stateOrder.indexOf(a) - stateOrder.indexOf(b);
    });


    useEffect(() => {
        // Example: show different components based on user privileges
        showExtraComponent(
            <Box
                className={cx('',{
                    'not-selected': selectedWorkSpace === null,
                    // 'not-selected': p,
                })}
                sx={{
                    height:40,
                    background:theme.palette.mode === "dark" ? colors.blue[800] : colors.primary[400],
                    borderRadius:5,
                    color:theme.palette.mode === "dark" ? colors.txt["primaryDark"] : colors.txt["white"],
                    fontSize:14,
                    fontWeight:"bold",
                    fontFamily:"Roboto, sans-serif",
                    lineHeight:"40px",
                    px:2,
                    "&.not-selected" : {
                        background:colors.red[400],
                        animation: 'pulse 1.5s infinite',
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(1)',
                          },
                          '50%': {
                            transform: 'scale(1.2)',
                          },
                          '100%': {
                            transform: 'scale(1)',
                          },
                        },
                    }
                }}
            >
                {selectedWorkSpace !== null ? selectedWorkSpace.name : t("no_selection")}
            </Box>
        );
  
        return () => {
            clearExtraComponent();
        };
    }, [selectedWorkSpace]);

    // console.log(groupedData);
    
    useEffect(() => {
        if(window.innerWidth > 1369){
            dispatch(updateMenuState(true));
        }
    },[])
    
    const handleGetPickingJobOrOrderToEcheck = async (e) => {
        if (e.key === 'Enter') {
            startFindOrders(e.target.value)
            .unwrap()
            .then(result => {
                setIncData(result);
                setScannedValue(e.target.value);
                setShowEcheck(true);
            })
            .catch(error => {
                setHighlightColor('red');
                toast.error(error.data?.message ??  `Scan een geldige krat of pickbon`, {
                    position: "bottom-left",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
        }
    }

    const getPickingJob = async (id) => {
       await getMyJob(id).unwrap()
        .then(result => {
            // console.log(result);
            setIncData(result);
            setScannedValue("");
            setShowEcheck(true);
        })
        .catch(error => {
            setHighlightColor('red');
            toast.error(error.data?.message ??  `Scan een geldige krat of pickbon`, {
                position: "bottom-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        });
    }

    useEffect(() => {
        if (highlightColor !== 'none') {
            setTimeout(() => setHighlightColor('none'), 500); // Duration to stay colored
        }
    }, [highlightColor]);


    const goBack = () => {
        setScannedValue(null);
        setShowEcheck(false);
    }

    const getBackgroundColor = () => {
        switch (highlightColor) {
            case 'orange':
                return colors.orange[400];
            case 'green':
                return colors.green[400];
            case 'red':
                return colors.red[400];
            default:
                return 'initial'; // or your default color
        }
    };
    


    return (
        <Box 
            height={1} 
            display={"flex"} 
            width={1} 
            gap={4} 
            paddingX={4} 
            paddingTop={13} 
            overflow={"auto"}
            sx={{
                // overflow:"hidden",
                "@media screen and (max-width: 47.9375em)" : {
                    
                },
                background: getBackgroundColor(), // You can use your original color instead of 'initial' if needed
                transition: 'background-color 0.5s'
            }}
        >

            {/* List content body */}
            {!showEcheck &&
                <Box
                    sx={{
                        flex:1,
                        display:"flex",
                        gap:4,
                    }}
                >
                    <Box
                        sx={{
                            minWidth:325,
                            overflow:"hidden",
                        }}
                    >
                        <Subheading>{t("to_process")}</Subheading>
                        <Box
                            sx={{
                                display:"flex",
                                gap:1.5,
                                mt:2,
                            }}
                        >
                            {stateOrder.map((state, idx) => (
                                <ShLabel
                                    onClick={() => {
                                        setStateVisibility((prevState) => ({
                                          ...prevState,
                                          [state]: !prevState[state],  // Toggle the visibility of the clicked state
                                        }));
                                    }}
                                    variant={stateVisibility[state] ? getPickingJobStateData(state,theme).labelcolor : theme.palette.mode === "dark" ? "greyLight" : "grey"}
                                    key={idx}
                                    clickable
                                    fitted
                                    borderless
                                    size={32}
                                    palette="normal"
                                >
                                    {t(state)}
                                </ShLabel>
                            ))}
                        </Box>

                        <Box
                            sx={{
                                height:760,
                                overflow:'auto',
                                mt:3,
                            }}
                        >
                            {groupedData && groupedData.groups &&
                                sortedStates.map((state) => (
                                    <Box key={state}
                                        sx={{
                                            display: stateVisibility[state] ? 'block' : 'none', // Hide/show based on visibility
                                        }}
                                    >
                                        <Text bold variant={getPickingJobStateData(state,theme).labelcolor}>{t(state)}</Text>
                                        {/* <ul> */}
                                        <Box
                                            sx={{
                                                display:"flex",
                                                flexDirection:"column",
                                                gap:1.5,
                                                mb:3,
                                                mt:1,
                                            }}
                                        >
                                            {groupedData.groups[state].map((item) => (
                                                <ButtonBase
                                                    onClick={() => {
                                                        getPickingJob(item.id);
                                                    }}

                                                    sx={{
                                                        display:'flex',
                                                        boxShadow:"0px 2px 4px rgba(49,65,95,.15)",
                                                        border:`1px solid ${colors.grey[100]}`,
                                                        p:2,
                                                        borderRadius:4,
                                                        justifyContent:"space-between",
                                                        alignItems:"center",
                                                        background: theme.palette.mode === 'dark' ? colors.grey[300] : colors.grey[0]
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            // display:'flex',
                                                            // justifyContent:"space-between",

                                                        }}
                                                    >
                                                        <Text semibold>
                                                            {item.barcode}
                                                        </Text>
                                                        <Box
                                                            sx={{
                                                                mt:0.5,
                                                                display:'flex',
                                                                // justifyContent:"space-between",

                                                            }}
                                                        >
                                                            <Text light>{t("orders")}</Text> &nbsp;
                                                            <Text bold>{item.orderCount}</Text>
                                                        </Box>
                                                    </Box>
                                                    
                                                    <ShLabel
                                                        key={theme.palette.mode}
                                                        onClick={() => {
                                                            setStateVisibility((prevState) => ({
                                                            ...prevState,
                                                            [state]: !prevState[state],  // Toggle the visibility of the clicked state
                                                            }));
                                                        }}
                                                        variant={getPickingJobStateData(state,theme).labelcolor}
                                                        clickable
                                                        fitted
                                                        // borderless={!stateVisibility[state]}
                                                        size={32}
                                                        palette="normal"
                                                        borderless
                                                        sx={{
                                                            flexShrink:0,
                                                        }}
                                                    >
                                                        {t(state)}
                                                    </ShLabel>
                                                    {/* <ShLabel
                                                        clickable
                                                        fitted
                                                        borderless
                                                        size={32}
                                                    >
                                                        {state === "completed" ? t("picked") : t(state)}
                                                    </ShLabel> */}

                                                </ButtonBase>
                                                // <li key={item.id}>
                                                //     {/* Display the properties of each item */}
                                                //     {item.id} - {item.state} - {item.total_products}
                                                // </li>
                                            ))}
                                        </Box>
                                        {/* </ul> */}
                                    </Box>
                                ))
                            }
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            flex: 1,
                            background: theme.palette.mode === "dark" ? colors.grey[300] : colors.bg["tertiary"],
                            borderTopRightRadius: 16,
                            borderTopLeftRadius: 16,
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            paddingBottom: 3,
                            justifyContent: 'center',  // To center contents vertically
                            alignItems: 'center',      // To center contents horizontally
                            gap: 2 // Gap between items
                        }}
                    >
                        <img 
                            src="/img/barcode.svg" 
                            alt="Barcode" 
                            style={{
                                width: '30%', 
                                height: '30%', 
                                objectFit: 'contain',
                                animation: 'pulsate 1.5s infinite'
                            }} 
                        />

                        <Box display="flex"
                            height="32px"
                            // maxWidth="400px"
                            borderRadius="20px"
                            backgroundColor={colors.grey[100]}
                            sx={{
                                marginTop:0.5,
                                marginBottom:0.5,
                                justifyContent:"space-between",
                                "@media screen and (max-width: 47.9375em)" : {
                                    flexDirection:"column"
                                }
                            }}
                        >
                            <InputBase sx={{ml: 2, flex:1, width: 248, textAlign: 'center', justifyContent: 'center'}} placeholder={t("scan_type_barcode")}
                                onKeyDown={handleGetPickingJobOrOrderToEcheck}
                                autoFocus={true}
                            />
                        </Box>
                    </Box>
                </Box>
            }

            {showEcheck && 
                <OrdersEcheckView2
                    firstScannedBarcode={scannedValue}
                    setHighlightColor={setHighlightColor}
                    incData={incData}
                    autoFinalize={autoFinalize}
                    carrierMandatory={carrierMandatory}
                    selectedWorkSpace={selectedWorkSpace}
                    goBack={goBack}
                    setIncData={setIncData}
                    setShowEcheck={setShowEcheck}
                />
            }
        </Box>
    );
};

export default Echeck;