import { Box, InputBase, List, useTheme,ToggleButton, ToggleButtonGroup } from "@mui/material"
import { useEffect, useState } from "react";

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import AddIcon from '@mui/icons-material/Add';
import { tokens } from "../../../theme";
import SHModal from "../../theme/Modal/SHModal";
import Shbutton from "../../theme/buttons/Shbutton";
import Subheading from "../../theme/text/Subheading";
import Text from "../../theme/text/Text";
import NumberInput from "../../theme/inputs/NumberInput";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify"; 
import cx from "classnames"
import {checkAllPropertiesTruthy } from "../../../utils"
import { useAddNewSupplierMutation } from "../../../newapi/global/supplier/supplierSlice";
import { showToastMessage, showToastMessagePostRequest, showToastMessageRequestError, showToastMessageUpdateRequest } from "../../../utils/toasts";
import * as yup from "yup";
import ShValidatedInput from "../../theme/inputs/ShValidatedInput";
import { yupRequired } from "../../../utils/validation";
import SelectGroup from "../../theme/Select/SelectGroup";
// import { required } from '../../utils/validation';
import { ReactComponent as DhlLogo } from '../../../styles/svg/dhl.svg';
import { ReactComponent as DpdLogo } from '../../../styles/svg/dpd.svg';
import { ReactComponent as PostNLLogo } from '../../../styles/svg/postnl.svg';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import SplitOrderLineList, { SplitOrderLineListMultiple } from "../../splitorder/SplitOrderBox";
import ButtonGroup from "../../theme/buttons/ButtonGroup";
import {
    DndContext,
    DragOverlay,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    MouseSensor,
    TouchSensor,
  } from "@dnd-kit/core";
  import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { act } from "react";
import SplitOrderLine from "../../splitorder/SplitOrderLine";
import { useGetOrderLinesQuery, useSplitOrderMutation } from "../../../newapi/order/ordersSlice";

const MSplitOrder = (props) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    let isStandAlone = false;
    if (window.matchMedia('(display-mode: standalone)').matches) {
        isStandAlone = true;
    }
    
    const [products, setProducts] = useState([]);
    const [fieldErrors, setFieldErrors] = useState([]);
    const {
        data,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } = useGetOrderLinesQuery({args : {
        ...(props.orderId && {id:props.orderId}),
        page:1,
        size:100,
        body:{}
    }}, {
        skip: !props.orderId
    });

    useEffect(() => {
        if(data && data.data) {
            setProducts(data.data.map((line,index) => {
                return {
                    orderLineId: line.id,
                    id: line.product.id, //productid
                    currAmount: line.amount,
                    amount:0, //toMove
                    title:line.product.description,
                    sku:line.product.sku
                }
            }));
        }
    }, [data])

    const [splitOrder, {splitOrderIsLoading}] = useSplitOrderMutation();
    const saveProducts = async () => {
        setFieldErrors([]);
        let errors = products.filter(product => product.amount > product.currAmount);
        if(errors.length > 0) { 
            setFieldErrors(errors);
            return;
        }
        try {
            let toSave = products.filter(product => product.amount > 0);
            const resp = await splitOrder({id: props.orderId, products: toSave});
            console.log(resp);
            if(resp.error){
                showToastMessageRequestError(t("split_order_failed"), t, resp.error);
            }else {
                showToastMessagePostRequest(t, resp.data);
                props.handleClose();
                
            }
        } catch (err) { 
            console.log(err);
            // showToastMessageRequestError
            showToastMessageRequestError(t("split_order_failed"), t, err);
        }
    }
   
    // const [EditPickingLine, {pickingLineIsLoading}] = useUpdateOrderPickingLineMutation();
    // const handlePickingLineLocationUpdate = async (params, stockObj) => {
    //     try {
    //         const response = await EditPickingLine({id: params.id, orderId: params.orderId, stockId: stockObj.id}).unwrap();
    //         showToastMessageUpdateRequest(t, response);
    //     } catch (err) {
    //         console.log(err);
    //         showToastMessageUpdateRequest(t, {success: false, error: err.error});
    //     }
    // };

    return(
        <SHModal
            open={props.open}
            onClose={() => props.handleClose(false)}
        >
                <Box 
                    sx={{
                        textAlign:"center",
                        paddingY:4,
                        paddingX:5,
                        display:"flex",
                        flexDirection:"column",
                        gap:1
                    }}>
                    <Subheading>{t("split_order")}</Subheading>
                    {/* <Text light>The data presented will automatically be filtered through the selected warehouses</Text> */}
                </Box>
                
                {/* BODY */}
                <Box
                    sx={{
                        background:theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0],
                        padding:3,
                        pb:1,
                        borderTopLeftRadius:16,
                        borderTopRightRadius:16,
                        display:"flex",
                        width:1,
                        gap:2,
                        flexDirection:"column"
                        // overflow:"scroll"
                    }}
                >
                    {products.map((product,index) => (
                        <SplitOrderLine     
                            setProducts={(index, newValue, setToZero) => {
                                // Create a new array with the updated product
                                const newProducts = [...products];
                                newProducts[index] = { ...newProducts[index], amount: parseInt(newValue) };
                                setProducts(newProducts);
                            }}
                            isError={fieldErrors.findIndex(error => error.orderLineId === product.orderLineId) > -1}
                           key={product.orderLineId} 
                           index={index}
                           product={product} 
                        />
                    ))}
                </Box>

                <Box
                    component="footer"
                    sx={{
                        display:"block",
                        width:1,
                        paddingBottom: isStandAlone ? "32px" : "16px",
                        display:"flex",
                        alignItems:"center",
                        justifyContent:"space-between",
                        paddingRight:3,
                        zIndex:1058,
                        position:"relative",
                        paddingTop:2,
                        background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                        borderBottomLeftRadius:24,
                        borderBottomRightRadius:24,
                        "@media screen and (max-width: 47.9375em)" : {
                            paddingRight:0,
                        }
                    }}
                >
                    <Shbutton isCancel={true} className={"always-visible"} onClick={() => props.handleClose(false)} color="primary" variant="text" startIcon={<ArrowRightAltIcon/>}>{t("cancel")}</Shbutton>
                    <ButtonGroup version={2}>
                        <Shbutton className={"group-btn"} color={"primary"}
                            block
                            variant="contained" 
                            onClick={() => saveProducts()}
                            // onClick={() => props.handleClose(true, selected)}
                        >{t("save")}</Shbutton>
                    </ButtonGroup>
                </Box>
        </SHModal>
        
    )
}

export default MSplitOrder;
