
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';

import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import InputIcon from '@mui/icons-material/Input';
import WarehouseIcon from '@mui/icons-material/Warehouse';

export const warehouseInboundInboundsConfig = (isFulfilment) => {
    return {
        inbounds:  {
            title: 'all_inbounds',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        inboundLines:  {
            title: 'all_inboundlines',
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        returns:  {
            title: 'all_returns', // Changed from all_inbounds to all_returns
            icon: <WarehouseIcon/>,
            isSingleDataGrid: true,
        },
        ...(isFulfilment && {
            customerInbounds:  {
                title: 'customer_inbounds',
                icon: <WarehouseIcon/>,
                isSingleDataGrid: true,
            },
        })
    };
};
