import { Box, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import Subheading from "../../../../components/theme/text/Subheading";
import Text from "../../../../components/theme/text/Text";
import { tokens } from "../../../../theme";

import OverflowRow from "../../../../components/theme/OverflowRow";
import Inbounds from "../../../../environments/warehouse/inbound/dashboard/views/Inbounds";

import { useTranslation } from "react-i18next";
import Heading from "../../../../components/theme/text/Heading";
import { useGetInboundDashboardQuery } from "../../../../newapi/reports/fulfillment/reportFulfillmentSlice";
import { InboundChart } from "../../../../components/charts/inbound/fulfillment/InboundChart";
import TabView3 from "../../../../components/theme/TabView3/TabView3";
import { warehouseInboundDashboardConfig } from "./warehouseInboundDashboardConfig";


const InboundDashboard = () => {
    const {t,ready} = useTranslation();

    
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    
    const [isExtended, setIsExtended] = useState(false);

    const [data, setData] = useState({});
    const [counts, setCounts] = useState({
      twoDaysAgo: 0,
      yesterday: 0,
      today: 0,
      tomorrow: 0
    });

    const {
        data: inboundData,
        isLoading,
        isFetching,
        isSuccess,
        isError,
        error,
        refetch
    } =useGetInboundDashboardQuery();

    useEffect(() => {
        if (inboundData) {
            //Chart data
            setData(inboundData);
    
            const todayDate = new Date();

            const yesterdayDate = new Date(todayDate);
            yesterdayDate.setDate(yesterdayDate.getDate() - 1);

            const twoDaysAgoDate = new Date(todayDate);
            twoDaysAgoDate.setDate(twoDaysAgoDate.getDate() - 2);

            const tomorrowDate = new Date(todayDate);
            tomorrowDate.setDate(tomorrowDate.getDate() + 1);
    
            const formatDate = date => {
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
                return `${day}-${month}`;
            };
            
            //For boxes in the top right
            setCounts({
                twoDaysAgo: inboundData[formatDate(twoDaysAgoDate)] || 0,
                yesterday: inboundData[formatDate(yesterdayDate)] || 0,
                today: inboundData[formatDate(todayDate)] || 0,
                tomorrow: inboundData[formatDate(tomorrowDate)] || 0
            });
        }
    }, [inboundData]); // Watch for changes in data

    const dates = Object.keys(data); // Assuming data is an object with dates as keys
    const values = dates.map(date => data[date]);
    const chartData = [{
        name: 'Inbounds',
        data: values
      }];

    return (
        <Box sx={{background:"", width:1,paddingTop:9}}>
            {ready ?
                <>
                    <OverflowRow>
                        <Box
                            sx={{
                                
                                display:"grid",
                                gridTemplateColumns:"repeat(1, 1fr)",
                                gridTemplateRows:"repeat(2, 1fr)",
                                columnGap:3,
                                rowGap:3,
                                flex:1 ,
                                "@media screen and (max-width: 64em)" : {
                                    minWidth:"calc(100% - 56px)",
                                    // minWidth:1,
                                },
                                "@media screen and (max-width: 47.9375em)" : {
                                    minWidth:"calc(100% - 48px)",
                                    // minWidth:1,
                                }
                            }}>
                                <Box
                                    sx={{
                                        background:`${colors.green[400]}`,
                                        borderRadius:4,
                                        padding:3,
                                    }}
                                >
                                    <Subheading variant={theme.palette.mode === "dark" ? "grey" : "white"} fontWeight="normal">{t("today")}</Subheading>
                                    <Box
                                        sx={{
                                            paddingTop:3,

                                        }}
                                    >
                                        <Heading color={theme.palette.mode === "dark" ? "#31415f" : "#fff"}>{counts.today}</Heading>
                                        {/* <Heading>870</Heading> */}
                                        <Box>
                                            <Text variant={theme.palette.mode === "dark" ? "grey" : "white"}>vs</Text>&nbsp;
                                            <Text variant={theme.palette.mode === "dark" ? "grey" : "white"} bold>{counts.tomorrow}</Text>&nbsp;
                                            <Text variant={theme.palette.mode === "dark" ? "grey" : "white"} >{t("tomorrow")}</Text>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{
                                        // width:0.5,
                                        background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                        borderRadius:4,
                                        padding:3,
                                    }}
                                >

                                    <Subheading fontWeight="normal">{t("yesterday")}</Subheading>
                                    <Box
                                        sx={{
                                            paddingTop:3,

                                        }}
                                    >
                                        <Heading>{counts.yesterday}</Heading>
                                        <Box>
                                            <Text light>vs</Text>&nbsp;<Text>{counts.twoDaysAgo}</Text>&nbsp;<Text light>{t("two_days_ago")}</Text>
                                        </Box>
                                    </Box>
                                </Box>
                                
                        </Box>

                        <InboundChart
                            categories={dates}
                            values={chartData}
                            refetch={refetch}
                            isLoading={isLoading || isFetching}
                            isError={isError}
                        />
                        
                        {/* <Box
                            sx={{

                                overflow:"hidden",
                                flex:3,
                                "@media screen and (max-width: 47.9375em)" : {
                                    minWidth: "calc(100% - 48px)"
                                }
                            }}
                        >

                            <AreaChart 
                                title={t("forecasted_and_received")}
                                subtitle="Per land"
                                type="bar"
                                colors={['primary']}
                                categories={dates}
                                values={chartData}
                                color={"default"}
                                tickAmount={30}
                            />
                        </Box> */}
                        {/* <Box
                            sx={{
                                padding:3,
                                paddingLeft:2,
                                paddingBottom:1,
                                background:`${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                flex:3,
                                borderRadius:4,
                                display:"flex",
                                flexDirection:"column",
                                position:'relative',
                                "@media screen and (max-width: 64em)" : {
                                    minWidth:"calc(100% - 48px)",
                                    width:500
                                }
                            }}
                            onClick={() => setIsExtended(true)}
                        >
                            
                            <Box
                                sx={{
                                    paddingLeft:1,
                                    paddingBottom:1
                                }}
                            >
                                <Subheading>{t("forecasted_and_received")}</Subheading>
                            </Box>
                            <Box
                                sx={{
                                    flex:1,
                                }}
                            >

                                    <AreaChart 
                                        title="Vervoerder prestaties"
                                        subtitle="Per land"
                                        type="bar"
                                        colors={['primary']}
                                        categories={dates}
                                        values={chartData}
                                        color={"default"}
                                        tickAmount={30}
                                    />
                            </Box>
                        </Box> */}
                    </OverflowRow>

                    <TabView3
                        tabConfig={warehouseInboundDashboardConfig}
                        sx={{
                            background:"green",
                            minHeight:"calc(100% - 431px)",
                            background: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                            position:"relative",
                            zIndex:2,

                            display:"flex",
                            flexDirection:"column",

                            "&:before" : {
                                content: "''",
                                display:"block",
                                width:"32px",
                                height:"32px",
                                position:"absolute",
                                left:0,
                                top:0,
                                zIndex:1,
                                backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                top:"40px",
                                height:"48px",
                                width:"24px",
                                borderTopLeftRadius:"24px",
                                boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                                transition:"250ms"
                            },

                            "&:after" : {
                                content: "''",
                                display:"block",
                                width:"32px",
                                height:"32px",
                                position:"absolute",
                                top:0,
                                zIndex:1,
                                backgroundColor: `${theme.palette.mode === "dark" ? colors.grey[200] : colors.grey[0]}`,
                                top:"40px",
                                right:0,
                                height:"48px",
                                width:"24px",
                                borderTopLeftRadius:"24px",
                                boxShadow: `0 -24px 0 0 ${colors.bg["tertiary"]}`,
                                transform:"scaleX(-1)",
                                transition:"250ms"
                            },

                            "@media screen and (max-width: 47.9375em)" : {
                                "&:after, &:before" : {
                                    top:"-0px"
                                }
                            }
                        }}
                        title={t("inbound")}
                       
                    >
                        <>
                            <Inbounds config={warehouseInboundDashboardConfig.inbounds} />
                        </>
                    </TabView3>
                </> 
            : null }
        </Box>
    );
};

export default InboundDashboard;